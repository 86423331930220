import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-policy',
  templateUrl: './pricing-policy.component.html',
  styleUrls: ['./pricing-policy.component.css']
})
export class PricingPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
