import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RsbService } from 'src/app/service/rsb.service';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
function panValidator(control: FormControl): { [s: string]: boolean } | null {
  const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  if (control.value && !panPattern.test(control.value)) {
    return { 'invalidPAN': true };
  }
  return null;
}
@Component({
  selector: 'app-mb-cibil',
  templateUrl: './mb-cibil.component.html',
  styleUrls: ['./mb-cibil.component.css']
})
export class MbCibilComponent implements OnInit {
  cibilForm:FormGroup
  awsFile: FileList;
  propertyImages='';
  getCibilListings:any;
  isShow:boolean=false;
  closeAddPage:boolean=true;

  constructor(
    private fb:FormBuilder,
    private rsbService:RsbService,
    private modalService: NgbModal,
    private toastr: ToastrService


  ) { }

  ngOnInit(): void {
    this.cibilForm=this.fb.group({
    panNum:['',[Validators.required, panValidator]],
    name:['',[Validators.required]],
    mobile:['',[Validators.required]],
    loanAmount:['',[Validators.required]]


    })
    this.cibilForm.get('panNum').valueChanges.subscribe(value => {
      if (value) {
        this.cibilForm.get('panNum').setValue(value.toUpperCase(), { emitEvent: false });
      }
    });

   this.getCibilRequest();
  }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      console.log('Selected file:', file);
    }
  }
  addPage(): void{
    this.isShow=true;
  }
  closePage(): void{
    this.isShow=false;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      const file = files[0];
      console.log('Dropped file:', file);
    }
  }
  // onFileSelected(event: any) {
  //   const file: File = event.target.files[0];
  //   if (file) {
  //     console.log('Selected file:', file);
  //   }
  // }
  uploadAws(event: any, fieldName?: string): void {
    this.awsFile = event.target.files;
    const file = this.awsFile.item(0);
    const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
    modalRef.componentInstance.file = file;
    modalRef.result.then((path: string) => {
      if (path) {
        this.propertyImages = path;
      }
    
    });
  }

  getCibilRequest(): void {
    this.rsbService.getCibilRequest().subscribe((res)=>{
      if(res.statusCode === 0 && res.contents.length) {
      this.getCibilListings=res.contents;
      } else {

      }
    })

  }
  submit(): void{
    if (this.cibilForm.invalid) {
      Object.values(this.cibilForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    const args={
      panNumber:this.panNum.value,
      name:this.name.value,
      mobile:this.mobile.value,
      loanAmount:this.loanAmount.value,
      cibilScoreFile:this.propertyImages,

    }
    this.rsbService.cibilScore(args).subscribe((res)=>{
      if(res.statusCode===0){
        this.toastr.success('Submitted successfully.');

        this.getCibilRequest();
        this.cibilForm.reset();
      }
    })
   
  }
  resetForm(){
    this.cibilForm.reset();
  }
  get panNum() { return this.cibilForm.get('panNum'); }
  get mobile() { return this.cibilForm.get('mobile');}
  get name() { return this.cibilForm.get('name');}
  get loanAmount() { return this.cibilForm.get('loanAmount');}


}
