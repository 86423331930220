import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
declare var jQuery: any;

@Component({
  selector: 'app-leads-for-properties',
  templateUrl: './leads-for-properties.component.html',
  styleUrls: ['./leads-for-properties.component.css']
})
export class LeadsForPropertiesComponent implements OnInit {
  moduleList: Array<any> = [];
  isMobileLayout:boolean=false;
  getMylead: any;
  getMyViewLead: any;
  getMyLeadsData: boolean = true;
  isviewLeads: boolean = false;
  countData:any;
  isNavOpen = false;
  navSideBar:boolean=false;
  isEmiOption:boolean=true;
  // selectedModuleInTable:any;
  
  selectedModule: string = 'properties';  // Default to 'properties'
  selectedLeadType: string = 'leads'; 
  tableRecords:any;


  constructor(
    private rsbService: RsbService,
    private rsbLookup: RsbLookupModel,
    private route: Router,
    private renderer: Renderer2) { }
  Postproperty(route: any) {
    this.route.navigate([route]);
  }
  Applyloan(route: any) {
    this.route.navigate([route]);
  }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    console.log("my lead")
    console.log(JSON.stringify(this.getMylead))
    console.log(this.rsbLookup.getLoggedUser())
   this.getDashboardCount()
     this.loadModuleList();

    setTimeout((obj) => {
      this.getMyleads();
    }, 1000)
    this.callApiBasedOnSelection();

    
    //this.getMyViewLeads();
  }
 

  // getMyleads() {
  //   this.rsbService.myLeads().subscribe((rsp) => {
  //     this.getMylead = rsp.contents;


  //   })

  // }

  // ngAfterViewInit() {
  //   (function($) {
  //     alert("getting");
  //     var $main_nav = $('#main-nav');
  //     var $toggle = $('.toggle');
  
  //     var defaultData = {
  //       maxWidth: false,
  //       customToggle: $toggle,
  //       levelTitles: true,
  //       insertClose: !0,
  //       closeLevels: false
  //     };
  
  //     // add new items to original nav
  //     $main_nav.find('li.add').children('a').on('click', function() {
  //       var $this = $(this);
  //       var $li = $this.parent();
  //       var items = eval('(' + $this.attr('data-add') + ')');
  
  //       $li.before('<li class="new"><a>'+items[0]+'</a></li>');
  
  //       items.shift();
  
  //       if (!items.length) {
  //         $li.remove();
  //       }
  //       else {
  //         $this.attr('data-add', JSON.stringify(items));
  //       }
  
  //       Nav.update(true);
  //     });
  
  //     // call our plugin
  //     var Nav = $main_nav.hcOffcanvasNav(defaultData);
  
  //     // demo settings update
  
  //     const update = (settings) => {
  //       if (Nav.isOpen()) {
  //         Nav.on('close.once', function() {
  //           Nav.update(settings);
  //           Nav.open();
  //         });
  
  //         Nav.close();
  //       }
  //       else {
  //         Nav.update(settings);
  //       }
  //     };
  
  //     $('.actions').find('a').on('click', function(e) {
  //       e.preventDefault();
  
  //       var $this = $(this).addClass('active');
  //       var $siblings = $this.parent().siblings().children('a').removeClass('active');
  //       var settings = eval('(' + $this.data('demo') + ')');
  
  //       update(settings);
  //     });
  
  //     $('.actions').find('input').on('change', function() {
  //       var $this = $(this);
  //       var settings = eval('(' + $this.data('demo') + ')');
  
  //       if ($this.is(':checked')) {
  //         update(settings);
  //       }
  //       else {
  //         var removeData = {};
  //         $.each(settings, function(index, value) {
  //           removeData[index] = false;
  //         });
  
  //         update(removeData);
  //       }
  //     });
  //   })(jQuery);
  // }
  getMyleads() {
   // alert("my leads"+this.moduleList.length);
    const selectedMainModuleId: Array<any> = this.moduleList.filter((obj) => {
     // alert(obj.selected);
      return obj.selected && obj.selected === true;
    })
    console.log("selectedMain moduleee is",selectedMainModuleId[0]);

    if (!selectedMainModuleId[0].moduleName.toUpperCase().includes('PG')) {
      this.rsbService.myLeads({ "mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMylead = rsp.contents;
        } else {
          this.getMylead = []
        }
      })
    } else {
      this.rsbService.myLeadsForPg({ "mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMylead = rsp.contents;
        } else {
          this.getMylead = []
        }
      }, (err) => {
        this.getMylead = []
      })
    }

  }
  toggleNav() {
    this.navSideBar = !this.navSideBar;
   
  }
  close(){
    this.navSideBar=false;
  }
  changeMainModule(module:string): void {
    this.selectedModule = module;
    //alert(module)
    this.moduleList.forEach((obj) => {
      if (module === obj.mainModuleId) {
        // alert("here");
        obj["selected"] = true;
      } else {
        obj["selected"] = false;
      }
    })
   
   if(this.isviewLeads) {
    this.getMyViewLeads();
   } else {
   // alert("getting here");
    this.getMyleads();
   }
   this.callApiBasedOnSelection();

  }
  onRadioChange(event: any) {
    this.selectedLeadType = event.target.value;
    // alert(this.selectedLeadType)
    this.callApiBasedOnSelection();
  }
  callApiBasedOnSelection() {
    //selected property
    if (this.selectedModule === 'properties' && this.selectedLeadType === 'leads') {
      this.rsbService.getMainLeadsOfAssociate().subscribe((rsp)=>{
        this.tableRecords=rsp.contents;

      })
    }

    else if(this.selectedModule === 'properties' && this.selectedLeadType === 'dealLeads'){
      this.rsbService.getLeadsForProperties().subscribe((rsp)=>{
        this.tableRecords=rsp.contents;
        // alert(JSON.stringify(this.tableRecords))
      })
    }
    else if(this.selectedModule === 'properties' && this.selectedLeadType === 'siteVisitLeads'){
      this.rsbService.getLeadsForSiteVisitProperties().subscribe((rsp)=>{
        this.tableRecords=rsp.contents;
        // alert(JSON.stringify(this.tableRecords))
      })
    }
    else if(this.selectedModule === 'properties' && this.selectedLeadType === 'emiLeads'){
      this.rsbService.getLeadsForEmiProperties().subscribe((rsp)=>{
        this.tableRecords=rsp.contents;
        // alert(JSON.stringify(this.tableRecords))
      })

    }
    else if(this.selectedModule === 'properties' && this.selectedLeadType === 'videoCall'){
      this.rsbService.getLeadsForVideocall().subscribe((rsp)=>{
        this.tableRecords=rsp.contents;
        // alert(JSON.stringify(this.tableRecords))
      })

    }
        else {
        console.log('Please select a valid module and lead type');
    }
    
    //selected project
    
    if (this.selectedModule === 'projects' && this.selectedLeadType === 'leads') {

      this.rsbService.getMainLeadsOfAssociate().subscribe((rsp)=>{
        this.tableRecords=rsp.contents;
        // alert(JSON.stringify(this.tableRecords))
      })
    }
    else if(this.selectedModule === 'projects' && this.selectedLeadType === 'dealLeads'){
      this.rsbService.getLeadsForProjects().subscribe((rsp)=>{
        this.tableRecords=rsp.contents;
        // alert(JSON.stringify(this.tableRecords))
      })
    }
    else if(this.selectedModule === 'projects' && this.selectedLeadType === 'siteVisitLeads'){
      this.rsbService.getLeadsForSiteVisitProjects().subscribe((rsp)=>{
        this.tableRecords=rsp.contents;
        // alert(JSON.stringify(this.tableRecords))
      })
    }
    else if(this.selectedModule === 'projects' && this.selectedLeadType === 'emiLeads'){
      this.rsbService.getLeadsForEmiProjects().subscribe((rsp)=>{
        this.tableRecords=rsp.contents;
        // alert(JSON.stringify(this.tableRecords))
      })

    }
        else {
        console.log('Please select a valid module and lead type');
    }

    //selected pg/hostel
    if (this.selectedModule === 'pg/hostels' && this.selectedLeadType === 'leads') {
      this.rsbService.getMainLeadsOfAssociate().subscribe((rsp)=>{
        this.tableRecords=rsp.contents;
        // alert(JSON.stringify(this.tableRecords))
      })
    }
        else {
        console.log('Please select a valid module and lead type');
    }
}

 

  getMyViewLeads() {
    const selectedMainModuleId: Array<any> = this.moduleList.filter((obj) => {
      return obj.selected && obj.selected === true;
    })
    console.log("selectedMain module is",selectedMainModuleId);
    if (!selectedMainModuleId[0].moduleName.toUpperCase().includes('PG')) {
      this.rsbService.myViewLeads({ "mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        let listings = rsp.contents[0];
        this.rsbLookup.setlistingId(listings.listingId)
        // listings.forEach((listingid)=>{
        //   this.rsbLookup.setlistingId(listingid.listingId)
        // })
        // alert(JSON.stringify(rsp.contents))
        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMyViewLead = rsp.contents;
        } else {
          this.getMyViewLead = []
        }
      })
    }
    else {
      this.rsbService.myViewLeadsForPg({"mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMylead = rsp.contents;
        } else {
          this.getMylead = []
        }
      }, (err) => {
        this.getMylead = []
      })
    }
  }

  loadModuleList(): void {
    const vendorId = this.rsbLookup.getLoggedUser().vendorTypeId;

    this.rsbService.getAllMainModules({}).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        if (rsp.contents.length > 0) {

          this.moduleList = rsp.contents;
          this.moduleList = this.moduleList.filter((data) => {

            return data.vendorTypeId.includes(vendorId)
          })
          this.moduleList.forEach((obj) => {
            obj["selected"] = false
          })
          this.moduleList[0].selected = true;
          console.log(this.moduleList[0]);
        }
        else {
          this.moduleList = [];
        }
      }
    })


  }
  getDashboardCount(){
    this.rsbService.getDashboardCount({}).subscribe((data)=>{
      this.countData=data;

    });
  }
  allLeads() {
    this.getMyLeadsData = true;
    this.isviewLeads = false;
    this.getMyleads();
  }
  viewLeads() {
    this.getMyLeadsData = false;
    this.isviewLeads = true;
    this.getMyViewLeads();

  }


}
