<div class="card padding-card t-align-l no-radius border">
    <!-- <form (ngSubmit)="publish()">
        <div class="card-body">
            <h5 class="card-title mb-4">Why do we charge for listings?</h5>
            <p>We don't make money by selling your data to third parties. That is not part of our
                business model. Charging dramatically reduces fraud, which our team monitors around
                the clock.</p>
            <p>We provide a full service. If you have any questions, you can call our customer
                service team, talk to a person, and we will help you out.</p>
        </div>

        <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2">PUBLISH</button>
    </form> -->
        <div class="container mt-4">
            <div class="row">
              <!-- Left Section with Heading and Form -->
              <div class="col-md-8">
                <h4 class="mb-2">
                    <i class="ri-file-list-3-line"></i>
                    Declaration of <span class="text-danger">Property Marketing</span> &
                  </h4>
                  <h4><span class="text-primary">Commission Rate - On Sale Of Property</span>
                  </h4>
          
                <form [formGroup]="marketingForm" (ngSubmit)="postPublihForAssociate()">
                  <!-- Marketing Permission -->
                  <div class="form-group">
                    <label>Will You Allow Nexus Partners to Market Your Property?</label>
                    <div class="form-check">
                      <input
                        type="radio"
                        formControlName="allowMarketing"
                        id="yesOption"
                        value="yes"
                        class="form-check-input"
                      />
                      <label for="yesOption" class="form-check-label">Yes</label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        formControlName="allowMarketing"
                        id="noOption"
                        value="no"
                        class="form-check-input"
                      />
                      <label for="noOption" class="form-check-label">No</label>
                    </div>
                  </div>
          
                  <!-- Commission Percentage -->
                  <div class="form-group mt-3">
                    <label for="commissionPercentage">
                      How Much Percentage Will You Share on Sale Of Property as Commission?
                    </label>
                    <input
                      type="text"
                      formControlName="commissionPercentage"
                      id="commissionPercentage"
                      placeholder="Ex: 2%"
                      class="form-control"
                    />
                  </div>
          
                  <!-- Action Buttons -->
                  <div class="mt-4">
                    <button type="button" class="btn btn-secondary" (click)="goBack()">Go Back</button>
                    <button type="submit" class="btn btn-success ml-2">Save & Submit</button>
                  </div>
                </form>
              </div>
          
              <!-- Right Section with Image -->
              <div class="col-md-4 d-flex align-items-center justify-content-center">
                <div class="image-background d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/to-do-list.png"
                    style="width: 150px; height: auto"
                    alt="Checklist Image"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          
    </div>
</div>