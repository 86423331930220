<div class="container-fluid" *ngIf="!isMobileLayout">
    <div class="pb-wrapper bg-white p-4">
        <h2 class="d-inline-block mr-3 mb-4">CIBIL Score</h2>
        
        <form class="agent-profile"  [formGroup]="cibilForm" (ngSubmit)="submit()" #cibForm="ngForm">
            <div class="form-row mb-5">
                <div class="col">
                    <label for="Name">PAN Number<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="panNum" >
                    <div *ngIf="cibilForm.get('panNum').invalid && (cibilForm.get('panNum').dirty || cibilForm.get('panNum').touched)">
                        <div *ngIf="cibilForm.get('panNum').errors?.required" class="text-danger">
                          PAN Number is required.
                        </div>
                        <div *ngIf="cibilForm.get('panNum').errors?.invalidPAN" class="text-danger">
                          PAN Number is invalid.
                        </div>
                      </div>
                </div>
                <div class="col">
                    <label for="Name">Name<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="name">
                    <div *ngIf="cibilForm.get('name').invalid && (cibilForm.get('name').dirty || cibilForm.get('panNum').touched)">
                        <div *ngIf="cibilForm.get('name').errors?.required" class="text-danger">
                          Name is required.
                        </div>
                      </div>
                      <small class="text-muted"><em style="color: green;">As per PANCARD</em></small>
                </div>
                <div class="col">
                  <label for="Agency Name">Mobile Number<span class="text-danger">*</span></label>
                  <input 
                      type="text" 
                      class="form-control" 
                      placeholder="Type here" 
                      formControlName="mobile" 
                      maxlength="10"
                      pattern="^[0-9]*$"
                  >
                  <div *ngIf="cibilForm.get('mobile').invalid && (cibilForm.get('mobile').dirty || cibilForm.get('mobile').touched)">
                      <div *ngIf="cibilForm.get('mobile').errors?.required" class="text-danger">
                          Mobile Number is required.
                      </div>
                      <div *ngIf="cibilForm.get('mobile').errors?.pattern" class="text-danger">
                          Mobile Number must contain only digits.
                      </div>
                  </div>
                  <small class="text-muted"><em style="color: green;">Linked with PANCARD</em></small>
              </div>
              <div class="col">
                <label for="Operating since">Required Loan Amount<span class="text-danger">*</span></label>
                <input 
                  type="text" 
                  class="form-control" 
                  placeholder="Type here" 
                  formControlName="loanAmount"
                >
                <div *ngIf="cibilForm.get('loanAmount').invalid && (cibilForm.get('loanAmount').dirty || cibilForm.get('loanAmount').touched)">
                  <div *ngIf="cibilForm.get('loanAmount').errors?.required" class="text-danger">
                    Loan Amount is required.
                  </div>
                </div>
              
                <!-- Display loan amount in words -->
                <small class="text-muted">
                  <em *ngIf="cibilForm.get('loanAmount')?.value">
                    {{ loanAmountInWords }}
                  </em>
                </small>
              </div>
              
              
            </div>
<!--           
            <div class="form-row mb-4">
                <div class="col-md-4">
                  <div class="drag-area">
                    <div class="icon"><i class="ri-upload-line"></i></div>
                    <div class="d-flex header">
                      <p>Drag & Drop to Upload File OR </p>
                      <label class="browse"> Browse File
                        <input type="file" (change)="onFileSelected($event)" hidden>
                      </label>
                    </div>
                  </div>
                  <p class="jpeg">( Jpeg/Png format - 10Kb Size )</p>
                </div>
              </div> -->
              <div class="form-row mb-4">
                <div class="col-md-4">
                  <!-- Drag-and-drop area for file upload -->
                  <div class="drag-area" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
                    <div class="icon"><i class="ri-upload-line"></i></div>
                    <div class="d-flex header">
                      <p (click)="fileInput.click()">Drag & Drop to Upload File OR </p>
                      <label class="browse">Browse File
                        <input type="file" #fileInput (change)="uploadAws($event)" accept="image/png, image/gif, image/jpeg" hidden>
                      </label>
                    </div>
                  </div>
                  <p class="jpeg">( Jpeg/Png format - 10Kb Size )</p>
                </div>
              
                <!-- PAN Card preview section -->
                <div class="col-md-6">
                  <h4 style="text-align: center;">PAN Card Preview</h4>

                  <div class="pan-preview"  *ngIf="imageSrc" [@fadeInOut]>
                    <img [src]="imageSrc" alt="PAN Card Preview" class="img-thumbnail" style="width: 60%; height: auto;">
                  </div>
                </div>
              </div>
              
            
              
              
            <button class="btn btn-primary pb-save" type="submit">submit</button>
            <button class="btn btn-primary pb-save ml-3 " (click)="resetForm()">Reset</button>
        </form>
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">CIBIL SCORES </h3>
                                </form>
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover agent-table">
                                <thead class="">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Client Name</th>
                                        <th>Date Added</th>
                                        <th>Mobile Number</th>
                                        <th>PAN Number</th>
                                        <th>Required Loan Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let list of getCibilListings;let i = index">
                                        <!-- <td><input type="checkbox"></td> -->
                                         
                                        <td>{{i+1}}</td>
                                        <td>{{list.name}}</td>
                                        <td>{{list.createdAt | date: 'short'}}</td>
                                        <td>{{list.mobile}}</td>
                                        <td>{{list.panNumber}}</td>
                                        <td>{{list.loanAmount}}</td>  
                                        <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Submitted" *ngIf="list" readonly></td>
                                    </tr>
                                
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Row -->
    

</div>
<app-mb-cibil *ngIf="isMobileLayout"></app-mb-cibil>
