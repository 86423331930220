<div class="mb-dashboard">
  <div class="container">
    <div class="row">
      <div style="text-align: center;">
        <h5>Welcome back!</h5>
        <h5>Let's continue your search</h5>
      </div>
      <div class="col-lg-12 my-3">
          <!-- <div class="mb_gb_pop">
          
                <ul class="nav nav-tabs">
                  <li class="nav-item" role="presentation" *ngFor="let module of lookupModel.getModuleList();">
                    <a class="nav-link" [ngClass]="{'active': module.moduleId === selectedModule.moduleId}"
                      data-bs-toggle="tab" [data-bs-target]="'#' + module.moduleName" role="tab"
                      [aria-controls]="module.moduleName" (click)="selectModule(module)"
                      [aria-selected]="module.moduleId === selectedModule.moduleId ? true : false">
                      <b>{{ module.moduleName }}</b>
                  </a>
                  
                  </li>
                </ul>
                </div>
              </div> -->


              
              <div class="mb_gb_search">
                <div class="input-group ">
                  <input type="text" class="form-control mb_searchclick" (click)="searchPropertyPage()" placeholder="Search House, Apartment, etc |"
                    aria-label="Search House, Apartment, etc |" aria-describedby="addon-wrapping">
                  <span class="input-group-text" id="addon-wrapping"><i class="ri-search-line"></i></span>
                </div>
              </div>

                 
              
            </div>
        </div>
      </div>

