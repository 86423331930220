import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OwlModule } from 'ngx-owl-carousel';
import { AgmCoreModule } from '@agm/core';
import { NguCarouselModule } from '@ngu/carousel';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from './core/loader.service';
import { InitialService } from './core/initial.service';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { UnitsComponent } from './components/projects/units/units.component';
import { LoginAssociateComponent } from './components/login-associate/login-associate.component';
import { ForgotPasswordMblComponent } from './components/mobile-layout-components/forgot-password-mbl/forgot-password-mbl.component';
import { PlotSidebarComponent } from './components/plot-and-land-in-propery-modules/plot-sidebar/plot-sidebar.component';
import { IncompleteProfilePopupAlertComponent } from './components/incomplete-profile-popup-alert/incomplete-profile-popup-alert.component';
import { PropertysidebarComponent } from './components/vendor/propertysidebar/propertysidebar.component';
import { PgSidebarComponent } from './components/PG-hostels/pg-sidebar/pg-sidebar.component';
import { RentSidebarComponent } from './components/Rent/rent-sidebar/rent-sidebar.component';
import { NexusNetworkComponent } from './components/nexus-network/nexus-network.component';
import { EditRentSidebarComponent } from './components/EDIT-RENT-PROPERTIES/edit-rent-sidebar/edit-rent-sidebar.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { MobileHeaderComponent } from './shared/components/mobile-header/mobile-header.component';
import { ProjectSidebarComponent } from './components/projects/project-sidebar/project-sidebar.component';
// import { SwiperModule } from 'swiper/angular';

// import { SalesComponent } from './components/vendor/sales/sales.component';
// import { MbSaleComponent } from './components/mobile-layout-components/mb-sale/mb-sale.component';
// import { PostPlanComponent } from './components/vendor/post-plan/post-plan.component';
// import { MbProfileEditComponent } from './components/mobile-layout-components/mb-profile-edit/mb-profile-edit.component';
// import { ProjectfloorplanComponent } from './components/projects/projectfloorplan/projectfloorplan.component';
// import { AddsuccessfulprojectComponent } from './components/companies/addsuccessfulproject/addsuccessfulproject.component';
// import { SuccessProjectsImagesComponent } from './components/companies/success-projects-images/success-projects-images.component';
// import { AddOngoingProjectsComponent } from './components/companies/add-ongoing-projects/add-ongoing-projects.component';
// import { OngoingProjectImagesComponent } from './components/companies/ongoing-project-images/ongoing-project-images.component';
// import { ProjectSidebarComponent } from './components/projects/project-sidebar/project-sidebar.component';
// import { ProjectLocationComponent } from './components/projects/project-location/project-location.component';
// import { ProjectinfoComponent } from './components/projects/projectinfo/projectinfo.component';
// import { ProjectadditionalinfoComponent } from './components/projects/projectadditionalinfo/projectadditionalinfo.component';
// import { ProjectgalleryComponent } from './components/projects/projectgallery/projectgallery.component';
// import { BrochureComponent} from './components/projects/brochure/brochure.component';
// import { ProjecthighlightsComponent } from './components/projects/projecthighlights/projecthighlights.component';
// import { ProjectlocationhighlightsComponent } from './components/projects/projectlocationhighlights/projectlocationhighlights.component';
// import { ProjectPublishComponent } from './components/projects/project-publish/project-publish.component';
// import { ProjectSpecificationsComponent } from './components/projects/project-specifications/project-specifications.component';

// import { MbSearchPropertiesComponent } from './components/mobile-layout-components/mb-search-properties/mb-search-properties.component';
// import { MbPropertyListingsComponent } from './components/mobile-layout-components/mb-property-listings/mb-property-listings.component';
// import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
// import { SignupPopupComponent } from './components/signup-popup/signup-popup.component';
// import { RequestSiteVisitPopupComponent } from './components/request-site-visit-popup/request-site-visit-popup.component';
// import { SharePopUpComponent } from './components/share-pop-up/share-pop-up.component';
// import { CompanyimagesComponent } from './components/companies/companyimages/companyimages.component';
// import { PlotAndLandListingsComponent } from './components/plot-and-land-listings/plot-and-land-listings.component';
// import { BuyListingsComponent } from './components/buy-listings/buy-listings.component';
// import { MbSearchPropertyPage1Component } from './components/mobile-layout-components/mb-search-property-page1/mb-search-property-page1.component';
// import { MbAddMoreLocationPageComponent } from './components/mobile-layout-components/mb-add-more-location-page/mb-add-more-location-page.component';
// import { SearchPropertiesComponent } from './components/associates/search-properties/search-properties.component';
// import { PropertyListingsComponent } from './components/property-listings/property-listings.component';
// import { ProjectListingsComponent } from './components/project-listings/project-listings.component';
// import { PgListingsComponent } from './components/pg-listings/pg-listings.component';
// import { AddAssociateComponent } from './components/associates/add-associate/add-associate.component';
// import { AssociateDashboardComponent } from './components/associates/associate-dashboard/associate-dashboard.component';
// import { MbChoosePlansComponent } from './components/mobile-layout-components/mb-choose-plans/mb-choose-plans.component';
// import { MbCibilComponent } from './components/mobile-layout-components/mb-cibil/mb-cibil.component';
// import { MbLoansComponent } from './components/mobile-layout-components/mb-loans/mb-loans.component';
// import { MbCctvEnquiryComponent } from './components/mobile-layout-components/mb-cctv-enquiry/mb-cctv-enquiry.component';
// import { MbLandSurveyComponent } from './components/mobile-layout-components/mb-land-survey/mb-land-survey.component';
// import { MbLandFencingComponent } from './components/mobile-layout-components/mb-land-fencing/mb-land-fencing.component';
// import { MbSolarComponent } from './components/mobile-layout-components/mb-solar/mb-solar.component';
// import { DashboardTableComponent } from './components/dashboard-table/dashboard-table.component';
// import { MbSupportAndHelpComponent } from './components/mobile-layout-components/mb-support-and-help/mb-support-and-help.component';
// import { MbDashBoardComponent } from './components/mobile-layout-components/mb-dash-board/mb-dash-board.component';
// import { MbMyTeamComponent } from './components/mobile-layout-components/mb-my-team/mb-my-team.component';
// import { MbMyPropertiesComponent } from './components/mobile-layout-components/mb-my-properties/mb-my-properties.component';
// import { MbPaymentLoginComponent } from './components/mobile-layout-components/mb-payment-login/mb-payment-login.component';
// import { LoginpopupComponent } from './components/loginpopup/loginpopup.component';
// import { PostPropertyComponent } from './components/vendor/post-property/post-property.component';
// import { AddCompanyComponent } from './components/companies/add-company/add-company.component';
// import { CompanySidebarComponent } from './components/companies/company-sidebar/company-sidebar.component';
// import { CompanyinfoComponent } from './components/companies/companyinfo/companyinfo.component';
// import { CompanyProfileComponent } from './components/companies/company-profile/company-profile.component';
// import { AddProjectComponent } from './components/projects/add-project/add-project.component';
// import { MyLeadsComponent } from './components/vendor/my-leads/my-leads.component';
// import { MyviewLeadsComponent } from './components/myview-leads/myview-leads.component';
// import { MyCompanyComponent } from './components/my-company/my-company.component';
// import { ReviewsAndRatingsComponent } from './components/vendor/reviews-and-ratings/reviews-and-ratings.component';
// import { MyProfileComponent } from './components/vendor/my-profile/my-profile.component';
// import { LeadsComponent } from './components/vendor/leads/leads.component';
// import { InteriorDesignEnquiryComponent } from './components/vendor/interior-design-enquiry/interior-design-enquiry.component';
// import { PropertyDetailsComponent } from './property-details/property-details.component';
// import { ProjectDescriptionComponent } from './project-description/project-description.component';
// import { PgDescriptionComponent } from './pg-description/pg-description.component';
// import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
// import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
// import { AboutUsComponent } from './components/about-us/about-us.component';
// import { ShippingPolicyComponent } from './components/shipping-policy/shipping-policy.component';
// import { CancellationRefundPolicyComponent } from './components/cancellation-refund-policy/cancellation-refund-policy.component';
// import { LeadsForProjectsComponent } from './components/leads-for-projects/leads-for-projects.component';
// import { LeadsForPghostelComponent } from './components/leads-for-pghostel/leads-for-pghostel.component';
// import { PricingPolicyComponent } from './components/pricing-policy/pricing-policy.component';
// import { ContactUsComponent } from './components/contact-us/contact-us.component';
// import { MyProjectsComponent } from './components/vendor/my-projects/my-projects.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginAssociateComponent,
    ForgotPasswordMblComponent,
    PropertysidebarComponent,
    PgSidebarComponent,
    RentSidebarComponent,
    ProjectSidebarComponent,
    PlotSidebarComponent,
    SidebarComponent,
    UnitsComponent,
    IncompleteProfilePopupAlertComponent,
    NexusNetworkComponent,
    EditRentSidebarComponent,
    FeedbackComponent,

    
    // SalesComponent,
    // MbSaleComponent,
    // PostPlanComponent,
    // PostPropertyComponent,
    // AddCompanyComponent,
    // CompanySidebarComponent,
    // CompanyinfoComponent,
    // MyProjectsComponent,
    // CompanyProfileComponent,
    // AddProjectComponent,
    // MyLeadsComponent,
    // MyviewLeadsComponent,
    // MyCompanyComponent,
    // ReviewsAndRatingsComponent,
    // MyProfileComponent,
    // LeadsComponent,
    // InteriorDesignEnquiryComponent,
    // PropertyDetailsComponent,
    // ProjectDescriptionComponent,
    // PgDescriptionComponent,
    // TermsAndConditionsComponent,
    // PrivacyPolicyComponent,
    // AboutUsComponent,
    // ShippingPolicyComponent,
    // CancellationRefundPolicyComponent,
    // LeadsForProjectsComponent,
    // LeadsForPghostelComponent,
    // PricingPolicyComponent,
    // ContactUsComponent,

    // AddsuccessfulprojectComponent,
    // SuccessProjectsImagesComponent,
    // AddOngoingProjectsComponent,
    // OngoingProjectImagesComponent,
    // ProjectSidebarComponent,
    // ProjectLocationComponent,
    // ProjectinfoComponent,
    // ProjectadditionalinfoComponent,
    // ProjectgalleryComponent,
    // BrochureComponent,
    // ProjecthighlightsComponent,
    // ProjectlocationhighlightsComponent,
    // ProjectPublishComponent,
    // ProjectfloorplanComponent,
    // ProjectSpecificationsComponent,
    // AddAssociateComponent,
    // AssociateDashboardComponent,
    // LoginpopupComponent,
    // MbChoosePlansComponent,
    // MbCibilComponent,
    // MbLoansComponent,
    // MbCctvEnquiryComponent,
    // MbLandSurveyComponent,
    // MbLandFencingComponent,
    // MbSolarComponent,
    // DashboardTableComponent,
    // MbSupportAndHelpComponent,
    // MbDashBoardComponent,
    // MbMyTeamComponent,
    // MbMyPropertiesComponent,
    // MbPaymentLoginComponent,
 
    // CompanyimagesComponent,
  
    // SearchPropertiesComponent,
    // PropertyListingsComponent,
    // ProjectListingsComponent,
    // PgListingsComponent,
    // BuyListingsComponent,
    // SharePopUpComponent,
    // SignupPopupComponent,
    // RequestSiteVisitPopupComponent,
    // MbSearchPropertiesComponent,
    // MbSearchPropertyPage1Component,
    // MbAddMoreLocationPageComponent,
    // MbPropertyListingsComponent,
    // PlotAndLandListingsComponent,
    // DateTimePickerComponent,
    // MbProfileEditComponent,





  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    FormsModule,
    NgbModalModule,
    ReactiveFormsModule,
    ShareButtonsModule.withConfig({
      debug:true
    }),
    ShareIconsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    NgxPaginationModule,
    NgbModule,
    ToastrModule.forRoot({
      // positionClass: 'toast-bottom-right',
      positionClass: 'toast-top-right',

      timeOut: 10000
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDWb9Xof86boGQouKT9E4hv0ipuu7LyQJM',
      libraries: ['places']
    }),
    NguCarouselModule,
    ImageCropperModule,
    OwlModule,
    
  ],
  providers: [
    LoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [InitialService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initEnvironment,
      deps: [LoaderService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
export function appInit(initService: InitialService): Function {
  return () => initService.loadApp();
}
export function initEnvironment(loaderService: LoaderService): Function {
  return () => loaderService.loadLocation();
}
