import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RsbService } from 'src/app/service/rsb.service';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
@Component({
  selector: 'app-nexus-network',
  templateUrl: './nexus-network.component.html',
  styleUrls: ['./nexus-network.component.css']
})
export class NexusNetworkComponent implements OnInit, OnDestroy {
  isMobileLayout: boolean = false;
  agents: any[] = [];
  skip: number = 0;
  limit: number = 10;
  loading: boolean = false;
  hasMoreData: boolean = true;
  observer: IntersectionObserver | undefined;

  constructor(private rsbService: RsbService,
    private lookupModel: RsbLookupModel
  ) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => (this.isMobileLayout = window.innerWidth <= 1024);
    this.loadAgents();
    this.setupIntersectionObserver();
  }
  loadAgents(): void {
    if (this.loading || !this.hasMoreData) return;
    this.loading = true;
    const args = {
      skip: this.skip,
      limit: this.limit,
      associateId: this.lookupModel.getLoggedUser().associateId
    };

    this.rsbService.getAllAssociatesExceptAssociate(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        if (rsp.contents.length &&rsp.contents[0].count[0].count > 20) {
          const newAgents = rsp.contents[0].associates;
          this.agents = [...this.agents, ...newAgents];
            this.hasMoreData = false;
            this.skip += 20;
            this.loading = false;
          
        }
      }
    })


  }

  setupIntersectionObserver(): void {
    const options = {
      root: null,
      threshold: 0.1
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !this.loading) {
          const args = {
            skip: this.skip,
            limit: this.limit,
            associateId: this.lookupModel.getLoggedUser().associateId
          };
          this.rsbService.getAllAssociatesExceptAssociate(args).subscribe((rsp) => {
            if (rsp.statusCode === 0) {
              if (rsp.contents.length &&rsp.contents[0].count[0].count > 20) {
                const newAgents = rsp.contents[0].associates;
                this.agents = [...this.agents, ...newAgents];
                  this.hasMoreData = false;
                  this.skip += 20;
                  this.loading = false;
                
              }
            }
          })
        }
      });
    }, options);

    const target = document.querySelector('.scroll-anchor');
    if (target) {
      this.observer.observe(target);
      // alert("calling")
      this.loadAgents()

    }
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}