<!-- <div class="mb-dashboard">
<input type="text" #locationInput class="form-control" placeholder="Enter city" />
<p *ngIf="selectedLocation">Selected City: {{fullCity.cityName}}</p>

</div> -->

<!-- <div class="tab-content">

    <div class="tab-pane container active" id="Buy">
        <div class="mb-search-field mb-3">
            <label for="">Select City/Localities</label>
            <div>
                
                        
                        <input type="text" #locationInput class="form-control" placeholder="Enter city" />

            </div>
        </div>
    </div>
</div> -->
<div class="mb-dashboard"></div>
<div class="container">
    <div class="row">
        <div class="col-lg-12 my-3">
            <div class="mb_gb_search">

                <div class="mb_gb_pop" style="display:block">
                    <div class="mb_gb_pop_content">


                        <div class="tab-content">

                            <div class="tab-pane container active" id="Buy">
                                <div class="mb-search-field mb-3">
                                    <label for="">Select City/Localities</label>
                                    <div class="input-group  searchsection">

                                        <ng-container *ngIf="cities.length" style="overflow-x: scroll;">
                                            <span class="input-group-text mb-loc">{{ cities[0].cityName }}<i
                                                    class="bi bi-x"></i></span>
                                          
                                        </ng-container>
                                      
                                        <input type="text" #locationInput class="form-control" placeholder="Enter city" />
                                    </div>
                                </div>
                            </div>
                          
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

