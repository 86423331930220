import { Component, OnInit } from '@angular/core';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
  selector: 'app-payment-log',
  templateUrl: './payment-log.component.html',
  styleUrls: ['./payment-log.component.css']
})
export class PaymentLogComponent implements OnInit {
  getLogsForAssociate: Array<any> = [];
  isMobileLayout:boolean=false;

  constructor(private rsbService:RsbService) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.getAllLogsForAssociate();
  }
  getAllLogsForAssociate(): void{
    this.rsbService.getAllLogsForAssociate().subscribe(
      (rsp) => {
        if (rsp.statusCode === 0) {
          this.getLogsForAssociate = rsp.contents;
        } else {
        }
      },
      (error) => {
        console.error('Error fetching logs:', error);
      }
    );
  }

}
