<div class="card padding-card t-align-l no-radius border">
  <div class="card-body">
    <h5 class="card-title mb-4">Property Description
      <button type="button" *ngIf="isMobileLayout" class="btn btn-primary btn-sm mb-3 float-right mr-2" (click)="next()">Next</button>
        <button type="button" *ngIf="isMobileLayout" class="btn btn-primary btn-sm mb-3 float-right mr-2" (click)="back()">Back</button>
    </h5>

    <div class="row">
      <div class="col-md-12 mb-3" *ngIf="tableView && tableData.length > 0">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">S.no</th>
              <th scope="col">Category Name</th>
              <th scope="col">No. Of Rooms</th>
              <th scope="col">Monthly Rent</th>
              <th scope="col">Security Deposit</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let subcategory of tableData; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ subcategory.subCategoryName ? subcategory. subCategoryName : subcategory.swatchName ? subcategory.swatchName: ''}}</td>
              <td>{{ subcategory.rooms }}</td>
              <td>{{ subcategory.monthlyRent }}</td>
              <td>{{ subcategory.securityDepPerBed }}</td>
              <td><button class="btn btn-primary" (click)="viewSubCategoryForm(subcategory)">View</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row"  *ngIf="currentSubCategory">
      <div class="col-md-12 mb-3">
        <label class="d-block" for="moduleId">Add Attributes<span class="text-danger">*</span></label>
        <ng-container>
          <button type="button" class="btn btn-primary rounded-pill">{{ currentSubCategory.swatchName ? currentSubCategory.swatchName : currentSubCategory.subCategoryName ? currentSubCategory.subCategoryName: '' }}</button>
        </ng-container>
      </div>
      <div class="col-md-12 mb-3">
        <form [formGroup]="attributeForm" (submit)="submitNextSubcategory()">
          <div class="form-group">
            <label for="rooms">No. of {{ currentSubCategory?.subCategoryName }} rooms in PG <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control" id="rooms" name="rooms" formControlName="rooms">
          </div>
          <div class="form-group">
            <label for="monthlyRent">Monthly Rent Per Bed (&#8377;) <span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="monthlyRent" name="monthlyRent" formControlName="monthlyRent">
          </div>
          <div class="form-group">
            <label for="securityDepPerBed">Security Deposit Per Bed (&#8377;) <span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="securityDepPerBed" name="securityDepPerBed"
              formControlName="securityDepPerBed">
          </div>
          <div class="form-group" *ngIf="listAmenities.length">
            <ng-container *ngFor="let attribute of listAmenities">
              <label class="d-block" for="categoryId">{{attribute.attributeName}} </label>
              <div class="properties_btn">
                <ng-container *ngFor="let properties of attribute.attributeProperties;let i = index;">
                  <button type="button" class="btn btn-primary rounded-pill"
                    (click)="changeAmenities(properties)"
                    [ngClass]="{'active':amenities.includes(properties.propertyId)}">{{
                    properties.swatchName}}</button>
                </ng-container>
              </div>
            </ng-container>
          </div>

          <div class="form-group">

            <div class="card-body">
              <h5 class="card-title mb-4">Property Preview Image {{propertyImage}} (Image size should atleast 800 * 550)</h5>
              <div class="row">
                  <div class="col-md-4 mt-2"   *ngFor="let propertyImage of propertyImages; let imgIndx = index;">
                      <div class="fuzone">
                          <img class="card-img-top" style="height: 150px;" *ngIf="propertyImage" [src]='propertyImage' alt="Card image cap">
                          <div class="fu-text" *ngIf="!propertyImage">
                              <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                  upload</span>
                          </div>
                          <input class="upload" type="file" id="{{'imageInput' + imgIndx}}" name="{{'imageInput' + imgIndx}}" #imageInput accept="image/*"
                              (change)="uploadAws($event, imgIndx)">
                      </div>
                  </div>
                
                  <div class="col-md-4 mt-2" *ngIf="propertyImages.length === 0">
                    <div class="fuzone">
                      <div class="fu-text">
                        <span><i class="mdi mdi-image-area"></i> Click here or drop files to upload</span>
                      </div>
                      <input class="upload" type="file" id="imageInputPlaceholder" name="imageInputPlaceholder" #imageInputPlaceholder accept="image/*" (change)="uploadAws($event, -1)">
                      
                    </div>
                  </div>
                 
                
                
  
  
                  <!-- <div class="col-md-4">
                      <div class="fuzone">
                          <div class="fu-text">
                              <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                  upload</span>
                          </div>
                          <input class="upload" type="file" id="imageInput1" name="imageInput1" #imageInput1 accept="image/*"
                              (change)="uploadAws($event)">
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="fuzone">
                          <div class="fu-text">
                              <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                  upload</span>
                          </div>
                          <input class="upload" type="file" id="imageInput2" name="imageInput2" #imageInput2 accept="image/*"
                              (change)="uploadAws($event)">
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="fuzone">
                          <div class="fu-text">
                              <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                  upload</span>
                          </div>
                          <input class="upload" type="file" id="imageInput3" name="imageInput3" #imageInput3 accept="image/*"
                              (change)="uploadAws($event)">
                      </div>
                  </div>
                  <div class="col-md-4 mt-2">
                      <div class="fuzone">
                          <div class="fu-text">
                              <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                  upload</span>
                          </div>
                          <input class="upload" type="file" id="imageInput4" name="imageInput4" #imageInput4 accept="image/*"
                              (change)="uploadAws($event)">
                      </div>
                  </div>
                  <div class="col-md-4 mt-2">
                      <div class="fuzone">
                          <img class="card-img-top" src="assets/img/list/1.png" alt="Card image cap">
                          <div class="fu-text" style="display: none;">
                              <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                  upload</span>
                          </div>
                          <input class="upload" type="file" id="imageInput5" name="imageInput5" #imageInput5 accept="image/*"
                              (change)="uploadAws($event)">
                      </div>
                  </div>
                  <div class="col-md-4 mt-2">
                      <div class="fuzone">
                          <div class="fu-text">
                              <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                  upload</span>
                          </div>
                          <input class="upload" type="file" id="imageInput6" name="imageInput6" #imageInput6 accept="image/*"
                              (change)="uploadAws($event)">
                      </div>
                  </div> -->
              </div>
          </div>
          </div>


          <div>
            <button type="submit" class="btn btn-warning" [disabled]="!attributeForm.valid">Save</button>
          </div>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mb-3">
        <button type="button" class="btn btn-primary btn-lg mb-3 float-right mr-2" (click)="next()">Next</button>
        <button type="button" class="btn btn-primary btn-lg mb-3 float-right mr-2" (click)="back()">Back</button>

      </div>
    </div>
  </div>
</div>