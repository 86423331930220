import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
function panValidator(control: FormControl): { [s: string]: boolean } | null {
  const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  if (control.value && !panPattern.test(control.value)) {
    return { 'invalidPAN': true };
  }
  return null;
}

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.css']
})
export class CreditCardComponent implements OnInit {
  loanForm: FormGroup;
  geoCoder;
  cityAutoComplete: any;
  selectedCountry: any;
  isShow:boolean=false;

  cityBounds: any;
  getLoansListings: any;
  isMobileLayout:boolean=false;


  private cityText: ElementRef;
  @ViewChild('cityText') set cityEle(cityElement: ElementRef) {
    if (cityElement && this.lookupModel.isBrowser) {
      this.cityText = cityElement;
      this.prepareCityAutocomplete();
    }
  }

  constructor(private fb: FormBuilder,
    private rsbService: RsbService,
    private modalService: NgbModal,
    private lookupModel: RsbLookupModel,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastr: ToastrService


  ) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.loanForm = this.fb.group({
      name: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required, 
          Validators.email, 
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.(com|org|net|edu|gov|mil)$') // stricter pattern for common TLDs
        ]
      ],  loanAmount: ['', [Validators.required]],
      panNum: ['', [Validators.required, panValidator]],
      income: ['', [Validators.required]],
      debits: ['', [Validators.required]],
      city: ['', [Validators.required]],
      cityAuto: ['', [Validators.required]],
      existingCardValue: ['', [Validators.required]],
      existingCardBankName: ['', [Validators.required]],

      

      

    })
    this.loanForm.get('panNum').valueChanges.subscribe(value => {
      if (value) {
        this.loanForm.get('panNum').setValue(value.toUpperCase(), { emitEvent: false });
      }
    });
    this.getLoanRequest();
  }
  prepareCityAutocomplete(): void {

    this.city.enable();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.cityAutoComplete = new google.maps.places.Autocomplete(this.cityText.nativeElement, {

        types: ['(cities)'],
        //bounds: []
        strictBounds: true
      });
      this.cityAutoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = this.cityAutoComplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.cityBounds = place.geometry.viewport;
          this.city.setValue(place.name);
          this.cityAuto.setValue(place.name);
        });
      });
    });
  }
  addPage(): void{
    this.isShow=true;
  }
  closePage(): void{
    this.isShow=false;
  }
  getLoanRequest(): void {
    this.rsbService.getCreditCardRequest().subscribe((res) => {
      if (res.statusCode === 0 && res.contents.length) {
        this.getLoansListings = res.contents;
      }
      
    })

  }

  submit() {
    if (this.loanForm.invalid) {
    
      Object.values(this.loanForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    const args = {
      name: this.name.value,
      mobile: this.mobile.value,
      email: this.email.value,
      loanAmount: this.loanAmount.value,
      panNumber: this.panNum.value,
      income: this.income.value,
      debits: this.debits.value,
      city: this.city.value,
      existingCardValue:this.existingCardValue.value,
      existingCardBankName:this.existingCardBankName.value

      
    }
    this.rsbService.applyForCreditCard(args).subscribe((res) => {
      if (res.statusCode === 0) {
        this.toastr.success('Submitted successfully.');
        this.getLoanRequest();
        this.resetForm();
      }
    })
    this.isShow=false;


  }
  resetForm() {
    this.loanForm.reset();
  }
  // get loanType() { return this.loanForm.get('loanType'); }
  get name() { return this.loanForm.get('name'); }
  get mobile() { return this.loanForm.get('mobile'); }
  get email() { return this.loanForm.get('email'); }
  get loanAmount() { return this.loanForm.get('loanAmount'); }
  get panNum() { return this.loanForm.get('panNum'); }
  get income() { return this.loanForm.get('income'); }
  get debits() { return this.loanForm.get('debits'); }
  get city() { return this.loanForm.get('city'); }
  get cityAuto() { return this.loanForm.get('cityAuto'); }
  get locality() { return this.loanForm.get('locality'); }
  get existingCardValue() { return this.loanForm.get('existingCardValue'); }
  get existingCardBankName() { return this.loanForm.get('existingCardBankName'); }

  



}
