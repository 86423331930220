import { Component, OnInit } from '@angular/core';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-mb-payment-login',
  templateUrl: './mb-payment-login.component.html',
  styleUrls: ['./mb-payment-login.component.css']
})
export class MbPaymentLoginComponent implements OnInit {
  getLogsForAssociate: Array<any> = [];

  constructor(private rsbService:RsbService) { }

  ngOnInit(): void {
    this.getAllLogsForAssociate();
  }
  getAllLogsForAssociate(): void{
    this.rsbService.getAllLogsForAssociate().subscribe(
      (rsp) => {
        if (rsp.statusCode === 0) {
          this.getLogsForAssociate = rsp.contents;
        } else {
        }
      },
      (error) => {
        console.error('Error fetching logs:', error);
      }
    );
  }

}
