<ng-container *ngIf="!isMobileLayout">
    <div id="wrapper">
        <!-- <share-buttons [theme]="'material-dark'" [include]="['whatsapp']" [show]="1" [size]="1" [url]="'https://pillarblocks.com'" [autoSetMeta]="false"></share-buttons> -->
        <rsb-sidebar
            *ngIf="!hideMainSidebar && !hideSidebar && !hidePropertyBar && !showprojectSideBar && !hidePgBar && !hideRentBar && !hideHeaderNavForLogin"></rsb-sidebar>
        <app-company-sidebar *ngIf="hideMainSidebar"></app-company-sidebar>
        <app-project-sidebar *ngIf="hideSidebar"></app-project-sidebar>
        <app-propertysidebar *ngIf="hidePropertyBar"></app-propertysidebar>
        <app-pg-sidebar *ngIf="hidePgBar"></app-pg-sidebar>
        <app-rent-sidebar *ngIf="hideRentBar"></app-rent-sidebar>
        <app-project-sidebar *ngIf="showprojectSideBar"></app-project-sidebar>
        <app-plot-sidebar *ngIf="showPlotsideBar"></app-plot-sidebar>
        <rsb-spinner></rsb-spinner>
        <div *ngIf="!hideFooterForLogin; else loginContent" id="content-wrapper" class="d-flex flex-column">
            <div id="content">
                <rsb-header *ngIf="!hideHeaderNavForSearchPropInAssociate && !hideHeaderNavForLogin"></rsb-header>
                <router-outlet></router-outlet>
            </div>
            <!-- Uncomment footer if needed -->
            <!-- <rsb-footer></rsb-footer> -->
        </div>
        
        <ng-template #loginContent>
            <div id="content-wrapper-login">
                <router-outlet></router-outlet>
            </div>
        </ng-template>
   

    </div>
</ng-container>
<ng-container *ngIf="isMobileLayout">
    <rsb-spinner></rsb-spinner>
    <app-mobile-header *ngIf="!hideHeaderNavForLogin && !hideRentBar && !showPlotsideBar && !hidePropertyBar && !hidePgBar || showMobileNavForMyProperties"></app-mobile-header>
    <app-rent-sidebar *ngIf="hideRentBar"></app-rent-sidebar>
    <app-pg-sidebar *ngIf="hidePgBar"></app-pg-sidebar>
    <app-propertysidebar *ngIf="hidePropertyBar"></app-propertysidebar>
    <app-plot-sidebar *ngIf="showPlotsideBar"></app-plot-sidebar>
    <router-outlet></router-outlet>
    
    
</ng-container>