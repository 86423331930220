<div class="container" *ngIf="!isMobileLayout">
<div class="container text-center">
  <div class="header d-flex justify-content-center align-items-center">
    <span class="fw-bold text-danger">Nexus</span> &nbsp;
    <span class="fw-bold text-dark"> Partner Network</span>
    <span class="separator mx-2"></span>
    <span class="fw-bold text-warning">Hyderabad</span>
    <span class="fw-bold text-primary" id="telangana"> (Telangana)</span>
    
  </div>

  <!-- <div class="search-box">
    <input type="text" class="form-control" placeholder="search by city" />
    <i class="ri-search-line search-icon"></i>
  </div> -->
</div>




<div class="agent-grid">
  <div class="agent-card" *ngFor="let agent of agents">
    <div class="agent-container">
      <div class="agent-photo-container">
        <img  [src]="agent.personalDetails.profilePic || 'assets/img/userProfile.jpg'" 
        [alt]="agent.personalDetails.firstName || 'Agent'"  class="agent-photo" />
      </div>
      <div class="agent-details-container">
        <div class="badges">
          <span *ngIf="agent.verified" class="verified-badge">✔ VERIFIED</span>
          <span *ngIf="agent.expert" class="expert-badge">Expert Pro Agent</span>
          <span *ngIf="agent.reraRegistered" class="rera-badge">✔ RERA REGISTERED</span>
        </div>
        <h3 class="agent-name">{{ agent.personalDetails.firstName || 'N/A' }}</h3>
        <div class="agent-details" *ngIf="agent.personalDetails.experience">
          <!-- <span class="agent-rating">⭐ {{ agent.rating }}</span> -->
          <span class="agent-experience">Exp:{{ agent.personalDetails.experience}}</span>
        </div>
        <div class="agent-contact">
          <p>
            <img src="assets/img/cell-phone.png" style="width: 14px; height: auto;"> {{ agent.personalDetails?.mobile }}
          </p>
          <p>
            <img src="assets/img/gmail.png" style="width: 14px; height: auto;">  {{ agent.personalDetails.email || 'N/A'  }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Scroll Anchor -->

</div>



<div class="container"  *ngIf="isMobileLayout" style="margin-top: 110px;">
  <!-- <div class="header d-flex justify-content-center align-items-center">
      
    <span><b class="text-info">Nexus Partner_</b><b class="text-success">Network Hyderabad</b> <b class="text-warning">(Telangana)</b></span> &nbsp;
    
  </div> -->
    <div class="row">
 
      <div class="col-6 col-md-6" *ngFor="let agent of agents">
        <div class="agent-card">
          <div class="agent-container">
            <div class="agent-photo-container">
              <img [src]="agent.personalDetails.profilePic || 'assets/img/userProfile.jpg'" 
                   [alt]="agent.personalDetails.firstName || 'Agent'" 
                   class="agent-photo" />
            </div>
            <div class="agent-details-container" style="margin-top: -60px;">
              <div class="badges">
                <span *ngIf="agent.verified" class="verified-badge">✔ VERIFIED</span>
                <span *ngIf="agent.expert" class="expert-badge">Expert Pro Agent</span>
                <span *ngIf="agent.reraRegistered" class="rera-badge">✔ RERA REGISTERED</span>
              </div>
              <h3 class="agent-name">{{ agent.personalDetails.firstName || 'N/A' }}</h3>
              <div class="agent-details" *ngIf="agent.personalDetails.experience">
                <span class="agent-experience">Exp: {{ agent.personalDetails.experience }}</span>
              </div>
              <div class="agent-contact">
                <p>
                  <img src="assets/img/cell-phone.png" style="width: 14px; height: auto;"> 
                  {{ agent.personalDetails?.mobile }}
                </p>
                <p>
                  <img src="assets/img/gmail.png" style="width: 14px; height: auto;"> 
                  {{ agent.personalDetails.email || 'N/A' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="scroll-anchor" style="height: 1px;"></div>

  <!-- Loading Spinner -->
  <div *ngIf="loading" class="loading-spinner">
    <!-- <p>Loading more agents...</p> -->
  </div>