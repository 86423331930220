<div class="modal-dialog custom-dialog modal-dialog-centered">
    <div class="modal-content custom-content text-center">
      <!-- Close Button -->
       <div>
      <button type="button" class="close" (click)="close()">&times;</button>
       </div>
  
      <!-- Modal Body -->
      <div class="modal-body">
        <img src="assets/img/flyimgemoji.jpg" alt="Emoji Parachute" class="mb-3" style="width: 100px; height: auto;" />
        <h5 class="text-purple fw-bold">Just a moment!</h5>
        <p class="small-text">
          Our Partners Get 
          <span class="text-primary fw-bold">Max Visibility</span> and 
          <span class="text-primary fw-bold">Engagements</span> with 
          <span class="fw-bold" style="color:black">Updated</span>&nbsp;
          <span class="text-danger fw-bold">Profile</span>

        </p>
        <button class="btn btn-primary custom-button" (click)="navigateToProfiePage()">
          UPDATE
        </button>
      </div>
    </div>
  </div>
  