<div class="tab-pane active" id="pb-cdetails">
<div class="card-body">
    <h5 class="card-title mb-4">Add Previous Project  <button (click)="addSwatch()">Add New</button></h5>
    <div class="row">
        <div class="form-group col-md-12" *ngFor="let swatch of swatchList; let i = index;">
            <label for="location">Enter Previous Project</label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Project Name" id="location" [(ngModel)]="swatchList[i].projectName" >
            </div>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Project Type" id="location" [(ngModel)]="swatchList[i].pType"/>
            </div>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Units" id="location" [(ngModel)]="swatchList[i].units"/>
            </div>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Project Location" id="location" [(ngModel)]="swatchList[i].projectLocation"/>
            </div>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Project Extent" id="location" [(ngModel)]="swatchList[i].projectExtent"/>
            </div>
        </div>
    </div>
   
</div>

<button type="button" (click)="submit()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
</div>
