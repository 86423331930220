<!-- <div class="mb-dashboard">
<input type="text" #locationInput class="form-control" placeholder="Enter city" />
<p *ngIf="selectedLocation">Selected City: {{fullCity.cityName}}</p>

</div> -->

<!-- <div class="tab-content">

    <div class="tab-pane container active" id="Buy">
        <div class="mb-search-field mb-3">
            <label for="">Select City/Localities</label>
            <div>
                
                        
                        <input type="text" #locationInput class="form-control" placeholder="Enter city" />

            </div>
        </div>
    </div>
</div> -->
<div class="mb-dashboard"></div>
<div class="container">
    <div class="row">
        <div class="col-lg-12 my-3">
            <div class="mb_gb_search">

                <div class="mb_gb_pop" style="display:block">
                    <div class="mb_gb_pop_content">


                        <div class="tab-content">

                            <div class="tab-pane container active" id="Buy">
                                <div class="row mb-2">
                                    <table>
                                        <tr>
                                            <td style="width:70%;padding-left: 2%;font-size: 14px;"><i class="bi bi-arrow-left" (click)="backToCitySearch()"></i> You're searching in <b *ngIf="cities.length">{{cities[0].cityName}}</b></td>
                                            <td style="width:30%;font-size: 14px;" align="right"><a (click)="backToCitySearch()">Change City</a></td>
                                        </tr>
                                    </table>
                                    <!-- <div class="col-8"><i class="bi bi-arrow-left"></i> You're searching in <b>Hyderabad</b></div>
                                    <div class="col-4 text-right">Change City</div> -->
                                </div>
                                <div class="row mb-3">
                                    <!-- <label for="">Select City/Localities</label> -->
                                    <div class="searchsection">
                                        <!-- <input type="text" #locationInput class="form-control searchbox" placeholder="Add another locality" /> -->
                                        <div class="search-box">
                                            <button type="submit">
                                                <i class="bi bi-search"></i>
                                                <!-- <img src="https://img.icons8.com/ios-filled/50/000000/search.png" alt="Search Icon"> -->
                                              </button>
                                            <input type="text" id="locationInput" [formControl]="citySearch" #locationInput placeholder="Add another locality">
                                          </div>
                                    </div>
                                    
                                </div>
                                <div class="row mb-3 p-2">
                                    <!-- <ng-container *ngIf="cities.length" style="overflow-x: scroll;">
                                        <span class=""><span *ngFor="let loc of cities">{{ loc.cityName }}</span><i class="bi bi-x"></i></span>
                                    </ng-container> -->
                                    <button class="btn btn-info btn-sm" (click)="backToCitySearch()">Done</button>
                                    <span class="loc_list" *ngFor="let loc of cities;let ind=index">
                                        <span class="loc" *ngIf="ind">
                                            <span>{{loc.cityName}}, {{cities[0].cityName}}</span>
                                            <i class="bi bi-x" (click)="clearSearchEle(ind)"></i>
                                        </span>
                                    </span>
                                </div>
                                <div>
                                    <div class="row mb-3"><b><i class="bi bi-geo-alt-fill"></i> Add Nearby Localities </b></div>
                                    <div class="row">
                                            <div class="near_box" *ngFor="let item of nearbyPlaceslist;let ind=index" (click)="addNearbyIntoLocality(ind)">
                                                <table style="width: 100%;">
                                                    <tr>
                                                        <td rowspan="2" class="plus">
                                                            <i class="bi bi-plus-circle-fill"></i>
                                                        </td>
                                                        <td class="placebox">
                                                            <div class="place">{{item.name}}</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="place_details">{{item.distance}} Km from {{fullCity.cityName}} <span *ngIf="cities.length && cities.length>1">, {{cities[0].cityName}}</span></div>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- <div class="plus"><i class="bi bi-plus-circle-fill"></i></div>
                                                <div class="place">
                                                    <div style="font-size: 14px;">Dullapally</div>
                                                    <div class="place_details">4.88 Km from Kompally, Hyderabad</div>
                                                </div> -->
                                            </div>
                                    
                                    </div>
                                </div>
                            </div>
                          
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

