import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OwlModule } from 'ngx-owl-carousel';

import { AgmCoreModule } from '@agm/core';
import { NguCarouselModule } from '@ngu/carousel';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { PostPropertyComponent } from './components/vendor/post-property/post-property.component';
import { PostLocationComponent } from './components/vendor/post-location/post-location.component';
import { PostDetailsComponent } from './components/vendor/post-details/post-details.component';
import { RentPostLocationComponent } from './components/Rent/rent-post-location/rent-post-location.component';
import { RentPostDetailsComponent } from './components/Rent/rent-post-details/rent-post-details.component';
import { RentPostFeaturesComponent } from './components/Rent/rent-post-features/rent-post-features.component';
import { RentPostGalleryComponent } from './components/Rent/rent-post-gallery/rent-post-gallery.component';
import { RentPropertyHighlightsComponent } from './components/Rent/rent-property-highlights/rent-property-highlights.component';
import { RentPropertyLocationHighlightsComponent } from './components/Rent/rent-property-location-highlights/rent-property-location-highlights.component';
import { RentPostPublishComponent } from './components/Rent/rent-post-publish/rent-post-publish.component';

import { PostFeaturesComponent } from './components/vendor/post-features/post-features.component';
import { PostGalleryComponent } from './components/vendor/post-gallery/post-gallery.component';
import { PostPlanComponent } from './components/vendor/post-plan/post-plan.component';
import { PostPublishComponent } from './components/vendor/post-publish/post-publish.component';
import { LoaderService } from './core/loader.service';
import { InitialService } from './core/initial.service';
import { PropertylocationComponent } from './components/vendor/propertylocation/propertylocation.component';
import { VendorDashboardComponent } from './components/vendor/vendor-dashboard/vendor-dashboard.component';
import { LoanComponent } from './components/vendor/loan/loan.component';
import { FloorplanComponent } from './components/vendor/floorplan/floorplan.component';
import { ProjectfloorplanComponent } from './components/projects/projectfloorplan/projectfloorplan.component';
import { SpecificationsComponent } from './components/vendor/specifications/specifications.component';
import { PropertyhighlightsComponent } from './components/vendor/propertyhighlights/propertyhighlights.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MyPropertiesComponent } from './components/vendor/my-properties/my-properties.component';
import { MyProjectsComponent } from './components/vendor/my-projects/my-projects.component';
import { CommercialPropertiesComponent } from './components/vendor/commercial-properties/commercial-properties.component';
import { LeadcenterComponent } from './components/vendor/leadcenter/leadcenter.component';
import { DealsComponent } from './components/vendor/deals/deals.component';
import { AddCompanyComponent } from './components/companies/add-company/add-company.component';
import { CompanySidebarComponent } from './components/companies/company-sidebar/company-sidebar.component';
import { CompanyinfoComponent } from './components/companies/companyinfo/companyinfo.component';
import { CompanyimagesComponent } from './components/companies/companyimages/companyimages.component';
import { AddsuccessfulprojectComponent } from './components/companies/addsuccessfulproject/addsuccessfulproject.component';
import { SuccessProjectsImagesComponent } from './components/companies/success-projects-images/success-projects-images.component';
import { AddOngoingProjectsComponent } from './components/companies/add-ongoing-projects/add-ongoing-projects.component';
import { OngoingProjectImagesComponent } from './components/companies/ongoing-project-images/ongoing-project-images.component';
import { CompanyProfileComponent } from './components/companies/company-profile/company-profile.component';
import { AddProjectComponent } from './components/projects/add-project/add-project.component';
import { ProjectSidebarComponent } from './components/projects/project-sidebar/project-sidebar.component';
import { ProjectLocationComponent } from './components/projects/project-location/project-location.component';
import { ProjectinfoComponent } from './components/projects/projectinfo/projectinfo.component';
import { ProjectadditionalinfoComponent } from './components/projects/projectadditionalinfo/projectadditionalinfo.component';
import { ProjectgalleryComponent } from './components/projects/projectgallery/projectgallery.component';
import { BrochureComponent} from './components/projects/brochure/brochure.component';
import { ProjecthighlightsComponent } from './components/projects/projecthighlights/projecthighlights.component';
import { ProjectlocationhighlightsComponent } from './components/projects/projectlocationhighlights/projectlocationhighlights.component';
import { ProjectPublishComponent } from './components/projects/project-publish/project-publish.component';
import { ProjectSpecificationsComponent } from './components/projects/project-specifications/project-specifications.component';
import { UnitsComponent } from './components/projects/units/units.component';
import { PropertysidebarComponent } from './components/vendor/propertysidebar/propertysidebar.component';
import { MyLeadsComponent } from './components/vendor/my-leads/my-leads.component';
import { MyviewLeadsComponent } from './components/myview-leads/myview-leads.component';
import { MyCompanyComponent } from './components/my-company/my-company.component';
import { PgLocationComponent } from './components/PG-hostels/pg-location/pg-location.component';
import { PgInfoComponent } from './components/PG-hostels/pg-info/pg-info.component';
import { PgLocationHighightsComponent } from './components/PG-hostels/pg-location-highights/pg-location-highights.component';
import { PgHighightsComponent } from './components/PG-hostels/pg-highights/pg-highights.component';
import { PgGalleryComponent } from './components/PG-hostels/pg-gallery/pg-gallery.component';
import { AddPgComponent } from './components/PG-hostels/add-pg/add-pg.component';
import { PgSidebarComponent } from './components/PG-hostels/pg-sidebar/pg-sidebar.component';
import { PgFeauturesComponent } from './components/PG-hostels/pg-feautures/pg-feautures.component';
import { AddAttributesCompnentComponent } from './components/PG-hostels/add-attributes-compnent/add-attributes-compnent.component';
import { ReviewsAndRatingsComponent } from './components/vendor/reviews-and-ratings/reviews-and-ratings.component';
import { ProjectFinanceComponent } from './components/vendor/project-finance/project-finance.component';
import { MyProfileComponent } from './components/vendor/my-profile/my-profile.component';
import { LoansComponent } from './components/vendor/loans/loans.component';
import { LeadsComponent } from './components/vendor/leads/leads.component';
import { CibilComponent } from './components/vendor/cibil/cibil.component';
import { SalesComponent } from './components/vendor/sales/sales.component';
import { CctvComponent } from './components/vendor/cctv/cctv.component';
import { InteriorDesignEnquiryComponent } from './components/vendor/interior-design-enquiry/interior-design-enquiry.component';
import { SolarEnquiryComponent } from './components/vendor/solar-enquiry/solar-enquiry.component';
import { AddRentComponent } from './components/Rent/add-rent/add-rent.component';
import { RentSidebarComponent } from './components/Rent/rent-sidebar/rent-sidebar.component';
import { PropertyModulesComponent } from './components/vendor/property-modules/property-modules.component';
import { RentTableListComponent } from './components/Rent/rent-table-list/rent-table-list.component';
import { PgHostelListComponent } from './components/PG-hostels/pg-hostel-list/pg-hostel-list.component';
import { AddAssociateComponent } from './components/associates/add-associate/add-associate.component';
import { ChoosePlanComponent } from './components/associates/choose-plan/choose-plan.component';
import { AssociateDashboardComponent } from './components/associates/associate-dashboard/associate-dashboard.component';
import { SearchPropertiesComponent } from './components/associates/search-properties/search-properties.component';
import { AssociateMyProfileComponent } from './components/associates/associate-my-profile/associate-my-profile.component';
import { PropertyListingsComponent } from './components/property-listings/property-listings.component';
import { ProjectListingsComponent } from './components/project-listings/project-listings.component';
import { PgListingsComponent } from './components/pg-listings/pg-listings.component';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';
import { MyTeamComponent } from './components/associates/my-team/my-team.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { ProjectDescriptionComponent } from './project-description/project-description.component';
import { PgDescriptionComponent } from './pg-description/pg-description.component';
import { LoginpopupComponent } from './components/loginpopup/loginpopup.component';
import { SharePopUpComponent } from './components/share-pop-up/share-pop-up.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ShippingPolicyComponent } from './components/shipping-policy/shipping-policy.component';
import { CancellationRefundPolicyComponent } from './components/cancellation-refund-policy/cancellation-refund-policy.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SignupPopupComponent } from './components/signup-popup/signup-popup.component';
import { RequestSiteVisitPopupComponent } from './components/request-site-visit-popup/request-site-visit-popup.component';
import { FavoritePropertyListComponent } from './components/favorite-lists/favorite-property-list/favorite-property-list.component';
import { FavoriteProjectListComponent } from './components/favorite-lists/favorite-project-list/favorite-project-list.component';
import { FavoritePgListComponent } from './components/favorite-lists/favorite-pg-list/favorite-pg-list.component';
import { FavoriteListingsComponent } from './components/favorite-listings/favorite-listings.component';
import { LeadsForPropertiesComponent } from './components/leads-for-properties/leads-for-properties.component';
import { FavoriteProjectsComponent } from './components/favorite-projects/favorite-projects.component';
import { FavoritePgHostelsComponent } from './components/favorite-pg-hostels/favorite-pg-hostels.component';
import { LeadsForProjectsComponent } from './components/leads-for-projects/leads-for-projects.component';
import { LeadsForPghostelComponent } from './components/leads-for-pghostel/leads-for-pghostel.component';
import { LoginAssociateComponent } from './components/login-associate/login-associate.component';
import { PricingPolicyComponent } from './components/pricing-policy/pricing-policy.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { ForgotPasswordMblComponent } from './components/mobile-layout-components/forgot-password-mbl/forgot-password-mbl.component';
import { MbProfileEditComponent } from './components/mobile-layout-components/mb-profile-edit/mb-profile-edit.component';
import { MbSearchPropertiesComponent } from './components/mobile-layout-components/mb-search-properties/mb-search-properties.component';
import { SupportHelpComponent } from './components/support-help/support-help.component';
import { MbChoosePlansComponent } from './components/mobile-layout-components/mb-choose-plans/mb-choose-plans.component';
import { LandSurveyComponent } from './components/vendor/land-survey/land-survey.component';
import { LandFencingComponent } from './components/vendor/land-fencing/land-fencing.component';
import { MbCibilComponent } from './components/mobile-layout-components/mb-cibil/mb-cibil.component';
import { MbLoansComponent } from './components/mobile-layout-components/mb-loans/mb-loans.component';
import { MbCctvEnquiryComponent } from './components/mobile-layout-components/mb-cctv-enquiry/mb-cctv-enquiry.component';
import { NexusAddonTablesComponent } from './components/nexus-addon-tables/nexus-addon-tables.component';
import { MbLandSurveyComponent } from './components/mobile-layout-components/mb-land-survey/mb-land-survey.component';
import { MbLandFencingComponent } from './components/mobile-layout-components/mb-land-fencing/mb-land-fencing.component';
import { MbSolarComponent } from './components/mobile-layout-components/mb-solar/mb-solar.component';
import { DashboardTableComponent } from './components/dashboard-table/dashboard-table.component';
import { MbSearchPropertyPage1Component } from './components/mobile-layout-components/mb-search-property-page1/mb-search-property-page1.component';
import { MbAddMoreLocationPageComponent } from './components/mobile-layout-components/mb-add-more-location-page/mb-add-more-location-page.component';
import { MbSupportAndHelpComponent } from './components/mobile-layout-components/mb-support-and-help/mb-support-and-help.component';
import { MbDashBoardComponent } from './components/mobile-layout-components/mb-dash-board/mb-dash-board.component';
import { MbMyTeamComponent } from './components/mobile-layout-components/mb-my-team/mb-my-team.component';
import { MbMyPropertiesComponent } from './components/mobile-layout-components/mb-my-properties/mb-my-properties.component';
import { MbSaleComponent } from './components/mobile-layout-components/mb-sale/mb-sale.component';
import { PaymentLogComponent } from './components/payment-log/payment-log.component';
import { MbPaymentLoginComponent } from './components/mobile-layout-components/mb-payment-login/mb-payment-login.component';
import { MbPropertyListingsComponent } from './components/mobile-layout-components/mb-property-listings/mb-property-listings.component';
import { FurnitureEnquiryComponent } from './components/vendor/furniture-enquiry/furniture-enquiry.component';
import { InteriorEnquiryComponent } from './components/vendor/interior-enquiry/interior-enquiry.component';
import { PackersAndMoversEnquiryComponent } from './components/vendor/packers-and-movers-enquiry/packers-and-movers-enquiry.component';
import { AddPlotAndLandComponent } from './components/plot-and-land-in-propery-modules/add-plot-and-land/add-plot-and-land.component';
import { PlotPostLocationComponent } from './components/plot-and-land-in-propery-modules/plot-post-location/plot-post-location.component';
import { PlotPostDetailsComponent } from './components/plot-and-land-in-propery-modules/plot-post-details/plot-post-details.component';
import { PlotPostFeaturesComponent } from './components/plot-and-land-in-propery-modules/plot-post-features/plot-post-features.component';
import { PlotPostGalleryComponent } from './components/plot-and-land-in-propery-modules/plot-post-gallery/plot-post-gallery.component';
import { PlotPropertyHighlightsComponent } from './components/plot-and-land-in-propery-modules/plot-property-highlights/plot-property-highlights.component';
import { PlotPropertyLocationHighlightsComponent } from './components/plot-and-land-in-propery-modules/plot-property-location-highlights/plot-property-location-highlights.component';
import { PlotPublishComponent } from './components/plot-and-land-in-propery-modules/plot-publish/plot-publish.component';
import { PlotSidebarComponent } from './components/plot-and-land-in-propery-modules/plot-sidebar/plot-sidebar.component';
import { PgPublishComponent } from './components/PG-hostels/pg-publish/pg-publish.component';
import { PlotAndLandListingsComponent } from './components/plot-and-land-listings/plot-and-land-listings.component';
import { BuyListingsComponent } from './components/buy-listings/buy-listings.component';
import { HomeAutomationComponent } from './components/vendor/home-automation/home-automation.component';
import { PropertyInsuranceComponent } from './components/vendor/property-insurance/property-insurance.component';
import { PrefabHomesAndPropertiesComponent } from './components/vendor/prefab-homes-and-properties/prefab-homes-and-properties.component';
import { DronesComponent } from './components/vendor/drones/drones.component';
import { CreditCardComponent } from './components/vendor/credit-card/credit-card.component';
import { MainDashboardComponent } from './components/vendor/main-dashboard/main-dashboard.component';
import { IncompleteProfilePopupAlertComponent } from './components/incomplete-profile-popup-alert/incomplete-profile-popup-alert.component';

@NgModule({
  declarations: [
    AppComponent,
    PostPropertyComponent,
    PostLocationComponent,
    PostDetailsComponent,
    PostFeaturesComponent,
    PostGalleryComponent,
    PostPlanComponent,
    PostPublishComponent,
    PropertylocationComponent,
    VendorDashboardComponent,
    LoanComponent,
    FloorplanComponent,
    SpecificationsComponent,
    PropertyhighlightsComponent,
    SidebarComponent,
    MyPropertiesComponent,
    MyProjectsComponent,
    CommercialPropertiesComponent,
    LeadcenterComponent,
    DealsComponent,
    AddCompanyComponent,
    CompanySidebarComponent,
    CompanyinfoComponent,
    CompanyimagesComponent,
    AddsuccessfulprojectComponent,
    SuccessProjectsImagesComponent,
    AddOngoingProjectsComponent,
    OngoingProjectImagesComponent,
    CompanyProfileComponent,
    AddProjectComponent,
    ProjectSidebarComponent,
    RentPostLocationComponent,
    ProjectLocationComponent,
    ProjectinfoComponent,
    ProjectadditionalinfoComponent,
    ProjectgalleryComponent,
    BrochureComponent,
    ProjecthighlightsComponent,
    ProjectlocationhighlightsComponent,
    ProjectPublishComponent,
    ProjectfloorplanComponent,
    ProjectSpecificationsComponent,
    UnitsComponent,
    PropertysidebarComponent,
    MyLeadsComponent,
    MyviewLeadsComponent,
    MyCompanyComponent,
    PgLocationComponent,
    PgInfoComponent,
    PgLocationHighightsComponent,
    PgHighightsComponent,
    PgGalleryComponent,
    AddPgComponent,
    PgSidebarComponent,
    PgFeauturesComponent,
    AddAttributesCompnentComponent,
    ReviewsAndRatingsComponent,
    ProjectFinanceComponent,
    MyProfileComponent,
    LoansComponent,
    LeadsComponent,
    CibilComponent,
    SalesComponent,
    CctvComponent,
    InteriorDesignEnquiryComponent,
    SolarEnquiryComponent,
    AddRentComponent,
    RentSidebarComponent,
    RentPostDetailsComponent,
    RentPostFeaturesComponent,
    RentPostGalleryComponent,
    RentPropertyHighlightsComponent,
    RentPropertyLocationHighlightsComponent,
    RentPostPublishComponent,
    PropertyModulesComponent,
    RentTableListComponent,
    PgHostelListComponent,
    AddAssociateComponent,
    ChoosePlanComponent,
    AssociateDashboardComponent,
    SearchPropertiesComponent,
    AssociateMyProfileComponent,
    PropertyListingsComponent,
    ProjectListingsComponent,
    PgListingsComponent,
    MyTeamComponent,
    PropertyDetailsComponent,
    ProjectDescriptionComponent,
    PgDescriptionComponent,
    LoginpopupComponent,
    SharePopUpComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    AboutUsComponent,
    ShippingPolicyComponent,
    CancellationRefundPolicyComponent,
    ChangePasswordComponent,
    SignupPopupComponent,
    RequestSiteVisitPopupComponent,
    FavoritePropertyListComponent,
    FavoriteProjectListComponent,
    FavoritePgListComponent,
    FavoriteListingsComponent,
    LeadsForPropertiesComponent,
    FavoriteProjectsComponent,
    FavoritePgHostelsComponent,
    LeadsForProjectsComponent,
    LeadsForPghostelComponent,
    LoginAssociateComponent,
    PricingPolicyComponent,
    ContactUsComponent,
    DateTimePickerComponent,
    ForgotPasswordMblComponent,
    MbProfileEditComponent,
    MbSearchPropertiesComponent,
    SupportHelpComponent,
    MbChoosePlansComponent,
    LandSurveyComponent,
    LandFencingComponent,
    MbCibilComponent,
    MbLoansComponent,
    MbCctvEnquiryComponent,
    NexusAddonTablesComponent,
    MbLandSurveyComponent,
    MbLandFencingComponent,
    MbSolarComponent,
    DashboardTableComponent,
    MbSearchPropertyPage1Component,
    MbAddMoreLocationPageComponent,
    MbSupportAndHelpComponent,
    MbDashBoardComponent,
    MbMyTeamComponent,
    MbMyPropertiesComponent,
    MbSaleComponent,
    PaymentLogComponent,
    MbPaymentLoginComponent,
    MbPropertyListingsComponent,
    FurnitureEnquiryComponent,
    InteriorEnquiryComponent,
    PackersAndMoversEnquiryComponent,
    AddPlotAndLandComponent,
    PlotPostLocationComponent,
    PlotPostDetailsComponent,
    PlotPostFeaturesComponent,
    PlotPostGalleryComponent,
    PlotPropertyHighlightsComponent,
    PlotPropertyLocationHighlightsComponent,
    PlotPublishComponent,
    PlotSidebarComponent,
    PgPublishComponent,
    PlotAndLandListingsComponent,
    BuyListingsComponent,
    HomeAutomationComponent,
    PropertyInsuranceComponent,
    PrefabHomesAndPropertiesComponent,
    DronesComponent,
    CreditCardComponent,
    MainDashboardComponent,
    IncompleteProfilePopupAlertComponent,

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    FormsModule,
    NgbModalModule,
    ReactiveFormsModule,
    ShareButtonsModule.withConfig({
      debug:true
    }),
    ShareIconsModule,
    AppRoutingModule,
    SharedModule,

    HttpClientModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    NgxPaginationModule,
    NgbModule,
    ToastrModule.forRoot({
      // positionClass: 'toast-bottom-right',
      positionClass: 'toast-top-right',

      timeOut: 10000
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDWb9Xof86boGQouKT9E4hv0ipuu7LyQJM',
      libraries: ['places']
    }),
    NguCarouselModule,
    ImageCropperModule,
    OwlModule,
    
  ],
  providers: [
    LoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [InitialService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initEnvironment,
      deps: [LoaderService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
export function appInit(initService: InitialService): Function {
  return () => initService.loadApp();
}
export function initEnvironment(loaderService: LoaderService): Function {
  return () => loaderService.loadLocation();
}
