import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';

@Component({
  selector: 'app-share-pop-up',
  templateUrl: './share-pop-up.component.html',
  styleUrls: ['./share-pop-up.component.css']
})
export class SharePopUpComponent implements OnInit {
  @Input() url : string;
  facebookIcon = 'assets/img/social.png';

  constructor(private activeModal: NgbActiveModal,
    private lookupmodel:RsbLookupModel
  ) { }
 

  ngOnInit(): void {
    this.lookupmodel.getSelectedPropListingIDs();

  }
    
urlGenerate(): string {
  return this.url
}

getWhatsAppMessageUrl(): string {
  const message = 'Check out this link: ';
  const fullMessage = `${message} ${this.urlGenerate()}`;
  return `https://wa.me/?text=${encodeURIComponent(fullMessage)}`;
}




  close(rsn?: any): void {
    this.activeModal.close();
     
   }
}
