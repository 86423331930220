<section class="Property_search" *ngIf="!isMobileLayout">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 property_leftlist">
        <div style="text-align: center; display: flex;">
          <!-- <button class="btn btn-primary m-2" (click)="favoriteListing()"><i class="ri-heart-3-line"></i> Favorite Properties</button>
              <button class="btn btn-success m-2" (click)="favoriteProjects()"><i class="ri-heart-3-line"></i> Favorite Projects</button>
              <button class="btn btn-primary m-2" (click)=" favoritePgHostels()"><i class="ri-heart-3-line"></i> Favorite PG/Hostels</button>
   -->
          <div class="form-group">
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" name="favCategory" value="Favorite Properties"
                  (change)="selectedFavCategory($event)">
                Favorite Properties
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" name="favCategory" value="Favorite Projects"
                  (change)="selectedFavCategory($event)">
                Favorite Projects
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" name="favCategory" value="Favorite Pg"
                  (change)="selectedFavCategory($event)" checked>
                Favorite Pg
              </label>
            </div>
          </div>

        </div>
        <div class="d-flex justify-content-between pagination_content">
          <!-- <div class="heading">
              <h1>Properties in Hyderabad for Buy</h1>
            </div> -->
        </div>
        <div *ngIf="selectedProp">
          <p
            style="background-color: white; width: 70%; display: flex; justify-content: space-between; align-items: center; padding: 10px;">
            <span>You have selected <b style="font-size: 20px;color: brown;"> {{ selectedPropCount
                }}</b>&nbsp;properties!</span>
            <span>
              <i class="ri-links-line" (click)="copyLink()" style="font-size: 18px; margin-left: 30px;"></i>
              {{copyMessage}}
              <i class="ri-share-forward-line" (click)="sharePopUp()" style="font-size: 18px; margin-left: 30px;"></i>
              <i class="ri-delete-bin-6-line" (click)="cancelSelection()"
                style="font-size: 18px; margin-left: 30px;"></i>
              <button class="white-button" (click)="cancelSelection()">Cancel</button>
            </span>
          </p>
        </div>
        <div class="pb_listItems" *ngFor="let property of listings; let propIndex = index;">
          <div class="row">
            <div class="col-sm-9 col-lg-9 morder-2">
              <div class="ps-wrap">
                <div class="pb_listimage">
                  <a (click)="openProperty(property)">
                    <img
                      [src]="property.images && property.images.length && property.images[0] ? property.images[0] : 'assets/img/property_search_image1.png'"
                      alt="" />
                    <!-- <p class="tag"><span><i class="ri-shield-star-line"></i> Premium</span></p> -->
                  </a>
                </div>

                <div class="ps-content">
                  <div class="d-flex justify-content-between">
                    <div class="list_price"></div>
                    <div class="shareicons">
                      <ul class="list-inline">
                        <li class="list-inline-item">
                          <input type="checkbox" [(ngModel)]="property.selected" (change)="selectedCheckProp(property)"
                            style="width: 20px; height: 20px;">
                        </li>
                        <ng-container *ngIf="!getPlanId">

                          <li class="list-inline-item">
                            <button class="btn btn-primary" (click)="choosePlanForShare(property)"> SHARE <i
                                class="bi bi-share-fill"></i></button>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="getPlanId">



                          <li class="list-inline-item">
                            <button class="btn btn-primary" (click)="shareSingleProp(property)"> SHARE <i
                                class="bi bi-share-fill"></i></button>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                  <h4 class="ps-heading" style="display: inline;cursor: pointer;">{{property.pgName}} </h4><span
                    class="label_color"> in {{property.area}}</span>
                  <div class="sqft_details">
                    <ul class="list-group list-group-horizontal">
                      <li class="list-group-item" *ngFor="let sub of property.attributesNew">
                        <p>{{sub.subCategoryName}}</p>
                        <h6>{{sub.securityDepPerBed}}</h6>
                      </li>

                    </ul>
                  </div>
                  <div>
                    <ng-container
                      *ngIf="!showFullContent[propIndex] && property.pgDescription.length > 100; else shortContent">
                      <p style="font-size: 12px;">{{ property.pgDescription | slice:0:250 }}...<a
                          (click)="toggleContent(propIndex)" style="font-size: 11px;">Read More</a>
                      </p>
                    </ng-container>
                    <ng-template #shortContent>
                      <p style="font-size: 12px;">{{ property.pgDescription }}<a *ngIf="showFullContent[propIndex]"
                          (click)="toggleContent(propIndex)" style="font-size: 11px;">Show Less</a>
                      </p>
                    </ng-template>
                  </div>
                </div>
              </div>

              <div class="bottomDiv morder-last nearby"
                *ngIf="property.locationHighlights && getLocationLength(property.locationHighlights) > 0">
                <ul class="list-inline">
                  <li class="list-inline-item">
                    <p><img src="assets/img/location.svg" alt="places">
                      {{getLocationLength(property.locationHighlights)}}
                      nearby places:</p>
                  </li>
                  <ng-container *ngFor="let item of property.locationHighlights | keyvalue">
                    <ng-container *ngIf="item.value && item.value !== null && item.key!=='aboutLocality'">
                      <li class="list-inline-item">
                        <p class="bgpink">
                          <img *ngIf="getIcon(item.key)" [src]="getIcon(item.key)" alt="places">{{ item.value }}
                        </p>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>

              </div>
            </div>
            <div class="col-sm-3 col-lg-3 morder-1">
              <div class="ps-rightside">
                <div class="list_price text-center">
                  <h2>{{property.price}}</h2>
                </div>
                <ul class="dowloadbrocher list-inline text-center">
                  <li class="list-inline-item">
                    <button class="btnbrocher" (click)="contactOwner(property)">Get Deal</button>
                  </li>
                  <!-- <li class="list-inline-item">
                      <button class="reqvisitbtn" (click)="requestSiteVisit(property)">Request Site Visit</button>
                    </li>
                    <li class="list-inline-item">
                      <button class="btn btn-danger text-white">2 Months EMI <span
                          class="label-warning">Free</span></button>
                    </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div> <!---Pb_listItems-->

      </div> <!--end  of col-9-->

    </div>
  </div>
</section>

<div class="mb-dashboard" *ngIf="isMobileLayout">
  <div class="container">
    <div id="mb_products" class="row">
    <div class="form-group">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio" class="form-check-input" name="favCategory" value="Favorite Properties"
            (change)="selectedFavCategory($event)">
          Favorite Properties
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio" class="form-check-input" name="favCategory" value="Favorite Projects"
            (change)="selectedFavCategory($event)">
          Favorite Projects
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio" class="form-check-input" name="favCategory" value="Favorite Pg"
            (change)="selectedFavCategory($event)" checked>
          Favorite Pg
        </label>
      </div>
    </div>
    <div id="mb_products" class="row"  *ngFor="let property of listings; let propIndex = index;">
      <div class="mb_item col-6 col-lg-4 mb_list_group_item">
        <div class="card">
          <div class="thumbnail ">
            <div class="img-event">
              <!-- <img class="group list-group-image img-fluid" src="assets/mobile/img/property_search_image1.png" alt="" /> -->
              
              <a (click)="openProperty(property)">
                <img class="group list-group-image img-fluid" [src]="property.images && property.images.length && property.images[0] ? property.images[0] : 'assets/img/property_search_image1.png'" alt=""/>
              </a>
            </div>
            
            <div class="caption card-body">
              <div class="mb_capHeader d-flex justify-content-between">
                <div class="mb_capPrice">
                  <h2><span>&#8377;</span>{{property.price}}</h2>

                </div>
                <div class="mb_share">
                  <ul class="list-inline">
                    <!-- <li class="list-inline-item gender"><span>Girls</span></li> -->
                    <!-- <li class="list-inline-item"><i class="ri-share-forward-line share"></i></li>
                    <li class="list-inline-item"><i class="ri-heart-line fav"></i></li> -->
                    <ng-container *ngIf="!getPlanId">
                      <li class="list-inline-item">
                        <span (click)="choosePlanForShare(property)"><i class="ri-heart-line fav"></i></span>
                      </li>
                      <li class="list-inline-item">
                        <span (click)="choosePlanForShare(property)"><i class="ri-share-forward-line share"></i></span>
                      </li>
                      </ng-container>
                      <ng-container *ngIf="getPlanId">
                          
                        <!-- <li class="list-inline-item" *ngIf="!favoriteStates[property.hostelId]">
                          <span (click)="favoriteSingleProp(property)">
                              <b style="color: rgb(111, 111, 216); margin-left: 6px;">FAVORITE</b>
                            </span>
                        </li> -->
                        <li class="list-inline-item" *ngIf="favoriteStates[property.hostelId]">

                          <span (click)="favoriteSingleProp(property)">
                            <i class="ri-heart-line fav"></i>
                          </span>
                        </li>
                      
                        <li class="list-inline-item">
                          <span (click)="shareSingleProp(property)"><i class="ri-share-forward-line share"></i></span>
                        </li>
                      </ng-container>
                  </ul>
                </div>
              </div>
              <!-- <h4 class="group card-title inner list-group-item-heading"> HACK N SLEEP WOMENS PG/Paying Guest HOSTEL
              </h4> -->
              <h4 class="group card-title inner list-group-item-heading">{{property.pgName}} </h4><span class="label_color"> in {{property.area}}</span>
              
                <ng-container *ngIf="!showFullContent[propIndex] && property.pgDescription.length > 30; else shortContent">
                 <p style="font-size: 12px;">{{ property.pgDescription | slice:0:50 }}...<a (click)="morePopup()" style="font-size: 11px;">Read More</a>
                 </p>
                </ng-container>
               
              
              <!-- <p class="mb_captionDes mb-0"><small> 2 BHK Independent House for Rent at OAKLAND MIRACLES The School,
                  Bachupally, Hyderabad </small></p> -->
            </div>
          </div> <!--end thumbnail div-->
         
          <ul class="list-inline mb_nearby">
            <li class="list-inline-item" *ngFor="let ame of property.amenities | slice:0:3">
              <p>
                <i class="ri-wifi-line"></i>
                <small>{{ame.attributePropertyName}}</small>
              </p>
            </li>
            <li class="list-inline-item">
              <a (click)="morePopup(property)"><p class="mb_noBg text-danger">More...</p></a>
            </li>
          </ul>
      
          <ul class="mb_prop_details list-inline pg_details">
            <li class="list-inline-item" *ngFor="let sub of property.attributesNew ">
              <p class="mb-0"> {{sub.subCategoryName}}</p>
              <h6>{{sub.securityDepPerBed}}</h6>
            </li>
          </ul>
          <!-- places popup -->
          <div class="mb_popup" [ngStyle]="{ 'display': mobilePopup ? 'block' : 'none' }" *ngIf="mobilePopup">
            <div class="popup-content">
              <a (click)="morePopup()"><span id="closePopup" class="close-btn">&times;</span></a>
             
              <h4>Amenities</h4>
              <ul class="list-inline pg_nearby">
                <li class="list-inline-item" *ngFor="let ame of property.amenities">
                  <img *ngIf="getAmentiesIcon(ame.attributePropertyName)" [src]="getAmentiesIcon(ame.attributePropertyName)" alt="places">

                  <h6>{{ame.attributePropertyName}}</h6>
                </li>
              </ul>

              <h4 class="mt-4 pg_nearby">Room Sharing</h4>
              <ng-container >
              <ul class="pg_nearby list-inline" >
                <li class="list-inline-item" *ngFor="let sub of property.attributesNew">
                  <p class="mb-0"> {{sub.subCategoryName}}</p>
                  <h6>{{sub.securityDepPerBed}}</h6>
                </li>
              </ul>
              </ng-container>
              <ng-container *ngIf="property.locationHighlights && getLocationLength(property.locationHighlightsNew) > 0">
                <ul class="list-inline mb_amenities">
                  <ng-container *ngFor="let item of morePropertyListing.locationHighlightsNew | keyvalue">
                    <ng-container *ngIf="item.value && item.value !== null">
                  <li>
                    <img *ngIf="getIcon(item.key)" [src]="getIcon(item.key)" alt="places">
                    <p> {{item.value}}</p>
                  </li>
                  </ng-container>
                  </ng-container>
                </ul>
                </ng-container>
              <div class="mb_projectDetail">
                <h4>About PG/Hostel</h4>
                <p>{{property.pgDescription}}</p>
              </div>
            </div>
          </div>
          
          <!-- bottom buttons -->
          <div class="mb_btmbuttons">
            <button class="btn btn-primary mb_deal" (click)="contactOwner(property)"> Contact Owner </button>
          
            <button class="btn btn-primary mb_emi" (click)="requestSiteVisit(property)"> View Phone No. </button>
            <button type="button" class="btn btn-success text-white"><i class="ri-check-line"></i> Beds
              Available</button>
          </div>
         
          
        </div> <!--end card-->
      </div> <!--end mb_item-->

    </div>
    </div>

  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 pb-4 text-center">
        <small class="copy-right-text">All trademarks, logos and names are properties of their respective owners.
          Copyright © 2024 - All rights reserved.</small>
      </div>
    </div>

  </div>

  <footer>
    <nav class="navbar fixed-bottom bg-secondary">
      <div class="container-fluid">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Terms & Conditions </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Policy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Home</a>
          </li>
        </ul>
      </div>
    </nav>
  </footer>
</div>