import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
export class SwatchItem {
  swatchName: string;
}
@Component({
  selector: 'app-pg-highights',
  templateUrl: './pg-highights.component.html',
  styleUrls: ['./pg-highights.component.css']
})
export class PgHighightsComponent implements OnInit {
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  swatchList: Array<SwatchItem> = [];
  isMobileLayout: boolean = false;
  constructor(
    private lookupModel: RsbLookupModel
  ) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
  }
  addSwatch(): void {
    const newswatch = new SwatchItem();
    this.swatchList.push(newswatch);
  }

  deleteSwatch(index: number): void {
    this.swatchList.splice(index, 1);
  }
  back() {
    this.showNextPage.emit(4)
  }
  submit() : void {
    let property = this.lookupModel.getVendorProperty();
    property.projectHighlights = this.swatchList;
    this.lookupModel.setVendorProperty(property);
    this.showNextPage.emit(8);
  }
}

