import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-mb-solar',
  templateUrl: './mb-solar.component.html',
  styleUrls: ['./mb-solar.component.css']
})
export class MbSolarComponent implements OnInit {
  solarRecords:Array<any> = []
  propertyTypes=["Home","Apartment","Villa","Society","Mall / Complex","Agri Land","Others"]
  solarForm:FormGroup;
  isMobileLayout:boolean=false;
  solarTableHeaders = ['Customer Name', 'Project Type', 'Required Budget','Project Location','Mobile Number','Description','Actions'];
  solarTableData: any[] = [];

  isShow:boolean=false;


  constructor(private fb:FormBuilder,
    private rsbService:RsbService,
    private lookupModel:RsbLookupModel
  ) { }
  
  ngOnInit(): void {

    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;

    this.getSolarRecords();
    this.solarForm=this.fb.group({
      projectType:['',[Validators.required]],
      propType:['',[Validators.required]],
      Budget:['',[Validators.required]],
      location:['',[Validators.required]],
      cName:['',[Validators.required]],
      mobile:['',[Validators.required]],
      email:['',[Validators.required]],
      description:['',[Validators.required]]
      
    })
  }
  addPage(): void{
    this.isShow=true;
  }
  closePage(): void{
    this.isShow=false;
  }

  getSolarRecords(): void {
    this.rsbService.getSolar().subscribe((rsp) => {
      if(rsp.statusCode === 0) {
        this.solarRecords = rsp.contents;
        this.solarTableData = rsp.contents.map(record => ({
          'Customer Name': record.cName,
          'Project Type': record.projectType,
          'Required Budget': record.budget,
          'Project Location': record.location,
          'Mobile Number':record.mobile,
          'Description': record.description,
          'Actions': 'delete'
        }));

      }
    })
  }
  submit(){
    if (this.solarForm.invalid) {
      Object.values(this.solarForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    const args={
      projectType:this.projectType.value,
      propertyType:this.propType.value,
      budget:this.budget.value,
      location:this.location.value,
      cName:this.cName.value,
      mobile:this.mobile.value,
      email:this.email.value,
      description:this.description.value,
    }
    this.rsbService.enquiryForSolar(args).subscribe((rsp)=>{
      if(rsp.statusCode===0){
        this.getSolarRecords();
        this.solarForm.reset();
      }
      

    })

  }
  get projectType() {return this.solarForm.get('projectType')}
  get propType() {return this.solarForm.get('propType')}
  get budget() {return this.solarForm.get('Budget')}
  get location() {return this.solarForm.get('location')}
  get cName() {return this.solarForm.get('cName')}
  get mobile() {return this.solarForm.get('mobile')}
  get email() {return this.solarForm.get('email')}
  get description() {return this.solarForm.get('description')}


}


