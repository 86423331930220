<div class="mb-dashboard">
    <div class="container">

        <div class="mb-profile-card">
            <h2>Payment login's</h2>
          
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="mb-3">
                                <h3>Payment Records</h3>
                                <div class="right-search">
                                    <input type="text" class="form-control mb-3 rounded-4" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control  rounded-4 mt-1">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>


                            <div class="table-head-fixed">
                                <table class="table table-striped">
                                    <thead class="table-dark">
                                        <tr>
                                            <th><input type="checkbox"></th>
                                            <th>PaymentId</th>
                                            <th>OrderId</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>status</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let getLogs of getLogsForAssociate">
                                            <td></td>
                                            <td>{{getLogs.paymentId}}</td>
                                            <td>{{getLogs.razorPayOrderId}}</td>
                                            <td>{{getLogs.updatedAt}}</td>
                                            <td>2000</td>
                                            <td *ngIf="getLogs.paymentStatus === 1">
                                                <input type="text" style="text-align: center; width: 100px" class="text text-success" value="Success" readonly>
                                              </td>
                                              <td *ngIf="getLogs.paymentStatus === 0">
                                                <input type="text" style="text-align: center; width: 100px" class="text text-warning" value="Pending" readonly>
                                              </td>
                                              <td *ngIf="getLogs.paymentStatus !== 1 && getLogs.paymentStatus !== 0">
                                                <input type="text" style="text-align: center; width: 100px" class="text text-danger" value="Failed" readonly>
                                              </td>
                                              
                                            <td></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 pb-4 text-center">
                <small class="copy-right-text">All trademarks, logos and names are properties of their respective
                    owners. Copyright © 2024 - All rights reserved.</small>
            </div>
        </div>

    </div>

    <!-- <footer>
        <nav class="navbar fixed-bottom bg-secondary">
            <div class="container-fluid">
                <ul class="nav justify-content-center">
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Terms & Conditions </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Policy</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Home</a>
                    </li>
                </ul>
            </div>
        </nav>                            
    </footer> -->
</div>