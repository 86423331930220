<div class="table-responsive" *ngIf="!isMobileLayout">
    <table class="table table-hover">
    <thead class="">
        <tr>
        <th scope="col">S.NO</th>
        <th scope="col">Hostel Id</th>
        <th scope="col">Hostel Name</th>
        <th scope="col">Date Added</th>                           
    </tr>
    </thead>
    <tbody>
        <tr *ngFor="let listings of allListings; let i =index">
        <td>{{i+1}}</td>
        <td>{{listings.hostelId}}</td>
        <td>{{listings.pgName}}</td>
        <td>{{listings.createdAt}}</td>
        <!-- <td><button class="btn btn-success btn-sm">Edit</button></td> -->
        </tr>
    
    </tbody>
    </table>
</div>
<div class="table-head-fixed" *ngIf="isMobileLayout">
    <table class="table table-striped">
        <thead class="table-dark">
            <tr>
                <th scope="col"><input type="checkbox"></th>
                <th scope="col">Hostel Id</th>
                <th scope="col">Hostel Name</th>
                <th scope="col">Date Added</th>                           
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let listings of allListings; let i =index">
                <td><input type="checkbox"></td>
                <td>{{listings.hostelId}}</td>
                <td>{{listings.pgName}}</td>
                <td>{{listings.createdAt}}</td>
                <!-- <td><button class="btn btn-success btn-sm">Edit</button></td> -->
                </tr>
        </tbody>
    </table>
</div>