<!-- <div class="container-fluid" *ngIf="!isMobileLayout">
    <div class="pb-wrapper bg-white p-4">
        
            <h2 class="d-inline-block mr-3 mb-4">Loans</h2>
            <form class="agent-profile" [formGroup]="loanApplication" (ngSubmit)="submit()">

                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" checked id="Home" value="Home Loan" formControlName="loanType">
                    <label class="custom-control-label" for="Home">Home Loan</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="Business" value="Business Loan" formControlName="loanType">
                    <label class="custom-control-label" for="Business">Business Loan</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="Mortgage" value="Mortgage Loan" formControlName="loanType">
                    <label class="custom-control-label" for="Mortgage">Mortgage Loan</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="Against" value="Loan Against Property" formControlName="loanType">
                    <label class="custom-control-label" for="Against">Loan Against Property</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="Construction" value="Construction Loan" formControlName="loanType">
                    <label class="custom-control-label" for="Construction">Construction Loan</label>
                </div>
            <div class="form-row mb-5">
                <div class="col">
                    <label for="Name">Client Nameee</label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="name" name="name">
                </div>
                <div class="col">
                    <label for="Agency Name">Mobile Number</label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="mobile" name="mobile">
                </div>
                <div class="col">
                    <label for="RERA Number">Email Id</label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="email" name="email">
                </div>
                <div class="col">
                    <label for="Operating since">Required Loan Amount</label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="loanAmount" name="loanAmount">
                </div>
            </div>
            <div class="form-row mb-2">
                <div class="col">
                    <label for="Name">PAN Number</label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="pan" name="pan">
                </div>
                <div class="col">
                    <label for="Agency Name">Source of Income</label>
                    <select class="form-control" formControlName="income" name="income">
                        <option value="">Select</option>
                        <option value="1">Salary</option>
                        <option value="2">Business</option>
                        <option value="3">Others</option>
                    </select>
                </div>
                <div class="col">
                    <label for="RERA Number">City</label>
                    <select class="form-control" formControlName="city" name="city">
                        <option value="">Select</option>
                        <option value="1">Mumbai</option>
                        <option value="2">Pune</option>
                        <option value="3">Nagpur</option>
                    </select>
                </div>
                <div class="col">
                    <label for="Operating since">Monthly Total Debits</label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="monthlyDebits" name="monthlyDebits">
                    <small class="text-muted">Loan EMi;s + Credit Card Payments</small>
                </div>
            </div>
            <button class="btn btn-primary pb-save" type="submit">ADD</button>
        </form>
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">Loans </h3>
                                </form>
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover agent-table">
                                <thead class="">
                                    <tr>
                                        <th><input type="checkbox"></th>
                                        <th>Client Name</th>
                                        <th>Date Added</th>
                                        <th>Mobile Number</th>
                                        <th>Email</th>
                                        <th>Required Loan Amount</th>
                                        <th>Source of Income</th>
                                        <th>City</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let list of getLoansListings">
                                        <td><input type="checkbox"></td>
                                        <td>{{list.name}}</td>
                                        <td>{{list.createdAt | date: 'short'}}</td>
                                        <td>{{list.mobile}}</td>
                                        <td>{{list.email}}</td>
                                        <td>{{list.loanAmount}}</td>  
                                        <td>{{list.income}}</td> 
                                        <td>{{list.city}}</td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                   
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
    

</div> -->
<div class="container-fluid" *ngIf="!isMobileLayout">
    <div class="pb-wrapper bg-white p-4">
        <h2 class="d-inline-block mr-3 mb-4">Loans</h2>

        <form [formGroup]="loanForm" (ngSubmit)="submit()" #loanApplication="ngForm">
            <div class="mb-5" [ngClass]="{'invalid-radio-group': loanForm.get('loanType').invalid && (loanForm.get('loanType').dirty || loanForm.get('loanType').touched)}">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" class="custom-control-input" id="personal" name="loanType" value="Personal Loan" formControlName="loanType">
                <label class="custom-control-label" for="Home">Personal Loan</label>
              </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="Home" name="loanType" value="Home Loan" formControlName="loanType">
                  <label class="custom-control-label" for="Home">Home Loan</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="Business" name="loanType" value="Business Loan" formControlName="loanType">
                  <label class="custom-control-label" for="Business">Business Loan</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="Mortgage" name="loanType" value="Mortgage Loan" formControlName="loanType">
                  <label class="custom-control-label" for="Mortgage">Mortgage Loan</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="Against" name="loanType" value="Loan Against Property" formControlName="loanType">
                  <label class="custom-control-label" for="Against">Loan Against Property</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="Construction" name="loanType" value="Construction Loan" formControlName="loanType">
                  <label class="custom-control-label" for="Construction">Construction Loan</label>
                </div>
              
                <div *ngIf="loanForm.get('loanType').invalid && (loanForm.get('loanType').dirty || loanForm.get('loanType').touched)">
                  <div class="text-danger">
                    Please select a loan type.
                  </div>
                </div>
              </div>
              
             
       
            <div class="form-row mb-5">
                <div class="col">
                    <label for="Name">Client Name<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="name">
                    <div *ngIf="loanForm.get('name').invalid && (loanForm.get('name').dirty || loanForm.get('name').touched)">
                        <div *ngIf="loanForm.get('name').errors?.required" class="text-danger">
                          Name is required.
                        </div>
                    </div>
                </div>
                <div class="col">
                    <label for="Agency Name">Mobile Number<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="mobile">
                    <div *ngIf="loanForm.get('mobile').invalid && (loanForm.get('mobile').dirty || loanForm.get('mobile').touched)">
                        <div *ngIf="loanForm.get('mobile').errors?.required" class="text-danger">
                            Mobile Number is required.
                        </div>
                    </div>
                </div>
                <div class="col">
                    <label for="Email">Email Id<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="email">
                    
                    <div *ngIf="loanForm.get('email').invalid && (loanForm.get('email').dirty || loanForm.get('email').touched)">
                      <div *ngIf="loanForm.get('email').errors?.required" class="text-danger">
                        Email is required.
                      </div>
                      <div *ngIf="loanForm.get('email').errors?.email" class="text-danger">
                        Please enter a valid email address.
                      </div>
                      <div *ngIf="loanForm.get('email').errors?.pattern" class="text-danger">
                        Please enter a valid email with a common domain (e.g., name@example.com).
                      </div>
                    </div>
                  </div>
                  
                  
                  
                <div class="col">
                    <label for="Operating since">Required Loan Amount<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="loanAmount">
                    <div *ngIf="loanForm.get('loanAmount').invalid && (loanForm.get('loanAmount').dirty || loanForm.get('loanAmount').touched)">
                        <div *ngIf="loanForm.get('loanAmount').errors?.required" class="text-danger">
                            Loan Amount  is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row mb-2">
                <div class="col">
                    <label for="Name">PAN Number<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="panNum">
                    <div *ngIf="loanForm.get('panNum').invalid && (loanForm.get('panNum').dirty || loanForm.get('panNum').touched)">
                        <div *ngIf="loanForm.get('panNum').errors?.required" class="text-danger">
                          PAN Number is required.
                        </div>
                        <div *ngIf="loanForm.get('panNum').errors?.invalidPAN" class="text-danger">
                          PAN Number is invalid.
                        </div>
                      </div>
                </div>
                <div class="col">
                    <label for="Agency Name">Source of Income<span class="text-danger">*</span></label>
                    <select class="form-control" formControlName="income">
                        <option value="">Select</option>
                        <option value="Salary">Salaried</option>
                        <option value="Business">Business</option>
                        <option value="Business">Employee</option>
                        <option value="Self Employed">Self Employed</option>
                        <option value="Govt Employee">Govt Employee</option>
                        <option value="Retired Employee">Retired Employee</option>
                        <option value="Retired Employee">Retired Employee</option>




                        <option value="Others">Others</option>
                    </select>
                    <div *ngIf="loanForm.get('income').invalid && (loanForm.get('income').dirty || loanForm.get('income').touched)">
                        <div *ngIf="loanForm.get('income').errors?.required" class="text-danger">
                            Source Of Income  is required.
                        </div>
                    </div>
                </div>
                <div class="col">
                        <label for="city">City<span class="text-danger">*</span></label>
                        <input type="text" #cityText class="form-control" placeholder="Enter City" id="city" name="city"
                            formControlName="city">
                            <span *ngIf="(loanApplication.submitted || loanApplication.touched) && loanApplication.errors && loanApplication.errors.required" style="color: red">City
                                is required.</span>
                       
                </div>
                <div class="col">
                    <label for="Operating since">Monthly Total Debits<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="debits">
                    <div *ngIf="loanForm.get('debits').invalid && (loanForm.get('debits').dirty || loanForm.get('debits').touched)">
                        <div *ngIf="loanForm.get('debits').errors?.required" class="text-danger">
                            Monthly Total Debits  is required.
                        </div>
                    </div>
                    <small class="text-muted">Loan EMi;s + Credit Card Payments</small>

                </div>
            </div>
            <button class="btn btn-primary pb-save" type="submit">ADD</button>
            <button class="btn btn-primary pb-save m-3" (click)="resetForm()">Reset</button>
        </form>
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">Loans </h3>
                                </form>
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;"  placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover agent-table">
                                <thead class="">
                                    <tr>
                                        <th><input type="checkbox"></th>
                                        <th>Client Name</th>
                                        <th>Date Added</th>
                                        <th>Mobile Number</th>
                                        <th>Email</th>
                                        <th>Required Loan Amount</th>
                                        <th>Source of Income</th>
                                        <th>City</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let list of getLoansListings">
                                        <td><input type="checkbox"></td>
                                        <td>{{list.name}}</td>
                                        <td>{{list.createdAt | date: 'short'}}</td>
                                        <td>{{list.mobile}}</td>
                                        <td>{{list.email}}</td>
                                        <td>{{list.loanAmount}}</td>  
                                        <td>{{list.income}}</td> 
                                        <td>{{list.city}}</td>
                                        <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Submitted" *ngIf="list" readonly></td>
                                    </tr>
                                 
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Row -->
    

</div>

<div class="mb-dashboard" *ngIf="isMobileLayout">
    <div class="container">
  
      <div class="mb-profile-card">
        <h2>Loans</h2>
        <div class="col-12">
          <p class="btn btn-light rounded-pill addbtn mb-0" (click)="addPage()"><i class="ri-add-line"></i> Add</p>
        </div>
        <div class="mb-edit-profile">
  
            <!-- <div class="mb-profile-name">
            
            </div> -->
            <div class="mb-cibil-popup" [ngStyle]="{'display':isShow?'block':'none'}">
             <span id="closePopup" class="close-btn" (click)="closePage()">&times;</span>

             <form [formGroup]="loanForm" (ngSubmit)="submit()" #loanApplication="ngForm">
                <div class="mb-5" [ngClass]="{'invalid-radio-group': loanForm.get('loanType').invalid && (loanForm.get('loanType').dirty || loanForm.get('loanType').touched)}">

                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="Home" name="loanType" value="Home Loan" formControlName="loanType">
                    <label class="custom-control-label" for="Home">Home Loan</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="Business" name="loanType" value="Business Loan" formControlName="loanType">
                    <label class="custom-control-label" for="Business">Business Loan</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="Mortgage" name="loanType" value="Mortgage Loan" formControlName="loanType">
                    <label class="custom-control-label" for="Mortgage">Mortgage Loan</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="Against" name="loanType" value="Loan Against Property" formControlName="loanType">
                    <label class="custom-control-label" for="Against">Loan Against Property</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="Construction" name="loanType" value="Construction Loan" formControlName="loanType">
                    <label class="custom-control-label" for="Construction">Construction Loan</label>
                  </div>
                  <div *ngIf="loanForm.get('loanType').invalid && (loanForm.get('loanType').dirty || loanForm.get('loanType').touched)">
                    <div class="text-danger">
                      Please select a loan type.
                    </div>
                  </div>
                  </div>

              <div class="row g-2">
  
                <div class="col-12">
                    <label for="Name">Client Name<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="name">
                    <div *ngIf="loanForm.get('name').invalid && (loanForm.get('name').dirty || loanForm.get('name').touched)">
                        <div *ngIf="loanForm.get('name').errors?.required" class="text-danger">
                          Name is required.
                        </div>
                    </div>
                   
                </div>
                <div class="col-12">
                    <label for="Agency Name">Mobile Number<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="mobile">
                    <div *ngIf="loanForm.get('mobile').invalid && (loanForm.get('mobile').dirty || loanForm.get('mobile').touched)">
                        <div *ngIf="loanForm.get('mobile').errors?.required" class="text-danger">
                            Mobile Number is required.
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <label for="RERA Number">Email Id<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="email">
                    <div *ngIf="loanForm.get('email').invalid && (loanForm.get('email').dirty || loanForm.get('email').touched)">
                        <div *ngIf="loanForm.get('email').errors?.required" class="text-danger">
                          Email is required.
                        </div>
                       
                        <div *ngIf="loanForm.get('email').errors?.pattern" class="text-danger">
                          Please enter a valid email with a common domain (e.g., name@example.com).
                        </div>
                      </div>
                </div>
                <div class="col-12">
                    <label for="Operating since">Required Loan Amount<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="loanAmount">
                    <div *ngIf="loanForm.get('loanAmount').invalid && (loanForm.get('loanAmount').dirty || loanForm.get('loanAmount').touched)">
                        <div *ngIf="loanForm.get('loanAmount').errors?.required" class="text-danger">
                            Loan Amount  is required.
                        </div>
                    </div>
              </div>
              <div class="col-12">
              <label for="Name">PAN Number<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="panNum">
                    <div *ngIf="loanForm.get('panNum').invalid && (loanForm.get('panNum').dirty || loanForm.get('panNum').touched)">
                        <div *ngIf="loanForm.get('panNum').errors?.required" class="text-danger">
                          PAN Number is required.
                        </div>
                        <div *ngIf="loanForm.get('panNum').errors?.invalidPAN" class="text-danger">
                          PAN Number is invalid.
                        </div>
                      </div>
                      </div>
                      <div class="col-12">
                        <label for="Agency Name">Source of Income<span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="income">
                            <option value="">Select</option>
                            <option value="Salary">Salaried</option>
                            <option value="Business">Business</option>
                            <option value="Business">Employee</option>
                            <option value="Self Employed">Self Employed</option>
                            <option value="Govt Employee">Govt Employee</option>
                            <option value="Retired Employee">Retired Employee</option>
                            <option value="Retired Employee">Retired Employee</option>
    
    
    
    
                            <option value="Others">Others</option>
                        </select>
                        <div *ngIf="loanForm.get('income').invalid && (loanForm.get('income').dirty || loanForm.get('income').touched)">
                            <div *ngIf="loanForm.get('income').errors?.required" class="text-danger">
                                Source Of Income  is required.
                            </div>
                        </div>

                      </div>
                      <div class="col">
                        <label for="city">City<span class="text-danger">*</span></label>
                        <input type="text" #cityText class="form-control" placeholder="Enter City" id="city" name="city"
                            formControlName="city">
                            <span *ngIf="(loanApplication.submitted || loanApplication.touched) && loanApplication.errors && loanApplication.errors.required" style="color: red">City
                                is required.</span>
                       
                </div>
                      <div class="col-12">
                        <label for="Operating since">Monthly Total Debits<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" placeholder="Type here" formControlName="debits">
                        <div *ngIf="loanForm.get('debits').invalid && (loanForm.get('debits').dirty || loanForm.get('debits').touched)">
                            <div *ngIf="loanForm.get('debits').errors?.required" class="text-danger">
                                Monthly Total Debits  is required.
                            </div>
                        </div>
                        <small class="text-muted">Loan EMi;s + Credit Card Payments</small>

                      </div>
         
  
              <div class="d-flex">
                <button class="btn btn-primary btn-small-width" type="submit">Submit</button>
                <button class="btn btn-secondary btn-small-width ml-3" (click)="resetForm()">Reset</button>
                
            </div>
             
              
              
  
  
              </div>
            </form>
  
            </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="all-leads">
            <div class="card shadow mb-4">
              <div class="card-body">
                <div class="mb-3">
                  <h3>Loans</h3>
                  <div class="right-search">
                    <input type="text" class="form-control mb-3 rounded-4" placeholder="Search">
                    <label for="Sort by:">Sort by:</label>
                    <select class="form-control  rounded-4 mt-1">
                      <option>Monthly</option>
                      <option>Weekly</option>
                      <option>Daily</option>
                    </select>
                  </div>
                </div>
  
  
                <div class="table-head-fixed">
                  <table class="table table-striped">
                    <thead class="table-dark">
                        <tr>
                            <th><input type="checkbox"></th>
                            <th>Client Name</th>
                            <th>Date Added</th>
                            <th>Mobile Number</th>
                            <th>Email</th>
                            <th>Required Loan Amount</th>
                            <th>Source of Income</th>
                            <th>City</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let list of getLoansListings">
                            <td><input type="checkbox"></td>
                            <td>{{list.name}}</td>
                            <td>{{list.createdAt | date: 'short'}}</td>
                            <td>{{list.mobile}}</td>
                            <td>{{list.email}}</td>
                            <td>{{list.loanAmount}}</td>  
                            <td>{{list.income}}</td> 
                            <td>{{list.city}}</td>
                            <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Submitted" *ngIf="list" readonly></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pb-4 text-center">
          <small class="copy-right-text">All trademarks, logos and names are properties of their respective
            owners. Copyright © 2024 - All rights reserved.</small>
        </div>
      </div>
  
    </div>
  
    <!-- <footer>
      <nav class="navbar fixed-bottom bg-secondary">
        <div class="container-fluid">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Terms & Conditions </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Home</a>
            </li>
          </ul>
        </div>
      </nav>
    </footer> -->
  </div>