<!-- <section class="section-padding bg-white">
    <div class="container">
        <div class="row  mt-5">
            <div class="pl-4 col-lg-5 col-md-5 pr-4">
                <img class="rounded img-fluid" src="assets/img/slider/2.jpg" alt="RSB">
            </div>
            <div class="col-lg-6 col-md-6 pl-5 pr-5">
                <h2 class="mb-3">Privacy Policy</h2>

                <p>“PillarBlocks.com” is a unit Of Teamwork Innovations Pvt Ltd committed to protecting and respecting your privacy. This Privacy Policy governs the manner in which . (“connexU,” “we,” or “us”) collects, uses, maintains and discloses information collected from users (a “user,” as well as “you,” “your” and similar terms). This privacy policy applies to each  website, event, app and other services (collectively, the “Services”) that refers or links to this Privacy Policy. </p>
                <p>By using the website or any of the Services, you signify your acceptance of this Privacy Policy and consent to Your Information being used as described. If you do not agree to this Privacy Policy, please do not use the Services.</p>
                <p><b>Personal information we collect</b></p>
                <p>We collect information about you in three ways: (1) from your input through our web forms, name badge scanners, app forms and other mechanisms, (2) from third-party sources, and (3) through automated technologies. </p>
                <p><b>Data you provide to us</b></p>
                <p>The types of personal information that we collect directly from you depends on how you interact with us and the Services and may include:
                </p>
                <p>Contact details, such as name, email address, postal address, social media handle and phone number</p>
                <p>Account login credentials, such as username and password, password hints and similar security information</p>
                <p>Other account or event registration and profile information, such as educational, employment and professional background, dietary and accessibility requirements, and photo</p>
                <p>Payment information, such as credit or debit card number</p>
                <p>Comments, feedback and other information you provide to us, including information that you send to customer support and messages, appointment inquiries and other information that you wish us to share with our exhibitors, visitors, partners and others</p>
                <p>Sensitive data revealing health, disability, religion, or race or ethnic origin, as part of a request for special assistance or dietary requirements or in a medical emergency; and/or</p>
                <p>To protect the safety and wellbeing of event participants, we may conduct disease prevention measures, which may include collection of health data through temperature screening, test-and-trace with the relevant health authority, monitoring for visible symptoms, or referral to quarantine or medical personnel.</p>
                <p><b>Data from third-party sources</b></p>
                <p>We also may obtain personal information about you from third parties, including:
                    <li>Service providers that help us determine a location based on your IP address in order to customise certain products to your location;</li>
                    <li>Partners with which we offer co-branded services or engage in joint marketing activities;</li>
                    <li>Social networks when you grant permission to the Services to access your data on one or more networks; and/or</li>
                    <li>	Publicly-available sources and data suppliers from which we obtain data to validate or supplement the information we hold.</li>
                </p>
                <p><b>Data from automated technologies</b></p>
                <p>When you visit the sites and interact with our Services, we may automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you use our Services, we collect information about the individual web pages, services or products that you view, what websites or search terms referred you to the Services, and information about how you interact with the Services. We refer to this automatically-collected information as “Device Information”.</p>
                <p>We collect Device Information using the following technologies:

                    <li>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</li>
                    <li>“Log files” track actions occurring on the Services, and collect data including your IP address, browser type, internet service provider, referring/exit pages, and date/time stamps.</li>
                    <li>“Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the Services.</li>
                    <li>	“Other Technologies” including Bluetooth and RFID.</li>

                </p>
                <p>We also collect footage and imagery captured through recording, photography, at events, offices and other locations.</p>
                            </div>
                            <p>How do we use your Personal Information?</p>
                            <p>Depending on how you interact with us and the Services, we use your personal information to:
                                <li>Provide, activate and manage your access to and use of the Services, including your attendance at events;</li>
                                <li>Process and fulfil a request, order, download or other transaction</li>
                                <li>Provide customer, technical, product and other support and to help keep the Services working, safe and secure;</li>
                                <li>Enhance and improve the Services and our other products and services and to develop new products, services and benefits</li>
                                <li>Offer you customised content and other personalisation to make the Services more relevant to your interests and geography</li>
                                <li>Respond to your requests, inquiries, comments and concerns</li>
                                <li>Notify you about changes, updates and other announcements related to the Services and our other products and services</li>
                                <li>Deliver targeted advertisements, promotional messages, notices, and other information about products, events and services of ours, our affiliates and third parties, such as exhibitors, sponsors, licensees and media partners</li>
                                <li>Identify usage trends and develop data analysis, including for purposes of research, audit, reporting and other business operations, including determining the effectiveness of our promotional campaigns and evaluating our business performance</li>
                                <li>Support health and safety at our events</li>
                                <li>Comply with our legal obligations, resolve disputes, and enforce our agreements.</li>
                            </p>
                            <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site and Digital Resources (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p>
                            <p>We also use Device Information to supply you with advertising that is more relevant to you when you are visiting our or other websites that promote our products (retargeting cookies).</p>
                            <p>We use the Professional Profile Information that we collect to help us connect you with other members of the Hero Series community and to supply you with advertising that is more relevant to you when you are visiting our or other websites that promote our products.</p>
                            <p><b>Sharing Your Information</b></p>
                            <p>Depending on the Services provided, we share your Personal Information with third parties to help us provide the services described above including but not limited to our service providers, suppliers, agents and representatives, including but not limited to, payment processors, venue providers, onsite event contractors, customer support, email service providers, IT service providers, mailing houses and shipping agents, to process the information as necessary to provide the Services, complete a transaction, fulfil your request, or otherwise on our behalf based on our instructions and in compliance with this privacy policy and any other appropriate confidentiality and security measures.</p>
                            <p>For example, use Google Analytics to help us understand how our customers use the Site – you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.</p>
                            <p>We may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights. We will share Your Information and/or your Device Information with any successor to our business.</p>
                            <p><b>Behavioural advertising</b></p>
                            <p>As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</p>
                            <p>You can opt out of targeted advertising by using the links below:
                               <li> – Facebook: https://www.facebook.com/settings/?tab=ads</li>
                               <li>– Google: https://www.google.com/settings/ads/anonymous</li>
                               <li>– Bing: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</li>
                               <li>– LinkedIn: https://www.linkedin.com/legal/privacy-policy</li>
                            </p>
                            <p>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: http://optout.aboutads.info/.</p>
                            <p><b>Embedded content from other websites</b></p>
                            <p>Articles in our Services may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>
                            <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website. You should satisfy yourself about the Privacy Policy of each of these sites</p>
                            <p><b>Your rights</b></p>
                            <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated or deleted. You may request that we cease processing Your Information in which case we shall cease to do so but that does not apply to Information previously processed. If you would like to exercise this right, please contact us through the contact information below.</p>
                            <p>Additionally, if you are a Asia & Europe resident you should note that we are processing Your Information in order to fulfill contracts we might have with you (e.g. if you place an order through the Site) in accordance with the GDPR and the safeguards imposed by those regulations, or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Asia, Europe, including to Australia, Canada and the United States.</p>
                            <p>Regardless of where you are located, if you have an account on our Services, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase or correct any personal data we hold about you. An erasure your request does not include any data we are obliged to keep for administrative, legal, or security purposes. Contact us as set out below to obtain access or to correct the data we hold.</p>
                            <p><b>Data retention</b></p>
                            <p>When you submit personal information through the Services or interact with our Services, we retain your personal information for as long as necessary to provide the Services and fulfil the transactions you have requested, or for other essential purposes such as complying with our legal obligations, maintaining business and financial records, resolving disputes, maintaining security, detecting and preventing fraud and abuse, and enforcing our agreements.</p>
                            <p><b>Complaints Procedure</b></p>
                            <p>If you have a complaint about the manner in which we maintain the privacy of Your Information, contact us as set out below. We may seek further information from you and if we agree to your complaint is to be well founded, we will consult with you to take steps to rectify the problem. If you are not satisfied you may refer to the matter to Australian Information Commissioner or a Data Protection Authority in your jurisdiction.</p>
                            <p><b>Changes</b></p>
                            <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
                            <p><b>Minors</b></p>
                            <p>The Services are not intended for individuals under the age of 18.</p>
        </div>

    </div>
    

</section> -->
<!-- End About -->
<!-- What We Provide -->

<!-- End What We Provide -->

<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="my-4 p-5 bg-light rounded">
                    <h3><b>Privacy Policy : ( Last Updated July 2024 )</b></h3>

<p>Pillarblocks.com, a unit of Teamwork Innovations Pvt Ltd ("we") operates the pillarblocks.com a real estate marketplace platform (the "Platform"). This Privacy Policy outlines how we collect, use, disclose, and protect the personal information of users ("you" or "users") of the Platform.</p>




<h5><b>1. Information We Collect</b></h5>

<p>1.1 Information You Provide: We collect information that you voluntarily provide when using the Platform, such as when you create an account with Name, Mobile number & email id, submit property listings, make inquiries, or communicate with other users. This information may include your name, email address, phone number, mailing address, property details, and other information you choose to provide.</p>

<p>1.2 Automatically Collected Information: We may automatically collect certain information when you access or use the Platform, including your IP address, device type, browser type, operating system, and usage data.</p>

<p>1.3 Cookies and Similar Technologies: We may use cookies, web beacons, and similar technologies to collect information about your interactions with the Platform and to improve your experience. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the Platform may become inaccessible or not function properly.</p>

<h5><b>2. How We Use Your Information</b></h5>

<p><b>We may use the information we collect for the following purposes:</b></p>

<li>To provide, operate, and improve the Platform.</li>
<li>To personalize your experience and tailor content and advertisements to your interests.</li>
<li>To communicate with you ( SMS, email & App Notifications ), respond to your inquiries, and provide customer support.</li>
<li>To facilitate transactions and interactions between users.</li>
<li>To comply with legal obligations and enforce our policies.</li>

<h5><b>3. How We Share Your Information</b></h5>

<p><b>We may share your information with third parties for the following purposes:</b></p>

<li>With other users of the Platform, as necessary to facilitate transactions and interactions.</li>
<li>With service providers who assist us in operating the Platform and providing services to you.</li>
<li>With third-party vendors and advertisers for marketing and advertising purposes.</li>
<li>In response to legal process or requests from law enforcement or government authorities.
In connection with a merger, acquisition, or sale of assets.</li>

<h5><b>4. Data Security</b></h5>

<p>We take reasonable measures to protect the security of your personal information and prevent unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.</p>

<h5><b>5. Your Choices</b></h5>

<p>You may update, correct, or delete your account information at any time by logging into your account settings. You may also opt out of receiving promotional emails from us by following the instructions provided in those emails. Please note that even if you opt out of receiving promotional communications, we may still send you non-promotional communications, such as those related to your account or transactions.</p>

<h5><b>6. Children's Privacy</b></h5>

<p>The Platform is not intended for children under the age of 18, and we do not knowingly collect personal information from children under the age of 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete the information.</p>

<h5><b>7. Changes to this Privacy Policy</b></h5>

<p>We may update this Privacy Policy from time to time in our sole discretion. If we make material changes to this Privacy Policy, we will notify you by posting the updated Privacy Policy on the Platform and updating the "Last Updated" date at the top of this page.</p>

<h5><b>8. Contact Us</b></h5>

<p>If you have any questions about this Privacy Policy or our privacy practices, please contact us at info@pillarblocks.com</p>

                  
                </div>
            </div>
        </div>
    </div>
</section>