<div class="row">
    <div class="col-xl-12 col-lg-12">
        <div class="all-leads mt-5">
            <div class="card shadow mb-4">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <form>
                            <h3 class="d-inline-block mr-3 mb-0">
                              All Leads <span class="badge badge-pill badge-secondary">26</span>
                            </h3>
                            <div class="custom-control custom-control-inline">
                              <select class="form-control" [(ngModel)]="selectedModule" id="moduleSelect" (change)="changeMainModule($event.target.value)">
                                <option value="properties">Properties</option>
                                <option value="projects">Projects</option>
                                <option value="pg/hostels">PG/Hostels</option>
                              </select>
                            </div>
                          
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="leadsRadio" name="leads" value="leads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                              Leads
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="dealLeadsRadio" name="leads" value="dealLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                              Deal Leads
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="siteVisitLeadsRadio" name="leads" value="siteVisitLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                              Site Visit Leads
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="emiLeadsRadio" name="leads" value="emiLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                              EMI Leads
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="emiLeadsRadio" name="leads" value="videoCall" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                              Video Call
                            </div>
                        
                          </form>
                        <div class="right-search">
                          <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                            <label for="Sort by:">Sort by:</label>
                            <!-- <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                <option>Monthly</option>
                                <option>Weekly</option>
                                <option>Daily</option>
                            </select> -->
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-hover table-responsive agent-table">
                        <thead class="">
                            <tr>
                                <th>Client Name</th>
                                <th>Mobile Number</th>
                                <th>Email ID</th>
                                <th>Date Added</th>
                                <th>Follow up Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let lead of tableRecords"> 
                                <td>{{lead.personalDetails?.firstName?lead.personalDetails?.firstName : lead?.customerName  }}</td>
                                <td>{{lead.personalDetails?.mobile ? lead.personalDetails?.mobile : lead?.cmobile}}</td>
                                <td>{{lead.personalDetails?.email ? lead.personalDetails?.email : lead?.cemail }}</td>
                                <td>{{lead.createdAt}}</td>
                                <td>{{lead.createdAt}}</td>
                               
                                    <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Success"  readonly></td>

                                
                            </tr>
                           
                        </tbody>
                        </table>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</div>