import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-companyinfo',
  templateUrl: './companyinfo.component.html',
  styleUrls: ['./companyinfo.component.css']
})
export class CompanyinfoComponent implements OnInit {

  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  propertyForm: FormGroup;
  selectedRentHeading: any;
  selectedCompany: any = {};
  awsFile: FileList;
  companyLogo: Array<string> = [''];
  constructor(
    private fb: FormBuilder,
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private router: Router
  ) { }


  ngOnInit(): void {

    this.selectedCompany = this.lookupModel.getBuilderCompany() ? this.lookupModel.getBuilderCompany() : {} ;
    this.propertyForm = this.fb.group({
      companyName: [this.selectedCompany && this.selectedCompany.companyName ? this.selectedCompany.companyName: '', [Validators.required]],
      cinNumber: [this.selectedCompany && this.selectedCompany.cinNumber ? this.selectedCompany.cinNumber : '',[Validators.required]],
      gstNumber: [this.selectedCompany && this.selectedCompany.gstNumber ? this.selectedCompany.gstNumber : '',[Validators.required]],
      contactNumber: [this.selectedCompany && this.selectedCompany.contactNumber ? this.selectedCompany.contactNumber : '',[Validators.required]],
      contactPerson: [this.selectedCompany && this.selectedCompany.contactPerson ? this.selectedCompany.contactPerson : '',[Validators.required]],
      companyNumber: [this.selectedCompany && this.selectedCompany.companyNumber ? this.selectedCompany.companyNumber : '', [Validators.required]],
      contactEmail: [this.selectedCompany && this.selectedCompany.contactEmail ? this.selectedCompany.contactEmail : '', [Validators.required]],
      established: [this.selectedCompany && this.selectedCompany.established ? this.selectedCompany.established : '', [Validators.required]],
      awards: [this.selectedCompany && this.selectedCompany.awards ? this.selectedCompany.awards : '', [Validators.required]],
      recognizations: [this.selectedCompany && this.selectedCompany.recognizations ? this.selectedCompany.recognizations : '', [Validators.required]],
      licencenumber: [this.selectedCompany && this.selectedCompany.licencenumber ? this.selectedCompany.licencenumber : '', [Validators.required]],
      companyAddress: [this.selectedCompany && this.selectedCompany.companyAddress ? this.selectedCompany.companyAddress : '', [Validators.required]],
      companyProfile: [this.selectedCompany && this.selectedCompany.companyProfile ? this.selectedCompany.companyProfile : '', [Validators.required]],
      companyDescription: [this.selectedCompany && this.selectedCompany.companyDescription ? this.selectedCompany.companyDescription : '', [Validators.required]],
     });
  }

  submit(formValue: any) {
    if (this.propertyForm.invalid ) {
      return;
    }
    let propertyInfo:any = this.lookupModel.getBuilderCompany() ? this.lookupModel.getBuilderCompany() : {} ;
    propertyInfo.companyName = formValue.companyName;
    propertyInfo.cinNumber = formValue.cinNumber;
    propertyInfo.gstNumber = formValue.gstNumber;
    propertyInfo.contactNumber = formValue.contactNumber;
    propertyInfo.contactPerson = formValue.contactPerson;
    propertyInfo.companyNumber = formValue.companyNumber;
    propertyInfo.contactEmail = formValue.contactEmail;
    propertyInfo.established = formValue.established;
    propertyInfo.awards = formValue.awards;
    propertyInfo.recognizations = formValue.recognizations;
    propertyInfo.licencenumber = formValue.licencenumber;
    propertyInfo.companyAddress = formValue.companyAddress;
    propertyInfo.companyProfile = formValue.companyProfile;
    propertyInfo.companyDescription = formValue.companyDescription;
    this.lookupModel.setBuilderCompany(propertyInfo)
    this.showNextPage.emit(2)
  }
  
  get companyName() { return this.propertyForm.get('companyName'); }
  get cinNumber() { return this.propertyForm.get('cinNumber'); }
  get gstNumber() { return this.propertyForm.get('gstNumber'); }
  get contactNumber() { return this.propertyForm.get('contactNumber'); }
  get contactPerson() { return this.propertyForm.get('contactPerson'); }
  get companyNumber() { return this.propertyForm.get('companyNumber'); }
  get contactEmail() { return this.propertyForm.get('contactEmail'); }
  get established() { return this.propertyForm.get('established'); }
  get awards() { return this.propertyForm.get('awards'); }
  get recognizations() { return this.propertyForm.get('recognizations'); }
  get licencenumber () { return this.propertyForm.get('licencenumber '); }
  get companyAddress() { return this.propertyForm.get('companyAddress'); }
  get companyProfile() { return this.propertyForm.get('companyProfile'); }
  get companyDescription() { return this.propertyForm.get('companyDescription'); }

}
