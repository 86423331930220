import { Component, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css']
})
export class DateTimePickerComponent implements OnInit {
  showModal: boolean = false;

  @Output() datetimeSelected = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }
  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  onChange(value: string) {
    this.datetimeSelected.emit(value);
  }

  save() {
    this.closeModal();
  }

}
