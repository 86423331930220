import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventBus {
  subject = new Subject<any>();

  constructor() { }

  on(event: Events, action: any): Subscription {
      return this.subject.pipe(
          filter((e: EmitEvent) => {
              return e.name === event;
          }),
          map((e: EmitEvent) => {
              return e.value;
          })
      ).subscribe(action);
  }

  emit(event: EmitEvent) {
      this.subject.next(event);
  }
}

export class EmitEvent {
  constructor(public name: string, public value?: any) {}
}

export enum Events {
  menuItemSelected = 'BUS_EVENT::MENU_ITEM_UPDATED',
  SPINNER_LOAD = 'SPINNER_LOAD'
}