<div class="mb-dashboard">
    <div class="container">

        <div class="mb-profile-card">
            <h2>Support & Help</h2>
            <div class="mb-edit-profile">

                <form>
                    <div class="mb-profile-name">
                        <div class="row g-2">
                         
                         
                         
                         
                            <div class="col-12">
                                <label for="Company Name">Description ( Upto 200 words )</label><span
                                    class="text-danger">*</span>
                                    <textarea class="form-control" [value]="comment" (input)="onCommentChange($event)" rows="5" id="comment" placeholder="Write us your issue & problems, we will update you on the same (Max 2000 Words)"></textarea>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-primary pb-save" type="submit" (click)="submit()">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="mb-3">
                                <h3>Support&Help </h3>
                                <div class="right-search">
                                    <input type="text" class="form-control mb-3 rounded-4" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control  rounded-4 mt-1">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>

                            
                            <app-nexus-addon-tables [headers]="supportTabHeaders" [data]="supportData"></app-nexus-addon-tables>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 pb-4 text-center">
                <small class="copy-right-text">All trademarks, logos and names are properties of their respective
                    owners. Copyright © 2024 - All rights reserved.</small>
            </div>
        </div>

    </div>

    <!-- <footer>
        <nav class="navbar fixed-bottom bg-secondary">
            <div class="container-fluid">
                <ul class="nav justify-content-center">
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Terms & Conditions </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Policy</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Home</a>
                    </li>
                </ul>
            </div>
        </nav>
    </footer> -->
</div>