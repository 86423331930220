<div class="row">
    <div class="col-xl-12 col-lg-12">
        <div class="all-leads mt-5">
            <h1 style="color: maroon;">Project Leads</h1>

            <div class="card shadow mb-4">
                <div class="card-body">

                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <form>
                            <h3 class="d-inline-block mr-3 mb-0">All Leads <span class="badge badge-pill badge-secondary">26</span></h3>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" checked id="Leads" name="example" value="customEx">
                                <label class="custom-control-label" for="Leads">Get Deals</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="Viewed" name="example" value="customEx">
                                <label class="custom-control-label" for="Viewed">Request site visit</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <select (change)="onSelectChange($event)">
                                    <option value="">--select--</option>
                                    <option value="propertyLeads">Property Leads</option>
                                    <option value="projectLeads" class="active">Project Leads</option>
                                    <option value="pgHostelLeads">PG/Hostel Leads</option>
                                </select>
                            </div>
                        </form>
                        <div class="right-search">
                          <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                            <label for="Sort by:">Sort by:</label>
                            <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                <option>Monthly</option>
                                <option>Weekly</option>
                                <option>Daily</option>
                            </select>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-hover agent-table">
                        <thead class="">
                            <tr>
                                <th>Client Name</th>
                                <th>Mobile Number</th>
                                <th>Email ID</th>
                                <th>Date Added</th>
                                <th>Follow up Date</th>
                                <th>City</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let customer of listings">
                                <td>{{customer.customerName}}</td>
                                <td>{{customer.cmobile}}</td>
                                <td>{{customer.cemail}}</td>
                                <td>12-08-2024</td>
                                <td>12-08-2024</td>  
                                <td>Hyderabad</td> 
                                <td>
                                    <select class="form-control btn btn-light text-left" id="staus">
                                        <option>In Progress</option>
                                        <option>New</option>
                                        <option>Lost</option>
                                        <option>closed</option>
                                    </select>
                                </td>
                                <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                            </tr>
                          
                        
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>