import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
import { MessageService, BUS_EVENTS } from 'src/app/common/events/message.service';
import { Message } from 'src/app/common/events/message';
import Swiper from 'swiper';
declare var Razorpay: any;

@Component({
  selector: 'app-mb-choose-plans',
  templateUrl: './mb-choose-plans.component.html',
  styleUrls: ['./mb-choose-plans.component.css']
})
export class MbChoosePlansComponent implements OnInit,AfterViewInit {
  swiper: Swiper;
  razorPayOptions = {
    key: 'rzp_live_0MqlEEtj9IVeKy',
    // key: 'rzp_test_LHeG5ETncPB4Pv',
    amount: 0,
    currency: 'INR',
    planId: '',
    subPlanId: '',
    name: '',
    description: "Live Transaction",
    order_id: '',
    handler: (response) => {
      this.responseHandler(response);
    },
    prefill: {},
    modal: {}
  }

  constructor(
    private fb: FormBuilder,
    public lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private messageService: MessageService,
  ) { }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    const swiper = new Swiper('.swiper-container', {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
          const ariaLabel = `Go to slide ${index + 1}`;
          return `<span class="${className}" tabindex="0" role="button" aria-label="${ariaLabel}"></span>`;
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
  buyPlan(agentPlanId: string, amount:any,days:any,minutes:any): void {

    const args = {
      planId: agentPlanId,
      type: 1,
      amount: amount,
      totalDays: days,
      minutes: minutes
    }

    this.lookupModel.setPaymentArguments(args)

    this.rsbService.updatePlan(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        const razorPay = rsp.contents[0];
        this.razorPayOptions.amount = amount;
        this.razorPayOptions.currency = 'INR';

        this.razorPayOptions.name = razorPay.name ? razorPay.name : 'pillarblocks.com';
        this.razorPayOptions.description = razorPay.description ? razorPay.description : 'Test Description';
        this.razorPayOptions.order_id = razorPay.razorPayOrderId;
        this.razorPayOptions.planId = agentPlanId;
        this.razorPayOptions.prefill = {
          name: this.lookupModel.getLoggedUser().personalDetails.firstName,
          email: this.lookupModel.getLoggedUser().personalDetails.email,
          contact: '+' + this.lookupModel.getLoggedUser().personalDetails.countryCode + this.lookupModel.getLoggedUser().personalDetails.mobile
        };
        this.razorPayOptions.modal = {
          escape: false,
          // ondismiss: (() => {
          //   // handle the case when user closes the form while transaction is in progress
          //   this.paymentCancelHandler();
          // })
        };
        let rzp = new Razorpay(this.razorPayOptions);
        rzp.open();
        rzp.on('payment.failed', (response) => {
          this.razorPaymentErrorHandler(response)
        });
      }
    });

  }
  paymentCancelHandler(): void {
    this.toastrService.error('Payment processing has been cancelled.');
  }

  responseHandler(response): void {
    this.messageService.sendMessage(new Message(BUS_EVENTS.SPINNER_LOAD, true));
    setTimeout(() => {
      this.razorPaymentHandler(response);
    }, 5000);
  }
  razorPaymentHandler(response): void {
    if (this.verifySignature(response.razorpay_order_id, response.razorpay_payment_id, response.razorpay_signature)) {
      if (this.lookupModel.getPaymentArguments().type === 1) {
        const args = {
          planId: this.lookupModel.getPaymentArguments().planId,
          amount: this.lookupModel.getPaymentArguments().amount,
          totalDays: this.lookupModel.getPaymentArguments().totalDays,
          minutes: this.lookupModel.getPaymentArguments().minutes,
          paymentStatus: 1,
          paymentId: response.razorpay_payment_id,
          paymentSignature: response.razorpay_signature,
          razorPayOrderId: response.razorpay_order_id ? response.razorpay_order_id : this.razorPayOptions.order_id,
          paymentInfo: response
        }
        this.rsbService.updatePaymentStatus(args).subscribe((rsp) => {
          if (rsp.statusCode === 0) {
            this.messageService.sendMessage(new Message(BUS_EVENTS.SPINNER_LOAD, true));
            this.lookupModel.setPaymentStatus(rsp.contents[0]);

            setTimeout(() => {
              // this.toastrService.success('Plan has been purchased successfully.');
              // this.nextPage.emit(4);


              this.messageService.sendMessage(new Message(BUS_EVENTS.SPINNER_LOAD, false));
            }, 1000);
            let url = window.location.origin + '/dashboard';
            window.open(url, '_self');
            // this.router.navigate(['home/listingSuccessPage']);
          }
        });
      }
    } else {
      const args = {
        paymentStatus: 2,
        paymentId: response.razorpay_payment_id,
        paymentSignature: response.razorpay_signature,
        razorPayOrderId: response.razorpay_order_id ? response.razorpay_order_id : this.razorPayOptions.order_id,
        paymentInfo: response
      };

      this.rsbService.updatePaymentStatus(args).subscribe((rsp) => {
        if (rsp.statusCode === 0) {
          // this.messageService.sendMessage(new Message(BUS_EVENTS.SPINNER_LOAD, true));
          this.lookupModel.setPaymentStatus(rsp.contents[0]);
          let url = window.location.origin + '/listingFailedPage';
          window.open(url, '_self');
          // this.router.navigate(['home/listingFailedPage']);
        }
        //this.toastrService.error('Error during payment. Please try again.')
      });
    }

  }

  razorPaymentErrorHandler(response): void {
    const args = {
      paymentStatus: 2,
      paymentId: response.error.metadata.payment_id,
      razorPayOrderId: response.error.metadata.order_id ? response.error.metadata.order_id : this.razorPayOptions.order_id,
      paymentInfo: response.error
    };

    this.rsbService.updatePaymentStatus(args).subscribe((rsp) => {

      this.toastrService.error('Error during payment. Please try again.')
      let url = window.location.origin + '/listingFailedPage';
      window.open(url, '_self');
    });
  }

  verifySignature(orderId: string, paymentId: string, signature: string): boolean {
    const key = '4oIJx0ToK2dJD0zQ3IO4O4Ac';
    //  const generatedSignature = Base64.stringify(hmacSHA512(orderId + "|" + paymentId, key));
    //console.log(generatedSignature);
    // return generatedSignature === signature;
    return true;
  }


}
