<div class="container-fluid">
    <div class="pb-wrapper">
        <div class="tab-content" *ngIf="showPropertyFlag">

            <ng-container [ngSwitch]="propertyView">
                <app-plot-post-location *ngSwitchCase="1" (formChange)="changeFormStatus($event)"
                    (showNextPage)="openPage($event)"></app-plot-post-location>
                <app-plot-post-details *ngSwitchCase="2" (formChange)="changeFormStatus($event)"
                    (showNextPage)="openPage($event)"></app-plot-post-details>
                <app-plot-post-features *ngSwitchCase="3" (formChange)="changeFormStatus($event)"
                    (showNextPage)="openPage($event)"></app-plot-post-features>
                <app-plot-post-gallery *ngSwitchCase="4" (formChange)="changeFormStatus($event)"
                    (showNextPage)="openPage($event)"></app-plot-post-gallery>
                <!-- <app-floorplan *ngSwitchCase="5" (formChange)="changeFormStatus($event)"
                    (showNextPage)="openPage($event)"></app-floorplan> -->
                <app-plot-property-highlights *ngSwitchCase="6" (formChange)="changeFormStatus($event)"
                    (showNextPage)="openPage($event)"></app-plot-property-highlights>
                <app-plot-property-location-highlights *ngSwitchCase="7" (formChange)="changeFormStatus($event)"
                    (showNextPage)="openPage($event)"></app-plot-property-location-highlights>
                <!-- <app-specifications *ngSwitchCase="8" (formChange)="changeFormStatus($event)"
                    (showNextPage)="openPage($event)"></app-specifications> -->
                <app-plot-publish *ngSwitchCase="9" (formChange)="changeFormStatus($event)"
                    (showNextPage)="openPage($event)"></app-plot-publish>
            </ng-container>
        </div>
    </div>
</div>