
<ng-container *ngIf="!isMobileLayout">
    <!-- Begin Page Content -->
    <div class="container-fluid">
        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h2 class="mb-0">Dashboard</h2>

        </div>
        <!-- <p *ngIf="lastLoginTime">Last Login: {{ lastLoginTime | date:'short' }}</p>
        <p *ngIf="lastLoginTime">Last Login Time: {{ lastLoginTime | date:'shortTime' }}</p> -->




        <!-- Content Row -->
        <div class="row pb-top-layout">
            <ng-container *ngFor="let countDash of countData">
                <div class="col-xl-3 col-md-6 mb-4">
                    <div class="h-100 py-2" [ngClass]="getDynamicClass(countDash)">
                        <div class="card-body">

                            <div class="row no-gutters align-items-center">

                                <div class="col mr-2">
                                    <h4 class="mb-1">{{countDash.moduleName}}</h4>
                                    <h5>{{countDash.property_count ? countDash.property_count : countDash.projects_count ?
                                        countDash.projects_count : countDash.pg_count ? countDash.pg_count : 0 }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="col-xl-3 col-md-6 mb-4">
                <div class="h-100 py-2 pb-leads">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <h4 class="mb-1">Total Leads</h4>
                                <h5>{{leadsCount}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="col-xl-4 col-md-4 mb-4">
                <div class="h-100 py-2 pb-current">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2" *ngIf="renewalDate">
                                <h4 class="mb-1">My Current Plan</h4>
                                <h5>Renewal Date : {{renewalDate}}</h5>
                            </div>
                            <div class="col mr-2" *ngIf="!renewalDate">
                                <h4 class="mb-1">My Current Plan</h4>
                                <h5>Renewal Date :<span (click)="gotoChoosePlan()" style="background-color: rgb(219, 86, 130);width: 200px;height: auto; color:white">Buy A Plan</span> </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 mb-4" *ngIf="lastLoginDate">
                <div class="h-100 py-2 pb-current">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <h4 class="mb-1">Last Login Time</h4>
                                <h5>{{ lastLoginDate | date: 'fullDate' }} {{ lastLoginDate | date: 'shortTime' }}</h5>
            
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
          
           
        </div>
        <!-- Content Row -->
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">
                                      All Leads <span class="badge badge-pill badge-secondary">{{leadsCount}}</span>
                                    </h3>
                                    <div class="custom-control custom-control-inline">
                                      <select class="form-control" [(ngModel)]="selectedModule" id="moduleSelect" (change)="changeMainModule($event.target.value)">
                                        <option [value]="properties">Properties</option>
                                        <option value="projects">Projects</option>
                                        <option value="pg/hostels">PG/Hostels</option>
                                      </select>
                                      <i class="bi bi-chevron-down position-absolute" style="right: 5px; top: 10px;"></i> <!-- Bootstrap Icon -->

                                    </div>
                                  
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="leadsRadio" name="leads" value="leads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                      Leads
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="dealLeadsRadio" name="leads" value="dealLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                      Deal Leads
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="siteVisitLeadsRadio" name="leads" value="siteVisitLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                      Site Visit Leads
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="emiLeadsRadio" name="leads" value="emiLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                      EMI Leads
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="videoCallRadio" name="leads" value="videoCall" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                        Video Call
                                      </div>
                                
                                  </form>
                                  
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                    <!-- <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select> -->
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover table-responsive agent-table">
                                <thead class="">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Property Id</th>

                                        <th>Client Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email ID</th>
                                        <th>Date Added</th>
                                        <th>Property Type</th>
                                        <th>Property Value</th>

                                        <th>Follow up Date</th>
                                        <th>Status</th>
                                        <th>Remarks</th>
                                        <th>Edit/Save</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  *ngFor="let lead of tableRecords;let i= index"> 
                                        <td>{{i+1}}</td>
                                        <td>{{lead.propertyId}}</td>

                                        <td>{{lead.personalDetails?.firstName?lead.personalDetails?.firstName : lead?.customerName  }}</td>
                                        <td>{{lead.personalDetails?.mobile ? lead.personalDetails?.mobile : lead?.cmobile}}</td>
                                        <td>{{lead.personalDetails?.email ? lead.personalDetails?.email : lead?.cemail }}</td>
                                        <td>{{lead.createdAt | date: 'short'}}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{{lead.createdAt | date: 'short'}}</td>
                                       
                                        <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Success"  readonly></td>
                                        <td></td>
                                        <td></td>

                                        
                                    </tr>
                                   
                                </tbody>
                                </table>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</ng-container>
<ng-container *ngIf="isMobileLayout">
    <div class="mb-dashboard">
        <div class="container">
            <div class="row">
                <ng-container *ngFor="let countDash of countData">
                    <div class="col-6 col-md-4 col-xl-3">
                        <!-- bg-c-blue,bg-c-green,bg-c-autumn -->
                        <div class="card order-card"  [ngClass]="getDynamicClass(countDash)">

                            <img class="card-img-top" [src]="getImage(countDash.moduleName)" alt="Card image">
                           
                            <div class="card-img-overlay card-block">
                                <h6>{{countDash.moduleName}}</h6>
                                <h2>{{countDash.property_count ? countDash.property_count : countDash.projects_count ?
                                        countDash.projects_count : countDash.pg_count ? countDash.pg_count : 0 }}</h2>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- <div class="col-6 col-md-4 col-xl-3">
                    <div class="card bg-c-blue order-card">
                        <img class="card-img-top" src="assets/img/sale-image.png" alt="Card image">
                        <div class="card-img-overlay card-block">
                            <h4>Sale Properties</h4>
                            <h5>0</h5>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-6 col-md-4 col-xl-3">
                    <div class="card bg-c-green order-card">
                        <img class="card-img-top" src="assets/img/rent-image.png" alt="Card image">
                        <div class="card-img-overlay card-block">
                            <h4>Rent Properties</h4>
                            <h5>0</h5>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-6 col-md-4 col-xl-3">
                    <div class="card bg-c-yellow order-card">
                        <img class="card-img-top" src="assets/img/real-estate-projects.png" alt="Card image">
                        <div class="card-img-overlay card-block">
                            <h6>Projects</h6>
                            <h2>0</h2>
                        </div>
                    </div>
                </div> -->

                <div class="col-6 col-md-4 col-xl-3">
                    <div class="card bg-c-pink order-card">
                        <img class="card-img-top" src="assets/img/total-leads.png" alt="Card image">
                        <div class="card-img-overlay card-block">
                            <h6>Total Leads</h6>
                            <h2>{{leadsCount}}</h2>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-6 col-md-4 col-xl-3">
                    <div class="card bg-c-alice order-card">
                        <img class="card-img-top" src="assets/img/commercial-projects.png" alt="Card image">
                        <div class="card-img-overlay card-block">
                            <h6>Commercial Properties</h6>
                            <h2>0</h2>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-6 col-md-4 col-xl-3">
                    <div class="card bg-c-autumn order-card">
                        <img class="card-img-top" src="assets/img/loan-approved.png" alt="Card image">
                        <div class="card-img-overlay card-block">
                            <h6>Loans Passed</h6>
                            <h2>0</h2>
                        </div>
                    </div>
                </div> -->
                <div class="col-12 col-md-12 col-xl-3">
                    <div class="card bg-c-forest order-card">
                        <img class="card-img-top" src="assets/img/plan.png" alt="Card image">
                        <div class="card-img-overlay card-block" *ngIf="renewalDate">
                            <h4>My Current Plan</h4>
                            <h5>Renewal Date : {{renewalDate}}</h5>
                        </div>
                        <div class="card-img-overlay card-block" *ngIf="!renewalDate">
                            <h4>My Current Plan</h4>
                            <h5>Renewal Date : <span (click)="gotoChoosePlan()" style="background-color: rgb(219, 86, 130); color:white">Buy A Plan</span></h5>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12">
                    <div class="all-leads">
                        <div class="card shadow mb-4">
                            <div class="card-body">
                                <div class="mb-3">
                                    <form>
                                        <h3 class="d-inline-block mr-3 mb-0">All Leads <span class="badge badge-pill badge-secondary">{{leadsCount}}</span></h3>
                                        <!-- <div class="custom-control custom-control-inline">
                                            <select class="form-control" [(ngModel)]="selectedModule" id="moduleSelect" (change)="changeMainModule($event.target.value)">
                                                <option [value]="properties">Properties</option>
                                                <option value="projects">Projects</option>
                                                <option value="pg/hostels">PG/Hostels</option>
                                              </select>
                                        </div> -->
                                        <div class="custom-control custom-control-inline custom-select-wrapper">
                                            <select class="form-control custom-select-icon" [(ngModel)]="selectedModule" id="moduleSelect" (change)="changeMainModule($event.target.value)">
                                                <option [value]="properties">Properties</option>
                                                <option value="projects">Projects</option>
                                              <option value="pg/hostels">PG/Hostels</option>
                                            </select>
                                          </div>
                                          

                                          <ng-container>
                                            <span class="radio-group">
                                              <label>
                                                <input type="radio" id="leadsRadio" name="leads" value="leads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                                Leads
                                              </label>
                                              
                                              <label>
                                                <input type="radio" id="dealLeadsRadio" name="leads" value="dealLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                                Deal Leads
                                              </label>
                                              
                                              <label>
                                                <input type="radio" id="siteVisitLeadsRadio" name="leads" value="siteVisitLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                                Site Visit Leads
                                              </label>
                                              
                                              <label>
                                                <input type="radio" id="emiLeadsRadio" name="leads" value="emiLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                                EMI Leads
                                              </label>
                                            </span>
                                          </ng-container>
                                          
                                       
                                    </form>
                                    <!-- <div class="right-search">
                                        <input type="text" class="form-control mb-3 rounded-4" placeholder="Search">
                                        <label for="Sort by:">Sort by:</label>
                                        <select class="form-control  rounded-4 mt-1">
                                            <option>Monthly</option>
                                            <option>Weekly</option>
                                            <option>Daily</option>
                                        </select>
                                    </div> -->
                                </div>
    
                                <div class="table-head-fixed" *ngIf="isMobileLayout">
                                    <table class="table table-striped">
                                        <thead class="table-dark">
                                            <tr>
                                                <th>Client Name</th>
                                                <th>Mobile Number</th>
                                                <th>Email ID</th>
                                                <th>Date Added</th>
                                                <th>Follow up Date</th>
                                                <th>Status</th>
                                            </tr>
                                             
                                        </thead>
                                        <tbody>
                                            <tr  *ngFor="let lead of tableRecords"> 
                                                <td>{{lead.personalDetails?.firstName?lead.personalDetails?.firstName : lead?.customerName  }}</td>
                                                <td>{{lead.personalDetails?.mobile ? lead.personalDetails?.mobile : lead?.cmobile}}</td>
                                                <td>{{lead.personalDetails?.email ? lead.personalDetails?.email : lead?.cemail }}</td>
                                                <td>{{lead.createdAt| date: 'dd-MM-yyyy'}}</td>
                                                <td>{{lead.createdAt| date: 'dd-MM-yyyy'}}</td>
                                               
                                                    <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Success"  readonly></td>
        
                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 pb-4 text-center">
                    <small>All trademarks, logos and names are properties of their respective owners. Copyright © 2024 -
                        All rights reserved.</small>
                </div>
            </div>

        </div>

        <footer>
            <nav class="navbar fixed-bottom bg-secondary">
                <div class="container-fluid">
                    <ul class="nav justify-content-center">
                        <li class="nav-item">
                            <a class="nav-link text-white" href="#">Terms & Conditions </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-white" href="#">Policy</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-white" href="#">Home</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </footer>
    </div>
</ng-container>


