<div class="container-fluid"  *ngIf="!isMobileLayout">
    <div class="pb-wrapper bg-white p-4">
        <h2 class="mb-4">Sales</h2>
         
        <form class="agent-profile" [formGroup]="saleForm" (ngSubmit)="submit()" *ngIf="isSaleForm">
            <div class="form-row mb-5">
          
                <div class="col-4">
                    <label for="Name">Client Name</label>
                    <input type="text" class="form-control" placeholder="Type here" name="name" formControlName="name" readonly>
                
                </div>
                <div class="col-4">
                    <label for="Agency Name">Mobile Number</label>
                    <input type="text" class="form-control" placeholder="Type here" name="mobile" formControlName="mobile"readonly>
                
                </div>
                <div class="col-4">
                    <label for="RERA Number">Email Id</label>
                    <input type="text" class="form-control" placeholder="Type here" name="email" formControlName="email"readonly>
                
                </div>
                <div class="col-4">
                    <label for="RERA Number">Date Added</label>
                    <input type="text" class="form-control" placeholder="Type here" name="dateAdded" formControlName="dateAdded"readonly>
                
                </div>
                <div class="col-4">
                    <label for="RERA Number">Date Approved</label>
                    <input type="text" class="form-control" placeholder="Type here" name="dateApproved" formControlName="dateApproved"readonly>
                
                </div>
                <div class="col-4">
                    <label for="Operating since">City</label>
                    <input type="text" class="form-control" placeholder="Type here" name="city" formControlName="city"readonly>

                </div>
                <div class="col">
                    <label for="Operating since">Remarks</label>
                    <input type="text" class="form-control" placeholder="Type here" name="remarks" formControlName="remarks"readonly>

                </div>
              
                  
            </div>
                <!-- <span class="file-tag">
                    <i class="ri-attachment-2"></i>
                    <span>Agreement of Sale.pdf</span>
                    <span class="file-size">(2.5MB)</span>
                </span> -->
                  
                <div class="file-upload">
                    <label for="file1">
                      <span>
                        <i class="ri-attachment-2"></i>
                      </span>
                      <span class="file-name">{{ file1Name ? file1Name : 'Attach File' }}</span>
                      <span class="file-size" *ngIf="file1Size">({{ file1Size }})</span>
                      <input type="file" id="file1" formControlName="file1" (change)="uploadAws($event, 'file1')">
                    </label>
                  
                    <label for="file2">
                      <i class="ri-attachment-2"></i>
                      <span class="file-name">{{ file2Name ? file2Name : 'Attach File' }}</span>
                      <span class="file-size" *ngIf="file2Size">({{ file2Size }})</span>
                      <input type="file" id="file2" formControlName="file2" (change)="uploadAws($event, 'file2')">
                    </label>
                  
                    <label for="file3">
                      <span>
                        <i class="ri-attachment-2"></i>
                      </span>
                      <span class="file-name">{{ file3Name ? file3Name : 'Attach File' }}</span>
                      <span class="file-size" *ngIf="file3Size">({{ file3Size }})</span>
                      <input type="file" id="file3" formControlName="file3" (change)="uploadAws($event, 'file3')">
                    </label>
                  </div>
                  
                  
            <button class="btn btn-primary pb-save" type="submit">save</button>
        </form>
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">Sales <span class="badge badge-pill badge-secondary">26</span></h3>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" checked id="Leads" name="example" value="customEx">
                                        <label class="custom-control-label" for="Leads">All Leads</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="Viewed" name="example" value="customEx">
                                        <label class="custom-control-label" for="Viewed">Viewed Leads</label>
                                    </div>
                                </form>
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover agent-table">
                                <thead class="">
                                    <tr>
                                        <th>Select</th>
                                        <th>Client Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email ID</th>
                                        <th>Date Added</th>
                                        <th>Date Approved</th>
                                        <th>City</th>
                                        <th>Remarks</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let salesRecords of saleRecords">
                                        <td>
                                        <input type="checkbox" (change)="selectedSaleRec(salesRecords)">
                                        </td>
                                        <td>{{salesRecords.name}}</td>
                                        <td>{{salesRecords.mobile}}</td>
                                        <td>{{salesRecords.email}}</td>
                                        <td>{{salesRecords.dateAdded}}</td>
                                        <td>{{salesRecords.dateApproved}}</td>
                                        <td>{{salesRecords.city}}</td>
                                        <td>{{salesRecords.remarks}}</td>
                                        
                                            <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Submitted" readonly></td>
                                        
    
                                        
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Row -->
    

</div>
<app-mb-sale *ngIf="isMobileLayout"></app-mb-sale>