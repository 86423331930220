<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="my-4 p-5 bg-light rounded">
                    <h3>Pricing Policy:</h3>
                    <p>Pilarblcoks.com is an online real estate proptech platform. it is not in to physical goods and its services related business. 
                    nexus.pillarblocks.com is exclusively for agents, Builders who subscribe the pillarblocks.cm platform
                    The subscriber can have a dedicated account for a time period where they can market their properties online for this he has to pay platform fee.
                    we have a plan for our subscribed users, each user upon login they can do the payment and avail the services.

                    For more details please visit  https://nexus.pillarblocks.com/login</p>

                </div>
            </div>
        </div>
    </div>
</section>