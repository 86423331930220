import { Pipe, PipeTransform } from "@angular/core";
import { getCurrencySymbol } from '@angular/common';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';

@Pipe({
    name: 'rsbcurrency'
})
export class RsbCurrencyPipe implements PipeTransform {
    
    constructor(private lookupModel: RsbLookupModel) {}

    transform(
        value: number,
        currencyCode: string,
    ): string | null {
        const country = this.lookupModel.getCountry();
        const symbol = getCurrencySymbol(currencyCode, 'wide');
        let val= "";
        if (value >= 10000000) {
            val = (value / 10000000).toFixed(1) + ' Cr';
          } else if (value >= 100000) {
            val = (value / 100000).toFixed(1) + ' L';
          } else if (value >= 1000) {
            val = (value / 1000).toFixed(1) + ' K';
          } else {
            val = value.toString();
          }
        // const val = new Intl.NumberFormat(country.locale ? country.locale : 'en-IN').format(value);
        return symbol + ' ' + val;
    }
}