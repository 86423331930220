<div class="container-fluid" *ngIf="!isMobileLayout">
    <div class="pb-wrapper bg-white p-4">
        <h2 class="d-inline-block mr-3 mb-4">Project Finance</h2>
        <form class="agent-profile" [formGroup]="projectFinanceForm" (ngSubmit)="submit()">
            <div class="form-row mb-2">
                <div class="col-md-4">
                    <label for="Name">Project Type</label><span  class="text-danger">*</span>
                    <div class="dropdown-wrapper">
                        <select class="form-control" formControlName="projectType" name="projectType">
                            <option value="">Select</option>
                            <option value="Residential">Residential</option>
                            <option value="Commercial">Commercial</option>
                        </select>
                        <span class="dropdown-icon"></span>
                    </div>
                    <div class="text-danger" *ngIf="projectFinanceForm.get('projectType')?.invalid && projectFinanceForm.get('projectType')?.touched">
                        Project Type is required
                      </div>
                    
                </div>
                
               
                
                <div class="col-md-4">
                    <label for="Name">Required Budget</label><span  class="text-danger">*</span>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="Budget" name="Budget" >
                    <div class="text-danger" *ngIf="projectFinanceForm.get('Budget')?.invalid && projectFinanceForm.get('Budget')?.touched">
                        Budget is required
                      </div>
                </div>
                <div class="col-md-4">
                    <label for="Name">Project Location</label><span  class="text-danger">*</span>
                    <div class="dropdown-wrapper">
                    <select class="form-control" formControlName="location" name="location">
                        <option value="">Select</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Mumbai">Mumbai</option>
                        <option value="Vizag">Vizag</option>      
                    </select>
                    <span class="dropdown-icon"></span>
                    </div>
                    <small class="text-muted"><em>Please select the city</em></small>
                    <div class="text-danger" *ngIf="projectFinanceForm.get('location')?.invalid && projectFinanceForm.get('location')?.touched">
                        location is required
                      </div>
                </div>
               
               
               
            </div>
            <div class="form-row mb-4">
                <div class="col-md-4">
                    <label for="Company Name">Description ( Upto 200 words )</label><span  class="text-danger">*</span>
                    <textarea class="form-control" rows="5" id="comment" formControlName="description" name="description"></textarea>
                    <div class="text-danger" *ngIf="projectFinanceForm.get('description')?.invalid && projectFinanceForm.get('description')?.touched">
                        Description is required
                      </div>
                </div>
            </div>
            <button class="btn btn-primary pb-save" type="submit">submit</button>
            <!-- <button class="btn  pb-addproject ml-3"><i class="ri-add-line"></i> create another project finance</button> -->
        </form>
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">Project Finance</h3>
                                </form>
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="table-responsive">
                                <table class="table table-hover agent-table">
                                <thead class="">
                                    <tr>
                                        <th><input type="checkbox"></th>
                                        <th>Customer Name</th>
                                        <th>Project Type</th>
                                        <th>Property Type</th>
                                        <th>Required Budget</th>
                                        <th>Project Location</th>
                                        <th>Mobile Number</th>
                                        <th>Email Id</th>
                                        <th>Date Added</th>
                                        <th>Description</th>
                                        <th>Actions</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let records of cctvRecords ">
                                        <td><input type="checkbox"></td>
                                        <td>{{records.cName}}</td>
                                        <td>{{records.projectType}}</td>
                                        <td>{{records.propertyType}}</td>
                                        <td>{{records.budget}}</td> 
                                        <td>{{records.location}}</td>  
                                        <td>{{records.mobile}}</td>  
                                        <td>{{records.email}}</td> 
                                        <td>05-09-2024</td> 
                                        <td>{{records.description}}</td> 
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                
                                </tbody>
                                </table>
                            </div> -->
                            
                            <app-nexus-addon-tables [headers]="projectFinanceTableHeaders" [data]="projectFinanceTableData"></app-nexus-addon-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Row -->
    

</div>
<div class="mb-dashboard" *ngIf="isMobileLayout">
    <div class="container">

        <div class="mb-profile-card">
            <h2>Project Finance</h2>
            <div class="col-12">
                <p class="btn btn-light rounded-pill addbtn mb-0" (click)="addPage()"><i class="ri-add-line"></i> Add</p>
              </div>
            <div class="mb-edit-profile">
                <div class="mb-cibil-popup" [ngStyle]="{'display':isShow?'block':'none'}">

                    <form class="agent-profile" [formGroup]="projectFinanceForm" (ngSubmit)="submit()">
                        <div class="mb-profile-name">
                        <span id="closePopup" class="close-btn" (click)="closePage()">&times;</span>

                        <div class="row g-2">
                            <div class="col-12">
                                <label for="Name">Project Type</label><span  class="text-danger">*</span>
                                <div class="dropdown-wrapper">
                                    <select class="form-control" formControlName="projectType" name="projectType">
                                        <option value="">Select</option>
                                        <option value="Residential">Residential</option>
                                        <option value="Commercial">Commercial</option>
                                    </select>
                                    <span class="dropdown-icon"></span>
                                </div>
                                <div class="text-danger" *ngIf="projectFinanceForm.get('projectType')?.invalid && projectFinanceForm.get('projectType')?.touched">
                                    Project Type is required
                                  </div>
                            </div>
                            <div class="col-12">
                                <label for="Name">Required Budget</label><span  class="text-danger">*</span>
                                <input type="text" class="form-control" placeholder="Type here" formControlName="Budget" name="Budget" >
                                <div class="text-danger" *ngIf="projectFinanceForm.get('Budget')?.invalid && projectFinanceForm.get('Budget')?.touched">
                                    Budget is required
                                  </div>
                               
                            </div>
                          
                            <div class="col-12">
                                <label for="Name">Project Location</label><span  class="text-danger">*</span>
                                <div class="dropdown-wrapper">
                                <select class="form-control" formControlName="location" name="location">
                                    <option value="">Select</option>
                                    <option value="Hyderabad">Hyderabad</option>
                                    <option value="Mumbai">Mumbai</option>
                                    <option value="Vizag">Vizag</option>      
                                </select>
                                <span class="dropdown-icon"></span>
                                </div>
                                <small class="text-muted"><em>Please select the city</em></small>
                                <div class="text-danger" *ngIf="projectFinanceForm.get('location')?.invalid && projectFinanceForm.get('location')?.touched">
                                    location is required
                                  </div>
                            </div>
                          
                            <div class="col-12">
                                <label for="Company Name">Description ( Upto 200 words )</label><span  class="text-danger">*</span>
                    <textarea class="form-control" rows="5" id="comment" formControlName="description" name="description"></textarea>
                    <div class="text-danger" *ngIf="projectFinanceForm.get('description')?.invalid && projectFinanceForm.get('description')?.touched">
                        Description is required
                      </div>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-success rounded-pill" type="submit">Submit</button>
                                <p class="btn btn-light rounded-pill addbtn mb-0"><i class="ri-add-line"></i> Add</p>
                            </div>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="mb-3">
                                <h3>Project Finance</h3>
                                <div class="right-search">
                                    <input type="text" class="form-control mb-3 rounded-4" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control  rounded-4 mt-1">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>
                            <div class="table-head-fixed">
                                <table class="table table-striped">
                                    <thead class="table-dark">
                                        <tr>
                                            <th>Project Type</th>
                                            <th>Required Budget</th>
                                            <th>Project Location</th>
                                            <th>Description</th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of projectFinanceRecords">
                                            <td>{{record.projectType || N/A}}</td>
                                            <td>{{record.projectType}}</td>
                                            <td>{{record.location}}</td>
                                            <td>{{record.projectType}}</td>

                                            
                                          </tr>
                                    </tbody>
                                </table>
                            </div>
                            

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 pb-4 text-center">
                <small class="copy-right-text">All trademarks, logos and names are properties of their respective
                    owners. Copyright © 2024 - All rights reserved.</small>
            </div>
        </div>

    </div>

    <!-- <footer>
        <nav class="navbar fixed-bottom bg-secondary">
            <div class="container-fluid">
                <ul class="nav justify-content-center">
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Terms & Conditions </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Policy</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Home</a>
                    </li>
                </ul>
            </div>
        </nav>
    </footer> -->
</div>
<!-- <app-mb-solar *ngIf="isMobileLayout"></app-mb-solar> -->