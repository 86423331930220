import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-incomplete-profile-popup-alert',
  templateUrl: './incomplete-profile-popup-alert.component.html',
  styleUrls: ['./incomplete-profile-popup-alert.component.css']
})
export class IncompleteProfilePopupAlertComponent implements OnInit {
  closeIcon:boolean=true;

  constructor(private route:Router,
    private activeModal: NgbActiveModal,

  ) { }

  ngOnInit(): void {
  }
  navigateToProfiePage(){
    this.route.navigate(['associateMyProfile'])
    this.close();


  }
  close(){
    this.activeModal.close();
  }

}
