import { Component, OnInit, ElementRef, ViewChild, NgZone, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-favorite-property-list',
  templateUrl: './favorite-property-list.component.html',
  styleUrls: ['./favorite-property-list.component.css']
})
export class FavoritePropertyListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}