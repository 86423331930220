<section>
    <button type="button" class="close" (click)="close()" >&times;</button>

<div class="container">



    <div class="icon-container">

        <h4>Share Property</h4>
        <img src="assets/img/share.jpg" style="width: 115px; height: auto;">

        <div class="icon-wrapper">
            <!-- <img src="assets/img/share.jpg" style="width: 100px; height: auto;"> -->
            <share-buttons style="display: inline-block;color: brown;" class="share-buttons" [theme]="'material-dark'"
                [include]="['email']" [show]="1" [size]="1" [url]=urlGenerate() [autoSetMeta]="false">
            </share-buttons>
            <label>Email</label>
        </div>
        <div class="icon-wrapper">
            <share-buttons style="display: inline-block;color: green;" class="share-buttons" [theme]="'material-dark'"
                [include]="['whatsapp']" [show]="1" [size]="1" [url]=urlGenerate() [autoSetMeta]="false">
            </share-buttons>
         
            
            
            <label>Whatsapp</label>
            
        </div>
        <div class="icon-wrapper">
            <share-buttons style="display: inline-block; color: blue;" class="share-buttons" [theme]="'material-dark'"
                [include]="['facebook']" [show]="1" [size]="1" [url]=urlGenerate() [autoSetMeta]="false">
            </share-buttons>
            <label>Facebook</label>
        </div>
        <div class="icon-wrapper">
            <share-buttons style="display: inline-block;color: burlywood;" class="share-buttons" [theme]="'material-dark'"
                [include]="['twitter']" [show]="1" [size]="1" [url]=urlGenerate() [autoSetMeta]="false">
            </share-buttons>
            <label>Twitter</label>
        </div>
    
        <!-- <div class="icon-wrapper">
            <a [href]="getWhatsAppMessageUrl()" target="_blank">
                <i class="fab fa-whatsapp" style="color: green; font-size: 24px;"></i> WhatsApp
            </a>
            
            
            
            <label>WhatsApp</label>
        </div> -->
    
        
    </div>
   
</div>
</section>
