import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mb-property-listings',
  templateUrl: './mb-property-listings.component.html',
  styleUrls: ['./mb-property-listings.component.css']
})
export class MbPropertyListingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
