<div class="tab-content search_content aos-init aos-animate" *ngIf="!isMobileLayout">


    <section class="Property_search">
        <div class="container-fluid">
            <div class="row">
              
                <div class="col-lg-12 property_leftlist">
                    <!-- <div style="text-align: center; display: flex;">
                        <button class="btn btn-success m-2" (click)="favoriteListing()"><i class="ri-heart-3-line"></i> Favorite Properties</button>
                        <button class="btn btn-primary m-2" (click)="favoriteProjects()"><i class="ri-heart-3-line"></i> Favorite Projects</button>
                        <button class="btn btn-primary m-2" (click)=" favoritePgHostels()"><i class="ri-heart-3-line"></i> Favorite PG/Hostels</button>
            
                    </div> -->
                    <div style="text-align: center; display: flex;">

                        <div class="form-group">
                            <div class="form-check form-check-inline">
                              <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="favCategory" value="Favorite Properties" (change)="selectedFavCategory($event)" checked>
                                Favorite Properties
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="favCategory" value="Favorite Projects" (change)="selectedFavCategory($event)">
                                Favorite Projects
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="favCategory" value="Favorite Pg" (change)="selectedFavCategory($event)">
                                Favorite Pg
                              </label>
                            </div>
                          </div>
                          
                          
                    <div>
                        </div>
                        <!-- <button class="favourites-button m-2" (click)="favoriteListing()" [ngClass]="{'active': isActive}" (click)="toggleActive()">
                            <span class="icon">
                                <i class="ri-heart-3-line"></i>
                            </span>
                            <span class="text">Favorite Properties</span>
                        </button> -->
                     
                       
                    </div>
                    <div class="d-flex justify-content-between pagination_content">

 
                        <div class="heading">
                            <!-- <h1>Properties in {{selectedCity && selectedCity.cityName ? selectedCity.cityName:''}} for
                {{selectedModule.moduleName ? selectedModule.moduleName : ''}}</h1> -->
                        </div>

                        <!-- <div class="sorting">
              <div class="row align-items-center">
                <div class="col-auto">
                  <p class="mb-0">Sort by: </p>
                </div>
                <div class="col-auto">
                  <select class="form-select" (change)="sortBy('price', $event)">
                    <option value="0">Price: Low to High</option>
                    <option value="1">Price: High to Low</option>
                  </select>
                </div>
              </div>
            </div> -->
                    </div>
                    <!-- <div *ngIf="selectedProp" [ngClass]="{'fixed-header': isFixed}" class="fixed-header">
            <p style="background-color: white; width: 70%; display: flex; justify-content: space-between; align-items: center; padding: 10px;">
                <span>You have selected <b style="font-size: 20px;color: brown;"> {{ selectedPropCount }}</b>&nbsp;properties!</span>
                <span>
                    <i class="ri-star-line" (click)="favorites()"  style="font-size: 18px; margin-left: 30px;"></i>
                    <i class="ri-links-line" (click)="copyLink()" style="font-size: 18px; margin-left: 30px;cursor: pointer;"></i>
                   <span style="color:green">{{ copyMessage }}</span>

                    <i class="ri-share-forward-line" (click)="sharePopUp()" style="cursor: pointer;font-size: 18px; margin-left: 30px;"></i>
                    <i class="ri-delete-bin-6-line"  (click)="cancelSelection()" style="cursor: pointer; font-size: 18px; margin-left: 30px;"></i>
                    <button class="white-button" (click)="cancelSelection()">Cancel</button>
                  </span>
            </p>
        </div> -->
      
        <div *ngIf="selectedProp" [ngClass]="{'fixed-header': isFixed}" class="fixed-header">
            <p style="background-color: white; width: 70%; display: flex; justify-content: space-between; align-items: center; padding: 10px;">
                <span>You have selected <b style="font-size: 20px;color: brown;"> {{ selectedPropCount }}</b>&nbsp;properties!</span>
                <span>
                    <!-- <i class="ri-star-line" (click)="favorites()"  style="font-size: 18px; margin-left: 30px;"></i> -->
                    <i class="ri-links-line" (click)="copyLink()" style="font-size: 18px; margin-left: 30px;cursor: pointer;"></i>
                   <span style="color:green">{{ copyMessage }}</span>

                    <i class="ri-share-forward-line" (click)="sharePopUp()" style="cursor: pointer;font-size: 18px; margin-left: 30px;"></i>
                    <i class="ri-delete-bin-6-line"  (click)="cancelSelection()" style="cursor: pointer; font-size: 18px; margin-left: 30px;"></i>
                    <button class="white-button" (click)="cancelSelection()">Cancel</button>
                  </span>
            </p>
        </div>

                    <div class="pb_listItems" *ngFor="let property of listings; let propIndex = index;">
                        <div class="row">
                            <div class="col-sm-9 col-lg-9 morder-2">
                                <div class="ps-wrap">
                                    <div class="pb_listimage">
                                        <a>
                                            <img [src]="property.propertyPreviewImage ? property.propertyPreviewImage : (property.images && property.images.length ? property.images[0] : 'assets/img/property_search_image1.png')"
                                                alt="" />
                                            <!-- <p class="tag"><span><i class="ri-shield-star-line"></i> Premium</span></p> -->
                                        </a>
                                      
                                    </div>
                                    <div class="ps-content">
                                        <div class="d-flex justify-content-between">
                                            <div class="list_price"></div>
                                            <div class="shareicons">
                                                <ul class="list-inline">
                                                    <li class="list-inline-item">
                                                        <input type="checkbox" [(ngModel)]="property.selected"
                                                            (change)="selectedCheckProp(property)"
                                                            style="width: 20px; height: 20px;">
                                                    </li>
                                                    <ng-container *ngIf="!getPlanId">
                                                        <li class="list-inline-item">
                                                            <button class="btn btn-primary"
                                                                (click)="choosePlanForShare(property)"> SHARE <i
                                                                    class="bi bi-share-fill"></i></button>
                                                        </li>
                                                    </ng-container>
                                                    <ng-container *ngIf="getPlanId">

                                                       
                                                       
                                                        <li class="list-inline-item">
                                                            <button class="btn btn-primary"
                                                                (click)="shareSingleProp(property)"> SHARE <i
                                                                    class="bi bi-share-fill"></i></button>
                                                        </li>
                                                    </ng-container>



                                                </ul>
                                            </div>
                                        </div>
                                        <h4 class="ps-heading">{{property.propertyTitle}}</h4>
                                        <div class="sqft_details">
                                            <ul class="list-group list-group-horizontal">
                                                <li class="list-group-item"
                                                    *ngFor="let attribute of property.attributes | slice:0:5;">
                                                    <p>{{attribute.attributeName}}</p>
                                                    <h6>{{attribute.attributePropertyName}}</h6>
                                                </li>
                                                <li class="list-group-item">
                                                    <p>Property Size</p>
                                                    <h6>{{property.propertySize}} {{property.measurementName}}</h6>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <ng-container>
                                                <p style="font-size: 12px;">{{ property.propertyDescription | slice:0:76
                                                    }}...<a (click)="toggleContent(propIndex)"
                                                        style="font-size: 11px;">Read More</a>
                                                </p>
                                            </ng-container>
                                            <!-- <ng-template #shortContent>
                                                <p style="font-size: 12px;">{{ property.propertyDescription }}<a
                                                        *ngIf="showFullContent[propIndex]"
                                                        (click)="toggleContent(propIndex)" style="font-size: 11px;">Show
                                                        Less</a>
                                                </p>
                                            </ng-template> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="bottomDiv morder-last nearby"
                                    *ngIf="property.locationHighlights && getLocationLength(property.locationHighlights) > 0">
                                    <ul class="list-inline">
                                        <li class="list-inline-item">
                                            <p><img src="assets/img/location.svg" alt="places">
                                                {{getLocationLength(property.locationHighlights)}}
                                                nearby places:</p>
                                        </li>
                                        <ng-container *ngFor="let item of property.locationHighlights | keyvalue">
                                            <ng-container *ngIf="item.value && item.value !== null && item.key!=='aboutLocality'">
                                                <li class="list-inline-item">
                                                    <p class="bgpink">
                                                        <img *ngIf="getIcon(item.key)" [src]="getIcon(item.key)"
                                                            alt="places">{{ item.value }}
                                                    </p>
                                                </li>
                                            </ng-container>
                                        </ng-container>


                                    </ul>

                                </div>
                            </div>
                            <div class="col-sm-3 col-lg-3 morder-1">
                                <div class="ps-rightside">
                                    <div class="list_price text-center">
                                        <h2>{{property.price | rsbcurrency : property.priceType }} <span
                                                class="emi">(EMI starts at ₹58k)</span></h2>
                                    </div>
                                    <ul class="dowloadbrocher list-inline text-center">
                                        <li class="list-inline-item">
                                            <button class="btnbrocher" (click)="contactOwner(property)">Get
                                                Deal</button>
                                        </li>
                                        <li class="list-inline-item">
                                            <button class="reqvisitbtn" style="display: flex; align-items: center; width: 100%;">
                                                <span (click)="requestSiteVisit(property)" style="flex-grow: 1; text-align: left;">
                                                  Schedule Site Visit
                                                </span>
                                                <span style="display: flex; align-items: center;">
                                                  <img src="assets/img/calender-icon.png" style="width: 25px; height: auto; margin-left: 5px;"
                                                    alt="Calendar Icon">
                                                </span>
                                              </button>
                                        </li>
                                        <!-- <li class="list-inline-item">
                                            <button class="btn btn-danger text-white">2 Months EMI <span
                                                    class="label-warning">Free</span></button>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> <!---Pb_listItems-->

                </div> <!--end  of col-9-->

            </div>
        </div>
    </section>
</div>

<!-- Mobile Layout -->
<section *ngIf="isMobileLayout">
    <div class="mb-dashboard">
    <div class="container">
  
      <div id="mb_products" class="row">
        <div class="form-group">
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" name="favCategory" value="Favorite Properties" (change)="selectedFavCategory($event)" checked>
              Favorite Properties
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" name="favCategory" value="Favorite Projects" (change)="selectedFavCategory($event)">
              Favorite Projects
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" name="favCategory" value="Favorite Pg" (change)="selectedFavCategory($event)">
              Favorite Pg
            </label>
          </div>
        </div>
        <div class="mb_item col-6 col-lg-4 mb_list_group_item" *ngFor="let property of listings; let propIndex = index;">
          <div class="card">
            <div class="thumbnail ">
              <div class="img-event">
                <img class="group list-group-image img-fluid" [src]="property.propertyPreviewImage ? property.propertyPreviewImage : (property.images && property.images.length ? property.images[0] : 'assets/img/property_search_image1.png')" alt="" />
              </div>
              <div class="caption card-body">
                <div class="mb_capHeader d-flex justify-content-between">
                  <div class="mb_capPrice">
                    <h2> {{property.price | rsbcurrency : property.priceType }}</h2>
                  </div>
                  <div class="mb_share">
                    <ul class="list-inline">
                      <!-- <li class="list-inline-item"><i class="ri-share-forward-line share"></i></li> -->
                      <ng-container *ngIf="!getPlanId">
                        <li class="list-inline-item" *ngIf="!favoriteStates[property.listingId]">
                          <i class="ri-heart-line fav" (click)="favoriteSingleProp(property)"></i>
                         </li>
                         <li class="list-inline-item" *ngIf="favoriteStates[property.listingId]">
                          <i class="ri-heart-line fav"></i>
                         </li>
                        <li class="list-inline-item">
                          <i class="ri-share-forward-line share" (click)="choosePlanForShare(property)"></i>
                        </li>
    
                        </ng-container>
                      <ng-container *ngIf="getPlanId">
                      <li class="list-inline-item" *ngIf="!favoriteStates[property.listingId]">
                        <i class="ri-heart-line fav" (click)="favoriteSingleProp(property)"></i>
                       </li>
                       <li class="list-inline-item" *ngIf="favoriteStates[property.listingId]">
                        <i class="ri-heart-line fav" (click)="favoriteSingleProp(property)"></i>
                       </li>
                      <li class="list-inline-item">
                        <i class="ri-share-forward-line share" (click)="shareSingleProp(property)"></i>
                      </li>
  
                      </ng-container>
                    </ul>
                  </div>
                </div>
                <p class="mb_captionDes mb-0"><small> {{property.propertyTitle}} </small></p>
              </div>
            </div> <!--end thumbnail div-->
            <ul class="list-inline mb_nearby">
              <ng-container *ngIf="property.locationHighlights && getLocationLength(property.locationHighlightsNew) > 0">
                <ng-container *ngIf="getHighlightElements(property.locationHighlightsNew)">
                <li class="list-inline-item">
                <p>
                  <img src="assets/img/location.svg" alt="">
                  <small>nearby places:</small>
                </p>
              </li>
            
              <li class="list-inline-item">
                
                <p>
                  <!-- <img *ngIf="getIcon(item.key)" [src]="getIcon(item.key)" alt="places"> -->
                  <small>{{ getHighlightElements(property.locationHighlightsNew) }}</small>
                </p>
              </li>
              
              <li class="list-inline-item">
                <p class="mb_noBg" (click)="morePopup(property)">More...</p>
              </li>
              </ng-container>
              </ng-container>
            </ul>
            
            <ul class="mb_prop_details list-inline">
              <li class="list-inline-item">
                <p class="mb-0">Sizes</p>
                <h6>{{property.propertySize}} {{property.measurementName}}</h6>
              </li>
              <li class="list-inline-item" *ngFor="let attribute of property.attributes | slice:0:3;">
                <p class="mb-0">{{attribute.attributeName}}</p>
                <h6>{{attribute.attributePropertyName}}</h6>
              </li>
            </ul>
            <!-- places popup -->
            <div class="mb_popup" [ngStyle]="{ 'display': mobilePopup ? 'block' : 'none' }" *ngIf="mobilePopup">
              <div class="popup-content">
                <a (click)="morePopup()"><span id="closePopup" class="close-btn">&times;</span></a>
                <h4>Near by</h4>
                <ng-container>
                <ul class="list-inline mb_amenities">
                  <ng-container *ngFor="let item of morePropertyListing.locationHighlightsNew | keyvalue">
                    <ng-container *ngIf="item.value && item.value !== null">
                  <li>
                    <img *ngIf="getIcon(item.key)" [src]="getIcon(item.key)" alt="places">
                    <p> {{item.value}}</p>
                  </li>
                  </ng-container>
                  </ng-container>
                </ul>
                </ng-container>
                <h4 class="mt-4 mb_sft_details">Project Details</h4>
                <ul class="mb_sft_details list-inline">
                  <li>
                    <p class="mb-0">Sizes</p>
                    <h6>{{morePropertyListing.propertySize}} {{morePropertyListing.measurementName}}</h6>
                  </li>
                  <li *ngFor="let attribute of morePropertyListing.attributes">
                    <p class="mb-0">{{attribute.attributeName}}</p>
                    <h6>{{attribute.attributePropertyName}}</h6>
                  </li>
                
                </ul>
                <div class="mb_projectDetail">
                  <h4>About Property</h4>
                  <p>{{morePropertyListing.propertyDescription}}</p>
                </div>
              </div>
            </div>
            <!-- bottom buttons -->
            <div class="mb_btmbuttons">
              <button class="btn btn-primary mb_deal" (click)="contactOwner(property)">Get Deal</button>
              <button class="btn btn-primary mb_site" (click)="requestSiteVisit(property)">Request Site Visit</button>
              <!-- <button class="btn btn-primary mb_emi">2 Months EMI <span>Free</span></button> -->
              
            </div>
          </div> <!--end card-->
        </div> <!--end mb_item-->
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pb-4 text-center">
          <small class="copy-right-text">All trademarks, logos and names are properties of their respective
            owners. Copyright © 2024 - All rights reserved.</small>
        </div>
      </div>
  
    </div>
  <!-- 
    <footer>
      <nav class="navbar fixed-bottom bg-secondary">
        <div class="container-fluid">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Terms & Conditions </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Home</a>
            </li>
          </ul>
        </div>
      </nav>
    </footer> -->
    </div>
  </section>