<div class="mb-dashboard">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 my-3">
                <div class="mb_gb_search">
                    <div class="input-group ">
                        <input type="text" class="form-control mb_searchclick"
                            placeholder="Search House, Apartment, etc |" aria-label="Search House, Apartment, etc |"
                            aria-describedby="addon-wrapping">
                        <span class="input-group-text" id="addon-wrapping"><i class="ri-search-line"></i></span>
                    </div>
                    <div class="mb_gb_pop" style="display:block">
                        <div class="mb_gb_pop_content">
                            <span class="close-btn" (click)="backToSearchProp()"><i
                                    class="ri-arrow-left-line"></i>Filters</span>
                            <div class="mb-search-tab">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item" *ngFor="let module of lookupModel.getModuleList();">
                                        <!-- <a class="nav-link active" data-bs-toggle="tab" href="#Buy">Buy</a> -->
                                        <a class="nav-link"
                                            [ngClass]="{'active': module.moduleId === selectedModule.moduleId}"
                                            data-bs-toggle="tab" [data-bs-target]="'#' + module.moduleName" role="tab"
                                            [aria-controls]="module.moduleName" (click)="selectModule(module)"
                                            [aria-selected]="module.moduleId === selectedModule.moduleId ? true : false">
                                            <b>{{ module.moduleName }}</b>
                                        </a>
                                    </li>

                                </ul>
                                <div class="tab-content">
                                    <form [formGroup]="searchForm" #srchForm="ngForm">

                                        <div class="tab-pane container active mb-5" id="Buy">
                                            <div class="mb-search-field mb-3">
                                                <label for="">Select City/Localities</label>
                                                <div class="input-group  searchsection" #cityEntryBox (click)="startSearch()">
                                                    <!-- <div class="searchlist" *ngIf="cities.length">
                                                        <div class="searchlistitem">{{ cities[0].cityName }} </div>
                                                        <div [ngStyle]="{ 'display': !searchboxFocus ? 'block' : 'none'}" class="itemclose"
                                                          (click)="removeEle(0)"> </div>
                                                      </div> -->
                                                      <ng-container *ngIf="cities.length" style="overflow-x: scroll;"> 
                                                    <!-- <span class="input-group-text mb-loc">{{ cities[0].cityName }}<i
                                                            class="bi bi-x"></i></span>
                                                            <span class="input-group-text mb-loc" *ngIf="selectedAddMoreCity">{{selectedAddMoreCity}}<i
                                                                class="bi bi-x"></i></span> -->
                                                                <span *ngFor="let item of cities">
                                                                    <span class="input-group-text mb-loc">{{ item.cityName }}<i
                                                                        class="bi bi-x"></i></span>
                                                                </span>

                                                        </ng-container>
                                                        <ng-container *ngIf="searchMore">
                                                            <span class="searchlistitem_count">+{{ cities.length-1 }} </span>
                                                          </ng-container>
                                                    <input type="text" #cityText class="form-control"
                                                        placeholder="Enter City" id="city" name="city"
                                                        formControlName="city" (click)="addMoreLocations()">
                                                </div>
                                            </div>

                                            <div class="mb-price mb-4">
                                                <label for="">Budget</label>
                                                <div class="price-input-container">
                                                    <div class="price-input">
                                                        <div class="price-field">
                                                            <select class="min-control form-control" style="width: 50%;">
                                                                <option>Budget</option>
                                                                <ng-container *ngFor="let budget of bugetList;index as bIndex;">
                                                                <option value="{{budget.budgetId}}"> {{budget.budgetName}}</option>
                                                                </ng-container>
                                                            </select>
                                                            
                                                        </div>
                                                    </div>
                                                    <!-- <div class="slider-container">
                                                        <div class="price-slider"></div>
                                                      </div> -->
                                                </div>
                                                <!-- <div class="range-input">
                                                      <input type="range" class="min-range" min="0" max="10000" value="0" step="1">
                                                      <input type="range" class="max-range" min="0" max="10000" value="10000" step="1">
                                                    </div> -->
                                            </div>
                                            <div class="mb-proprty-type mb-3">
                                                <label for="">Property Type</label>
                                                <div class="mb-property-container">
                                                    <div class="mb-prop-item"
                                                        *ngFor="let category of categoryList;let catIndex = index;">
                                                        <div class="checkbox-wrapper">
                                                            <input type="checkbox"  class="custom-checkbox" [id]="'category-' + catIndex" 
                                                                 [checked]="category.categoryId == selectedCategory.categoryId" (change)="subCatOpen(category)">
                                                            <label [for]="'category-' + catIndex" class="checkbox-label" ></label>
                                                        </div>
                                                        <!-- <img src="assets/mobile/img/mb-flat-icon.png" alt=""
                                                            class="img-fluid"> -->
                                                            <img *ngIf="getPropertyIcon(category.categoryName)" [src]="getPropertyIcon(category.categoryName)" alt="" class="img-fluid">
         
                                                        <div>
                                                            <a><p [attr.value]="category.categoryId" [attr.name]="'cat'" [attr.id]="catIndex">{{category.categoryName}}</p></a>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                            <div class="mb-proprty-type mb-3"
                                                *ngIf="!typePG && selectedCategory && selectedCategory.subCategory.length > 0">
                                                <strong>Unit Type</strong>
                                                <div class="mb-property-container">
                                                    <div class="mb-prop-item"
                                                        *ngFor="let subCat of selectedCategory.subCategory;let subIndex = index;">
                                                        <div class="checkbox-wrapper">
                                                            <input type="checkbox"  class="custom-checkbox" [id]="'subCategory-' + subIndex" (change)="selectSubCategory($event,subIndex,'property')" [checked]="subCat.isSelected">
                                                            <label [for]="'subCategory-' + subIndex" class="checkbox-label"></label>
                                                        </div>
                                                        <ng-container>
                                                            <img *ngIf="getSubCategoryIcon(subCat.subCategoryName)" [src]="getSubCategoryIcon(subCat.subCategoryName)" alt="" class="img-fluid">
                                                          </ng-container>
                                                        <div *ngIf="!typePG">
                                                            <p [attr.value]="subCat.subCategoryId" [attr.name]="'cat'" [attr.id]="subIndex">{{subCat.subCategoryName}}
                                                            </p>
                                                        </div>

                                                        <!-- <li>
                                            <label><input type="checkbox" class="optionInput unitCatIds subC" id="{{subCat.subCategoryId}}"
                                                    [checked]="subCat.isSelected" value="{{subCat.subCategoryId}}"
                                                    (change)="selectSubcat($event,subIndex,'property')">{{subCat.subCategoryName}}</label>
                                        </li> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-proprty-type mb-3" id="moresearch"
                                                *ngIf="typePG && occupancyTypes.length > 0">
                                                <strong>Unit Type</strong>
                                                <div class="mb-property-container">

                                                    <div class="mb-prop-item"
                                                        *ngFor="let subCat of occupancyTypes[0].attributeProperties;let subIndex = index;">
                                                        <div class="checkbox-wrapper">
                                                            <input type="checkbox" [id]="'subCat-' + subIndex" class="custom-checkbox" (change)="selectSubCategory($event,subIndex,'pg')" [checked]="subCat.isSelected">
                                                            <label [for]="'subCat-' + subIndex" class="checkbox-label"></label>
                                                        </div>
                                                        <ng-container>
                                                            <img *ngIf="getBedSharingIcon(subCat.swatchName)" [src]="getBedSharingIcon(subCat.swatchName)" alt="" class="img-fluid">
                                                          </ng-container>
                                                          

                                                        <div *ngIf="typePG">
                                                            <p [attr.value]="subCat.categoryId" [attr.name]="'subCat'" [attr.id]="subIndex">
                                                                {{subCat.swatchName}}</p>
                                                        </div>
                                                        <!-- 
                                            <label><input type="checkbox" class="optionInput unitCatIds subC" id="{{subCat.propertyId}}"
                                                    [checked]="subCat.isSelected" value="{{subCat.propertyId}}"
                                                    (change)="selectSubcat($event,subIndex,'pg')">{{subCat.swatchName}}</label> -->
                                                    </div>

                                                </div>
                                            </div>



                                            <div class="mb-bhk mb-3" *ngFor="let attrName of attrList;index as bIndex">
                                                <label for="">{{attrName.attributeName}}</label>
                                                <div class="mb-bhk-container">
                                                    <button
                                                        *ngFor="let attrProperties of attrName.attributeProperties; index as attrIndex;"
                                                        (click)="filterByAttribute(bIndex,attrIndex, true)"
                                                        class="btn btn-light rounded-pill active"
                                                        [ngClass]="{'active':attrProperties.isSelected}"><i
                                                            class="ri-add-line"></i>{{attrProperties.swatchName}}</button>

                                                </div>
                                            </div>
                                            <!-- <div class="mb-bhk mb-3">
                                                                    <label for="">Posted by</label>
                                                                    <div class="mb-bhk-container">
                                                                        <button class="btn btn-light rounded-pill active"><i class="ri-add-line"></i> Owners</button>
                                                                        <button class="btn btn-light rounded-pill"><i class="ri-add-line"></i> Agents</button>
                                                                        <button class="btn btn-light rounded-pill active"><i class="ri-add-line"></i> Builders</button>
                                                                    </div>
                                        </div> -->
                                            <!-- <div class="mb-amenities mb-3">
                            <label for="" class="d-block">Amenities</label>
                            <div class="mb-ame-checkbox">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                <label class="form-check-label" for="inlineCheckbox1">Reserved Parking</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                <label class="form-check-label" for="inlineCheckbox2">Power Back Up</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                <label class="form-check-label" for="inlineCheckbox1">Park</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                <label class="form-check-label" for="inlineCheckbox2">Gymnasium</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                <label class="form-check-label" for="inlineCheckbox1">Club House</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                <label class="form-check-label" for="inlineCheckbox2">Air Conditioned</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                <label class="form-check-label" for="inlineCheckbox1">Lift</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                <label class="form-check-label" for="inlineCheckbox2">Piped Gas</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                <label class="form-check-label" for="inlineCheckbox1">Kids play area</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                <label class="form-check-label" for="inlineCheckbox2">Swimming Pool</label>
                              </div>
                            </div>
                          </div> -->
                                        </div> <!--Buy-->
                                        <!-- <div class="tab-pane container fade" id="Rent">
                        <form>
                          <div class="mb-search-field mb-3">
                            <label for="">Select City/Localities</label>
                              <div class="input-group  searchsection">
                                  <span class="input-group-text mb-loc">Hyderabad <i class="bi bi-x"></i></span>
                                  <span class="input-group-text mb-add"><i class="ri-add-line"></i> Add More </span>
                                  <input type="text" class="form-control" placeholder="Search by Location, Project Name">
                              </div>
                          </div>
                          <div class="mb-price mb-4">
                            <label for="">Budget</label>
                            <div class="price-input-container">
                              <div class="price-input">
                                <div class="price-field">
                                  <select class="min-input form-control">
                                    <option value="">Min</option>
                                  </select>
                                  <span class="mx-3">to</span>
                                  <select class="max-input form-control">
                                    <option value="">Max</option>
                                  </select>
                                </div>
                              </div>
                              <div class="slider-container">
                                <div class="price-slider"></div>
                              </div>
                            </div>
                            <div class="range-input">
                              <input type="range" class="min-range" min="0" max="10000" value="0" step="1">
                              <input type="range" class="max-range" min="0" max="10000" value="10000" step="1">
                            </div>
                          </div>
                          <div class="mb-proprty-type mb-3">
                            <label for="">Property Typee</label>
                            <div class="mb-property-container">
                                <div class="mb-prop-item" *ngFor="let category of categoryList;let catIndex = index;">
                                    <div class="checkbox-wrapper">
                                        <input type="checkbox" id="flat" class="custom-checkbox" checked>
                                        <label for="flat" class="checkbox-label"></label>
                                    </div>
                                    <img src="img/mb-flat-icon.png" alt="" class="img-fluid">
                                    <div *ngIf="!typePG">
                                        <p value="{{category.categoryId}}" name="cat" id="{{catIndex}}">{{category.categoryName}}</p>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div class="mb-bhk mb-3">
                            <label for="">BHK</label>
                            <div class="mb-bhk-container">
                                <button class="btn btn-light rounded-pill active"><i class="ri-add-line"></i> 1 BHK</button>
                                <button class="btn btn-light rounded-pill"><i class="ri-add-line"></i> 2 BHK</button>
                                <button class="btn btn-light rounded-pill active"><i class="ri-add-line"></i> 3 BHK</button>
                                <button class="btn btn-light rounded-pill"><i class="ri-add-line"></i> 4 BHK</button>
                                <button class="btn btn-light rounded-pill"><i class="ri-add-line"></i> 5 BHK</button>
                                <button class="btn btn-light rounded-pill"><i class="ri-add-line"></i> >5 BHK</button>
                            </div>
                          </div>
                          <div class="mb-bhk mb-3">
                            <label for="">Posted by</label>
                            <div class="mb-bhk-container">
                                <button class="btn btn-light rounded-pill active"><i class="ri-add-line"></i> Owners</button>
                                <button class="btn btn-light rounded-pill"><i class="ri-add-line"></i> Agents</button>
                                <button class="btn btn-light rounded-pill active"><i class="ri-add-line"></i> Builders</button>
                            </div>
                          </div>
                          <div class="mb-amenities mb-3">
                            <label for="" class="d-block">Amenities</label>
                            <div class="mb-ame-checkbox">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                <label class="form-check-label" for="inlineCheckbox1">Reserved Parking</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                <label class="form-check-label" for="inlineCheckbox2">Power Back Up</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                <label class="form-check-label" for="inlineCheckbox1">Park</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                <label class="form-check-label" for="inlineCheckbox2">Gymnasium</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                <label class="form-check-label" for="inlineCheckbox1">Club House</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                <label class="form-check-label" for="inlineCheckbox2">Air Conditioned</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                <label class="form-check-label" for="inlineCheckbox1">Lift</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                <label class="form-check-label" for="inlineCheckbox2">Piped Gas</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                <label class="form-check-label" for="inlineCheckbox1">Kids play area</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                <label class="form-check-label" for="inlineCheckbox2">Swimming Pool</label>
                              </div>
                            </div>
                          </div>
                        </form> 
                      </div> Rent -->
                                        <div class="project-button">
                                            <button (click)="submit()">View Properties({{listingCount ? listingCount : 0}}) </button>
                                            <!-- <button (click)="submit()">View Properties({{ attrList.length }}) </button> -->
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="mb_lg_btns mb-2">
                <button class="btn active" id="list">
                    <i class="ri-list-check"></i>
                </button>
                <button class="btn" id="grid">
                    <i class="ri-layout-grid-fill"></i>
                </button>
            </div> -->
        </div>
        <!-- <div id="mb_products" class="row">
            <div class="mb_item col-6 col-lg-4 mb_list_group_item">
                <div class="card">
                    <div class="thumbnail ">
                        <div class="img-event">
                            <img class="group list-group-image img-fluid" src="img/property_search_image1.png" alt="" />
                        </div>
                        <div class="caption card-body">
                            <div class="mb_capHeader d-flex justify-content-between">
                                <div class="mb_capPrice">
                                    <h2><span>&#8377;</span> 50,000</h2>
                                </div>
                                <div class="mb_share">
                                    <ul class="list-inline">
                                        <li class="list-inline-item"><i class="ri-share-forward-line share"></i></li>
                                        <li class="list-inline-item"><i class="ri-heart-line fav"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <h4 class="group card-title inner list-group-item-heading">Gupta Heights</h4>
                            <p class="mb_captionDes mb-0"><small> 2 BHK Independent House for Rent at OAKLAND MIRACLES
                                    The School, Bachupally, Hyderabad </small></p>
                        </div>
                    </div> 
                    <ul class="list-inline mb_nearby">
                        <li class="list-inline-item">
                            <p>
                                <img src="img/location.svg" alt="">
                                <small>nearby places:</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p>
                                <img src="img/Food.svg" alt="">
                                <small>Kotyard Food..(1.3 km away)</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb_noBg">More...</p>
                        </li>
                    </ul>
                    <ul class="mb_prop_details list-inline">
                        <li class="list-inline-item">
                            <p class="mb-0">Sizes (SQ.FT)</p>
                            <h6>1590 - 2088 sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Average Price</p>
                            <h6>₹6,000/sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Possession Date</p>
                            <h6>Aug,2026</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Current Status</p>
                            <h6>Under Construction</h6>
                        </li>
                    </ul>
                    <div class="mb_popup">
                        <div class="popup-content">
                            <span id="closePopup" class="close-btn">&times;</span>
                            <h4>Project Near by</h4>
                            <ul class="list-inline mb_amenities">
                                <li>
                                    <img src="img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                                <li>
                                    <img src="img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                            </ul>
                            <h4 class="mt-4 mb_sft_details">Project Details</h4>
                            <ul class="mb_sft_details list-inline">
                                <li>
                                    <p class="mb-0">Sizes (SQ.FT)</p>
                                    <h6>1590 - 2088 sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Average Price</p>
                                    <h6>₹6,000/sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Possession Date</p>
                                    <h6>Aug,2026</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Current Status</p>
                                    <h6>Under Construction</h6>
                                </li>
                            </ul>
                            <div class="mb_projectDetail">
                                <h4>About Project</h4>
                                <p>Luxury 2 bhk multistorey apartment is available at a prime location in Bachupally .
                                    It has an area of 1100 sqft . The property is available FOR RENT. It has street
                                    lighting as well as rain water harvesting</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb_btmbuttons">
                        <button class="btn btn-primary mb_deal">Get Deal</button>
                        <button class="btn btn-primary mb_site">Request Site Visit</button>
                        <button class="btn btn-primary mb_emi">2 Months EMI <span>Free</span></button>
                    </div>
                </div> 
            </div> 
            <div class="mb_item col-6 col-lg-4 mb_list_group_item">
                <div class="card">
                    <div class="thumbnail ">
                        <div class="img-event">
                            <img class="group list-group-image img-fluid" src="img/property_search_image1.png" alt="" />
                        </div>
                        <div class="caption card-body">
                            <div class="mb_capHeader d-flex justify-content-between">
                                <div class="mb_capPrice">
                                    <h2><span>&#8377;</span> 50,000</h2>
                                </div>
                                <div class="mb_share">
                                    <ul class="list-inline">
                                        <li class="list-inline-item"><i class="ri-share-forward-line share"></i></li>
                                        <li class="list-inline-item"><i class="ri-heart-line fav"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <h4 class="group card-title inner list-group-item-heading">Gupta Heights</h4>
                            <p class="mb_captionDes mb-0"><small> 2 BHK Independent House for Rent at OAKLAND MIRACLES
                                    The School, Bachupally, Hyderabad </small></p>
                        </div>
                    </div> 
                    <ul class="list-inline mb_nearby">
                        <li class="list-inline-item">
                            <p>
                                <img src="img/location.svg" alt="">
                                <small>nearby places:</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p>
                                <img src="img/Food.svg" alt="">
                                <small>Kotyard Food..(1.3 km away)</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb_noBg">More...</p>
                        </li>
                    </ul>
                    <ul class="mb_prop_details list-inline">
                        <li class="list-inline-item">
                            <p class="mb-0">Sizes (SQ.FT)</p>
                            <h6>1590 - 2088 sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Average Price</p>
                            <h6>₹6,000/sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Possession Date</p>
                            <h6>Aug,2026</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Current Status</p>
                            <h6>Under Construction</h6>
                        </li>
                    </ul>
                    <div class="mb_popup">
                        <div class="popup-content">
                            <span id="closePopup" class="close-btn">&times;</span>
                            <h4>Project Near by</h4>
                            <ul class="list-inline mb_amenities">
                                <li>
                                    <img src="img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                                <li>
                                    <img src="img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                            </ul>
                            <h4 class="mt-4 mb_sft_details">Project Details</h4>
                            <ul class="mb_sft_details list-inline">
                                <li>
                                    <p class="mb-0">Sizes (SQ.FT)</p>
                                    <h6>1590 - 2088 sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Average Price</p>
                                    <h6>₹6,000/sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Possession Date</p>
                                    <h6>Aug,2026</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Current Status</p>
                                    <h6>Under Construction</h6>
                                </li>
                            </ul>
                            <div class="mb_projectDetail">
                                <h4>About Project</h4>
                                <p>Luxury 2 bhk multistorey apartment is available at a prime location in Bachupally .
                                    It has an area of 1100 sqft . The property is available FOR RENT. It has street
                                    lighting as well as rain water harvesting</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb_btmbuttons">
                        <button class="btn btn-primary mb_deal">Get Deal</button>
                        <button class="btn btn-primary mb_site">Request Site Visit</button>
                        <button class="btn btn-primary mb_emi">2 Months EMI <span>Free</span></button>
                    </div>
                </div> 
            </div> 
            <div class="mb_item col-6 col-lg-4 mb_list_group_item">
                <div class="card">
                    <div class="thumbnail ">
                        <div class="img-event">
                            <img class="group list-group-image img-fluid" src="img/property_search_image1.png" alt="" />
                        </div>
                        <div class="caption card-body">
                            <div class="mb_capHeader d-flex justify-content-between">
                                <div class="mb_capPrice">
                                    <h2><span>&#8377;</span> 50,000</h2>
                                </div>
                                <div class="mb_share">
                                    <ul class="list-inline">
                                        <li class="list-inline-item"><i class="ri-share-forward-line share"></i></li>
                                        <li class="list-inline-item"><i class="ri-heart-line fav"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <h4 class="group card-title inner list-group-item-heading">Gupta Heights</h4>
                            <p class="mb_captionDes mb-0"><small> 2 BHK Independent House for Rent at OAKLAND MIRACLES
                                    The School, Bachupally, Hyderabad </small></p>
                        </div>
                    </div> 
                    <ul class="list-inline mb_nearby">
                        <li class="list-inline-item">
                            <p>
                                <img src="img/location.svg" alt="">
                                <small>nearby places:</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p>
                                <img src="img/Food.svg" alt="">
                                <small>Kotyard Food..(1.3 km away)</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb_noBg">More...</p>
                        </li>
                    </ul>
                    <ul class="mb_prop_details list-inline">
                        <li class="list-inline-item">
                            <p class="mb-0">Sizes (SQ.FT)</p>
                            <h6>1590 - 2088 sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Average Price</p>
                            <h6>₹6,000/sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Possession Date</p>
                            <h6>Aug,2026</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Current Status</p>
                            <h6>Under Construction</h6>
                        </li>
                    </ul>
                    <div class="mb_popup">
                        <div class="popup-content">
                            <span id="closePopup" class="close-btn">&times;</span>
                            <h4>Project Near by</h4>
                            <ul class="list-inline mb_amenities">
                                <li>
                                    <img src="img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                                <li>
                                    <img src="img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                            </ul>
                            <h4 class="mt-4 mb_sft_details">Project Details</h4>
                            <ul class="mb_sft_details list-inline">
                                <li>
                                    <p class="mb-0">Sizes (SQ.FT)</p>
                                    <h6>1590 - 2088 sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Average Price</p>
                                    <h6>₹6,000/sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Possession Date</p>
                                    <h6>Aug,2026</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Current Status</p>
                                    <h6>Under Construction</h6>
                                </li>
                            </ul>
                            <div class="mb_projectDetail">
                                <h4>About Project</h4>
                                <p>Luxury 2 bhk multistorey apartment is available at a prime location in Bachupally .
                                    It has an area of 1100 sqft . The property is available FOR RENT. It has street
                                    lighting as well as rain water harvesting</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb_btmbuttons">
                        <button class="btn btn-primary mb_deal">Get Deal</button>
                        <button class="btn btn-primary mb_site">Request Site Visit</button>
                        <button class="btn btn-primary mb_emi">2 Months EMI <span>Free</span></button>
                    </div>
                </div> 
            </div> 
        </div> -->
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 pb-4 text-center">
                <small class="copy-right-text">All trademarks, logos and names are properties of their respective
                    owners. Copyright © 2024 - All rights reserved.</small>
            </div>
        </div>

    </div>

    <footer>
        <nav class="navbar fixed-bottom bg-secondary">
            <div class="container-fluid">
                <ul class="nav justify-content-center">
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Terms & Conditions </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Policy</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Home</a>
                    </li>
                </ul>
            </div>
        </nav>
    </footer>
</div>