<div class="container">
    <h3>Cancellation & Refund Policy </h3><br>
    <h5 style="color: brown;">REFUND POLICY:</h5>
    <li>In case of any cancellation of subscription , request has to be sent to info@pillarblocks.com and based on the signup date to service opted partial refund to full refund will be initiated after account verification. <br>
        <p>Full Refund (100%) if requested within 24 hrs after payment , 50% Refund within 72 hrs. And No Refund after 7 calendar days. </p>
    </li><br>

        <h5 style="color: brown;">CANCELLATION POLICY:</h5><br>

        <li>In case of any cancellation of subscription , request has to be sent to info@pillarblocks.com and based on the signup date to service opted partial refund to full refund will be initiated after account verification. 
            </li><br>
            <li>No Cancellation Charges with in 24 Hrs. Any request after 24hrs will be levied with a cancellation charge upto 10% of subscription fee. Cancellations are allowed with in 48 hrs.
            </li>

        
</div>