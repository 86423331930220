import { Component, OnInit } from '@angular/core';
import { RsbService } from 'src/app/service/rsb.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-plot-publish',
  templateUrl: './plot-publish.component.html',
  styleUrls: ['./plot-publish.component.css']
})
export class PlotPublishComponent implements OnInit {
  marketingForm: FormGroup;

 
  constructor(
    private rsbService: RsbService,
    private toastrService: ToastrService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.marketingForm = this.fb.group({
      allowMarketing: ['', Validators.required],
      commissionPercentage: ['', [Validators.required, Validators.pattern(/^\d+%?$/)]]
    });
  }
  goBack(): void {
    console.log('Go Back clicked');
  }
  onSubmit(): void {
    if (this.marketingForm.valid) {
      console.log('Form Submitted', this.marketingForm.value);
    } else {
      console.log('Form is invalid');
    }
  }

  publish(): void {
    this.rsbService.publishPlan().subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        this.toastrService.success('Property has been published successfully.');
        this.router.navigate(['myProperties']);
      }
    });

   
  }
  postPublihForAssociate(): void{

    const args={
      allowMarketing:this.allowMarketing.value,
      commissionPercentage:this.commissionPercentage.value,
  

    }
    // alert("getting"+args)
  
    this.rsbService.postPublishForAssociate(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        this.toastrService.success('Property has been published successfully.');
        this.router.navigate(['myProperties']);

      } else {
        this.toastrService.error('Failed to submit data.');
      }
    });
    // this.publish();
  }
  // get allowMarketing() {
  //   return this.marketingForm.get('allowMarketing');
  // }

  // get commissionPercentage() {
  //   return this.marketingForm.get('commissionPercentage');
  // }
  get allowMarketing() { return this.marketingForm.get('allowMarketing'); }
  get commissionPercentage() { return this.marketingForm.get('commissionPercentage');}


}

