<div class="container-fluid" *ngIf="!isMobileLayout">
    <div class="pb-wrapper bg-white p-4">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <h3 class="d-inline-block mr-3 mb-0" style="color: rgb(177, 128, 128);">Payment Login's
                                </h3>
                                <!-- <span>Search:
                                    <input type="text" placeholder="Search Members" [(ngModel)]="searchText"
                                        (ngModelChange)="onSearchChange()">
                                </span> -->
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover agent-table">
                                    <thead>
                                        <tr>
                                            <th><input type="checkbox"></th>
                                            <th>PaymentId</th>
                                            <th>OrderId</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>status</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let getLogs of getLogsForAssociate">
                                            <td></td>
                                            <td>{{getLogs.paymentId}}</td>
                                            <td>{{getLogs.razorPayOrderId}}</td>
                                            <td>{{getLogs.updatedAt}}</td>
                                            <td>{{getLogs.amount}}</td>
                                            <td *ngIf="getLogs.paymentStatus === 1">
                                                <input type="text" style="text-align: center; width: 100px" class="text text-success" value="Success" readonly>
                                              </td>
                                              <td *ngIf="getLogs.paymentStatus === 0">
                                                <input type="text" style="text-align: center; width: 100px" class="text text-warning" value="Pending" readonly>
                                              </td>
                                              <td *ngIf="getLogs.paymentStatus !== 1 && getLogs.paymentStatus !== 0">
                                                <input type="text" style="text-align: center; width: 100px" class="text text-danger" value="Failed" readonly>
                                              </td>
                                              
                                            <td></td>

                                        </tr>
                                          
                                        
                                    </tbody>
                                </table>
                            </div>
                            <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-mb-payment-login *ngIf="isMobileLayout"></app-mb-payment-login>