<div class="mb-dashboard">
    <div class="container">

        <div class="mb-profile-card">
            <h2>Team Members</h2>
            <div class="col-12">
                <p class="btn btn-light rounded-pill addbtn mb-0" (click)="addPage()"><i class="ri-add-line"></i> Add</p>
              </div>
            <div class="mb-edit-profile">
                <div class="mb-cibil-popup" [ngStyle]="{'display':isShow?'block':'none'}">

                <form #agentForm="ngForm" class="agent-profile" (ngSubmit)="onSubmit(agentForm)">
                    <span id="closePopup" class="close-btn" (click)="closePage()">&times;</span>

                    <div class="mb-profile-name">
                        <div class="row g-2">
                          
                            <div class="col-12">
                                <label for="Name">Name</label><span class="text-danger">*</span>
                                <input type="text" name="name" class="form-control" placeholder="Name" [(ngModel)]="member.name" required> 
                            </div>
                            <div class="col-12">
                                <label for="Name">Mobile</label><span class="text-danger">*</span>
                                <input type="text" name="mobileNumber" class="form-control" placeholder="Mobile Number"   [(ngModel)]="member.mobileNumber" required>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn btn-primary" [disabled]="!agentForm.valid">Add Member</button>

                            
                            </div>
                           
                           
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="mb-3">
                                <h3>Team Members</h3>
                                <div class="right-search">
                                    <input type="text" class="form-control mb-3 rounded-4" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control  rounded-4 mt-1">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>


                            <div class="table-head-fixed">
                                <table class="table table-striped">
                                    <thead class="table-dark">
                                        <tr>
                                            <th><input type="checkbox"></th>
                                            <th>Name</th>
                                            <th>Mobile Number</th>
                                            <th>Email ID</th>
                                            <th>Date Added</th>
                                            <th>City</th>
                                            <th>Remarks</th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                        *ngFor="let member of filteredMembers | paginate: { itemsPerPage: itemsPerPage, currentPage: p }">
                                        <td><input type="checkbox"></td>
                                        <td>{{ member.personalDetails.firstName }}</td>
                                        <td>{{ member.personalDetails.mobile || 'N/A' }}</td>
                                        <td>{{ member.personalDetails.email || 'N/A' }}</td>
                                        <td>{{ member.createdAt | date: 'dd-MM-yyyy' }}</td>
                                        <td>{{ member.city || 'N/A' }}</td>
                                        <td>{{ member.activeStatus === 1 ? 'Active' : 'Inactive' }}</td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 pb-4 text-center">
                <small class="copy-right-text">All trademarks, logos and names are properties of their respective
                    owners. Copyright © 2024 - All rights reserved.</small>
            </div>
        </div>

    </div>

    <!-- <footer>
        <nav class="navbar fixed-bottom bg-secondary">
            <div class="container-fluid">
                <ul class="nav justify-content-center">
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Terms & Conditions </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Policy</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Home</a>
                    </li>
                </ul>
            </div>
        </nav>                            
    </footer> -->
</div>