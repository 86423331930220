<div class="tab-pane active" id="pb-cdetails">
    <h5 class="card-title mb-4">Add OnGoing Project  <button (click)="addSwatch()">Add New</button></h5>
    <div class="card-body">
        <div class="row">
            <div class="form-group col-md-12" *ngFor="let swatch of swatchList; let i = index;">
                <label for="location">Enter Previous Project</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Project Name" id="location" [(ngModel)]="swatchList[i].projectName" >
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Project Description" id="location" [(ngModel)]="swatchList[i].projectDescription"/>
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Rera Number" id="Rera Number" [(ngModel)]="swatchList[i].reraNumber"/>
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Project Approved By" id="Project Approved By" [(ngModel)]="swatchList[i].projectApprovedBy"/>
                </div>
            </div>
        </div>
       
    </div>

    <button type="button" (click)="submit()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>


</div>