<div class="mb-dashboard">
  <div class="container">

    <div class="mb-profile-card">
      <h2>Cibil</h2>
      <div class="col-12">
        <p class="btn btn-light rounded-pill addbtn mb-0" (click)="addPage()"><i class="ri-add-line"></i> Add</p>
      </div>
      <div class="mb-edit-profile">

          <!-- <div class="mb-profile-name">
          
          </div> -->
          <div class="mb-cibil-popup" [ngStyle]="{'display':isShow?'block':'none'}">
            <form class="agent-profile" [formGroup]="cibilForm" (ngSubmit)="submit()" #cibForm="ngForm">

            <span id="closePopup" class="close-btn" (click)="closePage()">&times;</span>
            <div class="row g-2">

              <div class="col-12">
                <label for="Name">PAN Number<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" formControlName="panNum">
                <div
                  *ngIf="cibilForm.get('panNum').invalid && (cibilForm.get('panNum').dirty || cibilForm.get('panNum').touched)">
                  <div *ngIf="cibilForm.get('panNum').errors?.required" class="text-danger">
                    PAN Number is required.
                  </div>
                  <div *ngIf="cibilForm.get('panNum').errors?.invalidPAN" class="text-danger">
                    PAN Number is invalid.
                  </div>
                </div>
              </div>
              <div class="col-12">
                <label for="Name">Name<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" formControlName="name">
                <div
                  *ngIf="cibilForm.get('name').invalid && (cibilForm.get('name').dirty || cibilForm.get('panNum').touched)">
                  <div *ngIf="cibilForm.get('name').errors?.required" class="text-danger">
                    Name is required.
                  </div>
                </div>
                <small class="text-muted"><em style="color: green;">As per PANCARD</em></small>

              </div>
              <div class="col-12">
                <label for="Agency Name">Mobile Number<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" formControlName="mobile">
                <div *ngIf="cibilForm.get('mobile').invalid && (cibilForm.get('mobile').dirty || cibilForm.get('mobile').touched)">
                  <div *ngIf="cibilForm.get('mobile').errors?.required" class="text-danger">
                    Mobile Number is required.
                  </div>
                </div>
                <small class="text-muted"><em style="color: green;">Linked with PANCARD</em></small>
              </div>
              <div class="col-12">
                <label for="Operating since">Required Loan Amount<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" formControlName="loanAmount">
                <div *ngIf="cibilForm.get('loanAmount').invalid && (cibilForm.get('loanAmount').dirty || cibilForm.get('loanAmount').touched)">
                    <div *ngIf="cibilForm.get('loanAmount').errors?.required" class="text-danger">
                        Loan Amount is required.
                    </div>
                  </div>
            </div>
            <div class="form-row mb-4">
              <div class="col-12">
                <div class="drag-area" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
                  <div class="icon"><i class="ri-upload-line"></i></div>
                  <div class="d-flex header">
                    <p (click)="fileInput.click()">Drag & Drop to Upload File OR </p>
                    <label class="browse"> Browse File
                      <input type="file" #fileInput (change)="uploadAws($event)" accept="image/png, image/gif, image/jpeg" hidden>
                    </label>
                  </div>
                </div>
                <p class="jpeg">( Jpeg/Png format - 10Kb Size )</p>
              </div>
            </div>

            <div class="d-flex">
              <button class="btn btn-gradient-primary btn-small-width">Submit</button>
              <button class="btn btn-gradient-secondary btn-small-width ml-3" (click)="resetForm()">Reset</button>
            </div>
           
            
            


            </div>
          </form>

          </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="all-leads">
          <div class="card shadow mb-4">
            <div class="card-body">
              <div class="mb-3">
                <h3>Cibil</h3>
                <div class="right-search">
                  <input type="text" class="form-control mb-3 rounded-4" placeholder="Search">
                  <label for="Sort by:">Sort by:</label>
                  <select class="form-control  rounded-4 mt-1">
                    <option>Monthly</option>
                    <option>Weekly</option>
                    <option>Daily</option>
                  </select>
                </div>
              </div>


              <div class="table-head-fixed">
                <table class="table table-striped">
                  <thead class="table-dark">
                    <tr>
                      <th><input type="checkbox"></th>
                      <th>Client Name</th>
                      <th>Date Added</th>
                      <th>Mobile Number</th>
                      <th>PAN Number</th>
                      <th>Required Loan Amount</th>
                      <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of getCibilListings">
                      <td><input type="checkbox"></td>
                      <td>{{list.name}}</td>
                      <td>{{list.createdAt | date: 'short'}}</td>
                      <td>{{list.mobile}}</td>
                      <td>{{list.panNumber}}</td>
                      <td>{{list.loanAmount}}</td>  
                      <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 pb-4 text-center">
        <small class="copy-right-text">All trademarks, logos and names are properties of their respective
          owners. Copyright © 2024 - All rights reserved.</small>
      </div>
    </div>

  </div>

  <!-- <footer>
    <nav class="navbar fixed-bottom bg-secondary">
      <div class="container-fluid">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Terms & Conditions </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Policy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Home</a>
          </li>
        </ul>
      </div>
    </nav>
  </footer> -->
</div>