import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterExtService } from 'src/app/common/services/router-ext.service';
import { Router } from '@angular/router';
// import { ProfileimagecropperComponent } from 'src/app/shared/components/profileimagecropper/profileimagecropper.component';

import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  profileForm: FormGroup;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  isMobileLayout:boolean=false;

  constructor(
    private fb: FormBuilder,
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    console.log("change password")
    //const profile = this.lookupModel.getLoggedUserPersonalDetails();
    const profileDetails = this.lookupModel.getLoggedUser();
    this.profileForm = this.fb.group({
      newpassword: ['', [Validators.required]],
      confirmpassword: ['',[Validators.required]]
     
    });
  }

    update(){
      if (!this.profileForm.valid) {
        this.validateAllFormFields(this.profileForm); 
        return;
      
      }
      const args={
      associateId:this.lookupModel.getLoggedUser().associateId,
      newPassword:this.newpassword.value,
      confirmPassword:this.confirmpassword.value,

      }
      if(args.newPassword===args.confirmPassword){
        this.rsbService.changePassword(args).subscribe((rsp)=>{
          this.toastrService.success("Your Password Was Successfully Updated", "", {
            positionClass: 'toast-top-right'
          });

        })
      }
      else{
        this.toastrService.success("Something Went Wrong", "", {
          positionClass: 'toast-top-right'
        });      }
      
    }
    togglePassword(): void {
      this.hidePassword = !this.hidePassword;
    }
    toggleConfirmPassword() {
      this.hideConfirmPassword = !this.hideConfirmPassword;
    }
    validateAllFormFields(formGroup: FormGroup) {         
      Object.keys(formGroup.controls).forEach(field => {  
        const control = formGroup.get(field);             
        if (control instanceof FormControl) {             
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {        
          this.validateAllFormFields(control);            
        }
      });
    }

 

  get newpassword() { return this.profileForm.get('newpassword'); }
  get confirmpassword(){ return this.profileForm.get('confirmpassword'); }

   

}

