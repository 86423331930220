import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
import { RsbService } from 'src/app/service/rsb.service';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';

@Component({
  selector: 'rsb-post-gallery',
  templateUrl: './post-gallery.component.html',
  styleUrls: ['./post-gallery.component.css']
})
export class PostGalleryComponent implements OnInit {
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  propertyImages: Array<string> = [
    '',
    '',
    '',
    '',
    '',
    ''
  ];
  selectedProperty: any;

  constructor(
    private modalService: NgbModal,
    private rsbService: RsbService,
    private lookupModel: RsbLookupModel
  ) { }

  ngOnInit(): void {
    this.selectedProperty = this.lookupModel.getVendorProperty() ? this.lookupModel.getVendorProperty() : null;
    if(this.selectedProperty && this.selectedProperty.images) {
      this.propertyImages = this.selectedProperty.images;
    }
  }

  uploadAws(event: any, index: number): void {
    const mdlRef = this.modalService.open(ImageCropperComponent, { windowClass: 'signInForm'});
    mdlRef.componentInstance.imageChangedEvent = event;
    mdlRef.result.then((cropResp: {image: any, markDefault: boolean}) => {
      if(cropResp && cropResp.image) {
        const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
        modalRef.componentInstance.file = cropResp.image;
        modalRef.result.then((path: string) => {
          if (path) {
            if(this.propertyImages[index]) {
              this.propertyImages[index] = path;
            } else {
              const imageList = this.propertyImages.filter(item => item !== '');
              if(cropResp.markDefault) {
                imageList.unshift(path);
                this.selectedProperty.propertyPreviewImage = path;
              } else {
                imageList.push(path);
              }
              if(imageList.length < 6) {
                const length = 6 - imageList.length;
                for(var i=0 ; i < length; i++) {
                  imageList.push('');
                }
              }
              this.propertyImages = imageList;
            }
          }
        });
      }
    });
  }
  back() {
    this.showNextPage.emit(3)
  }
  submit(): void {
    let property = this.lookupModel.getVendorProperty();
    property.images = this.propertyImages;
    if (!property.listingId) {
      property.listingId = 'abc';
    }

    if(property.propertyPreviewImage) {
      property.propertyPreviewImage = this.propertyImages[0];
    }
    this.lookupModel.setVendorProperty(property);
    this.showNextPage.emit(5);
  }

}
