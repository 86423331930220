<div class="card padding-card t-align-l no-radius border">
    <form (ngSubmit)="submit()">
    <div class="card-body">

        <div class="form-group">
            <label>Packages Showing Currency <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text">INR</span>
                </div>
            </div>
        </div>
        <h4 class="text-info">Select Plan</h4>
        <table width="100%">
            <tr>
                <th>Included Features</th>
                <th class="text-center">USD10</th>
            </tr>
            <tr>
                <td>Placement in City based search</td>
                <td class="text-center"><i class="far fa-check-circle"></i></td>
            </tr>
            <tr>
                <td>Placement in Location based search</td>
                <td class="text-center"><i class="far fa-check-circle"></i></td>
            </tr>
            <tr>
                <td>Placement in Zip code based search</td>
                <td class="text-center"><i class="far fa-check-circle"></i></td>
            </tr>
        </table>

    </div>

        <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
    </form>
</div>