import { Component, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectorRef  } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-mb-add-more-location-page',
  templateUrl: './mb-add-more-location-page.component.html',
  styleUrls: ['./mb-add-more-location-page.component.css']
})
export class MbAddMoreLocationPageComponent implements OnInit {
  //@ViewChild('location-Input') locationInput: ElementRef;
  citySearch = new FormControl('');
  selectedLocation: string;
  fullCity = {
    cityName: '',
    cityBounds: {},
    zip: '',
    macroCity: '',
    lat: 0,
    long: 0
  };
  selectedCity:any;
  cities: Array<any> = [];
  nearbyPlaceslist: Array<any> =[];
  searchText:string = "";
  private geoCoder;
  private cityAutocomplete: any;
  private requestPlace: any;
  private locationInput: ElementRef;
  @ViewChild('locationInput') set getCityEle(cityElement: ElementRef) {
    this.locationInput = cityElement;
    if (this.locationInput && this.lookupModel.selectedCountry && this.lookupModel.isBrowser) {
      if (!this.cities.length) {
        this.lookupModel.cities$.subscribe((array) => {
          this.cities = array;
          if(array.length){
          this.change.detectChanges();
          this.fullCity = array.length>1 ? array[array.length-1] : array[0];
          console.log(this.cities,this.fullCity);
          this.selectedCity = this.fullCity;
          this.requestPlace = {
            componentRestrictions: {
              country: this.lookupModel.selectedCountry.twoDigitCountryCode,
            },
            types: ["geocode"],
            bounds: array[0].cityBounds, 
            strictBounds: true 
          }
          this.cityAutocomplete = new google.maps.places.Autocomplete(this.locationInput.nativeElement, this.requestPlace);
          this.cityAutocomplete.addListener('place_changed', this.changeEvent);
          this.getNearbyPlaces();
        }else{
          let obj = this.lookupModel.getCurrentLocation();
          let latlang = { lat: obj.latitude, lng: obj.longitude };
          this.fullCity = {
            cityName: obj.city,
            macroCity: obj.city,
            cityBounds: "",
            zip: obj.zip,
            lat: obj.latitude,
            long: obj.longitude,
          };
          this.getAddress(latlang,true,false);
          //this.backToCitySearch();
        }
        });
      }
    }
  }

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private lookupModel:RsbLookupModel,
    private router:Router,
    private rsbService: RsbService,
    private change : ChangeDetectorRef 
  ) { 
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
  }
    ngOnInit(): void {
      //let obj = this.lookupModel.getCurrentLocation();
      // let latlang = { lat: obj.latitude, lng: obj.longitude };
      // this.fullCity = {
      //   cityName: obj.city,
      //   macroCity: obj.city,
      //   cityBounds: "",
      //   zip: obj.zip,
      //   lat: obj.latitude,
      //   long: obj.longitude,
      // };
      // this.getAddress(latlang,true);
      // console.log(obj)
      // this.mapsAPILoader.load().then(() => {
      //   let bounds= this.fullCity.cityBounds;
      //   // const autocomplete = new google.maps.places.Autocomplete(this.locationInput.nativeElement, {
      //   //   types: ['geocode'], 
      //   //   componentRestrictions: { country: 'IN' } ,
      //   //   bounds:bounds,
      //   //   strictBounds:true
      //   // });
      
      //   // autocomplete.addListener('place_changed', () => {
      //   //   this.ngZone.run(() => {
      //   //     const place = autocomplete.getPlace();
            
      //   //     if (place.geometry) {
      //   //       this.selectedLocation = place.name;
      //   //       this.fullCity = {
      //   //         cityName: place.name,
      //   //         macroCity: place.address_components[1]?.long_name || '',
      //   //         cityBounds: place.geometry.viewport,
      //   //         zip: place.address_components.find(ac => ac.types.includes('postal_code'))?.long_name || '',
      //   //         lat: place.geometry.location.lat(),
      //   //         long: place.geometry.location.lng(),
      //   //       };
      //   //       this.lookupModel.setSelectedAddMoreCity(this.selectedLocation);
      //   //       this.cities.push(this.fullCity);
              
      //   //       console.log('Selected location:', this.selectedLocation);
      //   //       console.log(this.cities)
      //   //     }
      //   //     // if (this.selectedLocation) {
      //   //     //   this.router.navigate(['search-prop-page1']);
      //   //     // }
      //   //   });
      //   // });
      // });
      
  }
  changeEvent = (): void => {
    // this.ngZone.run(() => {
    //get the place result
    let place: google.maps.places.PlaceResult = this.cityAutocomplete.getPlace();

    //verify yt67
    if (!place || place.geometry === undefined || place.geometry === null) {
      return;
    }
    if (place.geometry && place.geometry.location) {
      let viewport = place.geometry.viewport;
      this.selectedLocation = place.name;
      this.fullCity = {
        cityName: place.name,
        macroCity: place.address_components[1].long_name,
        cityBounds: place.geometry.viewport,
        zip: '',
        lat: place.geometry.location.lat(),
        long: place.geometry.location.lng(),
      }
      this.lookupModel.setSelectedAddMoreCity(this.selectedLocation);
      console.log('Selected location:', this.selectedLocation);
      this.citySearch.setValue("");
    }
    this.getAddress({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },false,false);
  }
  getAddress(latLng: { lat: number, lng: number }, loadCity: boolean = false,nearBy:boolean=false) {
    // if(latLng)
    this.geoCoder.geocode({ 'location': latLng }, (results, status) => {
      if (status === 'OK') {
        // console.log(results);
        let postAddress;
        if(!nearBy){
         postAddress = results.find(item => item.types.indexOf("locality") !== -1); //'postal_code'
      } else {
         postAddress = results.find(item => item.types.indexOf("sublocality_level_1") !== -1); //'postal_code'
         this.fullCity = {
          cityName: postAddress.address_components[0].long_name,
          macroCity: postAddress.address_components[0].long_name,
          cityBounds: postAddress.geometry.viewport,
          zip: '',
          lat: postAddress.geometry.location.lat(),
          long: postAddress.geometry.location.lng(),
        }
        }
        const postAddresspin = results.find(item => item.types.indexOf("postal_code") !== -1);
        //console.log(postAddress)
        if (postAddress) {
          !nearBy ? this.fullCity.macroCity = postAddress.address_components[2].long_name: "";         
          const city = postAddress.address_components.find(comp => comp.types.indexOf('locality') !== -1);
          if (city && loadCity) {
            this.fullCity.cityName = city.long_name;
            this.fullCity.cityBounds = postAddress.geometry.viewport;
          }
          const postalCode = postAddresspin.address_components.find(comp => comp.types.indexOf('postal_code') !== -1);
          if (postalCode) {
            this.fullCity.zip = postalCode.long_name;
           // this.cities.length ? this.cities[this.cities.length - 1].zip = postalCode.long_name : "";
          }
          this.cities.push(this.fullCity);
          this.change.detectChanges();
          this.lookupModel.addItem(this.fullCity);
          console.log(this.cities)
          if(this.cities.length==1){
          this.selectedCity = this.fullCity;
          this.requestPlace = {
            componentRestrictions: {
              country: this.lookupModel.selectedCountry.twoDigitCountryCode,
            },
            types: ["geocode"],
            bounds: postAddress.geometry.viewport, 
            strictBounds: true 
          }
          this.cityAutocomplete = new google.maps.places.Autocomplete(this.locationInput.nativeElement, this.requestPlace);
          this.cityAutocomplete.addListener('place_changed', this.changeEvent);
            }
          }
        if (this.cities.length == 4) {
          this.locationInput.nativeElement.disabled = "disabled";
        }
        this.getNearbyPlaces();
      }
    });
  }
  backToCitySearch():void{
    this.router.navigate(['search-prop-page1'])
  }
  clearSearchEle(index: number):void {
    debugger;
    this.cities.splice(index, 1);
    this.change.detectChanges();
    this.lookupModel.removeItem(index);
    this.searchText = this.cities[0].cityName;
    this.locationInput.nativeElement.disabled = null;
  }
  addNearbyIntoLocality(index:number):void{
    this.getAddress({ lat: this.nearbyPlaceslist[index].lat, lng: this.nearbyPlaceslist[index].lon },false,true);
  }
  getNearbyPlaces():void{
    const args = {
      cities: [
    {
      "lat": this.fullCity.lat,
      "long": this.fullCity.long,
      "cityName": this.fullCity.cityName
    }
  ]
    }
    this.rsbService.fetchNearByLocations(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        this.nearbyPlaceslist = rsp.contents;
        console.log(this.nearbyPlaceslist)
        this.change.detectChanges();
      }

    })

  }
}