<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="my-4 p-5 bg-light rounded">
                    <h3><b>Shipping Policy :</b></h3>
                    
                        <p>nexus.pillarblocks.com ( a unit of Teamwork Innovations Pvt Ltd ) Will not do any physical goods sales or marketing and no invoices on the same. No physical products shipping will be done as we dont  do services in this category.</p>
                    

                </div>
            </div>
        </div>
    </div>
</section>



