<div class="mb-dashboard">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <h3 class="d-inline-block mr-3 mb-0">My Properties <span class="badge badge-pill badge-secondary">1</span></h3>

                            <div class="mb-3">
                                <form>
                                    <ng-container *ngFor="let module of moduleList; let i = index">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" class="custom-control-input" [id]="'module_' + i" [checked]="module.selected === true" [name]="'moduleRadio'" value="{{module.moduleId}}" (change)="changeMainModule(module)">
                                            <label class="custom-control-label" [for]="'module_' + i">{{ module.moduleName }}</label>
                                        </div>
                                    </ng-container>
                                    
                                    
                                    
                                </form>
                                <div class="right-search">
                                    <input type="text" class="form-control mb-3 rounded-4" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control  rounded-4 mt-1">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>


                            <app-rent-table-list [allListings]="allListings" *ngIf="selectedMainModuleId[0].moduleName.toUpperCase().includes('RENT')"></app-rent-table-list>
                            <app-pg-hostel-list  [allListings]="allListings" *ngIf="selectedMainModuleId[0].moduleName.toUpperCase().includes('HOSTEL')"></app-pg-hostel-list>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 pb-4 text-center">
                <small class="copy-right-text">All trademarks, logos and names are properties of their respective
                    owners. Copyright © 2024 - All rights reserved.</small>
            </div>
        </div>

    </div>

    <!-- <footer>
        <nav class="navbar fixed-bottom bg-secondary">
            <div class="container-fluid">
                <ul class="nav justify-content-center">
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Terms & Conditions </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Policy</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Home</a>
                    </li>
                </ul>
            </div>
        </nav>                            
    </footer> -->
</div>