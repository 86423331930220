<div class="tab-content search_content aos-init aos-animate" scrollTracker (scrolled)="updateScrollPos($event)"  *ngIf="!isMobileLayout">
    <form [formGroup]="filterForm" (ngSubmit) = "getPropertyListings()">
      <div id="buy" class="tab-pane active">
        <div class="search_wrap">
          <div class="search_left">
            <div class="input-group searchsection">
              <div class="searchbox_location">

                <div class="searchBox forFlexView">
                  <div class="searchbox_location" #cityEntryBox (click)="startSearch()">
                    <div class="searchBox" [ngClass]="{'forFlexView':searchboxFocus}">
                      <div class="searchlist" *ngIf="cities.length">
                        <div class="searchlistitem">{{ cities[0].cityName }} </div>
                        <div [ngStyle]="{ 'display': !searchboxFocus ? 'block' : 'none'}" class="itemclose"
                          (click)="removeEle(0)"> </div>
                      </div>
                      <ng-container *ngIf="!searchMore">
                        <div class="searchlist" *ngFor="let item of  cities;index as ind; let isFirst = first">
                          <div *ngIf="!isFirst" class="searchlistitem">{{ item.cityName }} </div>
                          <div [ngStyle]="{ 'display': !searchboxFocus || !isFirst ? 'block' : 'none'}"
                            class="itemclose" (click)="removeEle(ind)"> </div>
                        </div>
                      </ng-container>
                      <div class="searchlist" *ngIf="searchMore">
                        <div class="searchlistitem_count">+{{ cities.length-1 }} </div>
                      </div>
                      <!-- <span class="input-group-text locationname">{{getCitiesList()}} <i
                                class="bi bi-x"></i></span> -->
                      <input #cityTextRef type="text" class="_searchBar" placeholder="Search by Location, Project Name"
                        formControlName="city">
                      <!-- [ngClass]="{'required-error': (srchForm.submitted || city.touched) && city.errors}" -->
                      <!-- <span class="input-group-text nearmetext"><i
                                class="bi bi-crosshair"></i>
                            Near
                            me </span> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="search_right">
                <button class="btn btn-primary btn-lg" style="padding-top: 0.80rem; padding-bottom: 0.80rem;"><i
                    class="bi bi-search"></i> Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="searchheader" *ngIf="!isMobileLayout">
    <div class="filterheader" style="border-top:none;margin-top: 5px;">
      <div class="container-fluid">
        <div class="filter_item">
          <ul class="nav">
            <li class="nav-item dropdown">
              <a (click)="enableSubCategoryMenu()" [ngClass]="{'text-danger':subCatName.length}">
                <span *ngIf="!subCatName.length">Occupency Type</span> 
                <span *ngIf="subCatName.length">{{subCatName[0]}} <span *ngIf="subCatName.length>1">+{{ subCatName.length-1 }}</span> </span> 
                <i class="bi bi-chevron-down"></i> </a>
              <ul class="dropdown-menu" [ngStyle]="{ 'display': isSubCategoryDisplay ? 'block' : 'none' }">
                <h5>Top Properties</h5>
                <ul class="list-inline filter_details">
                  <li class="list-inline-item" *ngFor="let subCat of subCatList; index as subCatIndex;">
                    <button class="btn btn-outline-secondary rounded-pill" [ngClass]="{'active':subCat.isSelected}" (click)="filterBySubCategory(subCatIndex,true)"><i class="bi bi-plus-lg"></i> {{subCat.swatchName}}</button>
                  </li>
                </ul>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a (click)="enableBudgetMenu()" [ngClass]="{'text-danger':selBudgetCount.length}">
                <span *ngIf="!selBudgetCount.length">Budget</span> 
                <span *ngIf="selBudgetCount.length">{{selBudgetCount[0]}} <span *ngIf="selBudgetCount.length>1">+{{selBudgetCount.length-1}}</span> </span> 
                <i class="bi bi-chevron-down"></i> </a>
              <ul class="dropdown-menu" [ngStyle]="{ 'display': isBudgetDisplay ? 'block' : 'none' }">
                <h5>Budget</h5>
                <ul class="list-inline filter_details">
                  <li class="list-inline-item" *ngFor="let budget of bugetList;index as bIndex;">
                    <button class="btn btn-outline-secondary rounded-pill" [ngClass]="{'active':budget.isSelected}" (click)="filterByBudget(bIndex, true)"><i class="bi bi-plus-lg"></i> {{budget.budgetName}}</button>
                  </li>
                </ul>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a (click)="enableAmenitiesMenu()" [ngClass]="{'text-danger':selAmenitiesCount.length}">
                <span *ngIf="!selAmenitiesCount.length">Amenities</span> 
                <span *ngIf="selAmenitiesCount.length">{{selAmenitiesCount[0]}} <span *ngIf="selAmenitiesCount.length>1">+{{selAmenitiesCount.length-1}}</span> </span> 
                <i class="bi bi-chevron-down"></i> </a>
              <ul class="dropdown-menu" [ngStyle]="{ 'display': isAmenitiesDisplay ? 'block' : 'none' }">
                <h5>Amenities</h5>
                <ul class="list-inline filter_details">
                  <li class="list-inline-item" *ngFor="let ame of amenitiesList;index as aIndex;">
                    <button class="btn btn-outline-secondary rounded-pill" [ngClass]="{'active':ame.isSelected}" (click)="filterByAmenities(aIndex, true)"><i class="bi bi-plus-lg"></i> {{ame.swatchName}}</button>
                  </li>
                </ul>
              </ul>
            </li>
            <li class="nav-item dropdown" *ngFor="let attr of attrList; index as bIndex">
              <a (click)="enableFilters(bIndex)" [ngClass]="{'text-danger':attr.setAttrCount.length}">
                <span *ngIf="!attr.setAttrCount.length">{{attr.attributeLabel}} </span>
                <span *ngIf="attr.setAttrCount.length">{{attr.setAttrCount[0]}} <span *ngIf="attr.setAttrCount.length>1">+{{attr.setAttrCount.length-1}}</span> </span>
                <i class="bi bi-chevron-down"></i></a>
              <ul class="dropdown-menu" [ngStyle]="{ 'display': attr.isAttrDisplay ? 'block' : 'none' }">
                <h5> {{attr.attributeName}} Types</h5>
                <ul class="list-inline filter_details">
                  <li class="list-inline-item" *ngFor="let attribute of attr.attributeProperties; index as attrIndex;">
                    <button class="btn btn-outline-secondary rounded-pill" [ngClass]="{'active':attribute.isSelected}" (click)="filterByAttribute(bIndex,attrIndex, true)"><i class="bi bi-plus-lg"></i> {{attribute.swatchName}}</button>
                  </li>
                </ul>
              </ul>
            </li>
          </ul> 
        </div>
      </div>
    </div>
  </div>
  <section class="Property_search" *ngIf="!isMobileLayout">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 property_leftlist">
          <div class="d-flex justify-content-between pagination_content">
            <p class="text-uppercase mb-0">Showing <span *ngIf = "listings.length">1</span> - {{listings.length}} of {{count}}</p>
            <!-- <div class="heading">
              <h1>Properties in Hyderabad for Buy</h1>
            </div> -->
            <div class="sorting">
              <div class="row align-items-center">
                <div class="col-auto">
                  <p class="mb-0">Sort by: </p>
                </div>
                <div class="col-auto">
                  <select class="form-select" (change)="sortBy('price', $event)">
                    <option value="0">Price: Low to High</option>
                    <option value="1">Price: High to Low</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedProp">
            <p style="background-color: #7bb2c5; width: 70%; display: flex; justify-content: space-between; align-items: center; padding: 10px;">
                <span>You have selected <b style="font-size: 20px;color: brown;"> {{ selectedPropCount }}</b>&nbsp;properties!</span>
                <span>
                  <i class="ri-star-line" (click)="favorites()"  style="font-size: 18px; margin-left: 30px;"></i>
                  <i class="ri-links-line" (click)="copyLink()"style="font-size: 18px; margin-left: 30px;"></i>
                    {{copyMessage}}             
                    <i class="ri-share-forward-line" (click)="sharePopUp()" style="font-size: 18px; margin-left: 30px;"></i>
                    <i class="ri-delete-bin-6-line" (click)="cancelSelection()" style="font-size: 18px; margin-left: 30px;"></i>
                    <button class="white-button" (click)="cancelSelection()">Cancel</button>
                  </span>
            </p>
        </div>
          <div class="pb_listItems"  *ngFor="let property of listings; let propIndex = index;">
            <div class="row">
              <div class="col-sm-9 col-lg-9 morder-2">
                <div class="ps-wrap">
                  <div class="pb_listimage">
                    <a (click)="openProperty(property)">
                      <img
                      [src]="getImageUrl(property) || 'assets/img/noImage.png'"/>
                    <!-- <p class="tag"><span><i class="ri-shield-star-line"></i> Premium</span></p> -->
                  </a>
                  </div>
                 
                  <div class="ps-content">
                    <div class="d-flex justify-content-between">
                      <div class="list_price"></div>
                      <div class="shareicons">
                        <ul class="list-inline">
                          <li class="list-inline-item">
                            <input type="checkbox" [(ngModel)]="property.selected" (change)="selectedCheckProp(property)" style="width: 20px; height: 20px;">
                          </li>
                          <ng-container *ngIf="!getPlanId">
                            <li class="list-inline-item">
                              <button class="btn btn-warning" (click)="choosePlanForShare(property)"><i class="bi bi-heart"></i> FAVORITE</button>
                            </li>
                            <li class="list-inline-item">
                              <button class="btn btn-primary"  (click)="choosePlanForShare(property)"> SHARE <i class="bi bi-share-fill"></i></button>
                            </li>
                            </ng-container>
                            <ng-container *ngIf="getPlanId">
                            
                              <li class="list-inline-item" *ngIf="!favoriteStates[property.hostelId]">
                                <!-- <button class="btn btn-light"><i class="bi bi-heart"></i> FAVORITE</button> -->
                                <button type="button" (click)="favoriteSingleProp(property)" class="btn btn-labeled"
                                  style="border: 1px solid rgb(111, 111, 216); border-radius: 5px; padding-left: 0;padding-right:3px;">
                                  <span class="btn-label" style="display: flex; align-items: center; padding: 0;">
                                    <div
                                      style="background-color:rgb(66, 66, 230);height: 35px; padding: 0; margin-left: 5px; display: flex; align-items: center;">
                                      <img src="assets/img/favBeforeIcon.jpg" width="23px" height="35px" style="margin: 0; display: block;">
                                    </div>
                                    <b style="color: rgb(111, 111, 216); margin-left: 6px;">FAVORITE</b>
                                  </span>
                                </button>
                              </li>
                              <li class="list-inline-item" *ngIf="favoriteStates[property.hostelId]">
                                <button type="button" class="btn btn-labeled"
                                  style="border: 1px solid red; border-radius: 5px; padding-left: 0;padding-right:3px;">
                                  <span class="btn-label" style="display: flex; align-items: center; padding: 0;">
                                    <div
                                      style="background-color: red;height: 35px; padding: 0; margin-left: 5px; display: flex; align-items: center;">
                                      <img src="assets/img/favImage.jpg" width="30px" height="25px" style="margin: 0; display: block;">
                                    </div>
                                    <b style="color: red; margin-left: 6px;">FAVORITE</b>
                                  </span>
                                </button>
                              </li>
                            
                              <li class="list-inline-item">
                                <button class="btn btn-primary" (click)="shareSingleProp(property)"> SHARE <i class="bi bi-share-fill"></i></button>
                              </li>
                            </ng-container>
                        </ul>
                      </div>
                    </div>
                    <h4 class="ps-heading" style="display: inline;cursor: pointer;">{{property.pgName}} </h4><span class="label_color"> in {{property.area}}</span>
                    <div class="sqft_details">
                      <ul class="list-group list-group-horizontal">
                        <li class="list-group-item" *ngFor="let sub of property.attributesNew">
                          <p>{{sub.subCategoryName}}</p>
                          <h6>{{sub.securityDepPerBed}}</h6>
                        </li>
                    
                      </ul>
                    </div>
                    <div>
                      <ng-container *ngIf="!showFullContent[propIndex] && property.pgDescription.length > 100; else shortContent">
                       <p style="font-size: 12px;">{{ property.pgDescription | slice:0:250 }}...<a (click)="toggleContent(propIndex)" style="font-size: 11px;">Read More</a>
                       </p>
                      </ng-container>
                      <ng-template #shortContent>
                        <p style="font-size: 12px;">{{ property.pgDescription }}<a *ngIf="showFullContent[propIndex]" (click)="toggleContent(propIndex)" style="font-size: 11px;">Show Less</a>
                        </p>
                      </ng-template>
                    </div>
                  </div>
                </div>

                <div class="bottomDiv morder-last nearby" *ngIf="property.locationHighlights && getLocationLength(property.locationHighlightsNew) > 0">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <p><img src="assets/img/location.svg" alt="places"> {{getLocationLength(property.locationHighlightsNew)}}  
                        nearby places:</p>
                    </li>
                    <ng-container *ngFor="let item of property.locationHighlightsNew | keyvalue">
                      <ng-container *ngIf="item.value && item.value !== null">
                        <li class="list-inline-item">
                          <p class="bgpink">
                          <img  *ngIf="getIcon(item.key)" [src]="getIcon(item.key)" alt="places">{{ item.value }}
                          </p>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
        
                </div>
              </div>
              <div class="col-sm-3 col-lg-3 morder-1">
                <div class="ps-rightside">
                  <div class="list_price text-center">
                    <h2>{{property.price}}</h2>
                  </div>
                  <ul class="dowloadbrocher list-inline text-center">
                    <li class="list-inline-item">
                      <button class="btnbrocher" (click)="contactOwner(property)">Get Deal</button>
                    </li>
                    <!-- <li class="list-inline-item" style="display: flex; align-items: center;">
                      <button class="reqvisitbtn" style="display: flex; align-items: center; width: 100%;">
                        <span (click)="requestSiteVisit(property)" style="flex-grow: 1; text-align: left;">
                          Schedule Site Visit
                        </span>
                        <span (click)="openCalender()" style="display: flex; align-items: center;">
                          <img src="assets/img/calender-icon.png" style="width: 25px; height: auto; margin-left: 5px;" alt="Calendar Icon">
                        </span>
                      </button>
                    </li> -->
                    <!-- <li class="list-inline-item">
                      <button class="btn btn-danger text-white" (click)="EMI()">2 Months EMI <span
                          class="label-warning">Free</span></button>
                    </li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div> <!---Pb_listItems-->
 
        </div> <!--end  of col-9-->

      </div>
    </div>
  </section>
  <!-- mobile layout -->
  <div class="mb-dashboard" *ngIf="isMobileLayout">
    <div class="container">

      <div id="mb_products" class="row"  *ngFor="let property of listings; let propIndex = index;">
        <div class="mb_item col-6 col-lg-4 mb_list_group_item">
          <div class="card">
            <div class="thumbnail ">
              <div class="img-event">
                <!-- <img class="group list-group-image img-fluid" src="assets/mobile/img/property_search_image1.png" alt="" /> -->
                
                <a (click)="openProperty(property)">
                  <img class="group list-group-image img-fluid" [src]="property.images && property.images.length && property.images[0] ? property.images[0] : 'assets/img/property_search_image1.png'" alt=""/>
                </a>
              </div>
              
              <div class="caption card-body">
                <div class="mb_capHeader d-flex justify-content-between">
                  <div class="mb_capPrice">
                    <h2><span>&#8377;</span>{{property.price}}</h2>

                  </div>
                  <div class="mb_share">
                    <ul class="list-inline">
                      <!-- <li class="list-inline-item gender"><span>Girls</span></li> -->
                      <!-- <li class="list-inline-item"><i class="ri-share-forward-line share"></i></li>
                      <li class="list-inline-item"><i class="ri-heart-line fav"></i></li> -->
                      <ng-container *ngIf="!getPlanId">
                        <li class="list-inline-item">
                          <span (click)="choosePlanForShare(property)"><i class="ri-heart-line fav"></i></span>
                        </li>
                        <li class="list-inline-item">
                          <span (click)="choosePlanForShare(property)"><i class="ri-share-forward-line share"></i></span>
                        </li>
                        </ng-container>
                        <ng-container *ngIf="getPlanId">
                            
                          <!-- <li class="list-inline-item" *ngIf="!favoriteStates[property.hostelId]">
                            <span (click)="favoriteSingleProp(property)">
                                <b style="color: rgb(111, 111, 216); margin-left: 6px;">FAVORITE</b>
                              </span>
                          </li> -->
                          <li class="list-inline-item" *ngIf="favoriteStates[property.hostelId]">

                            <span (click)="favoriteSingleProp(property)">
                              <i class="ri-heart-line fav"></i>
                            </span>
                          </li>
                        
                          <li class="list-inline-item">
                            <span (click)="shareSingleProp(property)"><i class="ri-share-forward-line share"></i></span>
                          </li>
                        </ng-container>
                    </ul>
                  </div>
                </div>
                <!-- <h4 class="group card-title inner list-group-item-heading"> HACK N SLEEP WOMENS PG/Paying Guest HOSTEL
                </h4> -->
                <h4 class="group card-title inner list-group-item-heading">{{property.pgName}} </h4><span class="label_color"> in {{property.area}}</span>
                
                  <ng-container *ngIf="!showFullContent[propIndex] && property.pgDescription.length > 30; else shortContent">
                   <p style="font-size: 12px;">{{ property.pgDescription | slice:0:50 }}...<a (click)="morePopup()" style="font-size: 11px;">Read More</a>
                   </p>
                  </ng-container>
                 
                
                <!-- <p class="mb_captionDes mb-0"><small> 2 BHK Independent House for Rent at OAKLAND MIRACLES The School,
                    Bachupally, Hyderabad </small></p> -->
              </div>
            </div> <!--end thumbnail div-->
           
            <ul class="list-inline mb_nearby">
              <li class="list-inline-item" *ngFor="let ame of property.amenities | slice:0:3">
                <p>
                  <i class="ri-wifi-line"></i>
                  <small>{{ame.attributePropertyName}}</small>
                </p>
              </li>
              <li class="list-inline-item">
                <a (click)="morePopup(property)"><p class="mb_noBg text-danger">More...</p></a>
              </li>
            </ul>
        
            <ul class="mb_prop_details list-inline pg_details">
              <li class="list-inline-item" *ngFor="let sub of property.attributesNew ">
                <p class="mb-0"> {{sub.subCategoryName}}</p>
                <h6>{{sub.securityDepPerBed}}</h6>
              </li>
            </ul>
            <!-- places popup -->
            <div class="mb_popup" [ngStyle]="{ 'display': mobilePopup ? 'block' : 'none' }" *ngIf="mobilePopup">
              <div class="popup-content">
                <a (click)="morePopup()"><span id="closePopup" class="close-btn">&times;</span></a>
              
                <h4>Amenities</h4>
                <ul class="list-inline pg_nearby">
                  <li class="list-inline-item" *ngFor="let ame of morePropertyListing.amenities">
                    <!-- <h1>{{ame.Amenities}}</h1> -->
                    <img *ngIf="getAmentiesIcon(ame.attributePropertyName)" [src]="getAmentiesIcon(ame.attributePropertyName)" alt="places">

                    <h6>{{ame.attributePropertyName}}</h6>
                  </li>
                </ul>
                <h4>Near By Locations</h4>
                <ng-container *ngIf="property.locationHighlights && getLocationLength(property.locationHighlightsNew) > 0">
                  <ul class="list-inline mb_amenities">
                    <ng-container *ngFor="let item of morePropertyListing.locationHighlightsNew | keyvalue">
                      <ng-container *ngIf="item.value && item.value !== null">
                    <li>
                      <img *ngIf="getIcon(item.key)" [src]="getIcon(item.key)" alt="places">
                      <p> {{item.value}}</p>
                    </li>
                    </ng-container>
                    </ng-container>
                  </ul>
                  </ng-container>

                <h4 class="mt-4 pg_nearby">Room Sharing</h4>
                <ng-container >
                <ul class="pg_nearby list-inline" >
                  <li class="list-inline-item" *ngFor="let sub of morePropertyListing.attributesNew">
                    <p class="mb-0"> {{sub.subCategoryName}}</p>
                    <img *ngIf="getPgBedsIcon(sub.subCategoryName)" [src]="getPgBedsIcon(sub.subCategoryName)" alt="places">

                    <h6>{{sub.securityDepPerBed}}</h6>
                  </li>
                </ul>
                </ng-container>
              
                <div class="mb_projectDetail">
                  <h4>About PG/Hostel</h4>
                  <p>{{property.pgDescription}}</p>
                </div>
              </div>
            </div>
            <!-- bottom buttons -->
            <div class="mb_btmbuttons">
              <button class="btn btn-primary mb_deal" (click)="contactOwner(property)"> Contact Owner </button>
            
              <button class="btn btn-primary mb_emi" (click)="requestSiteVisit(property)"> View Phone No. </button>
              <button type="button" class="btn btn-success text-white"><i class="ri-check-line"></i> Beds
                Available</button>
            </div>
          </div> <!--end card-->
        </div> <!--end mb_item-->
 
      </div>

    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pb-4 text-center">
          <small class="copy-right-text">All trademarks, logos and names are properties of their respective owners.
            Copyright © 2024 - All rights reserved.</small>
        </div>
      </div>
  
    </div>
  
    <footer>
      <nav class="navbar fixed-bottom bg-secondary">
        <div class="container-fluid">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Terms & Conditions </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Home</a>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  </div>
  