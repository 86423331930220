<!-- <div class="card no-radius border t-align-l">
    <form [formGroup]="featureForm" (ngSubmit)="submit()" #ftrForm="ngForm">
        <div class="card-body">
            <div id="accordion" role="tablist" aria-multiselectable="true">

                <div class="faq-card mb-4" *ngFor="let catAttr of categoryAttrList; let i = index;">
                    <div class="faq-card-header mb-2" role="tab" id="{{'heading' + i}}">
                        <h5 class="mb-0">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="{{'#collapse' + i}}"
                                aria-expanded="false" aria-controls="{{'#collapse' + i}}">
                                {{catAttr.propertyTabName}} <i class="fas fa-chevron-down float-right"></i>
                            </a>
                        </h5>
                    </div>
                    <div id="{{'collapse' + i}}" class="collapse" [ngClass]="{'show': expandAllFlag}" role="tabpanel"
                        aria-labelledby="{{'heading' + i}}">
                        <fieldset [disabled]="checkReadOnly()">
                            <div class="row">

                                <ng-container *ngFor="let attr of catAttr.attributes;let attrIndex = index;">
                                    <ng-container *ngIf="attr.displayType === 1">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="{{ 'attrLabel' + i + attrIndex}}">{{attr.attributeName}}</label>
                                                <select class="form-control" id="{{ 'attrLabel' + i + attrIndex}}" name="{{ 'attrLabel' + i + attrIndex}}"
                                                    (change)="changeAddAttr($event, attr, catAttr)">
                                                    <option value="">Select</option>
                                                    <option value="{{prop.propertyId}}" [selected]="prop.isSelected" *ngFor="let prop of attr.attributeProperties; let propIndex = index;">{{prop.swatchName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="attr.displayType === 2">
                                        <div class="col-md-4" *ngFor="let prop of attr.attributeProperties;let propIndx = index;">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{ 'incUti1' + i + attrIndex + propIndx }}"
                                                    name="{{ 'incUti1' + i + attrIndex + propIndx }}" value="{{prop.propertyId}}"
                                                    [checked]="prop.isSelected" (change)="changeAddAttr($event, attr, catAttr)">
                                                <label class="custom-control-label" for="{{ 'incUti1' + i + attrIndex + propIndx }}">{{prop.swatchName}}</label>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="attr.displayType === 3">

                                    </ng-container>
                                    <ng-container *ngIf="attr.displayType === 4">

                                    </ng-container>
                                </ng-container>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" (click)="back()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Back</button>
        <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
    </form>
</div> -->
<div class="card no-radius border t-align-l">
<div class="accordion addproject" id="accordion" role="tablist" aria-multiselectable="true">
    <form [formGroup]="featureForm" (ngSubmit)="submit()" #ftrForm="ngForm">
        <button type="submit" *ngIf="isMobileLayout" class="btn btn-primary btn-sm mb-2 float-right mr-2">Next</button>
        <button type="button" *ngIf="isMobileLayout" class="btn btn-primary btn-sm mb-2 float-right mr-2" (click)="back()">Back</button>
        <div class="card" *ngFor="let catAttr of categoryAttrList; let i = index;">
            <div class="card-header"  role="tab" id="{{'heading' + i}}">     
                <h5 class="mb-0">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" (click)="pageCollaps('#collapse'+i)"
                        aria-expanded="false" aria-controls="{{'#collapse' + i}}">
                        {{catAttr.propertyTabName}} <i class="ri-arrow-down-s-line rotate-icon"></i>
                    </a>
                </h5>
            </div>
            <div id="{{'collapse' + i}}" class="collapse" role="tabpanel"
            aria-labelledby="{{'heading' + i}}">
                <div class="card-body">
                    <fieldset [disabled]="checkReadOnly()">
                        <div class="row">

                            <ng-container *ngFor="let attr of catAttr.attributes;let attrIndex = index;">
                                <ng-container *ngIf="attr.displayType === 1">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="{{ 'attrLabel' + i + attrIndex}}">{{attr.attributeName}}</label>
                                            <select class="form-control" id="{{ 'attrLabel' + i + attrIndex}}" name="{{ 'attrLabel' + i + attrIndex}}"
                                                (change)="changeAddAttr($event, attr, catAttr)">
                                                <option value="">Select</option>
                                                <option value="{{prop.propertyId}}" [selected]="prop.isSelected" *ngFor="let prop of attr.attributeProperties; let propIndex = index;">{{prop.swatchName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="attr.displayType === 2">
                                    <div class="col-md-4" *ngFor="let prop of attr.attributeProperties;let propIndx = index;">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="{{ 'incUti1' + i + attrIndex + propIndx }}"
                                                name="{{ 'incUti1' + i + attrIndex + propIndx }}" value="{{prop.propertyId}}"
                                                [checked]="prop.isSelected" (change)="changeAddAttr($event, attr, catAttr)">
                                            <label class="custom-control-label" for="{{ 'incUti1' + i + attrIndex + propIndx }}">{{prop.swatchName}}</label>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="attr.displayType === 3">

                                </ng-container>
                                <ng-container *ngIf="attr.displayType === 4">

                                </ng-container>
                            </ng-container>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
        <button type="button" (click)="back()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Back</button>
    </form>
</div>
</div>