<!-- Sidebar -->

<!-- End of Sidebar -->


<!-- Content Wrapper -->
<!-- Main Content -->
 <ng-container *ngIf="!isMobileLayout">
<div id="content">
  <!-- Topbar -->
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top">
    <!-- Sidebar Toggle (Topbar) -->
    <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
      <i class="ri-menu-line"></i>
    </button>
    <!-- <ul class="nav nav-tabs searchtablist" style="margin-left: 30px;" id="searchTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#buy" type="button" role="tab"
                  aria-controls="buy" aria-selected="true">
                  <i class="bi bi-house-door"></i> Rent </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#sale" type="button" role="tab"
                  aria-controls="sale" aria-selected="false">
                  <i class="bi bi-house-check"></i> Buy </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#rent" type="button" role="tab"
                  aria-controls="rent" aria-selected="false">
                  <i class="bi bi-file-earmark-text"></i> Projects </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#plot" type="button" role="tab"
                  aria-controls="plot" aria-selected="false">
                  <i class="bi bi-layers-half"></i> PG / Hostel </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#commercial" type="button" role="tab"
                  aria-controls="commercial" aria-selected="false">
                  <i class="bi bi-buildings"></i> Plot & Land </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#agent" type="button" role="tab"
                  aria-controls="agent" aria-selected="false">
                  <i class="bi bi-person-rolodex"></i> Commercial </button>
              </li>
            </ul> -->
    <!-- Topbar Navbar -->
    <ul class="nav nav-tabs searchtablist" id="searchTab" role="tablist">
      <li class="nav-item" role="presentation" *ngFor="let module of lookupModel.getModuleList();">
        <button class="nav-link" [ngClass]="{'active': module.moduleId === selectedModule.moduleId}"
          data-bs-toggle="tab" [data-bs-target]="'#' + module.moduleName" type="button" role="tab"
          [aria-controls]="module.moduleName" (click)="selectModule(module)"
          [aria-selected]="module.moduleId === selectedModule.moduleId ? true : false">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="37" height="28" viewBox="0 0 37 28"
                          fill="none">
                          <path
                              d="M18.0212 4.03071C18.2361 3.81574 18.6661 3.81574 18.881 4.03071L30.0595 12.4146C30.2745 12.5221 30.382 12.737 30.382 13.0595V14.7793L36.0787 19.0787C36.2937 19.1862 36.4012 19.4012 36.4012 19.7236V27.2476C36.4012 27.6775 36.0787 28 35.6488 28H1.36089C0.930951 28 0.608493 27.6775 0.608493 27.2476V19.7236C0.608493 19.5086 0.715978 19.2937 0.930949 19.0787L6.62769 14.7793V13.0595C6.62769 12.8445 6.73517 12.6296 6.95014 12.4146L18.0212 4.03071ZM19.311 22.6257H17.6987V26.3877H19.311V22.6257ZM18.4511 5.53551L8.025 13.382V26.3877H16.0864V21.8733C16.0864 21.4434 16.4089 21.1209 16.8388 21.1209H19.9559C20.3858 21.1209 20.7083 21.4434 20.7083 21.8733V26.3877H28.8772V13.382L18.4511 5.53551ZM6.62769 16.6065L2.11329 20.0461V26.4952H6.5202L6.62769 16.6065ZM30.382 16.6065V26.3877H34.7889V19.9386L30.382 16.6065ZM18.0212 0.161228C18.2361 -0.0537428 18.6661 -0.0537428 18.881 0.161228L35.9713 13.0595C36.2937 13.2745 36.4012 13.7044 36.1862 14.1344C35.9713 14.4568 35.5413 14.5643 35.1114 14.3493C35.1114 14.3493 35.0039 14.3493 35.0039 14.2418L18.4511 1.66603L1.79083 14.2418C1.46838 14.4568 0.930949 14.3493 0.715977 14.0269C0.501006 13.7044 0.608494 13.2745 0.823465 13.0595L18.0212 0.161228Z"
                              fill="#5F5F5F" />
                      </svg> -->
          <!-- <i [ngClass]="getIcon(module.moduleName)"></i><b>{{module.moduleName}}</b> -->
          <img  *ngIf="getIcon(module.moduleName)" [src]="getIcon(module.moduleName)" width="40px" height="auto" alt="places">
          <div><b>{{ module.moduleName }}</b></div>

          <!-- <img [src]="getIcon(module.moduleName)" width="5px" height="auto" alt="{{module.moduleName}} Icon">
          <b>{{module.moduleName}}</b> -->
          

        </button>
      
      </li>

    </ul>
      <ul class="navbar-nav ml-auto" class="unknown">

        <!-- Nav Item - Search Dropdown (Visible Only XS) -->
        <li class="nav-item dropdown no-arrow d-sm-none">
            <a class="nav-link dropdown-toggle" id="searchDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="ri-search-line"></i>
            </a>
            <!-- Dropdown - Messages -->
            <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                aria-labelledby="searchDropdown">
                <form class="form-inline mr-auto w-100 navbar-search">
                    <div class="input-group">
                        <input type="text" class="form-control bg-light border-0 small"
                            placeholder="Search for..." aria-label="Search"
                            aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button">
                                <i class="fas fa-search fa-sm"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </li>
        <li class="nav-item dropdown no-arrow mx-1">
            <!-- <a class="nav-link dropdown-toggle" id="messagesDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="ri-question-line"></i>
                Counter - Messages
                <span class="badge badge-danger badge-counter">7</span>
            </a> -->
            <!-- Dropdown - Messages -->
            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="messagesDropdown">
                <h6 class="dropdown-header">
                    Message Center
                </h6>
                <a class="dropdown-item d-flex align-items-center">
                    <div class="dropdown-list-image mr-3">
                        <img class="rounded-circle" src="img/undraw_profile_1.svg"
                            alt="...">
                        <div class="status-indicator bg-success"></div>
                    </div>
                    <div class="font-weight-bold">
                        <div class="text-truncate">Hi there! I am wondering if you can help me with a
                            problem I've been having.</div>
                        <div class="small text-gray-500">Emily Fowler · 58m</div>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center">
                    <div class="dropdown-list-image mr-3">
                        <img class="rounded-circle" src="img/undraw_profile_2.svg"
                            alt="...">
                        <div class="status-indicator"></div>
                    </div>
                    <div>
                        <div class="text-truncate">I have the photos that you ordered last month, how
                            would you like them sent to you?</div>
                        <div class="small text-gray-500">Jae Chun · 1d</div>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center">
                    <div class="dropdown-list-image mr-3">
                        <img class="rounded-circle" src="img/undraw_profile_3.svg"
                            alt="...">
                    </div>
                    <div>
                        <div class="text-truncate">Last month's report looks great, I am very happy with
                            the progress so far, keep up the good work!</div>
                        <div class="small text-gray-500">Morgan Alvarez · 2d</div>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center">
                    <div class="dropdown-list-image mr-3">
                        <img class="rounded-circle" src="img/undraw_profile_1.svg"
                            alt="...">
                        <div class="status-indicator bg-success"></div>
                    </div>
                    <div>
                        <div class="text-truncate">Am I a good boy? The reason I ask is because someone
                            told me that people say this to all dogs, even if they aren't good...</div>
                        <div class="small text-gray-500">Chicken the Dog · 2w</div>
                    </div>
                </a>
                <a class="dropdown-item text-center small text-gray-500">Read More Messages</a>
            </div>
        </li>
        <!-- Nav Item - Alerts -->
        <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link dropdown-toggle" id="alertsDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <!-- <i class="ri-notification-line"></i> -->
                <!-- Counter - Alerts -->
                <!-- <span class="badge badge-danger badge-counter">3+</span> -->
            </a>
            <!-- Dropdown - Alerts -->
            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="alertsDropdown">
                <h6 class="dropdown-header">
                    Alerts Center
                </h6>
                <a class="dropdown-item d-flex align-items-center">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="ri-file-list-line text-white"></i>
                        </div>
                    </div>
                    <div>
                        <div class="small text-gray-500">December 12, 2019</div>
                        <span class="font-weight-bold">A new monthly report is ready to download!</span>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center">
                    <div class="mr-3">
                        <div class="icon-circle bg-success">
                            <i class="ri-hand-coin-line text-white"></i>
                        </div>
                    </div>
                    <div>
                        <div class="small text-gray-500">December 7, 2019</div>
                        $290.29 has been deposited into your account!
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center">
                    <div class="mr-3">
                        <div class="icon-circle bg-warning">
                            <i class="ri-error-warning-line text-white"></i>
                        </div>
                    </div>
                    <div>
                        <div class="small text-gray-500">December 2, 2019</div>
                        Spending Alert: We've noticed unusually high spending for your account.
                    </div>
                </a>
                <a class="dropdown-item text-center small text-gray-500">Show All Alerts</a>
            </div>
        </li>

        <div class="topbar-divider d-none d-sm-block"></div>

        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown no-arrow" [ngClass]="{'show':visibleDropdown}" *ngIf="lookupModel.getLoggedUser()">
            <a class="nav-link dropdown-toggle" id="userDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="profileDropdown()">
                <img class="img-profile rounded-circle mr-2"
                [src]="lookupModel.getLoggedUser()?.personalDetails?.profilePic 
                       ? lookupModel.getLoggedUser()?.personalDetails?.profilePic 
                       : (propertyImages?.length 
                          ? propertyImages[0] 
                          : 'assets/img/userProfile.jpg')" 
                alt="">
            
                <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{lookupModel.getLoggedUser().personalDetails.firstName}} <i class="ri-arrow-down-s-line"></i><br><small>NEXUS PARTNER</small></span>
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" [ngClass]="{'show':visibleDropdown}"
                aria-labelledby="userDropdown">
                <a class="dropdown-item" (click)="profile()" >
                    <i class="ri-user-line"></i>
                    My Profile
                </a>
                <a class="dropdown-item" (click)="favoriteListing()">
                  <i class="ri-heart-3-line"></i> 
                  Favorites
                </a>
                <!-- <a class="dropdown-item" (click)="favoriteProjects()">
                  <i class="ri-heart-3-line"></i> 
                  Favorite Projects
                    
                </a>
                <a class="dropdown-item" (click)="favoritePgHostel()">
                  <i class="ri-heart-3-line"></i> 
                  Favorite Pg/Hostels
                </a> -->
                <a class="dropdown-item" (click)="getLeadsForProperties()">
                  <img src="assets/img/leadicon.png" style="width: 20px; height: auto;">
                  Leads
                </a>
                <!-- <a class="dropdown-item" (click)="getLeadsForProjects()">
                  <img src="assets/img/leadicon.png" style="width: 20px; height: auto;">
                  Project Leads
                </a>
                <a class="dropdown-item" (click)="getLeadsForPgHostels()">
                  <img src="assets/img/leadicon.png" style="width: 20px; height: auto;">
                  Pg/Hostels Leads
                </a> -->
                <!-- <a class="dropdown-item" (click)="myfavorites()">
                    <i class="ri-settings-2-line"></i>
                    My Favorites
                </a> -->
                <a class="dropdown-item" (click)="changepwd()">
                  <img src="assets/img/changepwdicon.png" style="width: 20px; height: auto;">
                  Change Password
                </a>
                <!-- <div class="dropdown-divider"></div> -->
                <a class="dropdown-item" (click)="logout()" >
                    <i class="ri-logout-box-line"></i>
                    Logout
                </a>
            </div>
        </li>
    </ul>
  </nav>

  <!-- End of Topbar -->
  <div class="tab-content search_content aos-init aos-animate">
    <form [formGroup]="searchForm" (ngSubmit)="submit()" #srchForm="ngForm">
      <div id="buy" class="tab-pane active">
        <div class="search_wrap">
          <div class="search_left">
            <div class="input-group searchsection">
                  <div class="searchbox_location" #cityEntryBox (click)="startSearch()">
                    <div class="searchBox" [ngClass]="{'forFlexView':searchboxFocus}">
                      <div class="searchlist" *ngIf="cities.length">
                        <div class="searchlistitem">{{ cities[0].cityName }} </div>
                        <div [ngStyle]="{ 'display': !searchboxFocus ? 'block' : 'none'}" class="itemclose"
                          (click)="removeEle(0)"> </div>
                      </div>
                      <ng-container *ngIf="!searchMore">
                        <div class="searchlist" *ngFor="let item of  cities;index as ind; let isFirst = first">
                          <div *ngIf="!isFirst" class="searchlistitem">{{ item.cityName }} </div>
                          <div [ngStyle]="{ 'display': !searchboxFocus || !isFirst ? 'block' : 'none'}"
                            class="itemclose" (click)="removeEle(ind)"> </div>
                        </div>
                      </ng-container>
                      <div class="searchlist" *ngIf="searchMore">
                        <div class="searchlistitem_count">+{{ cities.length-1 }} </div>
                      </div>
                      <!-- <span class="input-group-text locationname">{{getCitiesList()}} <i
                                class="bi bi-x"></i></span> -->
                      <input #cityText type="text" class="_searchBar" placeholder="Search by Location, Project Name"
                        formControlName="city">
                      <!-- [ngClass]="{'required-error': (srchForm.submitted || city.touched) && city.errors}" -->
                      <!-- <span class="input-group-text nearmetext"><i
                                class="bi bi-crosshair"></i>
                            Near
                            me </span> -->
                    </div>
                  </div>

                  <!-- <select class="form-select budget">
                    <option>Budget</option>
                    <ng-container *ngFor="let budget of bugetList;index as bIndex;">
                    <option value="{{budget.budgetId}}"> {{budget.budgetName}}</option>
                    </ng-container>
                  </select> -->
                  <div class="dropdown" id="unitTypeMore">
                    <button type="button" class="form-control dropdown-toggle " data-bs-toggle="dropdown"
                      aria-expanded="true"
                      [ngClass]="{'required-error': (srchForm.submitted || cat.touched) && cat.errors && cat.errors.required,'show':isDropDownVisible}"
                      (click)="toggleDropdown()">
                      <span class="value" *ngIf="!typePG">{{selectedCategory ? (selectedCategory.subCategory.length > 0
                        ? '+' + selectedCategory.subCategory.length + ' ' + selectedCategory.categoryName :
                        selectedCategory.categoryName) : 'Property Type'}}</span>
                      <span class="value" *ngIf="typePG">{{selectedCategory ? (selectedCategory.subCategory.length > 0 ?
                        '+' + selectedCategory.subCategory.length + ' ' + 'Occupency Type' : 'Occupency Type') :
                        'Occupancy Type'}}</span>
                      <span style="display: none" class="budge"></span>
                    </button>
                    <div class="dropdown-menu" [ngClass]="{'show':isDropDownVisible}"
                      style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(0px, 38px);">
                      <div class="contentBox">
                        <div class="moreBox">
                          <div class="columnBox" *ngIf="!typePG">
                            <div class="column">
                              
                              <ul class="checkBox propertyType">
                                <li *ngFor="let category of categoryList;let catIndex = index;">
                                  <label><input type="radio" name="cat" id="{{catIndex}}"
                                      class="optionInput radio unitCatIds Home_Search_L11"
                                      value="{{category.categoryId}}"
                                      formControlName="cat">{{category.categoryName}}</label>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="columnBox" id="moresearch" *ngIf="!typePG && selectedCategory && selectedCategory.subCategory.length > 0"> 
                            <strong>Unit Type</strong>
                            <div class="column">
                                <ul class="checkBox buildingType">
                                    <li *ngFor="let subCat of selectedCategory.subCategory;let subIndex = index;">
                                        <label><input type="checkbox" class="optionInput unitCatIds subC" id="{{subCat.subCategoryId}}" [checked]="subCat.isSelected" value="{{subCat.subCategoryId}}" (change)="selectSubcat($event,subIndex,'property')">{{subCat.subCategoryName}}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                          <div class="columnBox" id="moresearch" *ngIf="typePG && occupancyTypes.length > 0">
                            <div class="column">
                              <ul class="checkBox buildingType">
                                <li *ngFor="let subCat of occupancyTypes[0].attributeProperties;let subIndex = index;">
                                  <label><input type="checkbox" class="optionInput unitCatIds subC"
                                      id="{{subCat.propertyId}}" [checked]="subCat.isSelected"
                                      value="{{subCat.propertyId}}"
                                      (change)="selectSubcat($event,subIndex,'pg')">{{subCat.swatchName}}</label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              <div class="search_right">
                <button class="btn btn-primary btn-lg" style="padding-top: 0.80rem; padding-bottom: 0.80rem;"><i
                    class="bi bi-search"></i> Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  

</div>
</ng-container>
<!-- End of Main Content -->

<app-mb-search-properties *ngIf="isMobileLayout"></app-mb-search-properties>