<section class="login_wrapper" *ngIf="!isMobileLayout">
  <div class="container">
      
      <div class="row">
          <div class="col-lg-6">
              <div class="login_propLeft">
                  <div class="heading">
                      <h1>Welcome to <span><img src="../../../assets/img/pillarblock_nexus.jpg"></span></h1>
                      <p>We make Real Estate Easy for everyone</p>
                  </div>
                  <div class="cashbackimg">
                      <img src="../../../assets/img/login_image.svg" alt="">
                      <h2>Why you should choose us :</h2>

                      <ul>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>Connect with Global Network of Buyers, Investors, Agents, Property Consultants and Realtors.</span></p>
                        </li>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>Real FinTech Platform - Will leverage to close 10X Deals.</span></p>
                        </li>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>Credit Bureau Support for Financial Assessment.</span></p>
                        </li>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>Post a property for FREE.</span></p>
                        </li>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>List Properties till Sale.</span></p>
                        </li>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>Enjoy two months of Cash Back on Home Loan EMIs!</span></p>
                        </li>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>10+ Business Opportunities from Nexus Network.</span></p>
                        </li>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>Get home loans easily from the banks you already use.</span></p>
                        </li>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>Sale Your Properties 10X Fast with Profiled Buyers.</span></p>
                        </li>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>Avail Property Services ( Packers & Movers, Rental Agreement, Interior Design & Vaastu ).</span></p>
                        </li>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>Connect with Verified Buyers & Tenants.</span></p>
                        </li>
                        <li>
                            <p><i class="ri-checkbox-line"></i> <span>Get Unlimited Enquiries on Your Property.</span></p>
                        </li>
                    </ul>
                  </div>
                 
              </div>
          </div>
          <div class="col-lg-6">
              <div class="logincontainer">
                  <!-- Nav tabs -->
                  <ul class="nav nav-tabs" id="LoginTab" role="tablist">
                      <li class="nav-item" role="presentation">
                          <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#Login" type="button"
                              role="tab" aria-controls="login" aria-selected="true" >
                              Login
                          </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link"  data-bs-toggle="tab" data-bs-target="#SignUp" type="button"
                            role="tab" aria-controls="Sign Up" aria-selected="false">
                            Sign Up
                        </button>
                    </li>
                  </ul>

                  <!-- Tab panes -->

                  <div class="tab-content">

                      <div class="tab-pane active" id="Login" role="tabpanel" aria-labelledby="Login">
                          <div class="loginscreen">
                             
                              <form name="login" [formGroup]="loginForm" (ngSubmit)="login()" class="loginform">
                                  <div class="form-group">
                                      <label for="email" class="form-label">Phone Number</label><br>
                                      <ngx-intl-tel-input [cssClass]="'form-control'" [onlyCountries]="onlyCountries"
                                          [enableAutoCountrySelect]="true" [enablePlaceholder]="false"
                                          [searchCountryFlag]="false" [selectFirstCountry]="false" [maxLength]="15"
                                          [tooltipField]="TooltipLabel.Name" style="width: 125%;"
                                          [phoneValidation]="true" [separateDialCode]="true" id="mobile"
                                          [selectedCountryISO]="selectedISOCountry" name="loginMobile"
                                          formControlName="loginMobile">
                                      </ngx-intl-tel-input>
                                      <!-- <span class="text-danger"
                                          *ngIf="mobile.touched && mobile.errors && mobile.errors.required">
                                          Mobile Number is required.
                                      </span>
                                      <span class="text-danger"
                                          *ngIf="mobile.touched && mobile.errors && mobile.errors.validatePhoneNumber">
                                          Enter Valid Mobile Number.
                                      </span> -->
                                      <!-- <span class="text-danger" *ngIf="error_message">{{error_message}}</span>
                                      <span class="text-success" *ngIf="success_message">{{success_message}}</span> -->

                                  </div>
                                  <div class="form-group pwd mb-0">
                                      <label for="password" class="form-label">Password</label>
                                      <div class="input-group">
                                          <input type="{{ hidePassword ? 'password' : 'text' }}" toggle class="form-control" id="loginPassword" name="loginPassword" formControlName="loginPassword">
                                          <a href="javascript:void(0)" class="input-group-text" (click)="togglePassword()">
                                              <i class="bi" [ngClass]="hidePassword ? 'bi-eye' : 'bi-eye-slash'"></i>
                                          </a>
                                      </div>
                                      <span
                                          *ngIf="loginPassword.touched && loginPassword.errors && loginPassword.errors.required"
                                          style="color:red;" id="emailOtperror">
                                          Password is required.
                                      </span>
                                      <span
                                          *ngIf="loginPassword.touched && loginPassword.errors && loginPassword.errors.minlength"
                                          style="color:red;" id="passwordminerror">
                                          Password will be minimum of 6 characters.
                                      </span>
                                      <span
                                          *ngIf="loginPassword.touched && loginPassword.errors && loginPassword.errors.maxlength"
                                          style="color:red;" id="passwordmaxerror">
                                          Password will be maximum of 10 or 11 characters.
                                      </span>
                                  </div>

                                  <a style="float: right;font-size:13px;margin:5px 0;" (click)="forgotPwd()">Forgot Password</a>

                                  <button class="btn btn-primary" type="submit">Login</button>
                                  <!-- <div class="divder my-4">
                                  <p> Or </p>
                                </div>
                                <button type="button" class="btn btn-light googlebtn"><i class="bi bi-google"></i> Log in with Google</button>
                                <div class="newuser my-4">
                                  <p class="mb-0">New user? <a href="#SignUp">SIGN UP HERE</a> </p>
                                </div> -->
                              </form>
                          </div>
                      </div>

                      <div class="tab-pane" id="SignUp" role="tabpanel" aria-labelledby="Sign Up">
                        <div class="loginscreen">
                            <div class="loginheading">
                                <p>LET'S GET YOU STARTED</p>
                                <h2>Create an Account</h2>
                            </div>
                            <form name="signup" [formGroup]="signupForm" (ngSubmit)="register()" class="loginform">
                                <div class="form-group">
                                    <label for="Name" class="form-label">Your Name</label>
                                    <input type="text" class="form-control" placeholder="Enter First Name"
                                        id="firstName" name="firstName" formControlName="firstName"
                                        (keypress)="onlyNumbersAllowed($event)">
                                    <span *ngIf="firstName.touched && firstName.errors && firstName.errors.required"
                                        style="color: red">First Name is requried.</span>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label for="Number" class="form-label">Mobile Number</label>
                                        <ngx-intl-tel-input [cssClass]="'form-control'"
                                            [onlyCountries]="onlyCountries" [enableAutoCountrySelect]="true"
                                            [enablePlaceholder]="false" [searchCountryFlag]="false"
                                            [selectFirstCountry]="false" [maxLength]="15"
                                            [tooltipField]="TooltipLabel.Name" style="width: 100%;"
                                            [phoneValidation]="true" [separateDialCode]="true" id="mobile"
                                            [selectedCountryISO]="selectedISOCountry" name="Mobile"
                                            formControlName="mobile">
                                        </ngx-intl-tel-input>
                                    </div>


                                    <span class="text-danger"
                                        *ngIf="mobile.touched && mobile.errors && mobile.errors.required">
                                        Mobile Number is required.
                                    </span>
                                    <span *ngIf="mobile.valid && mobileExists" style="color:red;" id="mobileexists">
                                        Mobile Already Exists, Please Login
                                    </span>

                                    <span
                                        *ngIf="mobile.touched && !mobile.valid && mobile.errors && mobile.errors.validatePhoneNumber"
                                        style="color:red;" id="mobileNotValid">
                                        Enter Valid Mobile Number.
                                    </span>
                                    <span
                                        *ngIf="!mobileOtpResent && mobileOtpSent && !mobileOtpVerified && !mobileOtpMaxFlag"
                                        style="color:green;" id="mobileotpsuccessmsg1">
                                        OTP Sent to mobile successfully.
                                    </span>
                                    <span *ngIf="mobileOtpResent && !mobileOtpVerified" style="color:green;"
                                        id="mobileotpsuccessmsg1">
                                        OTP Resent to mobile successfully.
                                    </span>
                                    <span *ngIf="mobileOtpMaxFlag" style="color:red;" id="maxOtpError">
                                        Your OTP sending limit is over for today, please try after 24 hours again.
                                    </span>
                                    <span *ngIf="mobileOtpVerified" style="color:green;" id="emailotpsuccessmsg3">
                                        mobile Verified Successfully
                                    </span>
                                    <span *ngIf="mobileOtpError" style="color:red;" id="exists">
                                        Error in sending OTP. Please try again.
                                    </span>
                                    <span class="text-danger" *ngIf="error_message">{{error_message}}</span>
                                    <span class="text-success" *ngIf="success_message">{{success_message}}</span>
                                    <ul class="list-inline sendbtn mt-3">
                                        <li class="list-inline-item">
                                            <button type="button" class="btn btn-primary" *ngIf="!mobileOtpSent"
                                                [disabled]="mobile.invalid" (click)="sendOtp(mobile.value)">
                                                SEND OTP</button>
                                            <button type="button" class="btn btn-default2" *ngIf="mobileOtpSent"
                                                [disabled]="mobile.invalid" (click)="resendOtp(mobile.value)">
                                                RE-SEND
                                                OTP</button>
                                        </li>

                                        <li class="list-inline-item">
                                            <button type="button" class="btn btn-warning resend"
                                                (click)="changeMobile()">Change
                                                Mobile Number</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="form-group">
                                    <label for="Name" class="form-label">Enter OTP</label>
                                    <input type="text" class="form-control" placeholder="Enter OTP"
                                        formControlName="mobileOtp">
                                    <span *ngIf="mobileOtp.touched && mobileOtp.errors && mobileOtp.errors.required"
                                        style="color:red;" id="emailOtperror">
                                        OTP is required.
                                    </span>
                                    <span *ngIf="mobileOtpCheck && !mobileOtpVerified" style="color:red;"
                                        id="mobileOtperror2">
                                        OTP is incorrect.
                                    </span>
                                </div>
                                <div class="form-group">
                                    <label for="email" class="form-label">Email Id</label>
                                    <input type="email" class="form-control" placeholder="name@example.com"
                                        formControlName="email">
                                    <span *ngIf=" email.touched && email.errors && email.errors.required"
                                        style="color: red">
                                        Email is requried.
                                    </span>
                                    <span *ngIf="email.touched && email.errors && !email.errors.required"
                                        style="color: red">
                                        Enter Valid E-Mail.
                                    </span>
                                </div>
                                <div class="form-group pwd mb-3">
                                    <label for="password" class="form-label">Password</label>
                                    <div class="input-group">
                                    <input type="{{ hidePassword ? 'password' : 'text' }}" toggle class="form-control" class="form-control"
                                        value="password" id="password" name="password" formControlName="password" placeholder="Enter Password"> 
                                            <a href="javascript:void(0)" class="input-group-text" (click)="togglePassword()">
                                                <i class="bi" [ngClass]="hidePassword ? 'bi-eye' : 'bi-eye-slash'"></i>
                                            </a>
                                        </div>
                                    <!-- <a href="javascript:void(0)" class="input-group-text"><i
                                            class="bi bi-eye"></i></a> -->
                                    <!-- <span *ngIf="password.touched && password.errors && password.errors.required"
                                        style="color:red;" id="emailOtperror">
                                        Password is required.
                                    </span> -->
                                    <!-- <span *ngIf="passwordError" style="color:red;" id="passwordminerror">
                                            Password will be min of 6 characters and max of 10 characters.
                                        </span> -->
                          <!-- previous code -->
                                     <div *ngIf="password?.invalid && (password?.dirty || password?.touched)">
                                        <span class="invalid_msg"
                                            [ngClass]="password.hasError('required') || password.hasError('hasLength')  ? 'text-danger' : 'text-success'">
                                            <i class="material-icons">
                                                {{ password.hasError('required') || password.hasError('hasLength') ?
                                                'cancel' : 'check_circle' }}
                                            </i> must be 10 characters!
                                        </span><br>

                                        <span class="invalid_msg"
                                            [ngClass]="password.hasError('required') || password.hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                            <i class="material-icons">
                                                {{ password.hasError('required') || password.hasError('hasNumber') ?
                                                'cancel' : 'check_circle' }}
                                            </i>
                                            must contain at least 1 number!
                                        </span><br>
                                        <span class="invalid_msg"
                                            [ngClass]="password.hasError('required') || password.hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                            <i class="material-icons">
                                                {{ password.hasError('required') ||
                                                password.hasError('hasCapitalCase') ? 'cancel' : 'check_circle' }}
                                            </i>
                                            must contain at least 1 in Capital Case!
                                        </span><br>
                                        <span class="invalid_msg"
                                            [ngClass]="password.hasError('required') || password.hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                                            <i class="material-icons">
                                                {{ password.hasError('required') ||
                                                password.hasError('hasSmallCase') ? 'cancel' : 'check_circle' }}
                                            </i>
                                            must contain at least 1 Letter in Small Case!
                                        </span><br>
                                        <span class="invalid_msg"
                                            [ngClass]="password.hasError('required') || password.hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                            <i class="material-icons">
                                                {{ password.hasError('required') ||
                                                password.hasError('hasSpecialCharacters') ? 'cancel' :
                                                'check_circle' }}
                                            </i>must contain at least 1 Special Character!
                                        </span>
                                    </div>

                                </div>
                                <button type="submit" class="btn btn-primary" type="submit"
                                    [disabled]="signupForm.invalid">SIGN UP</button>
                                <!-- <div class="divder my-4"> -->
                                <!-- <p> Or </p>
                            </div>
                            <button type="button" class="btn btn-light googlebtn"><i class="bi bi-google"></i> Log in with Google</button>
                            <div class="newuser my-4">
                              <p class="mb-0">Already have an account? <a href="#Login"> LOGIN HERE</a> </p>
                            </div> -->
                            </form>
                        </div>
                    </div>
                     
                  </div>

              </div>
          </div>
      </div>
  </div>
</section>
<!-- <div class="mb-dashboard" *ngIf="isMobileLayout">
  
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-lg-12">
          <div class="mb-login">
            <div class="mb-header-image">
              <img src="assets/mobile/img/mb-login-bg.png" alt="">
            </div>
            <div class="mb-header-text">
              <h1><small> Let’s</small> <strong> Sign In </strong></h1>
              <h5>We make Real Estate Easy for everyone</h5>
            </div>
            <form name="login" [formGroup]="loginForm" (ngSubmit)="login()" class="loginform">

            <div class="mb-loginform">
              <label for="email" class="form-label">Phone Number</label><br>

              <div class="form-floating mb-3 mt-3">
                <ngx-intl-tel-input [cssClass]="'form-control'" [onlyCountries]="onlyCountries"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="false"
                    [searchCountryFlag]="false" [selectFirstCountry]="false" [maxLength]="15"
                    [tooltipField]="TooltipLabel.Name" style="width: 125%;"
                    [phoneValidation]="true" [separateDialCode]="true" id="mobile"
                    [selectedCountryISO]="selectedISOCountry" name="loginMobile"
                    formControlName="loginMobile">
                </ngx-intl-tel-input>                </div>
              
              <div class="form-floating mt-3 mb-1">
                <label for="password" class="form-label">Password</label>
                <div class="input-group">
                    <input type="{{ hidePassword ? 'password' : 'text' }}" toggle class="form-control" id="loginPassword" name="loginPassword" formControlName="loginPassword">
                    <a href="javascript:void(0)" class="input-group-text" (click)="togglePassword()">
                        <i class="bi" [ngClass]="hidePassword ? 'bi-eye' : 'bi-eye-slash'"></i>
                    </a>
                </div>
              </div>
              <a href="javascript:void(0)" class="d-block mb-3" (click)="forgotPwd()">Forgot password?</a>
              <button  type="submit" class="btn btn-primary mb-loginbtn d-block w-75 m-auto py-3 mt-4">Login</button>
            </div>
            </form>
          </div>
      </div>
  </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 pb-4 text-center">
        <small class="copy-right-text">All trademarks, logos and names are properties of their respective owners. Copyright © 2024 - All rights reserved.</small>
      </div>
    </div>
    
  </div>
  
  <footer>
    <nav class="navbar fixed-bottom bg-secondary">
      <div class="container-fluid">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Terms & Conditions </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Policy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Home</a>
          </li>
        </ul>
      </div>
    </nav>
  </footer>
</div> -->


<div class="mb-dashboard" *ngIf="isMobileLayout">
  <section class="mb-login_wrapper mb-5">
    <div class="container">
        <div class="heading pt-4 mb-4">
            <h1>Welcome to <span> <img src="assets/mobile/img/hand.png"></span></h1>
            <img src="assets/img/pillarblock_nexus.jpg">
            <p>We make Real Estate Easy for everyone</p>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="logincontainer">
                    <!-- Nav tabs -->
                    <ul class="nav nav-tabs" id="LoginTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link active"
                                data-bs-toggle="tab"
                                data-bs-target="#Login"
                                type="button"
                                role="tab"
                                aria-controls="login"
                                aria-selected="true"
                            >
                            Login
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link"  data-bs-toggle="tab" data-bs-target="#SignUp" type="button"
                              role="tab" aria-controls="Sign Up" aria-selected="false">
                              Sign Up
                          </button>
                      </li>
             
                    </ul>
                   
                    
                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div class="tab-pane active" id="Login" role="tabpanel" aria-labelledby="Login">
                            <div class="loginscreen">
                              <div class="loginheading">
                                <p>WELCOME BACK</p>
                                <h2>Log In to your Account</h2>
                              </div>
                              <form name="login" [formGroup]="loginForm" (ngSubmit)="login()" class="loginform">
                                <div class="form-group">
                                  <label for="email" class="form-label">Phone Number / Email Id</label>
                                  <div class="form-floating mb-3 mt-3">
                                    <ngx-intl-tel-input [cssClass]="'form-control'" [onlyCountries]="onlyCountries" [enableAutoCountrySelect]="true"
                                      [enablePlaceholder]="false" [searchCountryFlag]="false" [selectFirstCountry]="false" [maxLength]="15"
                                      [tooltipField]="TooltipLabel.Name" [phoneValidation]="true" [separateDialCode]="true"
                                      id="mobile" [selectedCountryISO]="selectedISOCountry" name="loginMobile" formControlName="loginMobile" style="width:124%">
                                    </ngx-intl-tel-input>
                                  </div>
                                </div>
                                    <div class="form-group mb-3">
                                      <label for="password" class="form-label">Password</label>
                                      <div class="input-group">
                                        <input type="{{ hidePassword ? 'password' : 'text' }}" toggle class="form-control" id="loginPassword" name="loginPassword" formControlName="loginPassword">
                                        <a href="javascript:void(0)" class="input-group-text" (click)="togglePassword()">
                                            <i class="bi" [ngClass]="hidePassword ? 'bi-eye' : 'bi-eye-slash'"></i>
                                        </a>
                                    </div>
                                    </div>
                                    <div class="remebersec mb-3">
                                      <div class="checkbox">
                                        <label><input type="checkbox"> Remember me</label>
                                      </div>
                                      <a href="javascript:void(0)" class="d-block mb-3" (click)="forgotPwd()">Forgot password?</a>
                                    </div>
                                  <button class="btn btn-primary" type="submit">CONTINUE</button>
                                  
                                  <!-- <div class="newuser my-4">
                                    <p class="mb-0">New user? <a href="#SignUp">SIGN UP HERE</a> </p>
                                  </div> -->
                                </form>
                            </div> 
                        </div>
                        <div class="tab-pane" id="SignUp" role="tabpanel" aria-labelledby="Sign Up">
                          <div class="loginscreen">
                              <div class="loginheading">
                                  <p>LET'S GET YOU STARTED</p>
                                  <h2>Create an Account</h2>
                              </div>
                              <form name="signup" [formGroup]="signupForm" (ngSubmit)="register()" class="loginform">
                                  <div class="form-group">
                                      <label for="Name" class="form-label">Your Name</label>
                                      <input type="text" class="form-control" placeholder="Enter First Name"
                                          id="firstName" name="firstName" formControlName="firstName"
                                          (keypress)="onlyNumbersAllowed($event)">
                                      <span *ngIf="firstName.touched && firstName.errors && firstName.errors.required"
                                          style="color: red">First Name is requried.</span>
                                  </div>
                                  <div class="form-group">
                                      <div class="input-group">
                                          <label for="Number" class="form-label">Mobile Number</label>
                                          <ngx-intl-tel-input [cssClass]="'form-control'"
                                              [onlyCountries]="onlyCountries" [enableAutoCountrySelect]="true"
                                              [enablePlaceholder]="false" [searchCountryFlag]="false"
                                              [selectFirstCountry]="false" [maxLength]="15"
                                              [tooltipField]="TooltipLabel.Name" style="width: 100%;"
                                              [phoneValidation]="true" [separateDialCode]="true" id="mobile"
                                              [selectedCountryISO]="selectedISOCountry" name="Mobile"
                                              formControlName="mobile">
                                          </ngx-intl-tel-input>
                                      </div>


                                      <span class="text-danger"
                                          *ngIf="mobile.touched && mobile.errors && mobile.errors.required">
                                          Mobile Number is required.
                                      </span>
                                      <span *ngIf="mobile.valid && mobileExists" style="color:red;" id="mobileexists">
                                          Mobile Already Exists, Please Login
                                      </span>

                                      <span
                                          *ngIf="mobile.touched && !mobile.valid && mobile.errors && mobile.errors.validatePhoneNumber"
                                          style="color:red;" id="mobileNotValid">
                                          Enter Valid Mobile Number.
                                      </span>
                                      <span
                                          *ngIf="!mobileOtpResent && mobileOtpSent && !mobileOtpVerified && !mobileOtpMaxFlag"
                                          style="color:green;" id="mobileotpsuccessmsg1">
                                          OTP Sent to mobile successfully.
                                      </span>
                                      <span *ngIf="mobileOtpResent && !mobileOtpVerified" style="color:green;"
                                          id="mobileotpsuccessmsg1">
                                          OTP Resent to mobile successfully.
                                      </span>
                                      <span *ngIf="mobileOtpMaxFlag" style="color:red;" id="maxOtpError">
                                          Your OTP sending limit is over for today, please try after 24 hours again.
                                      </span>
                                      <span *ngIf="mobileOtpVerified" style="color:green;" id="emailotpsuccessmsg3">
                                          mobile Verified Successfully
                                      </span>
                                      <span *ngIf="mobileOtpError" style="color:red;" id="exists">
                                          Error in sending OTP. Please try again.
                                      </span>
                                      <span class="text-danger" *ngIf="error_message">{{error_message}}</span>
                                      <span class="text-success" *ngIf="success_message">{{success_message}}</span>
                                      <ul class="list-inline sendbtn mt-3">
                                          <li class="list-inline-item">
                                              <button type="button" class="btn btn-primary" *ngIf="!mobileOtpSent"
                                                  [disabled]="mobile.invalid" (click)="sendOtp(mobile.value)">
                                                  SEND OTP</button>
                                              <button type="button" class="btn btn-default2" *ngIf="mobileOtpSent"
                                                  [disabled]="mobile.invalid" (click)="resendOtp(mobile.value)">
                                                  RE-SEND
                                                  OTP</button>
                                          </li>

                                          <li class="list-inline-item">
                                              <button type="button" class="btn btn-warning resend"
                                                  (click)="changeMobile()">Change
                                                  Mobile Number</button>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="form-group">
                                      <label for="Name" class="form-label">Enter OTP</label>
                                      <input type="text" class="form-control" placeholder="Enter OTP"
                                          formControlName="mobileOtp">
                                      <span *ngIf="mobileOtp.touched && mobileOtp.errors && mobileOtp.errors.required"
                                          style="color:red;" id="emailOtperror">
                                          OTP is required.
                                      </span>
                                      <span *ngIf="mobileOtpCheck && !mobileOtpVerified" style="color:red;"
                                          id="mobileOtperror2">
                                          OTP is incorrect.
                                      </span>
                                  </div>
                                  <div class="form-group">
                                      <label for="email" class="form-label">Email Id</label>
                                      <input type="email" class="form-control" placeholder="name@example.com"
                                          formControlName="email">
                                      <span *ngIf=" email.touched && email.errors && email.errors.required"
                                          style="color: red">
                                          Email is requried.
                                      </span>
                                      <span *ngIf="email.touched && email.errors && !email.errors.required"
                                          style="color: red">
                                          Enter Valid E-Mail.
                                      </span>
                                  </div>
                                  <div class="form-group pwd mb-3">
                                      <label for="password" class="form-label">Password</label>
                                      <div class="input-group">
                                      <input type="{{ hidePassword ? 'password' : 'text' }}" toggle class="form-control" class="form-control"
                                          value="password" id="password" name="password" formControlName="password" placeholder="Enter Password"> 
                                              <a href="javascript:void(0)" class="input-group-text" (click)="togglePassword()">
                                                  <i class="bi" [ngClass]="hidePassword ? 'bi-eye' : 'bi-eye-slash'"></i>
                                              </a>
                                          </div>
                                      <!-- <a href="javascript:void(0)" class="input-group-text"><i
                                              class="bi bi-eye"></i></a> -->
                                      <!-- <span *ngIf="password.touched && password.errors && password.errors.required"
                                          style="color:red;" id="emailOtperror">
                                          Password is required.
                                      </span> -->
                                      <!-- <span *ngIf="passwordError" style="color:red;" id="passwordminerror">
                                              Password will be min of 6 characters and max of 10 characters.
                                          </span> -->
                            <!-- previous code -->
                                       <div *ngIf="password?.invalid && (password?.dirty || password?.touched)">
                                          <span class="invalid_msg"
                                              [ngClass]="password.hasError('required') || password.hasError('hasLength')  ? 'text-danger' : 'text-success'">
                                              <i class="material-icons">
                                                  {{ password.hasError('required') || password.hasError('hasLength') ?
                                                  'cancel' : 'check_circle' }}
                                              </i> must be 10 characters!
                                          </span><br>

                                          <span class="invalid_msg"
                                              [ngClass]="password.hasError('required') || password.hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                              <i class="material-icons">
                                                  {{ password.hasError('required') || password.hasError('hasNumber') ?
                                                  'cancel' : 'check_circle' }}
                                              </i>
                                              must contain at least 1 number!
                                          </span><br>
                                          <span class="invalid_msg"
                                              [ngClass]="password.hasError('required') || password.hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                              <i class="material-icons">
                                                  {{ password.hasError('required') ||
                                                  password.hasError('hasCapitalCase') ? 'cancel' : 'check_circle' }}
                                              </i>
                                              must contain at least 1 in Capital Case!
                                          </span><br>
                                          <span class="invalid_msg"
                                              [ngClass]="password.hasError('required') || password.hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                                              <i class="material-icons">
                                                  {{ password.hasError('required') ||
                                                  password.hasError('hasSmallCase') ? 'cancel' : 'check_circle' }}
                                              </i>
                                              must contain at least 1 Letter in Small Case!
                                          </span><br>
                                          <span class="invalid_msg"
                                              [ngClass]="password.hasError('required') || password.hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                              <i class="material-icons">
                                                  {{ password.hasError('required') ||
                                                  password.hasError('hasSpecialCharacters') ? 'cancel' :
                                                  'check_circle' }}
                                              </i>must contain at least 1 Special Character!
                                          </span>
                                      </div>
  
                                  </div>
                                  <button type="submit" class="btn btn-primary" type="submit"
                                      [disabled]="signupForm.invalid">SIGN UP</button>
                                  <!-- <div class="divder my-4"> -->
                                  <!-- <p> Or </p>
                              </div>
                              <button type="button" class="btn btn-light googlebtn"><i class="bi bi-google"></i> Log in with Google</button>
                              <div class="newuser my-4">
                                <p class="mb-0">Already have an account? <a href="#Login"> LOGIN HERE</a> </p>
                              </div> -->
                              </form>
                          </div>
                      </div>
                    </div>
                    
                </div>
                <div class="mb-loginchoose mt-4 bg-white p-4 rounded-4 shadow-sm">
                  <h2>Why you should choose us :</h2>
                  <ul>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>Connect with Global Network of Buyers, Investors, Agents, Property Consultants and Realtors.</span></p>
                    </li>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>Real FinTech Platform - Will leverage to close 10X Deals.</span></p>
                    </li>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>Credit Bureau Support for Financial Assessment.</span></p>
                    </li>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>Post a property for FREE.</span></p>
                    </li>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>List Properties till Sale.</span></p>
                    </li>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>Enjoy two months of Cash Back on Home Loan EMIs!</span></p>
                    </li>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>10+ Business Opportunities from Nexus Network.</span></p>
                    </li>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>Get home loans easily from the banks you already use.</span></p>
                    </li>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>Sale Your Properties 10X Fast with Profiled Buyers.</span></p>
                    </li>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>Avail Property Services ( Packers & Movers, Rental Agreement, Interior Design & Vaastu ).</span></p>
                    </li>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>Connect with Verified Buyers & Tenants.</span></p>
                    </li>
                    <li>
                        <p><i class="ri-checkbox-line"></i> <span>Get Unlimited Enquiries on Your Property.</span></p>
                    </li>
                </ul>
                
              </div>
            </div>
        </div>
    </div>
  </section>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 pb-4 text-center">
        <small class="copy-right-text">All trademarks, logos and names are properties of their respective owners. Copyright © 2024 - All rights reserved.</small>
      </div>
    </div>
    
  </div>
  
  <!-- <footer>
    <nav class="navbar fixed-bottom bg-secondary">
      <div class="container-fluid">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Terms & Conditions </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Policy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#">Home</a>
          </li>
        </ul>
      </div>
    </nav>
  </footer> -->
</div>