<div id="Wrapper" *ngIf="!isMobileLayout">
    <!-- Sidebar -->

    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div>
        <div id="content">

            <!-- End of Topbar -->

            <!-- Begin Page Content -->

            <div class="container-fluid">
                <div class="pb-wrapper">
                    <form [formGroup]="profileForm" (ngSubmit)="submit()" #proForm="ngForm">
                        <div class="form-row mb-2">
                            <table width="100%">
                                <tr>
                                  <!-- <div class="profile-img">
                                                                        <img [src]="propertyImages[0]" alt=""/>
                                                                        <div class="file btn btn-lg btn-primary">
                                                                            Change Photo
                                                                            <input type="file" name="file"  #imageInput accept="image/png, image/gif, image/jpeg"
                                                                            (change)="uploadAws($event)" formControlName="images" />
                                                                        </div>
                                                                    </div> -->
                                  <td width="28%" rowspan="3" align="center">
                                    <img class="rounded-circle" style="width: 200px;height: 200px;"
                                      [src]="lookupModel.getLoggedUser().personalDetails.profilePic ? lookupModel.getLoggedUser().personalDetails.profilePic:propertyImages.length ? propertyImages[0] :'assets/img/userProfile.jpg'"
                                      alt="">
                                    <div class="text-danger"><small>(JPEG/PNG - 1MB Size)</small></div>
                                    <!-- <input type="file" name="file"  #imageInput accept="image/png, image/gif, image/jpeg"
                                                                        (change)="uploadAws($event)" formControlName="images" /> -->
                                    <div class="input-group" style="padding-left:100px;">
                                
                                
                                      <label class="btn btn-light" for="customFileInput" style="border: 1px solid black;">Choose File</label>
                                
                                
                                      <input type="file" name="file" #imageInput accept="image/png, image/gif, image/jpeg" (change)="uploadAws($event)"
                                        formControlName="images" class="d-none" id="customFileInput" />
                                    </div>
                                    <div><b class="text-gray"></b> <b class="text-danger">NP-{{lookupModel.getLoggedUser().associateId}}</b>
                                    </div>
                                  </td>
                                  <!-- <td width="28%" rowspan="3" align="center">
                                                                        <img class="rounded-circle" style="width: 200px; height: 200px;" [src]="profileImageUrl" alt="">
                                                                        <div class="text-danger"><small>( image-png,204MB )</small></div>
                                                                        <button class="btn btn-darkgray" (click)="changePhoto()">Change Photo</button>
                                                                        <div><b class="text-gray">ASSOCIATE ID </b><b class="text-danger">PTSO72401</b></div>
                                                                    </td> -->
                                
                                  <td width="24%">
                                    <div class="col">
                                      <input type="text" class="form-control" placeholder="First Name" formControlName="firstName">
                                      <span *ngIf="firstName.touched && firstName.errors && firstName.errors.required" style="color:red;">
                                        First Name is required
                                      </span>
                                    </div>
                                
                                  </td>
                                
                                  <td width="24%">
                                    <div class="col mb-2">
                                      <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName">
                                      <span *ngIf="lastName.touched && lastName.errors && lastName.errors.required" style="color:red;">
                                        Last Name is required
                                      </span>
                                    </div>
                                  </td>
                                  <td width="24%">
                                    <div class="col position-relative mb-2">
                                      <select class="form-control" formControlName="bloodGroup">
                                        <option value="" disabled selected>Select Blood Group</option>
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option>
                                        <option value="B+">B+</option>
                                        <option value="B-">B-</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                      </select>
                                      <i class="bi bi-chevron-down position-absolute" style="right: 20px; top: 5px;"></i> <!-- Bootstrap Icon -->
                                      <span *ngIf="bloodGroup.touched && bloodGroup.errors && bloodGroup.errors.required" style="color:red;">
                                        Blood Group is required
                                      </span>
                                    </div>
                                  </td>
                                
                                </tr>
                                <tr>
                                  <td>
                                    <div class="col mb-2">
                                      <input type="text" class="form-control" placeholder="Email ID" formControlName="email">
                                      <span *ngIf="email.touched && email.errors && email.errors.required" style="color:red;">
                                        Email is required
                                      </span>
                                
                                    </div>
                                  </td>
                                  <td>
                                    <div class="col">
                                      <input type="text" class="form-control" placeholder="Mobile Number" formControlName="mobile">
                                      <span *ngIf="mobile.touched && mobile.errors && mobile.errors.required" style="color:red;">
                                        Mobile Number is required
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="col">
                                      <input type="text" class="form-control" placeholder="Emergency Contact Number" formControlName="emergencyContact">
                                      <span *ngIf="emergencyContact.touched && emergencyContact.errors && emergencyContact.errors.required"
                                        style="color:red;">
                                        Emergency Contact is required
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="col">
                                      <input type="text" class="form-control" placeholder="Education Qualifications " formControlName="qualification">
                                      <span *ngIf="qualification.touched && qualification.errors && qualification.errors.required" style="color:red;">
                                        Qualification is required
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="col">
                                      <input type="text" class="form-control" placeholder="Real Estate Years Of Experience"
                                        formControlName="experience">
                                      <span *ngIf="experience.touched && experience.errors && experience.errors.required" style="color:red;">
                                        Experience is required
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="col">
                                      <input type="text" class="form-control" placeholder="RERA LICENCE NUMBER" formControlName="rera">
                                      <span *ngIf="rera.touched && rera.errors && rera.errors.required" style="color:red;">
                                        Rera Licence Number is required
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              
                              
                            </table>

                        </div>
                        <div class="mb-3 text-center">
                          <button class="btn btn-darkgray" type="submit">Update</button>
                      </div>
                    </form>
                    <!-- <form [formGroup]="partOfProfileForm" (ngSubmit)="partOfProfile()" #proForm="ngForm">
                      <div class="form-row mb-4">
                        <div class="col" style="margin-left: 30px;">
                            <input type="text" class="form-control" placeholder=" Education Qualifications " formControlName="qualification">
                           
                        </div>
                        <div class="col" style="margin-left: 30px;">
                            <input type="text" class="form-control" placeholder="Real Estate Years Of Experience" formControlName="experience">
                         
                        </div>
                        <div class="col" style="margin-left: 30px;">
                            <input type="text" class="form-control" placeholder="RERA LICENCE NUMBER" formControlName="rera">
                          
                        </div>
                        <div class="col" style="margin-left: 30px;">
                          <button type="submit" class="btn btn-darkgray">Update</button>
                        </div>
                       
                     
                    </div>
                    </form> -->

                    <form class="agent-profile"  [formGroup]="addressForm" (ngSubmit)="updateAddress()" #addrForm="ngForm">
                        <div class="form-row mb-3">
                            <b class="blueheader">Address</b>
                        </div>
                        <div class="form-row mb-3">
                            <div class="col">
                                <label class="text-danger">PRESENT ADDRESS</label>
                            </div>
                            <div class="col">
                                <input type="checkbox" style="width: 20px;height: 20px;" formControlName="sameAsPresent">
                                <b>PERMENNENT ADDRESS <span class="text-danger">SAME AS PRESENT ADDRESS</span></b>
                            </div>
                        </div>
                        <div class="form-row mb-4">
                            <div class="col">
                                <input type="text" class="form-control" placeholder="House Number / Flat Number" formControlName="houseNumber">
                                <span
                                *ngIf="houseNumber.touched && houseNumber.errors && houseNumber.errors.required"
                                style="color:red;">
                                House Number is required
                                 </span>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Building Name / Apartment Name" formControlName="buildingName">
                               <span
                                *ngIf="buildingName.touched && buildingName.errors && buildingName.errors.required"
                                style="color:red;">
                                Building Name is required
                                 </span>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Locality / Colony / Street Name" formControlName="locality">
                                <span
                                *ngIf="locality.touched && locality.errors && locality.errors.required"
                                style="color:red;">
                                Locality is required
                                 </span>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Village / Town / City" formControlName="village">
                                <span
                                *ngIf="village.touched && village.errors && village.errors.required"
                                style="color:red;">
                                Village is required
                                 </span>
                            </div>
                           
                            <!-- <div class="col">
                                <label for="Operating since">Operating since</label>
                                <select class="form-control">
                                    <option value="">Select Year</option>
                                    <option value="">2024</option>
                                    <option value="">2023</option>
                                    <option value="">2022</option>
                                    <option value="">2021</option>
                                </select>
                            </div> -->
                        </div>

                        <div class="form-row mb-4">
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Tahsil / Municipality / City" formControlName="tahsil">
                                <span
                                *ngIf="tahsil.touched && tahsil.errors && tahsil.errors.required"
                                style="color:red;">
                                Tahsil is required
                                 </span>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="District Name" formControlName="district">
                                <span
                                *ngIf="district.touched && district.errors && district.errors.required"
                                style="color:red;">
                                District is required
                                 </span>
                            </div>
                            
                            <div class="col">
                                <input type="text" class="form-control" placeholder="State Name" formControlName="state">
                                <span
                                *ngIf="state.touched && state.errors && state.errors.required"
                                style="color:red;">
                                State is required
                                 </span>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="PIN CODE" formControlName="pincode">
                                <span
                                *ngIf="pincode.touched && pincode.errors && pincode.errors.required"
                                style="color:red;">
                                Pincode is required
                                 </span>
                            </div>
                        </div>
                        <div class="form-row mb-3">
                            <div class="col">
                                <label class="text-danger">PERMENNENT ADDRESS</label>
                            </div>

                        </div>
                        <div class="form-row mb-4">
                            <div class="col">
                                <input type="text" class="form-control" placeholder="House Number / Flat Number" formControlName="permanentHouseNumber">
                                <span
                                *ngIf="permanentHouseNumber.touched && permanentHouseNumber.errors && permanentHouseNumber.errors.required"
                                style="color:red;">
                                Permanent HouseNumber is required
                                 </span>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Building Name / Apartment Name" formControlName="permanentBuildingName">
                                <span
                                *ngIf="permanentBuildingName.touched && permanentBuildingName.errors && permanentBuildingName.errors.required"
                                style="color:red;">
                                Permanent BuildingName is required
                                 </span>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Locality / Colony / Street Name" formControlName="permanentLocality">
                                <span
                                *ngIf="permanentLocality.touched && permanentLocality.errors && permanentLocality.errors.required"
                                style="color:red;">
                                Permanent Locality is required
                                 </span>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Village / Town / City" formControlName="permanentVillage">
                                <span
                                *ngIf="permanentVillage.touched && permanentVillage.errors && permanentVillage.errors.required"
                                style="color:red;">
                                Permanent Village is required
                                 </span>
                            </div>
                           
                            <!-- <div class="col">
                                <label for="Operating since">Operating since</label>
                                <select class="form-control">
                                    <option value="">Select Year</option>
                                    <option value="">2024</option>
                                    <option value="">2023</option>
                                    <option value="">2022</option>
                                    <option value="">2021</option>
                                </select>
                            </div> -->
                        </div>

                        <div class="form-row mb-4">
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Tahsil / Municipality / City" formControlName="permanentTahsil">
                                <span
                                *ngIf="permanentTahsil.touched && permanentTahsil.errors && permanentTahsil.errors.required"
                                style="color:red;">
                                Permanent Tahsil is required
                                 </span>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="District Name" formControlName="permanentDistrict">
                                <span
                                *ngIf="permanentDistrict.touched && permanentDistrict.errors && permanentDistrict.errors.required"
                                style="color:red;">
                                Permanent District is required
                                 </span>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="State Name" formControlName="permanentState">
                                <span
                                *ngIf="permanentState.touched && permanentState.errors && permanentState.errors.required"
                                style="color:red;">
                                Permanent State is required
                                 </span>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="PIN CODE" formControlName="permanentPincode" >
                                <span
                                *ngIf="permanentPincode.touched && permanentPincode.errors && permanentPincode.errors.required"
                                style="color:red;">
                                Permanent Pincode is required
                                 </span>
                            </div>
                        </div>
                        <div class="mb-3 text-center">
                            <button class="btn btn-darkgray" type="submit">Update</button>
                        </div>
                    </form>
                    <div class="container">
                      <!-- Side by Side Forms -->
                      <div class="row">
                          <!-- Bank Account Details Form (Left Side) -->
                          <div class="col-md-6">
                              <form [formGroup]="bankAccountForm" (ngSubmit)="bankDetails()" #AccountForm="ngForm">
                                  <!-- Bank Account Details Header -->
                                  <div class="form-row mb-3">
                                      <b class="header-box blueheader">Bank Account Details</b>
                                  </div>
                  
                                  <!-- First Row: Account Holder Name & Bank Account Number -->
                                  <div class="form-row mb-4">
                                      <div class="col">
                                          <label for="holderName">Name as per Bank records<span class="text-danger">*</span></label>
                                          <input type="text" class="form-control" placeholder="Type here" formControlName="holderName">
                                          <span *ngIf="holderName.touched && holderName.errors?.required" class="text-danger">
                                              Account Holder Name is required
                                          </span>
                                      </div>
                                      <div class="col">
                                          <label for="accountNumber">Bank Account Number<span class="text-danger">*</span> 
                                              <span *ngIf="lookupModel.getLoggedUser().bankAccountDetails?.cheque">
                                                  <a href={{lookupModel.getLoggedUser().bankAccountDetails.cheque}} target="_blank">View Cheque/ Passbook</a>
                                              </span>
                                          </label>
                                          <input type="text" class="form-control" placeholder="Type here" formControlName="accountNumber">
                                          <span *ngIf="accountNumber.touched && accountNumber.errors?.required" class="text-danger">
                                              Account Number is required
                                          </span>
                                      </div>
                                  </div>
                  
                                  <!-- Second Row: Bank Name & IFSC Code -->
                                  <div class="form-row mb-4">
                                      <div class="col">
                                          <label for="bankName">Bank Name<span class="text-danger">*</span></label>
                                          <input type="text" class="form-control" placeholder="Type here" formControlName="bankName">
                                          <span *ngIf="bankName.touched && bankName.errors?.required" class="text-danger">
                                              Bank Name is required
                                          </span>
                                      </div>
                                      <div class="col">
                                          <label for="ifsc">IFSC CODE<span class="text-danger">*</span></label>
                                          <input type="text" class="form-control" placeholder="Type here" formControlName="ifsc">
                                          <span *ngIf="ifsc.touched && ifsc.errors?.required" class="text-danger">
                                              IFSC Code is required
                                          </span>
                                      </div>
                                  </div>
                  
                                  <!-- File Upload & Update Button Row -->
                                  <div class="form-row mb-4">
                                      <div class="col">
                                          <label for="file-upload" class="btn btn-light">
                                              <input type="file" id="file-upload" accept="image/png, image/jpeg" (change)="uploadBankPassBook($event)" formControlName="images" />
                                              Choose File
                                          </label>
                                          <small>(Attach Cancelled cheque / Passbook Copy) jpeg image, 20kb size</small>
                                      </div>
                                      <div class="col text-right">
                                          <button class="btn btn-darkgray" type="submit">Update</button>
                                      </div>
                                  </div>
                              </form>
                          </div>
                  
                          <!-- PAN Card Details Form (Right Side) -->
                          <div class="col-md-6">
                              <form [formGroup]="panForm" (ngSubmit)="panAccount()">
                                  <!-- PAN Card Details Header -->
                                  <div class="form-row mb-3">
                                      <b class="header-box blueheader">PAN Card Details</b>
                                  </div>
                  
                                  <!-- First Row: Name as per PAN CARD & PAN Number -->
                                  <div class="form-row mb-4">
                                      <div class="col">
                                          <label for="nameAsPerPan">Name as per PAN CARD<span class="text-danger">*</span></label>
                                          <input type="text" class="form-control" placeholder="Type here" formControlName="nameAsPerPan">
                                          <span *ngIf="nameAsPerPan.touched && nameAsPerPan.errors?.required" class="text-danger">
                                              Name is required
                                          </span>
                                      </div>
                                      <div class="col">
                                          <label for="panNum">PAN Number<span class="text-danger">*</span>
                                              <span *ngIf="lookupModel.getLoggedUser().panCardDetails?.panCard">
                                                  <a href={{lookupModel.getLoggedUser().panCardDetails.panCard}} target="_blank">View PAN Card</a>
                                              </span>
                                          </label>
                                          <input type="text" class="form-control" placeholder="Type here" formControlName="panNum">
                                          <span *ngIf="panNum.touched && panNum.errors?.required" class="text-danger">
                                              PAN Number is required
                                          </span>
                                      </div>
                                  </div>
                  
                                  <!-- File Upload & Update Button Row -->
                                  <div class="form-row mb-4">
                                      <div class="col">
                                        <!-- class="btn btn-file-upload" -->
                                          <label for="pan-file-upload" class="btn btn-light">
                                              <input type="file" id="pan-file-upload" accept="image/png, image/jpeg" (change)="uploadPan($event)" formControlName="images" />
                                              Choose File
                                          </label>
                                          <small>(Attach PAN CARD) jpeg image, 20kb size</small>
                                      </div>
                                      <div class="col text-right">
                                          <button class="btn btn-darkgray" type="submit">Update</button>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
                  

                    
                </div>
            </div>
            <!-- /.container-fluid -->

        </div>
        <!-- End of Main Content -->


    </div>
    <!-- End of Content Wrapper -->

</div>


<div class="mb-dashboard" *ngIf="isMobileLayout">
    <div class="container">
      <div class="mb-profile-card my-3">
        <div class="mb-profile-header">
          <div class="mb_editicon d-flex align-items-center justify-content-between">
            <h2>Profile</h2>
            <a (click)="profileEdit()"><i class="ri-edit-box-line"></i>Edit</a>
          </div>
          
          <!-- <div class="mb-profile-image text-center">
            <img id="profile-img" src="{{mbProfileImg}}" alt="Profile Picture" class="img-fluid rounded-circle">
            <img id="profile-img"  [src]="lookupModel.getLoggedUser().personalDetails.profilePic ? lookupModel.getLoggedUser().personalDetails.profilePic:propertyImages.length ? propertyImages[0] :'assets/img/userProfile.jpg'" alt="Profile Picture" class="img-fluid rounded-circle">

            <div class="edit-icon" id="edit-icon" imageInput>
              <i class="ri-edit-line" for="customFileInput"></i>
            </div>
            <input type="file" name="file" #imageInput accept="image/png, image/gif, image/jpeg" (change)="uploadAws($event)"
            formControlName="images" class="d-none" id="customFileInput" />
            <input type="file" id="customFileInput" class="d-none" accept="image/*" (change)="onFileSelected($event)">
          </div> -->
          <div class="mb-profile-image text-center">
            <img
              id="profile-img"
              [src]="lookupModel.getLoggedUser().personalDetails.profilePic 
                      ? lookupModel.getLoggedUser().personalDetails.profilePic 
                      : (propertyImages.length 
                          ? propertyImages[0] 
                          : 'assets/img/userProfile.jpg')"
              alt="Profile Picture"
              class="img-fluid rounded-circle"
            />
          
            <div class="edit-icon" id="edit-icon" imageInput (click)="imageInput.click()">
              <i class="ri-edit-line"></i>
            </div>
          
            <input
              type="file"
              name="file"
              #imageInput
              accept="image/png, image/gif, image/jpeg"
              (change)="uploadAws($event)"
              formControlName="images"
              class="d-none"
              id="customFileInput"
            />
          </div>
          <div class="mb-3 text-center mt-3" *ngIf="showProfilePicBtn">
            <button class="btn btn-primary" (click)="profilePicSubmit()">Update Profile Picture</button>
        </div>
          
          <!-- <button class="mb-profile-name mt-2 text-center" (click)="profilePicSubmit()">Pic Submit</button> -->

          <h2 class="mb-profile-name mt-2 text-center">{{fName}}</h2>
          <p class="mb-profile-email text-center">{{mbProfileEmail}}</p>
          <div class="mb-profile-data">
            <form [formGroup]="profileForm" (ngSubmit)="submit()" #proForm="ngForm">
            
                <div class="d-flex align-items-center justify-content-between">
                    <h5>Mobile number</h5>
                    <h5>{{mbProfileMobile}}</h5>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <h5>Emergency Contact Number</h5>
                    <h5>9505148602</h5>
                  </div>
                <div class="d-flex align-items-center justify-content-between">
                    <h5>Blood Group</h5>
                    <h5>{{mbProfileBloodGroup}}</h5>
                  </div>
            </form>
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#mb-addressTab" aria-expanded="false" aria-controls="mb-addressTab">
                    Address
                  </button>
                </h2>
                <div id="mb-addressTab" class="accordion-collapse collapse" aria-labelledby="addresstab" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <address>
                      {{mbAddrHouseNum}}<br>
                      {{mbBuildingName}}<br>
                      {{mbLocality}},{{mbVillage}},<br>
                      {{mbTahsil}},{{mbDistrict}},<br>
                      {{mbState}},{{mbPincode}}
                    </address>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#mb-bankTab" aria-expanded="false" aria-controls="flush-collapseTwo">
                    Bank Account Details
                  </button>
                </h2>
                <div id="mb-bankTab" class="accordion-collapse collapse" aria-labelledby="banktab" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <div class="d-flex align-items-center justify-content-between">
                      <h5>Name as per Bank records</h5>
                      <h5>{{mbBankDetatils.holderName}}</h5>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">

                    <h5>Bank Name</h5>
                    <h5>{{mbBankDetatils.bankName}}</h5>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        
                      
                      <h5>Bank Account Number</h5>
                      <h5>{{mbBankDetatils.accountNumber}}</h5>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                      <h5>IFSC CODE</h5>
                      <h5>{{mbBankDetatils.ifsc}}</h5>
                    </div>
                    <img src="{{mbBankDetatils.cheque}}" class="img-fluid mb-passbook" alt="">
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#mb-panTab" aria-expanded="false" aria-controls="flush-collapseThree">
                    PAN Card Details
                  </button>
                </h2>
                <div id="mb-panTab" class="accordion-collapse collapse" aria-labelledby="mb-panTab" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <div class="d-flex align-items-center justify-content-between">
                      <h5>Name as per PAN CARD</h5>
                      <h5>{{mbPanDetails.nameAsPerPan}}</h5>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                      <h5>PAN Number</h5>
                      <h5>{{mbPanDetails.panNum}}</h5>
                    </div>
                    <img src="{{mbPanDetails.panCard}}" class="img-fluid mb-passbook" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pb-4 text-center">
          <small class="copy-right-text">All trademarks, logos and names are properties of their respective owners. Copyright © 2024 - All rights reserved.</small>
        </div>
      </div>
      
    </div>
<!--     
    <footer>
      <nav class="navbar fixed-bottom bg-secondary">
        <div class="container-fluid">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Terms & Conditions </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Home</a>
            </li>
          </ul>
        </div>
      </nav>
    </footer> -->
  </div>