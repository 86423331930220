import { Component, OnInit, ElementRef, ViewChild, NgZone, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { MapsAPILoader } from '@agm/core';
import { RsbService } from 'src/app/service/rsb.service';
import { error } from 'console';
@Component({
  selector: 'app-mb-my-properties',
  templateUrl: './mb-my-properties.component.html',
  styleUrls: ['./mb-my-properties.component.css']
})
export class MbMyPropertiesComponent implements OnInit {
  allListings: Array<any> = [];
  moduleList: Array<any> = [];
  selectedMainModuleId:Array<any> = [];
  constructor(
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService
  ) { }

  ngOnInit(): void {

    this.loadModuleList();
    setTimeout((obj) => {
      this.getAllListings();
    }, 1000)


  }

  getAllListings(): void {
    this.selectedMainModuleId = this.moduleList.filter((obj) => {
      return obj.selected && obj.selected === true;
    })
    if (!this.selectedMainModuleId[0].moduleName.toUpperCase().includes('PG')) {
      this.rsbService.getUserListings({ "mainModuleId": this.selectedMainModuleId && this.selectedMainModuleId.length ? this.selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents.length) {
          this.allListings = rsp.contents;
        } else {
          this.allListings = [];
        }
      })
    } else {
      this.rsbService.getUserListingsForPg({ "mainModuleId": this.selectedMainModuleId && this.selectedMainModuleId.length ? this.selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents.length) {
          this.allListings = rsp.contents
        } else {
          this.allListings = []
        }
      }, (error) => {
        this.allListings = []
      })
    }
  }

  changeMainModule(module): void {

    this.moduleList.forEach((obj) => {
      if (module.mainModuleId === obj.mainModuleId) {
        obj.selected = true;
      } else {
        obj.selected = false;
      }
    })

    this.getAllListings();
  }
  loadModuleList(): void {
    const vendorId = this.lookupModel.getLoggedUser().vendorTypeId;

    this.rsbService.getAllMainModules({}).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        if (rsp.contents.length > 0) {
          this.moduleList = rsp.contents;
          this.moduleList = this.moduleList.filter((data) => {
            // return data.vendorTypeId.includes(vendorId)
            return data.vendorTypeId;

          })
          this.moduleList.forEach((obj) => {
            obj["selected"] = false
          })
          this.moduleList[0].selected = true;
          console.log(this.moduleList[0]);
        }
        else {
          this.moduleList = [];
        }
      }
    })


  }

}

