
    <!-- Content Wrapper -->
        <!-- Main Content -->
        <div id="content" class="content bg-white"  *ngIf="!isMobileLayout">
            <!-- Topbar -->
             <h2 class="headerClass"><b>Choose your <span class="text-danger">Business Plan</span></b></h2>
           <div class="text-center mrg">
            <div class="box">
            <div class="box-in">
              <p class="boxHead">Business Starter</p>
              <p><span class="fnt" style="font-weight: bold;font-size: 40px;color:black;"><b>₹499/-</b></span></p>
              <p class="font-weight-bold"><b style="color: black;">( incl 18% GST )</b></p>
              <p><small>per user / Month</small></p>
              <button class="btn btn-outline-primary" (click)="buyPlan('starter','499','30','43800')" ><b>Pay Now</b></button>
              <p class="boxfooter"><small><i class="ri-shining-fill text-primary"></i> <b><span class="text-danger">Nexus</span> add-on available</b></small></p>
            </div>
            </div>
            <div class="box active">
              <div class="boxHeder">MOST POPULAR</div>
              <div class="box-in">
                <p class="boxHead">Business Standard</p>
                <p class="price"><span class="currency">₹</span><span class="fnt" style="font-size: 40px;">1349/-</span></p>
                <span class="badge no-radius" style="background-color: rgb(71, 165, 71);">10% OFF</span>
              
                <p class="font-weight-bold"><b style="color: black;">( incl 18% GST )</b></p>
              
                <p><small>per user / <b class="text-danger">3 Month</b></small></p>
                <button class="btn btn-primary" (click)="buyPlan('standard','1349','90','129600')"><b>Pay Now</b></button>
                <p class="boxfooter"><small><i class="ri-shining-fill text-primary"></i> <b><span class="text-danger">Nexus</span>
                      add-on available</b></small></p>
              </div>
            </div>
            <div class="box">
              <div class="box-in">
                <p class="boxHead">Business Plus</p>
                <p class="price"><span class="currency">₹</span><span class="fnt" style="font-size: 40px;">2399/-</span></p>
                <span class="badge no-radius"style="background-color: orangered">20% OFF</span>
                <p class="font-weight-bold"><b style="color: black;">( incl 18% GST )</b></p>

                <p><small>per user / <b style="color: brown;">6 Month</b></small></p>
                <button class="btn btn-outline-primary" (click)="buyPlan('plus','2399','180','262800')"><b>Pay Now</b></button>
                <p class="boxfooter"><small><i class="ri-shining-fill text-primary"></i> <b><span class="text-danger">Nexus</span> add-on available</b></small></p>
              </div>
            </div>
            <div class="box">
              <div class="box-in">
                <p class="boxHead">Enterprise</p>
                <p class="price"><span class="currency">₹</span><span class="fnt" style="font-size: 40px;">4199/-</span></p>
                <span class="badge badge-danger no-radius">30% OFF</span>
                <p class="font-weight-bold"><b style="color: black;">( incl 18% GST )</b></p>

                <p><small>per user / <b style="color: hotpink;">12 Month</b></small></p>
                <button class="btn btn-outline-primary" (click)="buyPlan('Enterprise','4199','360','525601')"><b>Pay Now</b></button>
                <p class="boxfooter"><small><i class="ri-shining-fill text-primary"></i> <b><span class="text-danger">Nexus</span> add-on available</b></small></p>
              </div>
            </div>
          </div>
        </div>
        <!-- End of Main Content -->
        <app-mb-choose-plans *ngIf="isMobileLayout"></app-mb-choose-plans>


