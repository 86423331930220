import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
import * as dateUtil from 'src/app/common/utility/date-time.utility';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { forkJoin } from 'rxjs';
import { NgbActiveModal , NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-plot-post-details',
  templateUrl: './plot-post-details.component.html',
  styleUrls: ['./plot-post-details.component.css']
})
export class PlotPostDetailsComponent implements OnInit {
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  @Output() formChange: EventEmitter<boolean> = new EventEmitter();
  propertyForm: FormGroup;
  selectedProperty: any;
  selectedCountry: any = {};
  selectedModule: any = {};
  selectedCategory: any = {};
  selectedSubCategory: any = {};
  selectedMeasurement: any = {};

  moduleList: Array<any> = [];
  categoryList: Array<any> = [];
  subCatList: Array<any> = [];
  baiscAttrList: Array<any> = [];
  categoryAttrList: Array<any> = [];
  countryList: Array<any> = [];
  measurementList: Array<any> = [];
  vendorDetails: any = {};
  basicAttrValues: any;
  currentDate = dateUtil.convertToPickerDate(new Date());
  propertyPreviewImage: string;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  onlyCountries = [];
  loadView: boolean = false;
  activeModel:NgbActiveModal;
  selectedISOCountry;

  titleProperties = [
    {
      id: 1,
      name: 'BEDROOM',
      value: ''
    },
    {
      id: 2,
      name: 'CATEGORY_TYPE',
      value: ''
    },
    {
      id: 3,
      name: 'PROPERTY_TYPE',
      value: ''
    },
    {
      id: 4,
      name: 'MODULE',
      value: ''
    },
    {
      id: 5,
      name: 'LOCATION',
      value: ''
    }
  ];
  firstLoad: boolean = false;
  isFormDirty: boolean = false;
  constructor(
    private fb: FormBuilder,
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    console.log("getting rent-post-details")
    this.firstLoad = true;
    this.selectedProperty = this.lookupModel.getVendorProperty() ? this.lookupModel.getVendorProperty() : null;
    // if (!this.selectedProperty.vendorDetails || !this.selectedProperty.vendorDetails.twoDigitCountryCode) {
    //   this.selectedISOCountry = this.findCountryIso(this.lookupModel.getLoggedUser().personalDetails.twoDigitCountryCode);
    // } else {
    //   this.selectedISOCountry = this.findCountryIso(this.selectedProperty.vendorDetails.twoDigitCountryCode);
    // }
    if (!this.selectedProperty.vendorDetails || !this.selectedProperty.vendorDetails.twoDigitCountryCode) {
      this.selectedISOCountry = this.findCountryIso(this.lookupModel.getLoggedUser().personalDetails.twoDigitCountryCode ? this.lookupModel.getLoggedUser().personalDetails.twoDigitCountryCode:"IN");
    } else {
      this.selectedISOCountry = this.findCountryIso(this.lookupModel.getLoggedUser().personalDetails.twoDigitCountryCode ? this.lookupModel.getLoggedUser().personalDetails.twoDigitCountryCode:"IN");
    }
    this.onlyCountries = [this.selectedISOCountry];
    if (!this.selectedProperty.vendorDetails) {
      this.vendorDetails = {
        name: this.lookupModel.getLoggedUser().personalDetails.firstName,
        email: this.lookupModel.getLoggedUser().personalDetails.email,
        countryCode: this.lookupModel.getLoggedUser().personalDetails.countryCode,
        whatsappcountryCode: this.lookupModel.getLoggedUser().personalDetails.countryCode,
        mobileNumber: this.lookupModel.getLoggedUser().personalDetails.mobile,
        wmobileNumber:''
      }
      this.selectedProperty['vendorDetails'] = this.vendorDetails;
    }

    this.selectedCountry = this.lookupModel.getPropertyCountry() ? this.lookupModel.getPropertyCountry() : {};
    this.selectedModule = this.lookupModel.getModule() ? this.lookupModel.getModule(): this.getModules();
    // this.propertyPreviewImage = this.selectedProperty && this.selectedProperty.propertyPreviewImage ? this.selectedProperty.propertyPreviewImage : '';
    this.propertyForm = this.fb.group({
      location: new FormControl({ value: this.selectedProperty ? this.prepareLocationText(this.selectedProperty) : '', disabled: true }),
      moduleId: [this.selectedProperty.moduleId ? this.selectedProperty.moduleId : this.selectedModule.moduleId, [Validators.required]],
      categoryId: [this.selectedProperty.categoryId ? this.selectedProperty.categoryId : '', [Validators.required]],
      subCategoryId: [this.selectedProperty.subCategoryId ? this.selectedProperty.subCategoryId : '', [Validators.required]],
      price: [this.selectedProperty.price ? this.selectedProperty.price : '', [Validators.required,Validators.pattern('^[0-9]*$')]],
      deposit: [this.selectedProperty.deposit ? this.selectedProperty.deposit : ''],
      propertySize: [this.selectedProperty.propertySize ? this.selectedProperty.propertySize : '', [Validators.required,Validators.pattern('^[0-9]*$')]],
      //dateAvailable: [this.selectedProperty.dateAvailable ? dateUtil.convertToPickerDate(this.selectedProperty.dateAvailable) : '', [Validators.required]],
      propertyTitle: [this.selectedProperty.propertyTitle ? this.selectedProperty.propertyTitle : '', [Validators.required]],
      propertyDescription: [this.selectedProperty.propertyDescription ? this.selectedProperty.propertyDescription : '', [Validators.required]],
      basicAttributes: this.fb.array([]),
      categoryAttributes: this.fb.array([]),
      priceCurrency: [this.selectedProperty.countryId ? this.selectedProperty.countryId : this.selectedCountry.codeId],
      depositCurrency: [this.selectedProperty.countryId ? this.selectedProperty.countryId : this.selectedCountry.codeId],
      measurement: [this.selectedProperty.measurementId ? this.selectedProperty.measurementId : this.selectedSubCategory.measurementId],
      name: [this.selectedProperty.vendorDetails.name ? this.selectedProperty.vendorDetails.name : '', [Validators.required]],
      email: [this.selectedProperty.vendorDetails.email ? this.selectedProperty.vendorDetails.email : '', [Validators.email]],
      mobile: [this.selectedProperty.vendorDetails.mobileNumber ? this.selectedProperty.vendorDetails.mobileNumber : '', [Validators.required]],
      whatsAppNumber: [this.selectedProperty.vendorDetails.wmobileNumber ? this.selectedProperty.vendorDetails.wmobileNumber : '',]
    });
    // this.changeModuleFields(true);
    // this.formChanges();
    // this.getModules();
    // this.getMeasurementList();
    this.loadDetails();
    this.getCountryList();
  }

  checkReadOnly(): boolean {
    const readonly: boolean = this.selectedProperty && ([1, 2, 3].includes(this.selectedProperty.status));
    return readonly;
  }
  prepareBasicArray(basicAttrList: Array<any>, basicValueList?: any[]): void {
    const formProp = this.propertyForm.get('basicAttributes') as FormArray;
    if (basicAttrList) {
      basicAttrList.forEach(attr => {
        if(attr.value) {
          formProp.push(this.createBasicGroup(attr.attributeId, attr.value,attr.required,attr.displayType, attr.validationType));
        }
        else if (attr.attributeProperties && attr.attributeProperties.length) {
          let selectedProp;
          if(basicValueList && basicValueList.length > 0) {
            selectedProp = basicValueList.find(item => item.attributeId === attr.attributeId);
            selectedProp.propertyId = selectedProp.attrValue;
          } else {
            selectedProp = attr.attributeProperties?.find(prop => prop?.selected);
          }
          if (selectedProp) {
            formProp.push(this.createBasicGroup(attr.attributeId, selectedProp.propertyId,attr.required,attr.displayType, attr.validationType));
          } else {
            formProp.push(this.createBasicGroup(attr.attributeId,'',attr.required,attr.displayType, attr.validationType));
          }
        } else {
          formProp.push(this.createBasicGroup(attr.attributeId,'',attr.required,attr.displayType, attr.validationType));
        }
      
      });
    }
  }
  createBasicGroup(attributeId: string, value?: any,required?:any,displayType?:number, validationType?:number ): FormGroup {
    let basicGroup: FormGroup;
    if (value) {
      if (required === 1 && displayType == 4) {
        if (validationType && validationType === 1) {
          basicGroup = this.fb.group({
            attributeId: attributeId,
            attrValue: [value, [Validators.required,Validators.pattern('[A-Za-z]+')]]
          });
        } else if (validationType && validationType === 2) {
          basicGroup = this.fb.group({
            attributeId: attributeId,
            attrValue: [value, [Validators.required,Validators.pattern('^[0-9]*$')]]
          });
        } else {
          basicGroup = this.fb.group({
            attributeId: attributeId,
            attrValue: [value, [Validators.required]]
          });
        }
      } else {
        basicGroup = this.fb.group({
          attributeId: attributeId,
          attrValue: [value]
        });
      }
    } else {
      if (required === 1 && displayType == 4) {
        //string
        if (validationType && validationType === 1) {
          basicGroup = this.fb.group({
            attributeId: attributeId,
            attrValue: ['', [Validators.required,Validators.pattern('[A-Za-z]+')]]
          });
      } else if (validationType && validationType === 2) {
        basicGroup = this.fb.group({
          attributeId: attributeId,
          attrValue: ['', [Validators.required,Validators.pattern('^[0-9]*$')]]
        });
      } else {
        basicGroup = this.fb.group({
          attributeId: attributeId,
          attrValue: ['', [Validators.required,]]
        });
      }
    
    } else {
        basicGroup = this.fb.group({
          attributeId: attributeId,
          attrValue: ['']
        });
      }
    }
    return basicGroup;
  }
  isValidInput(attributeId, validationType) {
    if(!validationType && validationType === null) {
        return true;
    }
    const ind = this.baiscAttrList.findIndex((prop) => prop.attributeId === attributeId)
    if (ind >= 0) {
      if (this.baiscAttrList[ind].value) {
        if (validationType && validationType === 1) {
   
          if (isNaN(this.baiscAttrList[ind].value)) {
            return true
          } else {
            return false;
          }
        } else if (validationType && validationType === 2) {
          console.log(!isNaN(this.baiscAttrList[ind].value))
          if (!isNaN(this.baiscAttrList[ind].value)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  disableForm(): void {
    this.propertyForm.disable({emitEvent: false});
    this.propertySize.enable();
    this.price.enable();
    this.deposit.enable();
    //this.dateAvailable.enable();
    this.whatsAppNumber.enable();
  }

  findCountryIso(key: string): string {
    let isoKey = Object.keys(CountryISO).find(item => CountryISO[item] === key.toLowerCase());
    if (isoKey) {
      return CountryISO[isoKey];
    }
  }

  loadDetails(): void {
    let moduleId=this.lookupModel.getModuleId();
    moduleId = moduleId && moduleId.length ? moduleId : JSON.parse(localStorage.getItem('mappedModuleId'));
    if(moduleId && moduleId.length) {
    this.moduleId.setValue(moduleId[0]);
    }
    const forkJoins = forkJoin({
      sourceOne: this.rsbService.getActiveModules(this.moduleId.value, this.selectedCountry.codeId),
      sourceTwo: this.rsbService.getActiveCategories(this.moduleId.value),
      sourceThree: this.rsbService.getActiveMeasurements(),
    });
    forkJoins.subscribe((rsp) => {
      if(rsp.sourceOne && rsp.sourceOne.statusCode === 0 && rsp.sourceOne.contents) {
        this.moduleList = rsp.sourceOne.contents;
        this.moduleList= this.moduleList.filter((data)=>{

          return moduleId.includes(data.moduleId)
        })  
        this.selectedModule = this.moduleList.find(item => item.moduleId === this.selectedModule.moduleId);
        // this.getCategories(this.moduleId.value);
        // this.changeModuleFields(true);
      }
      if(rsp.sourceTwo && rsp.sourceTwo.statusCode === 0 && rsp.sourceTwo.contents) {
        this.categoryList = rsp.sourceTwo.contents;
        this.categoryId.reset();
        this.subCategoryId.reset();
        this.subCategoryId.setValue('');
        if (this.selectedProperty && this.selectedProperty.listingId) {
          let category: any = this.categoryList.filter((cat) => cat.categoryId === this.selectedProperty.categoryId);
          this.selectedCategory = category[0];
          this.subCatList = category[0].subCategory;
          this.selectedSubCategory = this.subCatList.filter((subcat) => subcat.subCategoryId === this.selectedProperty.subCategoryId)[0];
          this.selectedMeasurement = this.selectedSubCategory;
          this.categoryId.setValue(this.selectedProperty.categoryId);
          this.subCategoryId.setValue(this.selectedProperty.subCategoryId);
          this.loadAttributes(this.selectedProperty.subCategoryId);
          if(this.checkReadOnly()) {
            this.disableForm();
          }
        } else {
          this.propertyForm.removeControl('basicAttributes');
          this.propertyForm.addControl('basicAttributes', new FormArray([]));
          this.firstLoad = false;
        }
      }
      if(rsp.sourceThree && rsp.sourceThree.statusCode === 0 && rsp.sourceThree.contents) {
        this.measurementList = rsp.sourceThree.contents;
        if (!this.measurement.value) {
          this.measurement.setValue(this.measurementList[0].measurementId);
        }
      }
      if(!this.firstLoad) {
        this.formChanges();
      }
    });
  }

  getModules(): void {
    this.rsbService.getActiveModules(this.moduleId.value, this.selectedCountry.codeId).subscribe((rsp) => {
      if (rsp.statusCode === 0 && rsp.contents) {
        this.moduleList = rsp.contents;
        this.selectedModule = this.moduleList.find(item => item.moduleId === this.moduleId.value);
        this.getCategories(this.moduleId.value);
        // this.changeModuleFields(true);
      }
    });
  }

  getCategories(moduleId: string): void {
    this.rsbService.getActiveCategories(moduleId).subscribe((rsp) => {
      if (rsp.statusCode === 0 && rsp.contents) {
        this.categoryList = rsp.contents;
        this.categoryId.reset();
        this.subCategoryId.reset();
        this.subCategoryId.setValue('');
        if (this.selectedProperty && this.selectedProperty.listingId) {
          let category: any = this.categoryList.filter((cat) => cat.categoryId === this.selectedProperty.categoryId);
          this.selectedCategory = category[0];
          this.subCatList = category[0].subCategory;
          this.selectedSubCategory = this.subCatList.filter((subcat) => subcat.subCategoryId === this.selectedProperty.subCategoryId)[0];
          this.selectedMeasurement = this.selectedSubCategory;
          this.categoryId.setValue(this.selectedProperty.categoryId);
          this.subCategoryId.setValue(this.selectedProperty.subCategoryId);
          if(this.checkReadOnly()) {
            this.disableForm();
          }
        } else {
          this.propertyForm.removeControl('basicAttributes');
          this.propertyForm.addControl('basicAttributes', new FormArray([]));
          this.firstLoad = false;
        }
      }
    });
  }

  getCountryList(): void {
    this.countryList = this.lookupModel.getCountryList();
    this.priceCurrency.disable();
    this.depositCurrency.disable();
  }

  getMeasurementList(): void {
    this.rsbService.getActiveMeasurements().subscribe((rsp) => {
      if (rsp.statusCode === 0 && rsp.contents) {
        this.measurementList = rsp.contents;
        if (!this.measurement.value) {
          this.measurement.setValue(this.measurementList[0].measurementId);
        }
      }
    });
    // this.measurement.disable();
  }

  prepareLocationText(propertyInfo: any): string {
    let locationText = '';
    if (propertyInfo.addressLine1) {
      locationText += propertyInfo.addressLine1 + ',';
    }

    if (propertyInfo.landmark) {
      locationText += propertyInfo.landmark + ',';
      const index = this.titleProperties.findIndex(item => item.name === 'LOCATION');
      this.titleProperties[index].value = propertyInfo.landmark + ', ';
    }

    if (propertyInfo.locality) {
      locationText += propertyInfo.locality + ',';
      const index = this.titleProperties.findIndex(item => item.name === 'LOCATION');
      this.titleProperties[index].value = this.titleProperties[index].value + propertyInfo.locality + ', ';
    }

    if (propertyInfo.city) {
      locationText += propertyInfo.city + ',';
      const index = this.titleProperties.findIndex(item => item.name === 'LOCATION');
      this.titleProperties[index].value = this.titleProperties[index].value + propertyInfo.city;
    }

    if (propertyInfo.countryName) {
      locationText += propertyInfo.countryName + ',';
    }

    if (propertyInfo.pincode) {
      locationText += propertyInfo.pincode;
    }
    return locationText;
  }

  formChanges(): void {
    this.propertyForm.valueChanges.subscribe((val) => {
      // console.log('Property Form Value changes :: ', val);
      this.emitFormChanges(true);
    });

    this.moduleId.valueChanges.subscribe((val) => {
      if (val) {
        this.selectedModule = this.moduleList.find(item => item.moduleId === val);
        this.getCategories(val);
        // this.changeModuleFields(val);
        this.resetForm();
      }
    });

    this.categoryId.valueChanges.subscribe((val) => {
      if (val) {
        // this.loadAttributes(val);
        let category: any = this.categoryList.filter((cat) => cat.categoryId === val);
        this.selectedCategory = category[0];
        this.subCatList = category[0].subCategory;
        this.selectedSubCategory = null;
        if(this.selectedProperty && this.selectedProperty.listingId && this.firstLoad && val === this.selectedProperty.categoryId) {
          this.firstLoad = false;
        } else {
          this.resetForm();
        }
      }
    });

    this.subCategoryId.valueChanges.subscribe((val) => {
      if (val) {
        this.loadAttributes(val);
        this.selectedSubCategory = this.subCatList.filter((subcat) => subcat.subCategoryId === val)[0];
        this.selectedMeasurement = this.selectedSubCategory;
        this.measurement.setValue(this.selectedMeasurement.measurementId);
        this.prepareSeoTitle(!(this.selectedProperty && this.selectedProperty.listingId));
      }
    });

    this.measurement.valueChanges.subscribe((val) => {
      if(val) {
        const item = this.measurementList.find(item => item.measurementId === val);
        if(item) {
          this.selectedMeasurement = item;
          this.prepareSeoTitle(!(this.selectedProperty && this.selectedProperty.listingId));
        } else {
          this.selectedMeasurement = null;
        }
      } else {
        this.selectedMeasurement = null;
      }
    });
  }

  // changeModuleFields(fillValue?: boolean): void {
  //   if(this.selectedModule.moduleStatus === 2) {
  //     const pricePerValue = this.selectedProperty.pricePer && fillValue ? this.selectedProperty.pricePer : '';
  //     const projectNameValue = this.selectedProperty.projectName && fillValue ? this.selectedProperty.projectName : '';
  //     this.propertyForm.addControl('pricePer', new FormControl(pricePerValue, Validators.required));
  //     this.propertyForm.addControl('projectName', new FormControl(projectNameValue, Validators.required));
  //   } else {
  //     this.propertyForm.removeControl('pricePer');
  //     this.propertyForm.removeControl('projectName');
  //   }
  // }

  loadAttributes(val: string): void {
    if (this.selectedProperty.listingId && this.selectedProperty.listingId !== 'abc' && this.selectedProperty.subCategoryId && this.selectedProperty.subCategoryId === val) {
      this.rsbService.getCategoriesForEdit(this.selectedProperty.listingId, this.categoryId.value, val, this.moduleId.value).subscribe((rsp) => {
        if (rsp.statusCode === 0) {
          const list = {
            basicAttributes: rsp.basicAttributes,
            categoryAttributes: rsp.additionalAttributes.concat(rsp.specialAttributes)
          };
        
          // this.mapAdditionalAttributes();

          if(this.firstLoad) {
            this.formChanges();
            this.firstLoad = false;
          }
        }
      });
    } else {
      this.rsbService.getAllPropertiesForCategories(this.categoryId.value, val, this.moduleId.value).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents) {
          const list = {
            basicAttributes: rsp.contents.basicAttributes,
            categoryAttributes: rsp.contents.categoryAttributes
          };
          if(this.selectedProperty.listingId === 'abc'){
            let basicAttr = this.lookupModel.getBasicValues();
            this.prepareCategoryProperties(list, basicAttr);
          } else {
            this.prepareCategoryProperties(list);
          }

        }
      });
    }
  }

  prepareCategoryProperties(list: any, basicList?: any[]): void {
    const basicProp = this.propertyForm.get('basicAttributes');
    if (basicProp) {
      this.propertyForm.removeControl('basicAttributes');
      this.propertyForm.addControl('basicAttributes', new FormArray([]));
    }
    console.log("basic attributesss is",list.basicAttributes);
    this.baiscAttrList = list.basicAttributes;
    if(basicList) {
      this.prepareBasicArray(this.baiscAttrList, basicList);
    } else {
      this.prepareBasicArray(this.baiscAttrList);
    }

    let ctgrList = list.categoryAttributes;
    if (ctgrList) {
      this.categoryAttrList = ctgrList.sort((a, b) => a.order - b.order);
    } else {
      this.categoryAttrList = [];
    }
    this.lookupModel.setPropertyCategoryList(this.categoryAttrList);
    if(this.checkReadOnly()) {
      this.basicAttributes.disable();
    }
  }

  // prepareBasicArray(basicAttrList: Array<any>, basicValueList?: any[]): void {
  //   const formProp = this.propertyForm.get('basicAttributes') as FormArray;
  //   if (basicAttrList) {
  //     basicAttrList.forEach(attr => {
  //       if(attr.value) {
  //         formProp.push(this.createBasicGroup(attr.attributeId, attr.value,attr.required));
  //       }
  //       else if (attr.attributeProperties && attr.attributeProperties.length) {
  //         let selectedProp;
  //         if(basicValueList && basicValueList.length > 0) {
  //           selectedProp = basicValueList.find(item => item.attributeId === attr.attributeId);
  //           selectedProp.propertyId = selectedProp.attrValue;
  //         } else {
  //           selectedProp = attr.attributeProperties?.find(prop => prop?.isSelected);
  //         }
  //         if (selectedProp) {
  //           formProp.push(this.createBasicGroup(attr.attributeId, selectedProp.propertyId,attr.required));
  //         } else {
  //           formProp.push(this.createBasicGroup(attr.attributeId,'',attr.required));
  //         }
  //       } else {
  //         formProp.push(this.createBasicGroup(attr.attributeId,'',attr.required));
  //       }
      
  //     });
  //   }
  // }

  // createBasicGroup(attributeId: string, value?: any,required?:any): FormGroup {
  //   let basicGroup: FormGroup;

  //   if (value) {
  //     if (required === 1) {
  //       basicGroup = this.fb.group({
  //         attributeId: attributeId,
  //         attrValue: [value, [Validators.required]]
  //       });
  //     } else {
  //       basicGroup = this.fb.group({
  //         attributeId: attributeId,
  //         attrValue: [value]
  //       });
  //     }
  //   } else {
  //     if (required === 1) {
  //       basicGroup = this.fb.group({
  //         attributeId: attributeId,
  //         attrValue: ['', [Validators.required]]
  //       });
  //     } else {
  //       basicGroup = this.fb.group({
  //         attributeId: attributeId,
  //         attrValue: ['']
  //       });
  //     }
  //   }
  //   return basicGroup;
  // }

  changeLocation(): void {
    this.showNextPage.emit(1);
  }

  changeMeasurment(): void {
    this.measurement.enable();
  }

  currencyChange(val: string): void {
    this.priceCurrency.setValue(val);
    this.depositCurrency.setValue(val);
    this.priceCurrency.disable();
    this.depositCurrency.disable();
  }

  changeCurrency(depositFlag?: boolean): void {
    if (depositFlag) {
      this.depositCurrency.enable();
    } else {
      this.priceCurrency.enable();
    }
  }

  uploadAws(event: any): void {
    const mdlRef = this.modalService.open(ImageCropperComponent, { windowClass: 'signInForm'});
    mdlRef.componentInstance.imageChangedEvent = event;
    mdlRef.result.then((result) => {
      if(result) {
        const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
        modalRef.componentInstance.file = result;
        modalRef.result.then((rsp) => {
          if (rsp) {
            this.propertyPreviewImage = rsp;
          }
        });
      }
    });
  }

  prepareSeoTitle(setTitle: boolean = true): string {
    let str = '';
    let propChangeFlag = false;
    this.titleProperties.forEach(prop => {
      switch (prop.name) {
        case 'BEDROOM': {
          const bedRoom = this.baiscAttrList?.find(item => item.attributeName.toUpperCase() === 'BHK');
          if (bedRoom) {
            const selectedBedRoom = this.basicAttributes.value.find(item => item.attributeId === bedRoom.attributeId);
            let bedRoomProp;
            if (selectedBedRoom) {
              bedRoomProp = bedRoom.attributeProperties.find(item => item.propertyId === selectedBedRoom.attrValue);
            }
            if (bedRoomProp && bedRoomProp !== prop.value) {
              prop.value = bedRoomProp.swatchName;
              propChangeFlag = true;
            } else {
              return;
            }
            str = prop.value + ' BHK ';
          }
          break;
        }
       // case 'AREA': {
          // if(this.selectedCategory.categoryTypeStatus === 3) {
         // if (this.propertySize.value) {
           // prop.value = this.propertySize.value + ' ' + this.selectedMeasurement.measurementName + ' ';
           // str += prop.value;
          //}
          // }
          //break;
        //}
        //case 'FURNISHED': {
          //const furnished = this.baiscAttrList.find(item => item.attributeName.toUpperCase() === 'FURNISHED');
          //if (furnished) {
            //const selectedFurnished = this.basicAttributes.value.find(item => item.attributeId === furnished.attributeId);
            //let furnishedProp;
            //if (selectedFurnished) {
             // furnishedProp = furnished.attributeProperties.find(item => item.propertyId === selectedFurnished.attrValue);
           // }
            //if (furnishedProp && furnishedProp !== prop.value) {
             // prop.value = furnishedProp.swatchName;
             // propChangeFlag = true;
            //} else {
              //return;
            //}
            //str = str + ' ' + prop.value;
          //}
          //break;
        //}
        case 'CATEGORY_TYPE': {
          if (this.selectedCategory.categoryTypeStatus === 3) {
            str += this.selectedCategory.categoryName;
          }
          break;
        }
        case 'PROPERTY_TYPE': {
          prop.value = this.selectedSubCategory ? this.selectedSubCategory.subCategoryName : '';
          if (prop.value) {
            str = str + ' ' + prop.value;
          }
          break;
        }
        case 'MODULE': {
          // prop.value = 'Rent';
          prop.value = this.selectedModule?.moduleHeading;
          if (prop.value) {
            str = str + ' for ' + prop.value + ' at';
          }
          break;
        }
        case 'LOCATION': {
          str = str + ' ' + prop.value;
          break;
        }
        default: {
          str = '';
          break;
        }
      }
    });
    // if(!this.propertyTitle.value || propChangeFlag) {
    // this.propertyTitle.setValue(str);
    // }
    if (setTitle) {
      this.propertyTitle.setValue(str);
    }
    return str;
  }

  submit(formvalue: any): void {
    console.log("form value is",formvalue.basicAttributes)
    if (this.propertyForm.invalid ) {
      return;
    }
    const propertyInfo = this.lookupModel.getVendorProperty();
    const mobile = this.mobile.value;
    const e164Number = mobile.e164Number;
    const mobileNumber = e164Number.replace(mobile.dialCode, ''); 
    const wmobile = this.whatsAppNumber.value;
    if (propertyInfo.status === 2 || propertyInfo.status === 3) {
      propertyInfo.status = 1;
    } else {
      propertyInfo.status = propertyInfo.status ? propertyInfo.status : 0;
    }
    propertyInfo.moduleId = formvalue.moduleId;
    propertyInfo.categoryId = formvalue.categoryId;
    propertyInfo.subCategoryId = formvalue.subCategoryId;
    propertyInfo.price = formvalue.price;
    // propertyInfo.deposit = formvalue.deposit;

    propertyInfo.propertySize = formvalue.propertySize;
    // propertyInfo.dateAvailable = dateUtil.convertFromPickerDate(formvalue.dateAvailable);
    propertyInfo.propertyTitle = formvalue.propertyTitle;
    propertyInfo.propertyDescription = formvalue.propertyDescription;
    propertyInfo.vendorDetails = {
      name: this.name.value,
      email:this.email.value,
      countryCode: parseInt(mobile.dialCode),
      mobileNumber: mobileNumber,
      twoDigitCountryCode: mobile.countryCode,
      wcountryCode: parseInt(wmobile.dialCode),
      wmobileNumber: wmobile

    }
    // propertyInfo.propertyPreviewImage = this.propertyPreviewImage;
    propertyInfo.basicAttributeId = formvalue.basicAttributes.map(attr => attr.attributeId);
    propertyInfo.basicAttributePropertyId = formvalue.basicAttributes.map(attr => attr.attrValue);
    propertyInfo.measurementId = this.measurement.value;
    propertyInfo.priceTypeId = this.priceCurrency.value;
    propertyInfo.seoTitle = this.prepareSeoTitle(true);
    if (this.selectedProperty.listingId && this.selectedProperty.listingId !== 'abc') {
      propertyInfo.listingId = this.selectedProperty.listingId;
    } else {
      propertyInfo.listingId = 'abc';
      this.lookupModel.setBasicValues(formvalue.basicAttributes);
    }

    // this.toletService.addUpdateListings(propertyInfo).subscribe((rsp) => {
    //   if (rsp.statusCode === 0) {
    //     if (rsp.contents.listingId) {
    //       propertyInfo.listingId = rsp.contents.listingId;

    //     }
    //     this.lookupModel.setLisStatus(rsp.contents.lisStatus);
    //     this.lookupModel.setVendorProperty(propertyInfo);
    //     this.showNextPage.emit(3);
    //   }
    // });

    this.lookupModel.setVendorProperty(propertyInfo);
    this.emitFormChanges(false);
    this.showNextPage.emit(3);
  }

  resetForm(): void {
    this.subCategoryId.reset();
    this.subCategoryId.setValue('');
    this.price.reset();
    this.deposit.reset();
    this.measurement.reset();
    this.propertySize.reset();
    this.propertyTitle.reset();
    this.propertyDescription.reset();
    // this.dateAvailable.reset();
    // this.propertyPreviewImage = '';
    this.propertyForm.removeControl('basicAttributes');
    // this.propertyForm.reset();
    this.propertyForm.addControl('basicAttributes', new FormArray([]));
  }


  emitFormChanges(val: boolean): void {
    if(this.isFormDirty !== val) {
      this.isFormDirty = val;
      this.formChange.emit(val);
    }
  }

  isvalidSubCategoryAttr(attributeId) {
    const ind = this.baiscAttrList.findIndex((prop) => prop.attributeId === attributeId && prop.required === 1);
    
    if (ind >= 0) {
      if (!this.baiscAttrList[ind].value) {
        console.log("att id is",attributeId);
        console.log("true is true")
        return false;

      } else {
        console.log("false is false")
        return true;
      }
    }

  }

  checkDateProperties(attributeId, date:NgbDateStruct) {

    const dateManager  = date.year +'-'+ date.month+'-'+ date.day;
    let ind = this.baiscAttrList.findIndex((obj) => {
      return obj.attributeId === attributeId
    })
    this.baiscAttrList[ind].value = dateManager;

  }

  back() {
    this.showNextPage.emit(1)
  }

  checkProperties(attributeId, event) {

    
    let ind = this.baiscAttrList.findIndex((obj) => {
      return obj.attributeId === attributeId
    })
    
    this.baiscAttrList[ind].value = event.target.value;


  }

  get moduleId() { return this.propertyForm.get('moduleId'); }
  get priceCurrency() { return this.propertyForm.get('priceCurrency'); }
  get depositCurrency() { return this.propertyForm.get('depositCurrency'); }
  get measurement() { return this.propertyForm.get('measurement'); }
  get categoryId() { return this.propertyForm.get('categoryId'); }
  get subCategoryId() { return this.propertyForm.get('subCategoryId'); }
  get price() { return this.propertyForm.get('price'); }
  get deposit() { return this.propertyForm.get('deposit'); }
  get propertySize() { return this.propertyForm.get('propertySize'); }
  get propertyTitle() { return this.propertyForm.get('propertyTitle'); }
  get propertyDescription() { return this.propertyForm.get('propertyDescription'); }
  get basicAttributes() { return this.propertyForm.get('basicAttributes'); }
  get categoryAttributes() { return this.propertyForm.get('categoryAttributes'); }
  // get dateAvailable() { return this.propertyForm.get('dateAvailable'); }
  get name() { return this.propertyForm.get('name') }
  get email() { return this.propertyForm.get('email') }
  get mobile() { return this.propertyForm.get('mobile') }
  get whatsAppNumber() { return this.propertyForm.get('whatsAppNumber') }
}

