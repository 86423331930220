import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-mb-support-and-help',
  templateUrl: './mb-support-and-help.component.html',
  styleUrls: ['./mb-support-and-help.component.css']
})
export class MbSupportAndHelpComponent implements OnInit {

  comment: string = ''; 
  getSupportData:any;
  statusForSubmitted:boolean=false;
  isMobileLayout:boolean=false;
  supportTabHeaders=['S.No', 'Ticket No', 'Ticket Date','Description','Ticket Updates', 'Status','Actions']
  supportData: any[] = [];
  statusForInProgress:boolean=false;
  statusForCancel:boolean=false;
  constructor(private rsbService: RsbService,private toastr: ToastrService) { }
  ngOnInit(): void {
    this.getSupport();

  }
  onCommentChange(event: Event): void {
    const inputElement = event.target as HTMLTextAreaElement;
    this.comment = inputElement.value; 
  }
  getSupport(): void {
    this.rsbService.getSupport().subscribe((res) => {
      if (res.statusCode === 0 && res.contents.length) {
        this.getSupportData = res.contents;
        this.supportData = res.contents.map(record => ({
          'S.No': '', // This can be populated based on index later
          'Ticket No': record.supportId,
          'Ticket Date': record.updatedAt,
          'Description': record.comment,
          'Ticket Updates': record.tktUpdates,
          'Status': this.getStatus(res.statusCode),
          'Actions': 'delete'
        }));
      }
    });
  }
  
  getStatus(statusCode: number): string {
    if (statusCode === 0) {
      return 'Submitted';
    } else if (statusCode === 1) {
      return 'In Progress';
    } else {
      return 'Cancel';
    }
  }
  
  

  submit() {
    console.log(this.comment); 
    if (this.comment) {
      this.rsbService.queryForSupport(this.comment).subscribe((rsp) => {
        if(rsp.statusCode===0){
          this.toastr.success('submitted successfully.');

          this.getSupport();
          this.statusForSubmitted=true;

        }

        });
    } 
  }
}


