import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  fileRequest;
  constructor() { }

  uploadFile(file, bucketName: string): Observable<any> {
    let fileUpload = new Observable((observer) => {
      const contentType = file.type;
      const bucket = new S3(
        {
          accessKeyId: 'AKIAV2TWAWZYHGR6BLVR',
          secretAccessKey: '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',
          region:'ap-south-1'
        }
      );
      const params = {
        Bucket: bucketName,
        Key: 'pb' + '-' + Math.floor((Math.random() * 1000)) + Date.now(),
        Body: file,
        ACL: 'public-read',
        ContentType: contentType,
        
      };

      this.fileRequest = bucket.upload(params).on('httpUploadProgress', function (evt) {
        observer.next({ type: 0, value: evt });
      });

      this.fileRequest.send(function (err, data) {
        if (err) {
          observer.error({ type: 2, value: err });
        } else {
          observer.next({ type: 1, value: data.Key });
        }
      });
    })
    return fileUpload;
  }

  haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    const toRadians = (degree: number) => (degree * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in km
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }

  cancelUpload(): void {
    setTimeout(this.fileRequest.abort.bind(this.fileRequest), 1000);
  }
}
