<section>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="my-4 p-5 bg-light rounded">
            <h3><b>Abou Us :</b></h3>

<h4>Pillar Blocks - ONE INDUSTRY, ONE VENUE</h4>
<li>With a Prime focus on the Indian Real Estate & Proptech market, we connect Landlords, Asset Managers, Property Agents, Channel Partners, Brokerages, Construction Companies, Builders, Developers, Property buyers & investors, Bankers & NBFCS All together – one place, one time!</li>
<li>Proptech Marketplace is where an entire industry joins together to discover, experience, collaborate and learn how innovation and technology are transforming the built world. It covers residential, commercial, retail, hospitality and industrial. It covers investment, construction, data, logistics and customer engagement. And it covers it all in an environment designed to maximise your efficiency and effectiveness.</li>
<li>Our aim is to provide a single place, on a single day, that accelerates the acquisition of new real estate technology for existing or new property businesses. The Proptech Marketplace format is stripped-back for optimum effectiveness, with technology providers hosting property professionals in their own branded meeting area, for face-to-face meetings and relationship building.</li>

          </div>
        </div>
      </div>
    </div>
  </section>
