  
  <div class="table-responsive" *ngIf="!isMobileLayout">
    <table class="table table-hover agent-table">
    <thead class="">
        <tr>
            <th *ngFor="let header of headers">{{ header }}</th>
            <th>Status</th>
          </tr>
    </thead>
    <tbody>
        
        <tr *ngFor="let row of data">
            <td *ngFor="let header of headers">{{ row[header] }}</td>
            <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Submitted" *ngIf="row" readonly></td>
           
          </tr>
    
    </tbody>
    </table>
</div>
  
<div class="table-head-fixed" *ngIf="isMobileLayout">
    <table class="table table-striped">
        <thead class="table-dark">
            <tr>
                <th *ngFor="let header of headers">{{ header }}</th>
              </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of data">
                <td *ngFor="let header of headers">{{ row[header] }}</td>
                
              </tr>
        </tbody>
    </table>
</div>