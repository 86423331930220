import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {
  isSaleForm:boolean=false;
  isMobileLayout:boolean=false;
  filePath = [];
  saleForm:FormGroup;

  awsFile: FileList;
  file1Size: string;
  file2Size: string;
  file3Size: string;
  file1Name:string;
  file2Name:string;
  file3Name:string;

  
saleRecords: Array<any> = [];


// saleRecords=[{
//   name:"Sairam",mobile:9505148602,email:"narendranadimpalli11@gmail.com",dateAdded:"16-09-2024",dateApproved:"16-09-2024",city:"Hyderabad",
//   remarks:"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore .."
// },
// {
//   name:"Narendra",mobile:9505148602,email:"narendranadimpalli11@gmail.com",dateAdded:"16-09-2024",dateApproved:"16-09-2024",city:"Hyderabad",
//   remarks:"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore .."
// }]


  constructor(private fb:FormBuilder,
    private rsbService:RsbService,
    private modalService: NgbModal,
    private lookupModel:RsbLookupModel

  ) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.saleForm=this.fb.group({
      name:['',Validators.required],
      mobile:['',Validators.required],
      email:['',Validators.required],
      dateAdded:['',Validators.required],
      dateApproved:['',Validators.required],
      city:['',Validators.required],
      remarks:['',Validators.required],
     


    })
    this.getSales();
  }
  getSales():void{
    this.rsbService.getSales().subscribe((rsp)=>{
      this.saleRecords=rsp.contents;
      this.lookupModel.setSaleId("sd12d");
    })
  }
  uploadFile(event: any) {
    const file = event.target.files[0];
    if (file) {
      
      console.log('Selected file:', file);
      // Handle file upload logic here
    }
  }
  // onFileSelected(event: Event, fileInput: string): void {
  //   const input = event.target as HTMLInputElement;
  //   if (input.files && input.files.length > 0) {
  //     const file = input.files[0];
  //     if (fileInput === 'file1') {
  //       this.file1Name = file.name;
  //     } else if (fileInput === 'file2') {
  //       this.file2Name = file.name;
  //     } else if (fileInput === 'file3') {
  //       this.file3Name = file.name;
  //     }
  //   }
  // }
  uploadAws(event: any, fileInput: string): void {
    const input = event.target as HTMLInputElement;
    this.awsFile = event.target.files;
    const file = this.awsFile.item(0);
  
    const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
    modalRef.componentInstance.file = file;
  
    modalRef.result.then((path: string) => {
      if (path && input.files && input.files.length > 0) {
        this.filePath = [path];
        const file = input.files[0];
        const fileSizeInKB = file.size / 1024;
        const fileSizeInMB = fileSizeInKB / 1024;
  
        let fileSize = '';
        if (fileSizeInMB >= 1) {
          fileSize = `${fileSizeInMB.toFixed(2)} MB`;  // Convert to MB if size >= 1 MB
        } else {
          fileSize = `${fileSizeInKB.toFixed(2)} KB`;  // Otherwise, convert to KB
        }
  
        if (fileInput === 'file1') {
          this.file1Name = file.name;
          this.file1Size = fileSize;
        } else if (fileInput === 'file2') {
          this.file2Name = file.name;
          this.file2Size = fileSize;
        } else if (fileInput === 'file3') {
          this.file3Name = file.name;
          this.file3Size = fileSize;
        }
      }
    });
  }
  selectedSaleRec(saleRecord: any = null): void {
    this.isSaleForm = !this.isSaleForm;

    if (saleRecord) {
      this.saleForm.patchValue({
        name: saleRecord.name,
        mobile: saleRecord.mobile,
        email: saleRecord.email,
        dateAdded: saleRecord.dateAdded,
        dateApproved: saleRecord.dateApproved,
        city: saleRecord.city,
        remarks: saleRecord.remarks
      });
    } else {
      this.saleForm.reset(); 
    }

  }
  
  submit(): void{
    const args={
      name:this.name.value,
      mobile:this.mobile.value,
      email:this.email.value,
      dateAdded:this.dateAdded.value,
      dateApproved:this.dateApproved.value,
      city:this.city.value,
      remarks:this.remarks.value,
      filePath:this.filePath[0],

      


    }
    this.rsbService.updateAttachments(args).subscribe((rsp)=>{})

  }
  get name(){return this.saleForm.get('name')}
  get mobile(){return this.saleForm.get('mobile')}
  get email(){return this.saleForm.get('email')}
  get dateAdded(){return this.saleForm.get('dateAdded')}
  get dateApproved(){return this.saleForm.get('dateApproved')}
  get city(){return this.saleForm.get('city')}
  get remarks(){return this.saleForm.get('remarks')}




}
