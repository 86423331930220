<div class="mb-dashboard">
    <div class="container">
      
        <div id="mb_products" class="row">
            <div class="mb_item col-6 col-lg-4 mb_list_group_item">
                <div class="card">
                    <div class="thumbnail ">
                        <div class="img-event">
                            <img class="group list-group-image img-fluid" src="assets/img/property_search_image1.png" alt="" />
                        </div>
                        <div class="caption card-body">
                            <div class="mb_capHeader d-flex justify-content-between">
                                <div class="mb_capPrice">
                                    <h2><span>&#8377;</span> 50,000</h2>
                                </div>
                                <div class="mb_share">
                                    <ul class="list-inline">
                                        <li class="list-inline-item"><i class="ri-share-forward-line share"></i></li>
                                        <li class="list-inline-item"><i class="ri-heart-line fav"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <h4 class="group card-title inner list-group-item-heading">Gupta Heights</h4>
                            <p class="mb_captionDes mb-0"><small> 2 BHK Independent House for Rent at OAKLAND MIRACLES
                                    The School, Bachupally, Hyderabad </small></p>
                        </div>
                    </div> <!--end thumbnail div-->
                    <ul class="list-inline mb_nearby">
                        <li class="list-inline-item">
                            <p>
                                <img src="assets/img/location.svg" alt="">
                                <small>nearby places:</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p>
                                <img src="assets/img/Food.svg" alt="">
                                <small>Kotyard Food..(1.3 km away)</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb_noBg">More...</p>
                        </li>
                    </ul>
                    <ul class="mb_prop_details list-inline">
                        <li class="list-inline-item">
                            <p class="mb-0">Sizes (SQ.FT)</p>
                            <h6>1590 - 2088 sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Average Price</p>
                            <h6>₹6,000/sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Possession Date</p>
                            <h6>Aug,2026</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Current Status</p>
                            <h6>Under Construction</h6>
                        </li>
                    </ul>
                    <!-- places popup -->
                    <div class="mb_popup">
                        <div class="popup-content">
                            <span id="closePopup" class="close-btn">&times;</span>
                            <h4>Project Near by</h4>
                            <ul class="list-inline mb_amenities">
                                <li>
                                    <img src="assets/img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                                <li>
                                    <img src="assets/img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                            </ul>
                            <h4 class="mt-4 mb_sft_details">Project Details</h4>
                            <ul class="mb_sft_details list-inline">
                                <li>
                                    <p class="mb-0">Sizes (SQ.FT)</p>
                                    <h6>1590 - 2088 sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Average Price</p>
                                    <h6>₹6,000/sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Possession Date</p>
                                    <h6>Aug,2026</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Current Status</p>
                                    <h6>Under Construction</h6>
                                </li>
                            </ul>
                            <div class="mb_projectDetail">
                                <h4>About Project</h4>
                                <p>Luxury 2 bhk multistorey apartment is available at a prime location in Bachupally .
                                    It has an area of 1100 sqft . The property is available FOR RENT. It has street
                                    lighting as well as rain water harvesting</p>
                            </div>
                        </div>
                    </div>
                    <!-- bottom buttons -->
                    <div class="mb_btmbuttons">
                        <button class="btn btn-primary mb_deal">Get Deal</button>
                        <button class="btn btn-primary mb_site">Request Site Visit</button>
                        <button class="btn btn-primary mb_emi">2 Months EMI <span>Free</span></button>
                    </div>
                </div> <!--end card-->
            </div> <!--end mb_item-->
            <div class="mb_item col-6 col-lg-4 mb_list_group_item">
                <div class="card">
                    <div class="thumbnail ">
                        <div class="img-event">
                            <img class="group list-group-image img-fluid" src="assets/img/property_search_image1.png" alt="" />
                        </div>
                        <div class="caption card-body">
                            <div class="mb_capHeader d-flex justify-content-between">
                                <div class="mb_capPrice">
                                    <h2><span>&#8377;</span> 50,000</h2>
                                </div>
                                <div class="mb_share">
                                    <ul class="list-inline">
                                        <li class="list-inline-item"><i class="ri-share-forward-line share"></i></li>
                                        <li class="list-inline-item"><i class="ri-heart-line fav"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <h4 class="group card-title inner list-group-item-heading">Gupta Heights</h4>
                            <p class="mb_captionDes mb-0"><small> 2 BHK Independent House for Rent at OAKLAND MIRACLES
                                    The School, Bachupally, Hyderabad </small></p>
                        </div>
                    </div> <!--end thumbnail div-->
                    <ul class="list-inline mb_nearby">
                        <li class="list-inline-item">
                            <p>
                                <img src="assets/img/location.svg" alt="">
                                <small>nearby places:</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p>
                                <img src="assets/img/Food.svg" alt="">
                                <small>Kotyard Food..(1.3 km away)</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb_noBg">More...</p>
                        </li>
                    </ul>
                    <ul class="mb_prop_details list-inline">
                        <li class="list-inline-item">
                            <p class="mb-0">Sizes (SQ.FT)</p>
                            <h6>1590 - 2088 sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Average Price</p>
                            <h6>₹6,000/sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Possession Date</p>
                            <h6>Aug,2026</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Current Status</p>
                            <h6>Under Construction</h6>
                        </li>
                    </ul>
                    <!-- places popup -->
                    <div class="mb_popup">
                        <div class="popup-content">
                            <span id="closePopup" class="close-btn">&times;</span>
                            <h4>Project Near by</h4>
                            <ul class="list-inline mb_amenities">
                                <li>
                                    <img src="assets/img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                                <li>
                                    <img src="assets/img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                            </ul>
                            <h4 class="mt-4 mb_sft_details">Project Details</h4>
                            <ul class="mb_sft_details list-inline">
                                <li>
                                    <p class="mb-0">Sizes (SQ.FT)</p>
                                    <h6>1590 - 2088 sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Average Price</p>
                                    <h6>₹6,000/sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Possession Date</p>
                                    <h6>Aug,2026</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Current Status</p>
                                    <h6>Under Construction</h6>
                                </li>
                            </ul>
                            <div class="mb_projectDetail">
                                <h4>About Project</h4>
                                <p>Luxury 2 bhk multistorey apartment is available at a prime location in Bachupally .
                                    It has an area of 1100 sqft . The property is available FOR RENT. It has street
                                    lighting as well as rain water harvesting</p>
                            </div>
                        </div>
                    </div>
                    <!-- bottom buttons -->
                    <div class="mb_btmbuttons">
                        <button class="btn btn-primary mb_deal">Get Deal</button>
                        <button class="btn btn-primary mb_site">Request Site Visit</button>
                        <button class="btn btn-primary mb_emi">2 Months EMI <span>Free</span></button>
                    </div>
                </div> <!--end card-->
            </div> <!--end mb_item-->
            <div class="mb_item col-6 col-lg-4 mb_list_group_item">
                <div class="card">
                    <div class="thumbnail ">
                        <div class="img-event">
                            <img class="group list-group-image img-fluid" src="assets/img/property_search_image1.png" alt="" />
                        </div>
                        <div class="caption card-body">
                            <div class="mb_capHeader d-flex justify-content-between">
                                <div class="mb_capPrice">
                                    <h2><span>&#8377;</span> 50,000</h2>
                                </div>
                                <div class="mb_share">
                                    <ul class="list-inline">
                                        <li class="list-inline-item"><i class="ri-share-forward-line share"></i></li>
                                        <li class="list-inline-item"><i class="ri-heart-line fav"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <h4 class="group card-title inner list-group-item-heading">Gupta Heights</h4>
                            <p class="mb_captionDes mb-0"><small> 2 BHK Independent House for Rent at OAKLAND MIRACLES
                                    The School, Bachupally, Hyderabad </small></p>
                        </div>
                    </div> <!--end thumbnail div-->
                    <ul class="list-inline mb_nearby">
                        <li class="list-inline-item">
                            <p>
                                <img src="assets/img/location.svg" alt="">
                                <small>nearby places:</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p>
                                <img src="assets/img/Food.svg" alt="">
                                <small>Kotyard Food..(1.3 km away)</small>
                            </p>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb_noBg">More...</p>
                        </li>
                    </ul>
                    <ul class="mb_prop_details list-inline">
                        <li class="list-inline-item">
                            <p class="mb-0">Sizes (SQ.FT)</p>
                            <h6>1590 - 2088 sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Average Price</p>
                            <h6>₹6,000/sq.ft</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Possession Date</p>
                            <h6>Aug,2026</h6>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0">Current Status</p>
                            <h6>Under Construction</h6>
                        </li>
                    </ul>
                    <!-- places popup -->
                    <div class="mb_popup">
                        <div class="popup-content">
                            <span id="closePopup" class="close-btn">&times;</span>
                            <h4>Project Near by</h4>
                            <ul class="list-inline mb_amenities">
                                <li>
                                    <img src="assets/img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                                <li>
                                    <img src="assets/img/Food.svg" alt="">
                                    <p> Kotyard Food..(1.3 km away)</p>
                                </li>
                            </ul>
                            <h4 class="mt-4 mb_sft_details">Project Details</h4>
                            <ul class="mb_sft_details list-inline">
                                <li>
                                    <p class="mb-0">Sizes (SQ.FT)</p>
                                    <h6>1590 - 2088 sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Average Price</p>
                                    <h6>₹6,000/sq.ft</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Possession Date</p>
                                    <h6>Aug,2026</h6>
                                </li>
                                <li>
                                    <p class="mb-0">Current Status</p>
                                    <h6>Under Construction</h6>
                                </li>
                            </ul>
                            <div class="mb_projectDetail">
                                <h4>About Project</h4>
                                <p>Luxury 2 bhk multistorey apartment is available at a prime location in Bachupally .
                                    It has an area of 1100 sqft . The property is available FOR RENT. It has street
                                    lighting as well as rain water harvesting</p>
                            </div>
                        </div>
                    </div>
                    <!-- bottom buttons -->
                    <div class="mb_btmbuttons">
                        <button class="btn btn-primary mb_deal">Get Deal</button>
                        <button class="btn btn-primary mb_site">Request Site Visit</button>
                        <button class="btn btn-primary mb_emi">2 Months EMI <span>Free</span></button>
                    </div>
                </div> <!--end card-->
            </div> <!--end mb_item-->
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 pb-4 text-center">
                <small class="copy-right-text">All trademarks, logos and names are properties of their respective
                    owners. Copyright © 2024 - All rights reserved.</small>
            </div>
        </div>

    </div>

    <footer>
        <nav class="navbar fixed-bottom bg-secondary">
            <div class="container-fluid">
                <ul class="nav justify-content-center">
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Terms & Conditions </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Policy</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Home</a>
                    </li>
                </ul>
            </div>
        </nav>
    </footer>
</div>