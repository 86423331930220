<div class="container-fluid" *ngIf="!isMobileLayout">
    <div class="pb-wrapper bg-white p-4">
        <h2 class="d-inline-block mr-3 mb-4">Support & Help</h2>
        <form class="agent-profile">
            <div class="form-row mb-2">
              
               
            </div>
                <div class="form-row mb-4">
                    <div class="col-md-6">
                        <textarea class="form-control" [value]="comment" (input)="onCommentChange($event)" rows="5" id="comment" placeholder="Write us your issue & problems, we will update you on the same (Max 2000 Words)"></textarea>
                    </div>
                </div>
                <button class="btn btn-primary pb-save" type="submit" (click)="submit()">Submit</button>
                
            <!-- <button class="btn  pb-addproject ml-3"><i class="ri-add-line"></i> create another project finance</button> -->
        </form>
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">Tickets </h3>
                                </form>
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover agent-table">
                                <thead class="">
                                    <tr>
                                        <th><input type="checkbox"></th>
                                        <th>S.No</th>
                                        <th>Ticket No</th>
                                        <th>Ticket Date</th>
                                        <th>Description</th>
                                        <th>Ticket Updates</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let support of getSupportData">
                                        <td><input type="checkbox"></td>
                                        <td>1</td>
                                        <td>{{support.supportId}}</td>
                                        <td>{{support.updatedAt}}</td>
                                        <td>{{support.comment}}</td>
                                        <td>{{support.tktUpdates || 'N/A'}}</td>  
                                        <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Submitted" *ngIf="statusForSubmitted" readonly></td>
                                        <td><input type="text" style="text-align: center;width:100px"  class="text text-warning"  value="In Progress" *ngIf="statusForInProgress" readonly></td>
                                        <td><input type="text" style="text-align: center;width:100px"  class="text text-danger"  value="Cancel" *ngIf="statusForCancel" readonly></td>

                                        <td class="text-center"><a href="">View</a></td>
                                    </tr>
                                 
                                  
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Row -->
    

</div>
<app-mb-support-and-help *ngIf="isMobileLayout"></app-mb-support-and-help>