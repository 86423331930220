import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, name: string): unknown {
  if(name === ""){
    return value;
  }
  return value.filter((x:any)=>x.name.toLowerCase().startsWith(name.toLowerCase()))
  }

}
