import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RsbService } from 'src/app/service/rsb.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { MessageService, BUS_EVENTS } from 'src/app/common/events/message.service';
import { Message } from 'src/app/common/events/message';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotpasswordComponent } from 'src/app/shared/components/forgotpassword/forgotpassword.component';
import { PatternValidatorsService } from 'src/app/common/services/pattern-validators.service';
// import { ForgotpasswordPopupComponent } from '../forgotpassword-popup/forgotpassword-popup.component';
@Component({
  selector: 'app-signup-popup',
  templateUrl: './signup-popup.component.html',
  styleUrls: ['./signup-popup.component.css']
})
export class SignupPopupComponent implements OnInit {
  @Input() listingId : string;
  @Input() hostelId : string;
  @Input() ProjectId:string;
  @Output() responseEventEmmiter: EventEmitter<any> = new EventEmitter();



  @Input() successLink: string;
  signupForm: FormGroup;
  loginForm: FormGroup;
  success_message: string;
  error_message: string;

  mobileOtpCheck: boolean = false;
  mobileOtpError: boolean = false;
  mobileotpVerified: boolean = false;
  mobileExists: boolean = false;
  mobileOtpSent: boolean = false;
  mobileOtpResent: boolean = false;
  mobileOtpMaxFlag: boolean = false;
  loginBlocked: boolean = false;
  incorrectOtp: boolean = false;
  loginFailed: boolean = false;
  mobileOtpVerified: boolean = false;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  onlyCountries = [];
  loadView: boolean = false;
  selectedISOCountry;
  signup: boolean = false;
  passwordError:boolean = false;
  hidePassword: boolean = true;
  loginPopUpDiv:boolean=true;
  isMobileLayout:boolean=false;

  
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private rsbService: RsbService,
    private lookupModel: RsbLookupModel,
    private messageService: MessageService,
    private toastrService: ToastrService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.loginForm = this.fb.group({
      loginMobile: ['', [Validators.required]],
      loginPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]]
    });

    this.signupForm = this.fb.group({
      mobileOtp: ['', [Validators.required]],
      password: ['', [
        Validators.required,
        PatternValidatorsService.patternValidators({
          hasNumber: true,
          hasCapitalCase: true,
          hasSmallCase: true,
          hasSpecialCharacters: true,
          hasLength:true
        }),
      ]],      mobile: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      firstName:['',[Validators.required]]
    });


    const localCountrys = this.lookupModel.getCountryList();
    this.onlyCountries = [];
    localCountrys.forEach(country => {
      let isoCountry = this.findCountryIso(country.twoDigitCountryCode);
      if (isoCountry) {
        this.onlyCountries.push(isoCountry);
      }
    });
    const country = this.lookupModel.getCountry();
    if (country) {
      let isoCountry = this.findCountryIso(country.twoDigitCountryCode);
      if (isoCountry) {
        this.selectedISOCountry = isoCountry;
      }
    }
  }

  findCountryIso(countryCode: string): string {
    let isoKey = Object.keys(CountryISO).find(item => CountryISO[item] === countryCode.toLowerCase());
    if (isoKey) {
      return CountryISO[isoKey];
    } else {
      return '';
    }
  }

  sign(): void {
    this.signup = true;
  }

  loginPanel(): void {
    this.signup = false;
  }
  // forgotpwd(): void {

  //   this.modalService.open(ForgotpasswordComponent);
  //   this.close();



  // }

  forgotPwd(): void {

    this.router.navigate(["forgotpassword"])



  }

  onlyNumbersAllowed(event): boolean {
    const charCode = event.which || event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode === 32) {
      return true;
    }
    return false;

  }
  changeMobile(): void {
    this.mobileExists = false;
    this.mobileOtpSent = false;
    this.mobileOtpMaxFlag = false;
    this.mobileotpVerified = false;
    this.error_message = '';
    this.success_message = '';
    this.incorrectOtp = false;
    this.mobile.enable();
  }

  sendOtp(mobile: any): void {
    this.mobileotpVerified = false;
    this.mobileOtpSent = false;
    this.mobileOtpMaxFlag = false;
    this.success_message = '';
    this.error_message = '';
    this.incorrectOtp = false;

    const e164Number = mobile.e164Number;
    const mobileNumber = e164Number.replace(mobile.dialCode, '');
    this.rsbService.sendMobileOtp(mobileNumber, parseInt(mobile.dialCode).toString()).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        this.mobile.disable();
        this.mobileOtpSent = true;
      } else if (rsp.statusCode === 1) {
        this.mobileOtpMaxFlag = true;
      } else if (rsp.statusCode === 3) {
        this.mobileExists = true;
      }
      else if (rsp.statusCode === 2) {
        this.mobileOtpMaxFlag = true;
      }
      else {
        this.mobileOtpError = true;
      }
    }, (err) => {
      this.mobileOtpSent = false;
    });
  }

  resendOtp(mobile: any): void {
    this.mobileotpVerified = false;
    this.mobileOtpResent = false;
    this.mobileOtpMaxFlag = false;
    this.success_message = '';
    this.error_message = '';
    this.incorrectOtp = false;

    const e164Number = mobile.e164Number;
    const mobileNumber = e164Number.replace(mobile.dialCode, '');
    this.rsbService.resendMobileOtp(mobileNumber, parseInt(mobile.dialCode).toString()).subscribe((rsp) => {
      if (rsp.statusCode === 1) {
        this.loginBlocked = true;
        this.mobileOtpSent = false;
        this.mobileOtpResent = false;
        this.error_message = 'Login has been blocked by due to Maximum OTP send Limit per day is completed, please try after 24 hours'
      } else if (rsp.statusCode === 2) {
        this.mobileOtpMaxFlag = true;
        this.mobileOtpSent = true;
        this.mobileOtpResent = true;
        this.error_message = 'Mobile reached maximum otp limit.please try after 24 hours';
      } else {
        this.mobileOtpSent = true;
        this.mobileOtpResent = true;
        this.success_message = 'Re-sent otp successfully.';
      }
    }, (err) => {
      this.mobileOtpSent = false;
      this.mobileOtpResent = false;
    });
  }

  validatePassword(password) {
    const val = password.target.value;
    if(val.length < 6 || val.length > 10) {
      this.passwordError = true
    } else {
      this.passwordError = false;
    }
  }

  login(): void {
    this.clearEmailStatus();
    //alert("getting into login");
    const logMobile = this.loginMobile.value;
    const e164Number = logMobile.e164Number;
    const logMobileNumber = e164Number.replace(logMobile.dialCode, '');
    this.rsbService.customerLogin(parseInt(logMobile.dialCode), logMobileNumber, this.loginPassword.value).subscribe((rsp) => {
      this.loginFailed = false;

      if (rsp.statusCode === 0) {
        if (this.successLink) {
          //alert("get")
          this.redirect();
        } else {
         // alert("coming into status code 0")
          //alert("active status is" + rsp.contents[0].activeStatus)
          if (rsp.contents[0].activeStatus === 1) {
            this.close(rsp);

          }
          else {
            //  console.log("ciming into status code else");
            this.lookupModel.setProfileDisplayType(0);
           // alert("getting here")
            if (this.lookupModel.getRedirectRoute() === "contactOwner") {
             // alert("getting into this loop");
              this.close(rsp);
            } else {
              this.router.navigate(['completeRegistration'])

            }
            this.close(rsp);
          }
        }
      }
      else {
        this.loginFailed = true;
        this.toastrService.error("Invalid mobile or password")
        setTimeout(() => {
          this.loginFailed = false
        }, 5000)
        //this.close();
      }
      //  ("going here");
    });
  }
  forgotPassword(){
    this.loginPopUpDiv=false;
    this.close();
    // this.modalService.open(ForgotpasswordPopupComponent, { size: 'lg', });
  
  }

  clearEmailStatus(): void {
    this.mobileOtpSent = false;
    this.mobileOtpError = false;
    this.mobileExists = false;
    this.mobileOtpVerified = false;
    this.mobileOtpResent = false;
    this.mobileOtpMaxFlag = false;
    this.loginFailed = false;
  }


  register(): void {

    // alert("getting")
    this.clearEmailStatus()
    this.mobileOtpVerified = false;
    const mobile = this.mobile.value;
    const e164Number = mobile.e164Number;
    const mobileNumber = e164Number.replace(mobile.dialCode, '');
    this.rsbService.associateCustomerVerifyMobileOtp(this.mobileOtp.value, this.password.value,
      parseInt(mobile.dialCode),
      mobileNumber,
      mobile.countryCode,this.firstName.value,this.email.value).subscribe((rsp) => {
        this.mobileOtpCheck = true;
        if (rsp.statusCode === 0) {
          this.lookupModel.setProfileDisplayType(0);
          this.mobileOtpVerified = true;
          this.lookupModel.setMobileType(1);
          // alert("rsp os"+JSON.stringify(rsp));
          this.close(rsp)
        } else {
          this.mobileOtpVerified = false;
        }
      }, (err) => {
        this.mobileOtpSent = false;
      });
    
    // this.registerContactSellarsInProjectlist();
    // this.registerForGetDealInPglist();
   
  }


  // registerContactSellarsInProjectlist(){
  //   if(this.projectId){
  //     // alert("getting")
  //     this.clearEmailStatus()
  //     this.mobileOtpVerified = false;
  //     const mobile = this.mobile.value;
  //     const e164Number = mobile.e164Number;
  //     const mobileNumber = e164Number.replace(mobile.dialCode, '');
  //     this.rsbService.associateCustomerVerifyMobileOtp(this.mobileOtp.value, this.password.value,
  //       parseInt(mobile.dialCode),
  //       mobileNumber,
  //       mobile.countryCode,this.firstName.value,this.email.value).subscribe((rsp) => {
  //         this.mobileOtpCheck = true;
  //         if (rsp.statusCode === 0) {
  //           this.responseEventEmmiter.emit(rsp); 
  //           this.lookupModel.setProfileDisplayType(0);
  //           this.mobileOtpVerified = true;
  //           this.lookupModel.setMobileType(1);
  //           if (this.lookupModel.getRedirectRoute() === "profile") {
  //             this.close(rsp);
  //           } else {
  //             this.redirect();
  //             this.close();
  //           }
  //         } else {
  //           this.mobileOtpVerified = false;
  //         }
  //       }, (err) => {
  //         this.mobileOtpSent = false;
  //       });
  //     }

  // }
  // registerForGetDealInPglist(){
  //   if(this.hostelId){
  //     // alert("getting")
  //     this.clearEmailStatus()
  //     this.mobileOtpVerified = false;
  //     const mobile = this.mobile.value;
  //     const e164Number = mobile.e164Number;
  //     const mobileNumber = e164Number.replace(mobile.dialCode, '');
  //     this.rsbService.associateCustomerVerifyMobileOtp(this.mobileOtp.value, this.password.value,
  //       parseInt(mobile.dialCode),
  //       mobileNumber,
  //       mobile.countryCode,this.firstName.value,this.email.value).subscribe((rsp) => {
  //         this.mobileOtpCheck = true;
  //         if (rsp.statusCode === 0) {
  //           this.lookupModel.setProfileDisplayType(0);
  //           this.mobileOtpVerified = true;
  //           this.lookupModel.setMobileType(1);
  //           if (this.lookupModel.getRedirectRoute() === "profile") {
  //             this.close(rsp);
  //           } else {
  //             this.close();
  //           }
  //         } else {
  //           this.mobileOtpVerified = false;
  //         }
  //       }, (err) => {
  //         this.mobileOtpSent = false;
  //       });
  //     }

  // }

  redirect(): void {
   // alert(this.successLink);
    if (this.successLink) {
      if (this.successLink === "postProperty") {
        this.lookupModel.setRedirectRoute("postProperty");
        //alert(this.lookupModel.getLoggedUser().activeStatus)
        if (this.lookupModel.getLoggedUser().activeStatus === 1) {
          window.open(this.lookupModel.getLoggedUser().redirectUrl + '/home', '_self');
        } else {

          this.router.navigate(['profile']);
          this.close();
        }
      }
      else {
        this.lookupModel.setRedirectRoute("postRequirement");
        if (this.lookupModel.getLoggedUser().activeStatus === 1) {
          window.open(this.successLink, '_self');
        } else {

          this.router.navigate(['profile']);
          this.close();
        }
      }
    } else if (this.successLink === 'contactOwner') {

      this.close();
    }
    else {

      this.lookupModel.setRedirectRoute("login");
      if (this.lookupModel.getLoggedUser().activeStatus === 0) {
        this.router.navigate(['profile']);
        this.close();
      } else {
        this.router.navigate(['dashboard']);
        this.close();
      }

    }
  }
  togglePassword(): void {
    this.hidePassword = !this.hidePassword;
  }

  close(rsn?: any): void {
    // alert("getting rsn in close"+JSON.stringify(rsn))
    // alert(rsn)


   this.activeModal.close(rsn);
    
  }

  get mobileOtp() { return this.signupForm.get('mobileOtp'); }
  get mobile() { return this.signupForm.get('mobile'); }
  get password() { return this.signupForm.get('password'); }
  get firstName() { return this.signupForm.get('firstName'); }
  get email() { return this.signupForm.get('email'); }
  get loginMobile() { return this.loginForm.get('loginMobile'); }
  get loginPassword() { return this.loginForm.get('loginPassword'); }

}
