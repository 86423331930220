import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-mb-sale',
  templateUrl: './mb-sale.component.html',
  styleUrls: ['./mb-sale.component.css']
})
export class MbSaleComponent implements OnInit {
  isSaleForm:boolean=false;

saleRecords=[{
  name:"Sairam",mobile:9505148602,email:"narendranadimpalli11@gmail.com",dateAdded:"16-09-2024",dateApproved:"16-09-2024",city:"Hyderabad",
  remarks:"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore .."
},
{
  name:"Narendra",mobile:9505148602,email:"narendranadimpalli11@gmail.com",dateAdded:"16-09-2024",dateApproved:"16-09-2024",city:"Hyderabad",
  remarks:"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore .."
}]
saleForm:FormGroup;
  constructor(private fb:FormBuilder,
    private rsbService:RsbService
  ) { }

  ngOnInit(): void {
    this.saleForm=this.fb.group({
      name:['',Validators.required],
      mobile:['',Validators.required],
      email:['',Validators.required],
      dateAdded:['',Validators.required],
      dateApproved:['',Validators.required],
      city:['',Validators.required],
      remarks:['',Validators.required],


    })
  }
  selectedSaleRec(saleRecord: any = null): void {
    this.isSaleForm = !this.isSaleForm;

    if (saleRecord) {
      this.saleForm.patchValue({
        name: saleRecord.name,
        mobile: saleRecord.mobile,
        email: saleRecord.email,
        dateAdded: saleRecord.dateAdded,
        dateApproved: saleRecord.dateApproved,
        city: saleRecord.city,
        remarks: saleRecord.remarks
      });
    } else {
      this.saleForm.reset(); 
    }

  }
  
  submit(): void{
    const args={
      name:this.name.value,
      mobile:this.mobile.value,
      email:this.email.value,
      dateAdded:this.dateAdded.value,
      dateApproved:this.dateApproved.value,
      city:this.city.value,
      remarks:this.remarks.value

    }
    this.rsbService.updateAttachments(args).subscribe((rsp)=>{})


  }
  get name(){return this.saleForm.get('name')}
  get mobile(){return this.saleForm.get('mobile')}
  get email(){return this.saleForm.get('email')}
  get dateAdded(){return this.saleForm.get('dateAdded')}
  get dateApproved(){return this.saleForm.get('dateApproved')}
  get city(){return this.saleForm.get('city')}
  get remarks(){return this.saleForm.get('remarks')}


}
