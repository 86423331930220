

    <!-- Begin Page Content -->
    <div class="container" style="max-width:1120px" *ngIf="!isMobileLayout">
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h2 class="mb-0">Dashboard</h2>
        </div>
        <div class="row pb-top-layout">
            <div class="col-xl-4 col-md-4 mb-4">
                <div class="bg-purple rounded overflow-hidden">
                    <div class="px-4 pt-4 d-flex align-items-center">
                        <i class="ri-shopping-bag-line text-white display-3"></i>
                        <div class="ml-4">
                            <p class="text-white text-uppercase small mb-2">Sales</p>
                            <h3 class="text-white h4 font-weight-bold">0.00</h3>
                            <p class="text-white small">0% higher than last month</p>
                        </div>
                    </div>
                    
                    <div class="bg-boxes">
                        <svg width="420" height="50">
                          <rect x="0" y="30" width="130" height="20" fill="rgba(255, 255, 255, 0.5)"></rect>
                          <rect x="138" y="19" width="130" height="31" fill="rgba(255, 255, 255, 0.5)"></rect>
                          <rect x="280" y="0" width="130" height="50" fill="rgba(255, 255, 255, 0.5)"></rect>
                        </svg>
                    </div>
                </div>
            </div> <!--bg-purple-->
            <div class="col-xl-4 col-md-4 mb-4">
                <div class="bg-success rounded overflow-hidden">
                    <div class="px-4 pt-4 d-flex align-items-center">
                        <i class="bi bi-cash text-white display-3"></i>
                        <div class="ml-4">
                            <p class="text-white text-uppercase small mb-2">Self Commision</p>
                            <h3 class="text-white h4 font-weight-bold">0.00</h3>
                            <p class="text-white small">0% higher than last month</p>
                        </div>
                    </div>
                    
                    <div class="bg-boxes">
                        <svg width="420" height="50">
                          <rect x="0" y="30" width="130" height="20" fill="rgba(255, 255, 255, 0.5)"></rect>
                          <rect x="138" y="19" width="130" height="31" fill="rgba(255, 255, 255, 0.5)"></rect>
                          <rect x="280" y="0" width="130" height="50" fill="rgba(255, 255, 255, 0.5)"></rect>
                        </svg>
                    </div>
                </div>
            </div> <!--bg-success-->
            <div class="col-xl-4 col-md-4 mb-4">
                <div class="bg-ornage rounded overflow-hidden">
                    <div class="px-4 pt-4 d-flex align-items-center">
                        <i class="ri-medal-line text-white display-3"></i>
                        <div class="ml-4">
                            <p class="text-white text-uppercase small mb-2">Team Commision</p>
                            <h3 class="text-white h4 font-weight-bold">0.00</h3>
                            <p class="text-white small">0% higher than last month</p>
                        </div>
                    </div>
                    
                    <div class="bg-boxes">
                        <svg width="420" height="50">
                          <rect x="0" y="30" width="130" height="20" fill="rgba(255, 255, 255, 0.5)"></rect>
                          <rect x="138" y="19" width="130" height="31" fill="rgba(255, 255, 255, 0.5)"></rect>
                          <rect x="280" y="0" width="130" height="50" fill="rgba(255, 255, 255, 0.5)"></rect>
                        </svg>
                    </div>
                </div>
            </div> <!--bg-success-->
        </div>
        <!-- Page Heading -->
        <!-- <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h2 class="mb-0">Dashboard</h2>

        </div> -->
        <!-- <p *ngIf="lastLoginTime">Last Login: {{ lastLoginTime | date:'short' }}</p>
        <p *ngIf="lastLoginTime">Last Login Time: {{ lastLoginTime | date:'shortTime' }}</p> -->




        <!-- Content Row -->
        <div class="row pb-top-layout">
            <ng-container *ngFor="let countDash of countData">
                <div class="col-xl-3 col-md-6 mb-4">
                    <div class="h-100 py-2" [ngClass]="getDynamicClass(countDash)">
                        <div class="card-body">

                            <div class="row no-gutters align-items-center">

                                <div class="col mr-2">
                                    <h4 class="mb-1">{{countDash.moduleName}}</h4>
                                    <h5>{{countDash.property_count ? countDash.property_count : countDash.projects_count ?
                                        countDash.projects_count : countDash.pg_count ? countDash.pg_count : 0 }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="col-xl-3 col-md-6 mb-4">
                <div class="h-100 py-2 pb-leads">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <h4 class="mb-1">Total Leads</h4>
                                <h5>{{leadsCount}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="col-xl-4 col-md-4 mb-4">
                <div class="h-100 py-2 pb-current">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2" *ngIf="renewalDate">
                                <h4 class="mb-1">My Current Plan</h4>
                                <h5>Renewal Date : {{renewalDate}}</h5>
                            </div>
                            <div class="col mr-2" *ngIf="!renewalDate">
                                <h4 class="mb-1">My Current Plan</h4>
                                <h5>Renewal Date :<span (click)="gotoChoosePlan()" style="background-color: rgb(219, 86, 130);width: 200px;height: auto; color:white">Buy A Plan</span> </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 mb-4" *ngIf="lastLoginDate">
                <div class="h-100 py-2 pb-current">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <h4 class="mb-1">Last Login Time</h4>
                                <h5>{{ lastLoginDate | date: 'fullDate' }} {{ lastLoginDate | date: 'shortTime' }}</h5>
            
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
          
           
        </div>
        <!-- Content Row -->
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">
                                      All Leads <span class="badge badge-pill badge-secondary">{{leadsCount}}</span>
                                    </h3>
                                    <div class="custom-control custom-control-inline">
                                      <select class="form-control" [(ngModel)]="selectedModule" id="moduleSelect" (change)="changeMainModule($event.target.value)">
                                        <option [value]="properties">Properties</option>
                                        <option value="projects">Projects</option>
                                        <option value="pg/hostels">PG/Hostels</option>
                                      </select>
                                      <i class="bi bi-chevron-down position-absolute" style="right: 5px; top: 10px;"></i> <!-- Bootstrap Icon -->

                                    </div>
                                  
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="leadsRadio" name="leads" value="leads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                      Leads
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="dealLeadsRadio" name="leads" value="dealLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                      Deal Leads
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="siteVisitLeadsRadio" name="leads" value="siteVisitLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                      Site Visit Leads
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio" id="emiLeadsRadio" name="leads" value="emiLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                      EMI Leads
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="videoCallRadio" name="leads" value="videoCall" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                        Video Call
                                      </div>
                                
                                  </form>
                                  
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                    <!-- <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select> -->
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover table-responsive agent-table">
                                <thead class="">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Property Id</th>

                                        <th>Client Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email ID</th>
                                        <th>Date Added</th>
                                        <th>Property Type</th>
                                        <th>Property Value</th>

                                        <th>Follow up Date</th>
                                        <th>Status</th>
                                        <th>Remarks</th>
                                        <th>Edit/Save</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  *ngFor="let lead of tableRecords;let i= index"> 
                                        <td>{{i+1}}</td>
                                        <td>{{lead.propertyId}}</td>

                                        <td>{{lead.personalDetails?.firstName?lead.personalDetails?.firstName : lead?.customerName  }}</td>
                                        <td>{{lead.personalDetails?.mobile ? lead.personalDetails?.mobile : lead?.cmobile}}</td>
                                        <td>{{lead.personalDetails?.email ? lead.personalDetails?.email : lead?.cemail }}</td>
                                        <td>{{lead.createdAt | date: 'short'}}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{{lead.createdAt | date: 'short'}}</td>
                                       
                                        <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Success"  readonly></td>
                                        <td></td>
                                        <td></td>

                                        
                                    </tr>
                                   
                                </tbody>
                                </table>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
<ng-container *ngIf="isMobileLayout">
    <!-- <div class="mb-dashboard">
        <div class="container">
            <div class="row">
                <ng-container *ngFor="let countDash of countData">
                    <div class="col-6 col-md-4 col-xl-3">
                        <div class="card order-card"  [ngClass]="getDynamicClass(countDash)">

                            <img class="card-img-top" [src]="getImage(countDash.moduleName)" alt="Card image">
                           
                            <div class="card-img-overlay card-block">
                                <h6>{{countDash.moduleName}}</h6>
                                <h2>{{countDash.property_count ? countDash.property_count : countDash.projects_count ?
                                        countDash.projects_count : countDash.pg_count ? countDash.pg_count : 0 }}</h2>
                            </div>
                        </div>
                    </div>
                </ng-container>


                <div class="col-6 col-md-4 col-xl-3">
                    <div class="card bg-c-pink order-card">
                        <img class="card-img-top" src="assets/img/total-leads.png" alt="Card image">
                        <div class="card-img-overlay card-block">
                            <h6>Total Leads</h6>
                            <h2>{{leadsCount}}</h2>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-12 col-xl-3">
                    <div class="card bg-c-forest order-card">
                        <img class="card-img-top" src="assets/img/plan.png" alt="Card image">
                        <div class="card-img-overlay card-block" *ngIf="renewalDate">
                            <h4>My Current Plan</h4>
                            <h5>Renewal Date : {{renewalDate}}</h5>
                        </div>
                        <div class="card-img-overlay card-block" *ngIf="!renewalDate">
                            <h4>My Current Plan</h4>
                            <h5>Renewal Date : <span (click)="gotoChoosePlan()" style="background-color: rgb(219, 86, 130); color:white">Buy A Plan</span></h5>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12">
                    <div class="all-leads">
                        <div class="card shadow mb-4">
                            <div class="card-body">
                                <div class="mb-3">
                                    <form>
                                        <h3 class="d-inline-block mr-3 mb-0">All Leads <span class="badge badge-pill badge-secondary">{{leadsCount}}</span></h3>
                              
                                        <div class="custom-control custom-control-inline custom-select-wrapper">
                                            <select class="form-control custom-select-icon" [(ngModel)]="selectedModule" id="moduleSelect" (change)="changeMainModule($event.target.value)">
                                                <option [value]="properties">Properties</option>
                                                <option value="projects">Projects</option>
                                              <option value="pg/hostels">PG/Hostels</option>
                                            </select>
                                          </div>
                                          

                                          <ng-container>
                                            <span class="radio-group">
                                              <label>
                                                <input type="radio" id="leadsRadio" name="leads" value="leads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                                Leads
                                              </label>
                                              
                                              <label>
                                                <input type="radio" id="dealLeadsRadio" name="leads" value="dealLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                                Deal Leads
                                              </label>
                                              
                                              <label>
                                                <input type="radio" id="siteVisitLeadsRadio" name="leads" value="siteVisitLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                                Site Visit Leads
                                              </label>
                                              
                                              <label>
                                                <input type="radio" id="emiLeadsRadio" name="leads" value="emiLeads" [(ngModel)]="selectedLeadType" (change)="onRadioChange($event)">
                                                EMI Leads
                                              </label>
                                            </span>
                                          </ng-container>
                                          
                                       
                                    </form>
                         
                                </div>
    
                                <div class="table-head-fixed" *ngIf="isMobileLayout">
                                    <table class="table table-striped">
                                        <thead class="table-dark">
                                            <tr>
                                                <th>Client Name</th>
                                                <th>Mobile Number</th>
                                                <th>Email ID</th>
                                                <th>Date Added</th>
                                                <th>Follow up Date</th>
                                                <th>Status</th>
                                            </tr>
                                             
                                        </thead>
                                        <tbody>
                                            <tr  *ngFor="let lead of tableRecords"> 
                                                <td>{{lead.personalDetails?.firstName?lead.personalDetails?.firstName : lead?.customerName  }}</td>
                                                <td>{{lead.personalDetails?.mobile ? lead.personalDetails?.mobile : lead?.cmobile}}</td>
                                                <td>{{lead.personalDetails?.email ? lead.personalDetails?.email : lead?.cemail }}</td>
                                                <td>{{lead.createdAt| date: 'dd-MM-yyyy'}}</td>
                                                <td>{{lead.createdAt| date: 'dd-MM-yyyy'}}</td>
                                               
                                                    <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Success"  readonly></td>
        
                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 pb-4 text-center">
                    <small>All trademarks, logos and names are properties of their respective owners. Copyright © 2024 -
                        All rights reserved.</small>
                </div>
            </div>

        </div>

        <footer>
            <nav class="navbar fixed-bottom bg-secondary">
                <div class="container-fluid">
                    <ul class="nav justify-content-center">
                        <li class="nav-item">
                            <a class="nav-link text-white" href="#">Terms & Conditions </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-white" href="#">Policy</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-white" href="#">Home</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </footer>
    </div> -->
    <!-- <header>
        <div class="container-fluid bg-white fixed-top">
          <div class="d-flex align-items-center justify-content-between py-3">
              <img src="assets/img/icon/nexus-logo.png" alt="Pillar Blocks Logo">
              <div>
                  <i class="bi bi-bell-fill me-3"></i>
                  <i class="bi bi-question-circle"></i>
              </div>
          </div>
          </div>
    </header> -->
    

    <div class="mb-bg-body pt-3">
        <div class="mb-dashboard">
          <!-- Nexus my dashboard -->
           <div class="nexus-dashboard mt-4 card">
            <div class="container">
              <h5 class="text-uppercase fw-bold">MY DASHBOARD</h5>
              <div class="swiper my-dashboard-slider mb-nexus-container d-flex flex-wrap">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <ng-container *ngFor="let countDash of countData">
                    <div class="mb-nexus-box" [ngClass]="getDynamicClass(countDash)">
                      <h2>{{countDash.moduleName}}</h2>
                      <p>{{countDash.property_count ? countDash.property_count : countDash.projects_count ?
                        countDash.projects_count : countDash.pg_count ? countDash.pg_count : 0 }}</p>
                    </div>
                    </ng-container>
                    <div class="mb-nexus-box">
                      <h2>Leads</h2>
                      <p>{{leadsCount}}</p>
                    </div>          
                    <div class="mb-nexus-box light-green" *ngIf="renewalDate">
                      <h2>Plan Expiry</h2>
                      <p class="text-primary"><strong>{{renewalDate}}</strong></p>
                    </div>
                    <div class="mb-nexus-box light-green" *ngIf="!renewalDate">
                        <h2>Plan Expiry</h2>
                        <h5>Renewal Date : <span (click)="gotoChoosePlan()" style="background-color: rgb(219, 86, 130); color:white">Buy A Plan</span></h5>
                      </div>
                  </div>
                  <!-- <div class="swiper-slide">
                    <div class="mb-nexus-box light-blue">
                      <h2>Sale</h2>
                      <p>(10)</p>
                    </div>
                    <div class="mb-nexus-box light-green">
                      <h2>Rent</h2>
                      <p>(06)</p>
                    </div>
                    <div class="mb-nexus-box light-red">
                      <h2>Plot/Land</h2>
                      <p>(14)</p>
                    </div>
                    <div class="mb-nexus-box light-pink">
                      <h2>Commercial</h2>
                      <p>(04)</p>
                    </div> 
                  </div> -->
                </div>
                <div class="swiper-pagination"></div>
              </div>
            </div>
           </div>
          <!-- Nexus Elite -->
          <div class="nexus-elite m-2 mt-4 card">
            <div class="container">
            <h5 class="text-uppercase fw-bold">NEXUS ELITE</h5>
              <div class="row text-center">
                <div class="col-3" (click)="navigateToLoan()">
                    <div class="icon">
                      <img src="assets/img/icon/loan.png" alt="Loan">
                    </div>
                    <p>Loan</p>
                </div>
                <div class="col-3" (click)="navigateToProjectFinance()">
                    <div class="icon">
                      <img src="assets/img/icon/project-finance.png" alt="Project Finance">
                    </div>
                    <p>Project Finance</p>
                </div>
                <div class="col-3" (click)="navigateToCreditScore()">
                    <div class="icon">
                      <img src="assets/img/icon/credit-score.png" alt="Credit Score">
                    </div>
                    <p>Credit Score</p>
                </div>
                <div class="col-3" (click)="navigateToCreditCard()">
                    <div class="icon">
                      <img src="assets/img/icon/card.png" alt="Credit Card">
                    </div>
                    <p>Credit Card</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Nexus Prime -->
          <div class="nexus-prime m-2 mt-4 card">
            <div class="container">
            <h5 class="text-uppercase fw-bold">NEXUS PRIME</h5>
              <div class="row text-center">
                  <div class="col-4" (click)="navigateToInteriorDesign()">
                      <div class="icon">
                        <img src="assets/img/icon/interior-design.png" alt="Interior Design">
                      </div>
                      <p>Interior Design</p>
                  </div>
                  <div class="col-4" (click)="navigateToFurnishing()">
                      <div class="icon">
                        <img src="assets/img/icon/furnishing.png" alt="Furnishing">
                      </div>
                      <p>Furnishing</p>
                  </div>
                  <div class="col-4" (click)="navigateToHomeAutomation()">
                      <div class="icon">
                        <img src="assets/img/icon/home-automation.png" alt="Home Automation">
                      </div>
                      <p>Home Automation</p>
                  </div>
                  <div class="col-4" (click)="navigateToPackers()">
                      <div class="icon">
                        <img src="assets/img/icon/packers-movers.png" alt="Packers & Movers">
                      </div>
                      <p>Packers & Movers</p>
                  </div>
                  <div class="col-4" (click)="navigateToSolar()">
                    <div class="icon">
                      <img src="assets/img/icon/solar.png" alt="Solar">
                    </div>
                    <p>Solar</p>
                  </div>
                  <div class="col-4" (click)="navigateToCCtv()">
                    <div class="icon">
                      <img src="assets/img/icon/cctv.png" alt="CCTV">
                    </div>
                    <p>CCTV</p>
                  </div>
                  <div class="col-4" (click)="navigateToDigitalSurvery()">
                    <div class="icon">
                      <img src="assets/img/icon/digital-survey.png" alt="Digital Survery">
                    </div>
                    <p>Digital Survery</p>
                  </div>            
                  <div class="col-4" (click)="navigateToLandFencing()">
                    <div class="icon">
                      <img src="assets/img/icon/land-fencing.png" alt="Land Fencing">
                    </div>
                    <p>Land Fencing</p>
                  </div>            
                  <div class="col-4" (click)="navigateToPestControl()">
                    <div class="icon">
                      <img src="assets/img/icon/pest-control.png" alt="Pest Control">
                    </div>
                    <p>Pest Control</p>
                  </div>            
                  <div class="col-4" (click)="navigateToDrones()">
                    <div class="icon">
                      <img src="assets/img/icon/drones.png" alt="Drones">
                    </div>
                    <p>Drones</p>
                  </div>                        
                  <div class="col-4" (click)="navigateToPropertyinsurance()">
                    <div class="icon">
                      <img src="assets/img/icon/property-insurance.png" alt="Property insurance">
                    </div>
                    <p>Property insurance</p>
                  </div>                        
                  <div class="col-4" (click)="navigateToPrefabProperty()">
                    <div class="icon">
                      <img src="assets/img/icon/pre-fab-property.png" alt="Pre fab Property">
                    </div>
                    <p>Pre fab Property</p>
                  </div>
              </div>
            </div>
          </div>
          <!-- Bottom Navigation -->
          <div class="mb-bottom-menu fixed-bottom bg-white rounded d-flex justify-content-around py-2" *ngIf="bottomNav">
            <a class="toggle position-relative" (click)="openSidebar()">
              <img src="assets/img/icon/menu-line.png" class="img-fluid d-block" alt="menu-icon">
              Menu
            </a>
            <a (click)="navigateToSearchProp()">
              <img src="assets/img/icon/search-property-icon.png" class="img-fluid d-block" alt="search property">
              Search Property
            </a>
            <a (click)="navigateToPostProp()">
              <img src="assets/img/icon/add-property.png" class="img-fluid d-block" alt="Add property">
              Ad Property
            </a>
            <a (click)="navigateToLeads()">
              <img src="assets/img/icon/leads.png" class="img-fluid d-block" alt="Leads">
              Leads
            </a>
            <a (click)="navigateToFavourite()">
              <img src="assets/img/icon/favourite.png" class="img-fluid d-block" alt="Favourite">
              Favourite
            </a>
          </div>
          <div id="main-nav">
            <ul class="nav">
              <li class="head-nav">
                <h5>Sign in <span class="badge rounded-pill bg-danger">Login in</span></h5>
                <div class="d-flex align-items-center my-2">
                  <img src="img/photo1.png" width="50px" alt="profile image pillarBlock" class="rounded-4 p-1 bg-white me-2">
                  <h5>Sakshi G</h5>
                </div>
              </li>
              <li class="nav-item active">
                <a class="nav-link" href="dashboard.html">
                    <img src="img/dashboard.svg" alt="Dashboard">
                    <span>Dashboard</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/myproperty.svg" alt="myproperty">
                    <span>My Properties</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/myproject.svg" alt="myproject">
                    <span>My Projects</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/Viewed Leads.svg" alt="My Leads">
                    <span>My Leads</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/Viewed Leads.svg" alt="Viewed Leads">
                    <span>Viewed Leads</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/sale.svg" alt="Sales">
                    <span>Sales</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/loan.svg" alt="Loans">
                    <span>Loans</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/cibil.svg" alt="CIBIL Score">
                    <span>CIBIL Score</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/projectfinance.svg" alt="Project Finance">
                    <span>Project Finance</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/cctv.svg" alt="CCTV Enquiry">
                    <span>CCTV Enquiry</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/Interior Design Enquiry.svg" alt="Interior Design Enquiry">
                    <span>Interior Design Enquiry</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/Solar Enquiry.svg" alt="Solar Enquiry">
                    <span>Solar Enquiry</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <img src="img/Reviews.svg" alt="Reviews & Ratings">
                    <span>Reviews & Ratings</span>
                </a>
            </li>
            </ul>
            <ul class="bottom-nav">
              <li class="notification-icon">
                <a href="#">
                  <i class="ri-notification-2-line"></i>
                </a>
              </li>
              <li class="email-icon">
                <a href="#">
                  <i class="ri-question-line"></i>
                </a>
              </li>
              <li class="setting-icon">
                <a href="#">
                  <i class="ri-settings-3-line"></i>
                </a>
              </li>
              
            </ul>
          </div>
          <!-- <footer>
            <nav class="navbar bg-white fixed-bottom p-3 mb-home-bottom card">
                
                <ul class="nav">
                  <li class="nav-item">
                    <a class="toggle position-relative">
                      <img src="assets/img/icon/menu-line.png" class="img-fluid d-block" alt="menu-icon">
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">
                      <img src="assets/img/icon/search-property-icon.png" class="img-fluid d-block" alt="search property">
                      Search Property
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">
                      <img src="assets/img/icon/add-property.png" class="img-fluid d-block" alt="Add property">
                      Add Property
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">
                      <img src="assets/img/icon/leads.png" class="img-fluid d-block" alt="Leads">
                      Leads
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">
                      <img src="assets/img/icon/favourite.png" class="img-fluid d-block" alt="Favourite">
                      Favourite
                    </a>
                  </li>
                </ul>
            </nav>
          </footer> -->
        </div>
      </div>
</ng-container>


