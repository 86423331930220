import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pg-hostel-list',
  templateUrl: './pg-hostel-list.component.html',
  styleUrls: ['./pg-hostel-list.component.css']
})
export class PgHostelListComponent implements OnInit {
  @Input() allListings: any[];
  isMobileLayout:boolean=false;

  constructor() { }
 
  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
  }
  

}
