import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RsbService } from 'src/app/service/rsb.service';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
function panValidator(control: FormControl): { [s: string]: boolean } | null {
  const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  if (control.value && !panPattern.test(control.value)) {
    return { 'invalidPAN': true };
  }
  return null;
}
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-cibil',
  templateUrl: './cibil.component.html',
  styleUrls: ['./cibil.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.10s ease-in-out', style({ opacity: 1 })) 
      ]),
      transition(':leave', [
        animate('0.10s ease-in-out', style({ opacity: 0 })) 
      ])
    ])
  ]
})
export class CibilComponent implements OnInit {
  cibilForm:FormGroup
  awsFile: FileList;
  propertyImages='';
  getCibilListings:any;
  imageSrc: string | ArrayBuffer | null = null;

  isMobileLayout:boolean=false;


  constructor(
    private fb:FormBuilder,
    private rsbService:RsbService,
    private modalService: NgbModal,
    private toastr: ToastrService


  ) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.cibilForm=this.fb.group({
    panNum:['',[Validators.required, panValidator]],
    name:['',[Validators.required]],
    mobile: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    loanAmount: ['', [Validators.required, Validators.pattern('^[0-9]*$')]]


    })
    this.cibilForm.get('panNum').valueChanges.subscribe(value => {
      if (value) {
        this.cibilForm.get('panNum').setValue(value.toUpperCase(), { emitEvent: false });
      }
    });

   this.getCibilRequest();
  }
  numberToWords(num: number): string {
    const a = [
      '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
    ];
    const b = [
      '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
    ];
    
    const c = ['hundred', 'thousand', 'lakh', 'crore'];

    if (num === 0) return 'zero';

    const numToWordsHelper = (n: number) => {
      if (n < 20) return a[n];
      if (n < 100) return b[Math.floor(n / 10)] + (n % 10 !== 0 ? ' ' + a[n % 10] : '');
      if (n < 1000) return a[Math.floor(n / 100)] + ' ' + c[0] + (n % 100 !== 0 ? ' ' + numToWordsHelper(n % 100) : '');
      return '';
    };

    let result = '';
    let crore = Math.floor(num / 10000000);
    num %= 10000000;
    let lakh = Math.floor(num / 100000);
    num %= 100000;
    let thousand = Math.floor(num / 1000);
    num %= 1000;
    let hundred = Math.floor(num / 100);
    let remainder = num % 100;

    if (crore > 0) {
      result += numToWordsHelper(crore) + ' crore ';
    }
    if (lakh > 0) {
      result += numToWordsHelper(lakh) + ' lakh ';
    }
    if (thousand > 0) {
      result += numToWordsHelper(thousand) + ' thousand ';
    }
    if (hundred > 0) {
      result += numToWordsHelper(hundred) + ' hundred ';
    }
    if (remainder > 0) {
      result += 'and ' + numToWordsHelper(remainder);
    }

    return result.trim();
  }

  // Getter for the form control value
  get loanAmountInWords(): string {
    const loanAmount = this.cibilForm.get('loanAmount')?.value;
    return loanAmount ? this.numberToWords(+loanAmount) + ' rupees' : '';
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      console.log('Selected file:', file);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      const file = files[0];
      console.log('Dropped file:', file);
    }
  }
  // onFileSelected(event: any) {
  //   const file: File = event.target.files[0];
  //   if (file) {
  //     console.log('Selected file:', file);
  //   }
  // }
  // uploadAws(event: any, fieldName?: string): void {

  //   this.awsFile = event.target.files;
  //   const file = this.awsFile.item(0);
  //   const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
  //   modalRef.componentInstance.file = file;
  //   modalRef.result.then((path: string) => {
  //     if (path) {
  //       this.propertyImages = path;
  //     }
    
  //   });
  // }
  uploadAws(event: any, fieldName?: string): void {
    this.awsFile = event.target.files;
    const file = this.awsFile.item(0);
  
    // Image Preview Logic
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageSrc = reader.result;
      };
      reader.readAsDataURL(file);
    }
  
    const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
    modalRef.componentInstance.file = file;
    modalRef.result.then((path: string) => {
      if (path) {
        this.propertyImages = path;
      }
    });
  }
  

  getCibilRequest(): void {
    this.rsbService.getCibilRequest().subscribe((res)=>{
      if(res.statusCode === 0 && res.contents.length) {
      this.getCibilListings=res.contents;
      } else {

      }
    })

  }
  submit(): void{
    if (this.cibilForm.invalid) {
      Object.values(this.cibilForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    const args={
      panNumber:this.panNum.value,
      name:this.name.value,
      mobile:this.mobile.value,
      loanAmount:this.loanAmount.value,
      cibilScoreFile:this.propertyImages,

    }
    this.rsbService.cibilScore(args).subscribe((res)=>{
      if(res.statusCode===0){
        this.toastr.success('Submitted successfully.');

        this.getCibilRequest();
        this.cibilForm.reset();
      }
    })
   
  }
  resetForm(){
    this.cibilForm.reset();
  }
  get panNum() { return this.cibilForm.get('panNum'); }
  get mobile() { return this.cibilForm.get('mobile');}
  get name() { return this.cibilForm.get('name');}
  get loanAmount() { return this.cibilForm.get('loanAmount');}


}
