<div class="table-responsive"  *ngIf="!isMobileLayout">
    <table class="table table-hover">
        <thead class="">
            <tr>
                <th scope="col">S.NO</th>
                <th scope="col">Property Id</th>
                <th scope="col">Property Name</th>
                <th scope="col">Date Added</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let listings of allListings; let i =index">
                <td>{{i+1}}</td>
                <td>{{listings.listingId}}</td>
                <td>{{listings.propertyTitle}}</td>
                <td>{{ listings.createdAt | date: 'dd/MM/yyyy' }}</td>
                <!-- <td><button class="btn btn-success btn-sm">Edit</button></td> -->
            </tr>

        </tbody>
    </table>
</div>
<div class="table-head-fixed" *ngIf="isMobileLayout">
    <table class="table table-striped">
        <thead class="table-dark">
            <tr>
                <th scope="col">S.No</th>
                <th scope="col">Property Id</th>
                <th scope="col">Property Name</th>
                <th scope="col">Date Added</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let listings of allListings; let i =index">
                <td>{{i+1}}</td>
                <td>{{listings.listingId}}</td>
                <td>{{listings.propertyTitle}}</td>
                <td>{{ listings.createdAt | date: 'dd/MM/yyyy' }}</td>
                <!-- <td><button class="btn btn-success btn-sm">Edit</button></td> -->
            </tr>
        </tbody>
    </table>
</div>