
  <div class="mb-dashboard">
    
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="mb-choose-plan">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                  <!-- Business Starter Plan -->
                  <div class="swiper-slide " style="background: linear-gradient(135deg, #f12711, #f5af19);">
                      <h3>Business Starter</h3>
                      <div class="price">₹ 499 <span>INR</span></div>
                      <h5>( incl 18% GST )</h5>
                      <h6>per user / month</h6>
                      <button class="buy-now-btn my-4" (click)="buyPlan('starter','499','30','43800')">PAY NOW</button>
                      <p><i class="ri-shining-fill text-primary"></i> Nexus add-on available</p>
                  </div>
          
                  <!-- Business Standard Plan -->
                  <div class="swiper-slide " style="background: linear-gradient(135deg, #6a11cb, #2575fc);">
                      <h2>MOST POPULAR</h2>
                      <h3>Business Standard</h3>
                      <div class="price">₹ 1349 <span>INR</span></div>
                      <span>10% OFF</span>
                      <h5>( incl 18% GST )</h5>
                      <h6>per user / <b>3 month</b></h6>
                      <button class="buy-now-btn my-4" (click)="buyPlan('standard','1349','90','129600')">PAY NOW</button>
                      <p><i class="ri-shining-fill text-primary"></i> Nexus add-on available</p>
                  </div>
          
                  <!-- Business Plus Plan -->
                  <div class="swiper-slide" style="background: linear-gradient(135deg, #00c6ff, #0072ff);">
                      <h3>Business Plus</h3>
                      <div class="price">₹ 2399 <span>INR</span></div>
                      <span>20% OFF</span>
                      <h5>( incl 18% GST )</h5>
                      <h6>per user / <b>6 month</b></h6>
                      <button class="buy-now-btn my-4" (click)="buyPlan('plus','2399','180','262800')">PAY NOW</button>
                      <p><i class="ri-shining-fill text-primary"></i> Nexus add-on available</p>
                  </div>
          
                  <!-- Enterprise Plan -->
                  <div class="swiper-slide" style="background: linear-gradient(135deg, #fc4a1a, #f7b733);">
                      <h3>Enterprise</h3>
                      <div class="price">₹ 4199 <span>INR</span></div>
                      <span>30% OFF</span>

                      <h5>( incl 18% GST )</h5>
                      <h6>per user / <b>12 month</b></h6>
                      <button class="buy-now-btn my-4" (click)="buyPlan('Enterprise','4199','360','525601')">PAY NOW</button>
                      <p><i class="ri-shining-fill text-primary"></i> Nexus add-on available</p>
                  </div>
          
              </div>
              <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span>
                <span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 2"></span>  
                <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span>
                <span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 4" aria-current="true"></span>
            </div>
            </div>
          </div>
        </div>
    </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pb-4 text-center">
          <small class="copy-right-text">All trademarks, logos and names are properties of their respective owners. Copyright © 2024 - All rights reserved.</small>
        </div>
      </div>
      
    </div>
    
    <!-- <footer>
      <nav class="navbar fixed-bottom bg-secondary">
        <div class="container-fluid">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Terms & Conditions </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Home</a>
            </li>
          </ul>
        </div>
      </nav>
    </footer> -->
  </div>