import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[scrollTracker]',
})
export class ScrollTracker {
  @Output() scrolled = new EventEmitter<any>();
  private endReached = false;
  private debounceTimeout: any;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    clearTimeout(this.debounceTimeout);

    // Debounce the scroll event to prevent excessive calls
    this.debounceTimeout = setTimeout(() => {
      const tracker = document.documentElement;
      const scrollPosition = tracker.scrollTop;
      const limit = tracker.scrollHeight - tracker.clientHeight;

      // Add a small buffer to avoid exact matching issues
      const buffer = 10;

      if (scrollPosition >= limit - buffer && !this.endReached) {
        this.endReached = true;
        console.log('REACHED END');
        this.scrolled.emit({
          pos: scrollPosition,
          endReached: true,
        });
      } else if (scrollPosition < limit - buffer) {
        // Reset if the user scrolls back up
        this.endReached = false;
      }
    }, 200); // Adjust debounce time (in milliseconds) as needed
  }
}
