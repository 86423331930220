<ng-container *ngIf="showHeaderForRentSearch || showHeaderForProjectSearch || showHeaderForPgHostelsSearch || showHeaderForBuySearch || showHeaderForPlotSearch">
<ul class="nav nav-tabs searchtablist" id="searchTab" role="tablist">
  <li class="nav-item" role="presentation" *ngFor="let module of lookupModel.getModuleList();">
    <button  class="nav-link" [ngClass]="{'active': selectedModuleId === module.moduleId}" 
    (click)="selectModule(module)"> 
    <!-- <i [ngClass]="getIcon(module.moduleName)" (click)=" backToSearchProp()"></i>
    <b (click)=" backToSearchProp()">{{ module.moduleName }}</b> -->
    <img  *ngIf="getIcon(module.moduleName)" (click)=" backToSearchProp()" [src]="getIcon(module.moduleName)" width="40px" height="auto" alt="places">
    <div><b (click)=" backToSearchProp()">{{ module.moduleName }}</b></div>
  </button>


  </li>

</ul>
</ng-container>