import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
function panValidator(control: FormControl): { [s: string]: boolean } | null {
  const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  if (control.value && !panPattern.test(control.value)) {
    return { 'invalidPAN': true };
  }
  return null;
}

@Component({
  selector: 'app-mb-profile-edit',
  templateUrl: './mb-profile-edit.component.html',
  styleUrls: ['./mb-profile-edit.component.css']
})
export class MbProfileEditComponent implements OnInit {
  profileForm:FormGroup;
  addressForm:FormGroup;
  bankAccountForm:FormGroup;
  panForm:FormGroup;
  awsFile: FileList;
  propertyImages = [];
  panCardPath=[];
  bankPassbookPath=[];
  isMobileLayout:boolean=false;
  //Mobile Layout profile
  fName:string='';
  mbProfileEmail:string='';
  mbProfileBloodGroup:string='';
  mbProfileMobile:any='';
  mbProfileImg:string='';
  mbEmergencyContact:any='';
  //Mobile Layout Address Profile
  mbAddrHouseNum:any='';
  mbBuildingName:any='';
  mbLocality:any='';
  mbVillage:any='';
  mbTahsil:any='';
  mbDistrict:any='';
  mbState:any='';
  mbPincode:any='';



  constructor(
    private fb:FormBuilder,
    public lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private router:Router
    


  ) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.getProfile();
    this.profileForm = this.fb.group({
      firstName: [this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.firstName ? this.lookupModel.getLoggedUser().personalDetails.firstName:'', [Validators.required]],
      lastName: [this.lookupModel.getLoggedUser().personalDetails &&this.lookupModel.getLoggedUser().personalDetails.lastName ? this.lookupModel.getLoggedUser().personalDetails.lastName:'',[Validators.required]],
      bloodGroup: [this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.bloodGroup ? this.lookupModel.getLoggedUser().personalDetails.bloodGroup:'',[Validators.required]],
      email: [this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.email ? this.lookupModel.getLoggedUser().personalDetails.email:'', [Validators.required, Validators.email]],
      mobile: [this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.mobile ? this.lookupModel.getLoggedUser().personalDetails.mobile:'', [Validators.required]],
      emergencyContact: [this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.emergencyContactNumber ? this.lookupModel.getLoggedUser().personalDetails.emergencyContactNumber:'', [Validators.required]],
      qualification: [this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.qualification ? this.lookupModel.getLoggedUser().personalDetails.qualification:'', [Validators.required]],
      experience: [this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.experience ? this.lookupModel.getLoggedUser().personalDetails.experience:'', [Validators.required]],
      rera: [this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.rera ? this.lookupModel.getLoggedUser().personalDetails.experience:'', [Validators.required]],

    });
    //For Mobile Layouts 
    this.fName=this.firstName.value;
    this.mbProfileEmail=this.email.value;
    this.mbProfileBloodGroup=this.bloodGroup.value;
    this.mbProfileMobile=this.mobile.value;
    this.mbProfileImg=this.lookupModel.getLoggedUser().personalDetails.profilePic;
    this.mbEmergencyContact=this.emergencyContact.value;
    this.addressValidForm();
    this.bankAcDetails();
    this.panDetails();

  }
  addressValidForm(){
    this.addressForm = this.fb.group({
      sameAsPresent: [false],
      houseNumber: [this.lookupModel.getLoggedUser().presentAddress ? this.lookupModel.getLoggedUser().presentAddress.houseNumber:'', [Validators.required]],
      buildingName: [this.lookupModel.getLoggedUser().presentAddress ? this.lookupModel.getLoggedUser().presentAddress.buildingName:'',[Validators.required]],
      locality: [this.lookupModel.getLoggedUser().presentAddress? this.lookupModel.getLoggedUser().presentAddress.locality:'',[Validators.required]],
      village: [this.lookupModel.getLoggedUser().presentAddress ? this.lookupModel.getLoggedUser().presentAddress.village:'', [Validators.required]],
      tahsil: [this.lookupModel.getLoggedUser().presentAddress ?  this.lookupModel.getLoggedUser().presentAddress.tahsil : '', [Validators.required]],
      district: [this.lookupModel.getLoggedUser().presentAddress ?  this.lookupModel.getLoggedUser().presentAddress.district :'', [Validators.required]],
      state: [this.lookupModel.getLoggedUser().presentAddress ?  this.lookupModel.getLoggedUser().presentAddress.state :'', [Validators.required]],
      pincode: [this.lookupModel.getLoggedUser().presentAddress ?  this.lookupModel.getLoggedUser().presentAddress.pincode :'', [Validators.required]],
       // Permanent Address fields
       permanentHouseNumber: [this.lookupModel.getLoggedUser().permanentAddress ? this.lookupModel.getLoggedUser().permanentAddress.permanentHouseNumber:'', [Validators.required]],
       permanentBuildingName: [this.lookupModel.getLoggedUser().permanentAddress ? this.lookupModel.getLoggedUser().permanentAddress.permanentBuildingName:'', [Validators.required]],
       permanentLocality: [this.lookupModel.getLoggedUser().permanentAddress ? this.lookupModel.getLoggedUser().permanentAddress.permanentLocality:'', [Validators.required]],
       permanentVillage: [this.lookupModel.getLoggedUser().permanentAddress ? this.lookupModel.getLoggedUser().permanentAddress.permanentVillage:'', [Validators.required]],
       permanentTahsil: [this.lookupModel.getLoggedUser().permanentAddress ? this.lookupModel.getLoggedUser().permanentAddress.permanentTahsil:'', [Validators.required]],
       permanentDistrict: [this.lookupModel.getLoggedUser().permanentAddress ? this.lookupModel.getLoggedUser().permanentAddress.permanentDistrict:'', [Validators.required]],
       permanentState: [this.lookupModel.getLoggedUser().permanentAddress ? this.lookupModel.getLoggedUser().permanentAddress.permanentState:'', [Validators.required]],
       permanentPincode: [this.lookupModel.getLoggedUser().permanentAddress ? this.lookupModel.getLoggedUser().permanentAddress.permanentPincode:'', [Validators.required]]
       
    });

    this.addressForm.get('sameAsPresent').valueChanges.subscribe(checked => {
      if (checked) {
        this.copyPresentAddressToPermanent();
      } else {
        this.clearPermanentAddress();
      }
    });
    this.mbAddrHouseNum=this.houseNumber.value;
    this.mbBuildingName=this.buildingName.value;
    this.mbLocality=this.locality.value;
    this.mbVillage=this.village.value;
    this.mbTahsil=this.tahsil.value;
    this.mbDistrict=this.district.value;
    this.mbState=this.state.value;
    this.mbPincode=this.pincode.value;


  }
  triggerFileInput(): void {
    const fileInput = document.getElementById('file-input') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const profileImg = document.getElementById('profile-img') as HTMLImageElement;
        if (profileImg) {
          profileImg.src = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    }
  }
  profileEdit(){
    this.router.navigate(['profile-edit'])
  }
  backToProfile(){
    this.router.navigate(['associateMyProfile'])
  }

  getProfile() {
    this.rsbService.getAssociateProfileInfo().subscribe((rsp) => {
      if(rsp.statusCode === 0) {
        this.propertyImages = [];
        if(this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.profilePic ) {
        this.propertyImages.push(this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.profilePic ? this.lookupModel.getLoggedUser().personalDetails.profilePic : '')
        }
      }
    })
  }
  bankAcDetails(){
    this.bankAccountForm= this.fb.group({
      holderName: [ this.lookupModel.getLoggedUser().bankAccountDetails ? this.lookupModel.getLoggedUser().bankAccountDetails.holderName:'', [Validators.required]],
      accountNumber: [this.lookupModel.getLoggedUser().bankAccountDetails ? this.lookupModel.getLoggedUser().bankAccountDetails.accountNumber:'',[Validators.required]],
      bankName: [this.lookupModel.getLoggedUser().bankAccountDetails ? this.lookupModel.getLoggedUser().bankAccountDetails.bankName:'',[Validators.required]],
      ifsc: [this.lookupModel.getLoggedUser().bankAccountDetails ? this.lookupModel.getLoggedUser().bankAccountDetails.ifsc:'', [Validators.required]],
    })
  }
  panDetails(){
    this.panForm= this.fb.group({
      nameAsPerPan: [this.lookupModel.getLoggedUser().panCardDetails ? this.lookupModel.getLoggedUser().panCardDetails.nameAsPerPan:'', [Validators.required]],
      panNum: [this.lookupModel.getLoggedUser().panCardDetails ? this.lookupModel.getLoggedUser().panCardDetails.panNum:'',[Validators.required]],  
    })
    this.panForm.get('panNum').valueChanges.subscribe(value => {
      if (value) {
        this.panForm.get('panNum').setValue(value.toUpperCase(), { emitEvent: false });
      }
    });
  }
  uploadAws(event: any, fieldName?: string): void {
    this.awsFile = event.target.files;
    const file = this.awsFile.item(0);
    const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
    modalRef.componentInstance.file = file;
    modalRef.result.then((path: string) => {
      if (path) {
        this.propertyImages = [path];
      }
    });
  }
  uploadBankPassBook(event: any, fieldName?: string): void {
    this.awsFile = event.target.files;
    const file = this.awsFile.item(0);
    const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
    modalRef.componentInstance.file = file;
    modalRef.result.then((path: string) => {
      if (path) {
        this.bankPassbookPath = [path];
      }
    });
  }
  uploadPan(event: any, fieldName?: string): void {
    this.awsFile = event.target.files;
    const file = this.awsFile.item(0);
    const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
    modalRef.componentInstance.file = file;
    modalRef.result.then((path: string) => {
      if (path) {
        this.panCardPath = [path];
      }
    });
  }
  
  submit(){
    if (this.profileForm.invalid) {
      Object.values(this.profileForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    console.log(this.profileForm.value);
    const profile = this.lookupModel.getLoggedUserPersonalDetails();
    const args = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      bloodGroup:this.bloodGroup.value,
      email: this.email.value,
      mobile:this.mobile.value,
      emergencyContact:this.emergencyContact.value,
      qualification:this.qualification.value,
      experience:this.experience.value,
      rera:this.rera.value,
      profilePic:this.propertyImages[0]
    };

    this.rsbService.updateAssociatePersonalDetails(args).subscribe((rsp) => {
      if(rsp.statusCode === 0) {
        this.getProfile();
      }
    })

  }
    copyPresentAddressToPermanent() {
    const presentAddress = this.addressForm.value;
    this.addressForm.patchValue({
      permanentHouseNumber: presentAddress.houseNumber,
      permanentBuildingName: presentAddress.buildingName,
      permanentLocality: presentAddress.locality,
      permanentVillage: presentAddress.village,
      permanentTahsil: presentAddress.tahsil,
      permanentDistrict: presentAddress.district,
      permanentState: presentAddress.state,
      permanentPincode: presentAddress.pincode
    });
  }
  clearPermanentAddress() {
    this.addressForm.patchValue({
      permanentHouseNumber: '',
      permanentBuildingName: '',
      permanentLocality: '',
      permanentVillage: '',
      permanentTahsil: '',
      permanentDistrict: '',
      permanentState: '',
      permanentPincode: ''
    });
  }
  updateAddress(){
    if (this.addressForm.invalid) {
      Object.values(this.addressForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    const args = {
      houseNumber: this.houseNumber.value,
      buildingName: this.buildingName.value,
      locality:this.locality.value,
      village: this.village.value,
      tahsil:this.tahsil.value,
      district:this.district.value,
      state:this.state.value,
      pincode:this.pincode.value,
      permanentHouseNumber:this.permanentHouseNumber.value,
      permanentBuildingName:this.permanentBuildingName.value,
      permanentLocality:this.permanentLocality.value,
      permanentVillage:this.permanentVillage.value,
      permanentTahsil:this.permanentTahsil.value,
      permanentDistrict:this.permanentDistrict.value,
      permanentState:this.permanentState.value,
      permanentPincode:this.permanentPincode.value,
      associateId: this.lookupModel.getLoggedUser().associateId

    };
    this.rsbService.updateAssociateAddress(args).subscribe((rsp) => {
      if(rsp.statusCode === 0) {
        this.getProfile();
      }
    })


  }


  bankDetails(){
    if (this.bankAccountForm.invalid) {
      Object.values(this.bankAccountForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    // const profile = this.lookupModel.getLoggedUserPersonalDetails();
    const args = {
      holderName: this.holderName.value,
      accountNumber: this.accountNumber.value,
      bankName:this.bankName.value,
      ifsc: this.ifsc.value,
      cheque:this.bankPassbookPath[0],
      associateId: this.lookupModel.getLoggedUser().associateId


    };
    this.rsbService.bankAccountDetails(args).subscribe((rsp) => {
      if(rsp.statusCode === 0) {
        this.getProfile();
      }
    })


  }
  panAccount(){
    if (this.panForm.invalid) {
      Object.values(this.panForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    // const profile = this.lookupModel.getLoggedUserPersonalDetails();
    const args = {
      nameAsPerPan: this.nameAsPerPan.value,
      panNum: this.panNum.value,
      panCard:this.panCardPath[0],
      associateId: this.lookupModel.getLoggedUser().associateId
    
    };
    this.rsbService.panAccountDetails(args).subscribe((rsp) => {
      if(rsp.statusCode === 0) {
        this.getProfile();
      }
    })



  }
     // personalDetails update
    

  get firstName() { return this.profileForm.get('firstName'); }
  get lastName() { return this.profileForm.get('lastName'); }
  get bloodGroup() { return this.profileForm.get('bloodGroup'); }
  get email() { return this.profileForm.get('email'); }
  get mobile() { return this.profileForm.get('mobile'); }
  get emergencyContact() { return this.profileForm.get('emergencyContact'); }
  get qualification (){return this.profileForm.get('qualification')}
  get experience(){return this.profileForm.get('experience')}
  get rera(){return this.profileForm.get('rera')}
  //Present Address Update
  get houseNumber() { return this.addressForm.get('houseNumber'); }
  get buildingName() { return this.addressForm.get('buildingName'); }
  get locality() { return this.addressForm.get('locality'); }
  get village() { return this.addressForm.get('village'); }
  get tahsil() { return this.addressForm.get('tahsil'); }
  get district() { return this.addressForm.get('district'); }
  get state() { return this.addressForm.get('state'); }
  get pincode() { return this.addressForm.get('pincode'); }
  //Permanent Address Update
  get permanentHouseNumber() { return this.addressForm.get('permanentHouseNumber'); }
  get permanentBuildingName() { return this.addressForm.get('permanentBuildingName'); }
  get permanentLocality() { return this.addressForm.get('permanentLocality'); }
  get permanentVillage() { return this.addressForm.get('permanentVillage'); }
  get permanentTahsil() { return this.addressForm.get('permanentTahsil'); }
  get permanentDistrict() { return this.addressForm.get('permanentDistrict'); }
  get permanentState() { return this.addressForm.get('permanentState'); }
  get permanentPincode() { return this.addressForm.get('permanentPincode'); }


  //Bank Account Details
  get holderName() { return this.bankAccountForm.get('holderName'); }
  get accountNumber() { return this.bankAccountForm.get('accountNumber'); }
  get bankName() { return this.bankAccountForm.get('bankName'); }
  get ifsc() { return this.bankAccountForm.get('ifsc'); }
  //Pan Account Details
  get nameAsPerPan() { return this.panForm.get('nameAsPerPan'); }
  get panNum() { return this.panForm.get('panNum'); }

  





}
