import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { Router } from '@angular/router';
@Component({
  selector: 'app-mb-add-more-location-page',
  templateUrl: './mb-add-more-location-page.component.html',
  styleUrls: ['./mb-add-more-location-page.component.css']
})
export class MbAddMoreLocationPageComponent implements OnInit {
  @ViewChild('locationInput') locationInput: ElementRef;
  selectedLocation: string;
  fullCity = {
    cityName: '',
    cityBounds: {},
    zip: '',
    macroCity: '',
    lat: 0,
    long: 0
  };
  cities: Array<any> = [];


  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private lookupModel:RsbLookupModel,
    private router:Router
  ) { }
    ngOnInit(): void {
      this.mapsAPILoader.load().then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.locationInput.nativeElement, {
          types: ['geocode'], 
          componentRestrictions: { country: 'IN' } 
        });
      
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place = autocomplete.getPlace();
            
            if (place.geometry) {
              this.selectedLocation = place.name;
              this.fullCity = {
                cityName: place.name,
                macroCity: place.address_components[1]?.long_name || '',
                cityBounds: place.geometry.viewport,
                zip: place.address_components.find(ac => ac.types.includes('postal_code'))?.long_name || '',
                lat: place.geometry.location.lat(),
                long: place.geometry.location.lng(),
              };
              this.lookupModel.setSelectedAddMoreCity(this.selectedLocation);
              this.cities.push(this.fullCity);
      
              console.log('Selected location:', this.selectedLocation);
            }
      
            if (this.selectedLocation) {
              this.router.navigate(['search-prop-page1']);
            }
          });
        });
      });
      
  }
}