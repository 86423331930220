<div class="container-fluid">
    <div class="pb-wrapper bg-white p-4">
        <form class="agent-profile" [formGroup]="saleForm" (ngSubmit)="submit()" *ngIf="isSaleForm">
            <div class="form-row mb-5">
          <div class="col">
            <label for="Name">Client Name</label>
            <input type="text" class="form-control" placeholder="Type here" name="name" formControlName="name" readonly>
          </div>
          <div class="col">
            <label for="Agency Name">Mobile Number</label>
            <input type="text" class="form-control" placeholder="Type here" name="mobile" formControlName="mobile"readonly>
        
          </div>
          <div class="col">
            <label for="Agency Name">Mobile Number</label>
            <input type="text" class="form-control" placeholder="Type here" name="mobile" formControlName="mobile"readonly>
        
          </div>
          <div class="col">
            <label for="RERA Number">Email Id</label>
            <input type="text" class="form-control" placeholder="Type here" name="email" formControlName="email"readonly>
                
          </div>
          <div class="col">
            <label for="RERA Number">Date Added</label>
            <input type="text" class="form-control" placeholder="Type here" name="dateAdded" formControlName="dateAdded"readonly>
        
          </div>
          <div class="col">
            <label for="RERA Number">Date Approved</label>
            <input type="text" class="form-control" placeholder="Type here" name="dateApproved" formControlName="dateApproved"readonly>
                
          </div>
          <div class="col">
            <label for="Operating since">City</label>
            <input type="text" class="form-control" placeholder="Type here" name="city" formControlName="city"readonly>

          </div>
          <div class="col">
            <label for="Operating since">Remarks</label>
            <input type="text" class="form-control" placeholder="Type here" name="remarks" formControlName="remarks"readonly>

          </div>
        
          <div class="col">
            <button class="btn btn-primary pb-save" type="submit">save</button>
          </div>
        </div>
      </form>
  
  
  
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="all-leads mt-5">
            <div class="card shadow mb-4">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <h3 class="d-inline-block mr-3 mb-0" style="color: rgb(177, 128, 128);">Team Members</h3>
                  <span>Search:
                  </span>
                </div>
                <div class="table-responsive">
                  <table class="table table-hover agent-table">
                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>Client Name</th>
                            <th>Mobile Number</th>
                            <th>Email ID</th>
                            <th>Date Added</th>
                            <th>Date Approved</th>
                            <th>City</th>
                            <th>Remarks</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let salesRecords of saleRecords">
                            <td>
                            <input type="checkbox" (change)="selectedSaleRec(salesRecords)">
                            </td>
                            <td>{{salesRecords.name}}</td>
                            <td>{{salesRecords.mobile}}</td>
                            <td>{{salesRecords.email}}</td>
                            <td>{{salesRecords.dateAdded}}</td>
                            <td>{{salesRecords.dateApproved}}</td>
                            <td>{{salesRecords.city}}</td>
                            <td>{{salesRecords.remarks}}</td>
                            
                                <td><input type="text" style="text-align: center;width:100px"  class="text text-success"  value="Submitted" readonly></td>
                            

                            
                            <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
