import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,NavigationEnd, Router } from '@angular/router';
import { EventBus, EmitEvent, Events } from 'src/app/common/events/event-bus.service';

import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';



export interface ISubmenuOption {
  submenuId: number;
  label: string;
  routeUrl?: string;
  sideSubMenuicon:string;
  active: boolean;
}
export interface IMenuOption {
  label: string;
  active: boolean;
  routeUrl?: string;
  sideMenuicon:string;
  submenuList?: Array<ISubmenuOption>;




}

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.css']
})
export class MobileHeaderComponent implements OnInit {
navSideBar:boolean=false;
closeIcon:boolean=false;
sideBarNav:boolean=true;
subMenuOpenFlag: boolean = false;
currentUrl;
personalDetails:any;
propertyImages = [];
isNexusAddonOpen = false;
  subMenuLabelColl:boolean=false;
  subMenuLabelSkip:boolean;

menuOptions: Array<IMenuOption> = [
  {
    label: 'Dashboard',
    active: true,
    routeUrl:'dashboard',
    sideMenuicon:'assets/img/dashboard.svg'
  },
  {
    label: 'My Profile',
    active: false,
    routeUrl:'associateMyProfile',
    sideMenuicon:'assets/mobile/img/account-circle-fill.svg'
  },

  
  {
    label: 'Search Properties',
    active: false,
    routeUrl:'search-prop-page1',
    sideMenuicon:'assets/img/product.png'
  },
  {
    label: 'My Favorites',
    active: false,
    routeUrl:'favorite-listings',
    sideMenuicon:'assets/mobile/img/heart-fill.svg'
  },

  {
    label: 'My Team',
    active: false,
    routeUrl:'myTeam',
    sideMenuicon:'assets/img/Viewed Leads.svg'
  },
  {
    label: 'Choose Plans',
    active: false,
    routeUrl:'choosePlan',
    sideMenuicon:'assets/img/money.svg'
  },
  {
    
    label: 'Post Property',
    active: false,
    // routeUrl:'post-property/new',
    routeUrl:'propertyModules',
    sideMenuicon:'assets/img/projectfinance.svg'

  
  },
  {
    
    label: 'My Properties',
    active: false,
    // routeUrl:'post-property/new',
    routeUrl:'myProperties',
    sideMenuicon:'assets/mobile/img/real-estate.png'

  
  },
 
  // {
  //   label: 'My Profile',
  //   active: false,
  //   routeUrl:'associateMyProfile',
  //   sideMenuicon:'assets/img/user-line.svg'
    
  // },
 
 

  // {
  //   label: 'My Profile',
  //   active: false,
  //   routeUrl:'myProfile',
  //   sideMenuicon:'assets/img/Viewed Leads.svg'
  // },
  
 
  // {
  //   label: 'My Properties',
  //   active: false,
  //   routeUrl:'myProperties',
  //   sideMenuicon:'assets/img/myproperty.svg'

  
  // },
  // {
  //   label: 'My Projects',
  //   active: false,
  //   routeUrl:'myProjects',
  //   sideMenuicon:'assets/img/myproject.svg'
  //  },
  //  {
    
  //   label: 'My Leads',
  //   active: false,
  //   routeUrl:'leads',
  //   sideMenuicon:'assets/img/projectfinance.svg'    
  // },
  // {
    
  //   label: 'View Leads',
  //   active: false,
  //   routeUrl:'myViewLead',
  //   sideMenuicon:'assets/img/Viewed Leads.svg'
  // },

  
  // {
    
  //   label: 'Sales',
  //   active: false,
  //   routeUrl:'sales',
  //   sideMenuicon:'assets/img/sale.svg'

  
  // },
  {
    label: 'Nexus Ad-On',
    active: false,
    routeUrl: '',
    sideMenuicon: 'assets/img/sale.svg',
    submenuList: [
      {
        submenuId: 1,
        label: 'Loans',
        routeUrl: 'loans',
        sideSubMenuicon:'assets/img/loan.svg',
        active: false,
      },
      {
        submenuId: 2,
        label: 'CIBIL Score',
        routeUrl: 'cibil',
        sideSubMenuicon:'assets/img/cibil.svg',
        active: false,
      },
      {
        submenuId: 3,
        label: 'Project Finance',
        routeUrl: 'projectFinance',
        sideSubMenuicon:'assets/img/projectfinance.svg',
        active: false,

      },
       {

         submenuId: 11,
         label: 'Solar Enquiry',
         routeUrl:'solarEnquiry',
         active: false,
         sideSubMenuicon:'assets/img/Solar Enquiry.svg',
        
       },
       {

        submenuId: 11,
        label: 'Furniture Enquiry',
        routeUrl:'Furniture-Enquiry',
        active: false,
        sideSubMenuicon:'assets/img/furniture-svgrepo-com.svg',
       
      },
      {

        submenuId: 11,
        label: 'Interior Enquiry',
        routeUrl:'Interior-Enquiry',
        active: false,
        sideSubMenuicon:'assets/img/home-2-svgrepo-com.svg',
       
      },
      {

        submenuId: 11,
        label: 'Packers&Movers Enquiry',
        routeUrl:'Packers-&-Movers-Enquiry',
        active: false,
        sideSubMenuicon:'assets/img/truck.svg',
       
      },
       
      {
        submenuId: 4,
        label: 'CCTV Enquiry',
        routeUrl: 'cctvEnquiry',
        active: false,
        sideSubMenuicon:'assets/img/cctv.svg',

      },
      {

        submenuId: 15,
        label: 'Land Survey',
        routeUrl:'land-survey',
        active: false,
        sideSubMenuicon:'assets/img/sale.svg',

      },{

        submenuId: 16,
        label: 'Land Fencing',
        active: false,
        routeUrl:'land-fencing',
        sideSubMenuicon:'assets/img/sale.svg',

      },
      // {
      //   submenuId: 5,
      //   label: 'Interior Design Enquiry',
      //   routeUrl: 'interiorDesignEnquiry',
      //   active: false,
      // },
     
      // Additional submenus can be added here...
    ]
  },
  {
    
    label: 'Support & Help',
    active: false,
    routeUrl:'Support&Help',
    sideMenuicon:'assets/mobile/img/customer-service.png'

  
  },
  {
      
    label: 'Billing',
    active: false,
    routeUrl:'payment-log',
    sideMenuicon:'assets/mobile/img/wallet.png'

  
  },
  {
      
    label: 'Change Password',
    active: false,
    routeUrl:'change-password',
    sideMenuicon:'assets/img/changepwdicon.png'

  
  },
  {
      
    label: 'Log Out',
    active: false,
    routeUrl:'login',
    sideMenuicon:'assets/mobile/img/logout.png'

  
  },
 
  // {
    
  //   label: 'Reviews & Ratings',
  //   active: false,
  //   routeUrl:'reviewsAndRatings',
  //   sideMenuicon:'assets/img/Reviews.svg'

  
  // },

 
  // {
    
  //   label: 'Lead Center',
  //   active: false,
  //   routeUrl:'',
  //   sideMenuicon:'ri-group-3-line'

  
  // },
  // {
  //   label: 'Deals',
  //   active: false,
  //   routeUrl:'',
  //   sideMenuicon:'ri-shake-hands-line'

  
  // },
  // {
  //   label: 'Loans',
  //   active: false,
  //   routeUrl:'',
  //   sideMenuicon:'ri-money-rupee-circle-line'

  
  // },
  // {
  //   label: 'Support',
  //   active: false,
  //   routeUrl:'',
  //   sideMenuicon:'ri-settings-2-line'

  
  // },
]
  constructor( private router: Router,
    private route: ActivatedRoute,
    private eventBus: EventBus,
    private rsbService:RsbService,
    private lookupModel:RsbLookupModel) { }

  ngOnInit(): void {
    this.getProfile();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url !== '/') {
          const split = val.url.split('/');
          if (split.length === 2) {
            this.currentUrl = split[1];
            if (this.currentUrl) {
              this.menuOptions.map((obj) => {
                if (obj.routeUrl === this.currentUrl) {
                  obj.active = true;
                } else {
                  obj.active = false;
                }
              })
            }
          }
        }
      }
    })

  }
  goToMyprofile(): void{
    this.subMenuLabelColl=false;
    this.navSideBar = false;
    this.router.navigate(['associateMyProfile'])
  }
  collapse(){
    this.subMenuLabelColl=!this.subMenuLabelColl;
  }
  closeCollapse(){
    this.subMenuLabelColl=false;
    this.navSideBar = false;
  }
  toggleNexusAddon(event: Event) {
    event.stopPropagation(); // Prevent the event from propagating to the parent elements
    this.isNexusAddonOpen = !this.isNexusAddonOpen;
  }
  openMenuItem(menu: IMenuOption): void {
    this.menuOptions.forEach(item => {
      item.active = false;
      if (item.submenuList) {
        item.submenuList.forEach(submenu => submenu.active = false);
      }
    });
  
    menu.active = !menu.active;
  
    if (menu.label === 'Nexus AddOn' && menu.submenuList) {
      this.subMenuOpenFlag = !this.subMenuOpenFlag;
    } else {
      this.subMenuOpenFlag = false;
    }
  
    if (menu.routeUrl && menu.active) {
      this.router.navigate([menu.routeUrl]);
    }
  }
  
  openSubmenuItem(menu: IMenuOption, submenu: ISubmenuOption): void {
    if (!submenu.active) {
      menu.submenuList.forEach(item => {
        item.active = false;
      });
    }
    submenu.active = !submenu.active;
    if (submenu.active && submenu.routeUrl) {
      this.router.navigate([submenu.routeUrl]);
      this.eventBus.emit(new EmitEvent(Events.menuItemSelected, { title: submenu.label }));
    }
    this.navSideBar = false;
  }
  toggleNav() {
    this.navSideBar = !this.navSideBar;
    this.closeIcon=true;
   
  }
  closeNav() {
    this.navSideBar = false;
  }
  getProfile() {
    this.rsbService.getAssociateProfileInfo().subscribe((rsp) => {
      if(rsp.statusCode === 0) {
        this.personalDetails=rsp.contents[0].personalDetails;
        this.propertyImages = [];
        if(this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.profilePic ) {
        this.propertyImages.push(this.lookupModel.getLoggedUser().personalDetails && this.lookupModel.getLoggedUser().personalDetails.profilePic ? this.lookupModel.getLoggedUser().personalDetails.profilePic : '')
        
      }
      }
    })
  }

  
    

}
