import { Component, ElementRef, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
import * as d3 from 'd3';
import { title } from 'process';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-mb-my-team',
  templateUrl: './mb-my-team.component.html',
  styleUrls: ['./mb-my-team.component.css']
})
export class MbMyTeamComponent implements OnInit {
  p: number = 1;
  itemsPerPage: number = 5;
  searchText: string = ''; 
  getMyTeamMembers: Array<any> = [];
  filteredMembers: Array<any> = []; 
  isShow:boolean=false;

  member = {
    associateId: this.lookupModel.getLoggedUser().associateId,
    name: '',
    mobileNumber: ''
  };

  constructor(
    private rsbService: RsbService,
    private lookupModel: RsbLookupModel,
    private el: ElementRef,
    private toastrService: ToastrService

  ) { }

  ngOnInit(): void {
   
    this.getTeamMembers();
  }
 
  addPage(): void{
    this.isShow=true;
  }
  closePage(): void{
    this.isShow=false;
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.rsbService.isMobileExist(this.member).subscribe((rsp) => {
        if (rsp.statusCode === 0) {
          this.rsbService.myTeamMembers(this.member).subscribe(
            (rsp) => {
              this.getTeamMembers();
              form.reset();
            },
            (error) => {
              console.error('Error adding member:', error);
            }
          );
          this.toastrService.success('Customer has been created successfully.', 'Success');


        }
        else if(rsp.statusCode===3){
          this.toastrService.error('Mobile number already exists.', 'Error');


        }
        else{
          this.toastrService.error('Something went wrong .', 'Error');


        }
      })

    }
  }

  getTeamMembers() {
    this.rsbService.getTeamMembers({}).subscribe(
      (rsp) => {
        if (rsp.statusCode === 0) {
          this.getMyTeamMembers = rsp.contents;
          this.applyFilter(); 
        } else {
        }
      },
      (error) => {
        console.error('Error fetching team members:', error);
      }
    );
  }

  applyFilter() {
    if (!this.searchText) {
      this.filteredMembers = this.getMyTeamMembers;
    } else {
      const lowercasedSearchText = this.searchText.toLowerCase();
      this.filteredMembers = this.getMyTeamMembers.filter(member =>
        (member.personalDetails.firstName?.toLowerCase().startsWith(lowercasedSearchText)) ||
        (member.personalDetails.mobile?.toLowerCase().startsWith(lowercasedSearchText)) ||
        (member.personalDetails.email?.toLowerCase().startsWith(lowercasedSearchText)) ||
        (member.city?.toLowerCase().startsWith(lowercasedSearchText))
      );
    }
  }
  onSearchChange() {
    this.applyFilter();
  }
}