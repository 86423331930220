import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { SeoService } from 'src/app/common/services/seo.service';
import { RsbService } from 'src/app/service/rsb.service';
import { RequestSiteVisitPopupComponent } from '../request-site-visit-popup/request-site-visit-popup.component';
import { SignupPopupComponent } from '../signup-popup/signup-popup.component';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { SharePopUpComponent } from '../share-pop-up/share-pop-up.component';

@Component({
  selector: 'app-favorite-listings',
  templateUrl: './favorite-listings.component.html',
  styleUrls: ['./favorite-listings.component.css']
})
export class FavoriteListingsComponent implements OnInit {
  changeEvent(arg0: string, changeEvent: any) {
    throw new Error('Method not implemented.');
  }
  listings:Array<any> = [];
  selectedProp:boolean=false;
  property:any = {}
  showFullContent: boolean[] = [];

  private cityTextRef: ElementRef;
  @ViewChild('cityEntryBox') cityEntryBox!: ElementRef;
  cities: any = [];
  isActive: boolean = true;

  @ViewChild('cityTextRef') set getCityEle(cityTextRef: ElementRef) {
    this.cityTextRef = cityTextRef;
    if (this.cityTextRef && this.lookupModel.isBrowser) {
      // this.prepareCityAutoComplete();
      // if(!this.cities.length && this.searchInt){
      //console.log(this.lookupModel.getCurrentLocation());
      // let obj = this.lookupModel.getCurrentLocation();
      // let latlang = {lat:obj.latitude,lng:obj.longitude};
      // this.selectedCity.cityName = obj.city;
      if(!this.cities || !this.cities.length){
     let lat = this.queryParams.lat.split(",");
     let lng = this.queryParams.long.split(",");
     let loc = this.queryParams.location.split(",");
     let pin = this.queryParams.zip.split(",");
     for(var x=0;x<lat.length;x++){
     let obj={
       cityName: !x ? this.queryParams.city:loc[x-1],
       macroCity: !x ? this.queryParams.city:loc[x-1],
       cityBounds: !x ? JSON.parse(this.queryParams.cityBounds):"",
       zip: pin[x],
       lat: lat[x],
       long: lng[x],
     }
     this.cities.push(obj);
     }
     //console.log(this.cities);
      this.geoCoder = new google.maps.Geocoder;
      this.requestPlace = {
        componentRestrictions: {
          country: this.lookupModel.selectedCountry.twoDigitCountryCode,
        },
        types: this.cities.length ? ['geocode'] : ['(cities)'],
        bounds: this.cities[0].cityBounds,
        strictBounds: true
      }
      this.cityAutocomplete = new google.maps.places.Autocomplete(this.cityTextRef.nativeElement, this.requestPlace);
      this.cityAutocomplete.addListener('place_changed', this.changeEvent);
    }
     // this.getAddress(latlang); 
  }
    //}
  }
  private localityTextRef: ElementRef;
  private requestPlace: any;
  subCatName: any = [];
  searchboxFocus: boolean = true;
  searchMore: boolean;
  searchInt: boolean = true;
  @ViewChild('localityTextRef') set getLocalityEle(localityElement: ElementRef) {
    if (localityElement && this.lookupModel.isBrowser) {
      this.localityTextRef = localityElement;
    }
  }
  private geoCoder;
  private cityAutocomplete: any;
  private localityAutocomplete: any;
  cityBounds: any;
  firstTwoValues: any[] = [];
  lastValues: any[] = [];
  selectedcodeType: string;
  listingContents: number = 0;
  nearByListingContents: number = 0;
  minDistance: Number = 1000;
  maxDistance: Number = 20000;
  catList: Array<any> = [];
  attrList: Array<any> = [];
  subCatList: Array<any> = [];
  selBudgetCount: any = [];
  bugetList: Array<any> = [];
  selectedCity: any;
  selectedLocality: any;
  filterForm: FormGroup;
  selectedCategory: any;
  selectedSubCat: any;
  selectedBudget: any;
  selectedAttribute: any;
  selectedModule: any;
  showViewFlag: boolean = false;
  p: number = 1;
  count: number = 0;
  queryParams: any;
  vendorTypes: []
  sortValue: string = ''
  allListings: any[] = [];
  selectedSubCatList = [];
  selAttributeProperty = [];
  listingLength: any;
  attributeCols: string;
  text: any = '';
  textNew: any = '';
  urlHeading: any;
  showMoreProperties: Boolean = false;
  isDropdownVisible: boolean = false;
  isSubCategoryDisplay: boolean = false;
  isBudgetDisplay: boolean = false;
  shortenedContent: string;
  combinedContent: any;
  getPlanId:any;
  selectedListingIds: string[] = [];
  selectedPropCount:any;
  properties:any;
  copyMessage: string = '';
  isFixed = false;
  isMobileLayout:boolean=false;

  favoriteActive:boolean=false;
  favoriteStates: { [key: string]: boolean } = {};
  selectedSubCats: string[] = [];
  categoryName:any;
  constructor( private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private fb: FormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private seoService: SeoService,
    private http:HttpClient,) {
      this.rsbService.currentParams.subscribe((params) => {
        this.queryParams = params;
      });
      this.listings.forEach(() => this.showFullContent.push(false));
     }

  ngOnInit(): void {
    // alert("getting listingss favorites");
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.selectedCategory = this.lookupModel.getCategory();
    this.getPlanId=this.lookupModel.getLoggedUserPersonalDetails().planId;
    
    this.rsbService.getFavoriteProperties().subscribe((res)=>{
      // alert(JSON.stringify(res))
      if(res.statusCode === 0 && res.contents.length) {
        this.listings = res.contents;
       this.categoryName=res.categoryName;
        
      }
    })
    this.loadDetails();

    this.checkScroll();
  }
  toggleActive() {
    this.isActive = !this.isActive;
  }
  

  getLocationLength(obj): number {
    let count = 0;
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '' && key != "aboutLocality") {
        count++;
      }
    }

    // this.getFirstTwoNonNullValues(obj)
    // this.getLastNonNullValues(obj);
    return count;
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.checkScroll();
  }

  selectedFavCategory(event: Event) {
    const selectedFavCat = (event.target as HTMLSelectElement).value;
    console.log(selectedFavCat);
    switch(selectedFavCat){
      case 'Favorite Properties':
        return this.router.navigate(['favorite-listings']);
        case 'Favorite Projects':
          return this.router.navigate(['favorite-projects']);
          case 'Favorite Pg':
            return this.router.navigate(['favorite-hostels'])



    }
  }
  
  checkScroll() {
    // Adjust the scroll threshold as needed
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    this.isFixed = scrollPosition > 100; // 100px threshold
  }
  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
  ngAfterViewInit() {

  }
  enableSubCategoryMenu(): void {
    this.attrList.forEach((obj) => {
      obj.isAttrDisplay=false;
    })
    this.isBudgetDisplay = false
    this.isSubCategoryDisplay = !this.isSubCategoryDisplay
  }
  enableBudgetMenu(): void {
    this.attrList.forEach((obj) => {
      obj.isAttrDisplay=false;
    })
    this.isSubCategoryDisplay = false
    this.isBudgetDisplay = !this.isBudgetDisplay
  }

  enableFilters(findex: number): void {
    this.isSubCategoryDisplay = false
    this.isBudgetDisplay = false
    this.attrList.forEach((obj ,index) => {
      findex != index ? obj.isAttrDisplay=false : "";
    })
    this.attrList[findex]['isAttrDisplay'] = !this.attrList[findex]['isAttrDisplay']
  }
 
  selectedCheckProp(property: any) {

    console.log(property);
    this.properties = property
    // this.selectedProp = this.listings.some(p => p.selected);
    const selectedListingId = property.listingId; 

    if (property.selected) {
      if (!this.selectedListingIds.includes(selectedListingId)) {
        this.selectedListingIds.push(selectedListingId);
      }
    } else {
      const index = this.selectedListingIds.indexOf(selectedListingId);
      if (index !== -1) {
        this.selectedListingIds.splice(index, 1);
      }
    }
    this.selectedPropCount = this.selectedListingIds.length; 

    this.selectedProp = this.selectedListingIds.length > 0;
    // alert(this.selectedListingIds)
    this.lookupModel.setSelectedPropListingIDs(this.selectedListingIds);
  

  }

  getPropertyListings(event?: any): void {
    debugger;
    if (event?.endReached) {
      let listingIds = this.listings.map((obj) => {
        return obj.listingId
      })
    }
    if (this.cities.length) {
      let location = "", pincode = "", lat = "", long = "";
      this.cities.filter((item, index) => {
        index ? location = location ? location + "," + item.cityName : item.cityName : "";
        pincode = pincode ? pincode + "," + item.zip : item.zip;
        lat = lat ? lat + "," + item.lat : item.lat;
        long = long ? long + "," + item.long : item.long;
      })
      const args = {
        moduleId: [this.queryParams.module],
        categoryId: this.cat.value,
        subCategoryId: this.getSubCategoryFilterValues(),
        attributes: this.getAttributeFilterValues(),
        budgets: this.getBudgetFilterValues(),
        priceTypeId: this.selectedBudget ? this.lookupModel.getCountry().codeId : '',
        city: this.cities[0].cityName,//this.selectedCity ? this.selectedCity.cityName : this.queryParams.city,
        location: location,//this.selectedCity ? "" : this.queryParams.location,
        area: this.locality.value,
        macroCity: this.selectedCity ? this.selectedCity.macroCity : '',
        countryId: this.lookupModel.getCountry().codeId,
        latitude: lat,//this.selectedLocality ? this.selectedLocality.latitude.toFixed(2) : (this.selectedCity ? this.selectedCity.lat : ''),
        longitude: long,//this.selectedLocality ? this.selectedLocality.longitude.toFixed(2) : (this.selectedCity ? this.selectedCity.long : ''),
        max: this.selectedLocality ? 3000 : 0,
        zip: pincode,//this.selectedCity ? this.selectedCity.zip : this.queryParams.zip,
        listings: this.listings,
        customerListings: this.lookupModel.getLoggedUser() ? this.lookupModel.getLoggedUser().listings : [],
        minDistance: this.minDistance,
        maxDistance: this.maxDistance,
        allSubCat: this.selectedCategory.subCategory,
        skipListingRecords: this.listingContents,
        nearBySkipListingRecords: this.nearByListingContents,
        sortVal: this.sortValue
      };
      this.listings = [];
      this.rsbService.getListingsBasedOnFilters(args).subscribe((rsp) => {
        if (rsp.statusCode === 0) {
          let list = [];
          if (rsp.contents && rsp.contents.length > 0) {

            list = rsp.contents;
            this.listingContents = list.length;
            //alert(list.length);
            // if(rsp.nearByLocationsContents && rsp.nearByLocationsContents.length > 0) {
            //   list.push(rsp.nearByLocationsContents);
            // }
            // this.listings = this.getText(list);

          }
          if (rsp.nearByLocations && rsp.nearByLocations.length > 0) {
            this.nearByListingContents = rsp.nearByLocations.length
            list = list.concat(rsp.nearByLocations);
            this.minDistance = rsp.maxDistance;
            this.maxDistance = rsp.maxDistance + 20000;
          }
          this.allListings = [...list];
          this.listingLength = rsp.count;
          if (this.listingLength > 0) {
            this.showMoreProperties = true
          }
          //alert("getting here");
          this.listings = list;
          this.listings.filter(re=>{
            if(re.locationHighlights){
            let obj= JSON.parse(JSON.stringify(re.locationHighlights));
            delete obj.aboutLocality;
            re.locationHighlightsNew = obj;
            }
          });
          this.listings = this.listings.sort((a, b) => a.price - b.price);
          this.count = rsp.count;
          let text = '';
          if (this.beds.value !== 'abc' && this.selectedAttribute) {
            text += this.selectedAttribute.propertyName + this.selectedAttribute.attributeName + ' ';
          }
          if (this.subCat.value) {
            text += this.selectedCategory.categoryName + ' ' + this.selectedSubCat.subCategoryName + '\'s';
          }
          else if (this.listings.length > 0) {
            text += this.subCatList[0].subCategoryName + ' properties';
          } else {
            text += 'properties';
          }
          text += ' available for ' + this.lookupModel.getModule().moduleName
          if (this.selectedCity) {
            text += ' at ' + this.selectedCity.name;
          }

          this.prepareKeywords(text);
          this.router.navigate(
            ['listings', this.city.value ? this.city.value : 'all', this.selectedCategory.categoryName],
            {
              queryParams: {
                cat: this.selectedCategory.categoryId,
                subcat: this.getSubCategoryFilterValues().toString(),
                module:this.queryParams.module,
                location:location,// this.city.value ? this.city.value : this.queryParams.location,
                city: this.cities[0].cityName,//this.selectedCity ? this.selectedCity.macroCity : this.queryParams.city,
                zip: pincode,//rsp.zip ? rsp.zip : this.queryParams.zip,
                country: this.lookupModel.getCountry().codeId,
                cityBounds:JSON.stringify(this.cities[0].cityBounds),
                // loc: this.locality.value ? this.locality.value : null,
                lat: lat,//this.selectedCity ? this.selectedCity.lat:"",
                long: long,//this.selectedCity ? this.selectedCity.long:"",
              }
            }
          );
        }
      });
    }
  }
  loadDetails(): void {
    this.selectedModule = this.lookupModel.getModuleList().find((obj) => {
      return obj.moduleId === this.queryParams.module;
    });
    if (this.queryParams.subcat) {
      const subCatArray = this.queryParams.subcat.split(',');
      this.subCatName =[];
      //this.selSubCatCount=subCatArray.length;
      this.selectedCategory.subCategory.forEach((item, index) => {
        if (subCatArray.includes(item.subCategoryId) === true) {
          item["isSelected"] = true;
          this.subCatName.push(item.subCategoryName);
        }
      });
      if (this.queryParams.subcat) {
        this.lookupModel.setSubCategory(this.queryParams.subcat && this.queryParams.subcat.includes(',') ? this.queryParams.subcat.split(',') : [this.queryParams.subcat]);
      }

      //console.log("subCategoryArray  is",this.selectedCategory.subCategory);
    }
    this.selectedcodeType = this.lookupModel.getCountry().codeType;
    //this.subCatList = [{ subCategoryName: 'All ' + this.selectedCategory.categoryName, subCategoryId: '' }];
    this.subCatList = this.selectedCategory.subCategory;
    this.selectedCity = this.lookupModel.getCity();
    if (this.selectedCity) {
      this.cityBounds = this.selectedCity.cityBounds;
    }
    if (this.lookupModel.getLocality()) {
      this.selectedLocality = this.lookupModel.getLocality();
    }
    this.filterForm = this.fb.group({
      cat: [this.selectedCategory ? this.selectedCategory.categoryId : ''],
      subCat: [this.selectedSubCat ? this.selectedSubCat.subCategoryId : ''],
      //city: [this.selectedCity && this.selectedCity.cityName ? this.selectedCity.cityName : ''],
      city: [''],
      locality: [this.selectedLocality ? this.selectedLocality.localityName : ''],
      budget: [''],
      beds: ['']
    });
    this.getFilterProperties();
    this.getPropertyListings();
    this.locality.disable();
    this.showViewFlag = true;
  }


  shareSingleProp(property:any){
    // alert(JSON.stringify(property.locationHighlightsNew));

        const listingId=property.listingId;
    const moduleName=this.lookupModel.getModule().moduleName;
    
    // alert(JSON.stringify(moduleName))
    const baseUrl = 'https://pillarblocks.com/'+moduleName+'/'+property.city+'-'+this.categoryName+'-'+'property/'+this.lookupModel.getLoggedUser().associateId+'?listingId='+listingId;
    const modelRef =this.modalService.open(SharePopUpComponent);
    modelRef.componentInstance.url = baseUrl

  }
  favorites(){
    const args={
      associateId:this.lookupModel.getLoggedUser().associateId,
      selectedListingIds:this.selectedListingIds
    }
    // alert(JSON.stringify(args))

    this.rsbService.favoriteProperties(args).subscribe((res)=>{
      // alert(res)
    })
  }
  favoriteSingleProp(property:any){
    
    // alert("gettting"+this.selectedListingIds)
    this.favoriteStates[property.listingId] = true;
    this.selectedListingIds.push(property.listingId);
    this.favoriteActive=true;
    const args={
      associateId:this.lookupModel.getLoggedUser().associateId,
      selectedListingIds:this.selectedListingIds
    }
    // alert(JSON.stringify(args))

    this.rsbService.favoriteProperties(args).subscribe((res)=>{
      // alert(JSON.stringify(res))
    })
    

   
  }
  cancelSelection() {
    this.selectedListingIds = [];
    this.selectedPropCount = 0;
    this.selectedProp = false;
    this.listings.forEach(property => {
      property.selected = false;
    });
  }
  sharePopUp(){
    const baseUrl = 'https://pillarblocks.com/'+this.properties.moduleName+'/'+this.properties.city+'-'+this.properties.categoryName+'-'+'property/'+this.lookupModel.getLoggedUser().associateId+'?listingId='+this.selectedListingIds.join(",");
    const modelRef =this.modalService.open(SharePopUpComponent);
    modelRef.componentInstance.url = baseUrl

  }
  copyLink() {
    const baseUrl = 'https://pillarblocks.com/'+this.properties.moduleName+'/'+this.properties.city+'-'+this.properties.categoryName+'-'+'property/'+this.lookupModel.getLoggedUser().associateId+'?listingId='+this.selectedListingIds.join(",");

    if (navigator.clipboard) {
      navigator.clipboard.writeText(baseUrl).then(() => {
        this.copyMessage = 'Link copied!'; 
        this.clearCopyMessageAfterDelay(); 
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = baseUrl;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      this.copyMessage = 'Link copied!'; 
      this.clearCopyMessageAfterDelay(); 
    }
  }

  clearCopyMessageAfterDelay() {
    setTimeout(() => {
      this.copyMessage = ''; 
    }, 2000); 
  }
  // close(rsn?: any): void {
  //   this.activeModal.close(rsn);
  // }

  // url(prop: any,queryParams:any) {
  //   console.log("query params",queryParams)
  //   const httpUrl = 'https://pillarblocks.com/associate/';
  //   return httpUrl;
  // }
 
 
  // url(prop: any, queryParams: any): string {
  //   this.selectedModule.moduleName+'/'+queryParams.city+'-'+this.selectedCategory.categoryName+'-'+'property/'+this.lookupModel.getLoggedUser().associateId+'?listingId='+prop.listingId;
  //   console.log("query params", queryParams);
  //   let httpUrl = baseUrl;
  //   return httpUrl
  //   // if (queryParams) {
  //   //   let params = new HttpParams();
  //   //   for (let key in queryParams) {
  //   //     if (queryParams.hasOwnProperty(key)) {
  //   //       params = params.set(key, queryParams[key]);
  //   //     }
  //   //   }
  //   //   httpUrl += `?${params.toString()}`;
  //   // }
  //   // return httpUrl;
  // }


  // fetchData(prop: any, queryParams: any) {
  //   const url = this.url(prop, queryParams);
  //   return this.http.get(url);
  // }
  

  getIcon(key: string): string {
    switch (key) {
      case 'banks':
        return 'assets/img/bankIcon.svg';
      case 'hospitals':
        return 'assets/img/hospital-fill.svg';
      case 'colleges':
        return 'assets/img/school-fill.svg';
        case 'transport':
        return 'assets/img/truck-fill.svg';
        case 'touristSpots':
        return 'assets/img/tourist-fill.svg';
        case 'hotelResorts':
        return 'assets/img/Food.svg';
        case 'ResidentialComplex':
          return 'assets/img/apartments-fill.svg';
          case 'mallsMultiplex':
            return 'assets/img/apartments-fill.svg';
            case 'shoppingCenter':
            return 'assets/img/apartments-fill.svg';
          
        
      default:
        return undefined;
    }
  }

  filterBySubCategory(index: any, getListings?: boolean): void {
    this.minDistance = 1000;
    this.maxDistance = 20000;
    this.subCatList[index].isSelected = !this.subCatList[index].isSelected;
    //subCat['isSelected'] = !subCat['isSelected'];
    if (this.subCatList[index].isSelected) {
      this.subCatName.push(this.subCatList[index].subCategoryName);
    } else {
      var eleInd = this.subCatName.indexOf(this.subCatList[index].subCategoryName);
      this.subCatName.splice(eleInd, 1);
    }
    //this.selSubCatCount=this.subCatList[index].isSelected ? this.selSubCatCount + 1 : this.selSubCatCount - 1;
    console.log("getlistings is", this.subCatList[index]);
     }
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (this.cityEntryBox && !this.searchboxFocus) {
      if (!this.cityEntryBox.nativeElement.contains(event.target)) {
        // Click occurred outside the element
        this.searchboxFocus = true;
        this.searchMore = this.cities.length > 1 && this.searchboxFocus ? true : false;
      }
    }
  }
  startSearch(): void {
    this.searchboxFocus = false;
    this.searchMore = this.cities.length > 1 && this.searchboxFocus ? true : false;
  }
  removeEle(index: number) {
    this.cities.splice(index, 1);
    this.searchInt = false;
    if(this.cities.length<4){
    this.cityTextRef.nativeElement.disabled=null;
    }
    if(!this.cities.length){
      this.cityTextRef.nativeElement.placeholder="Search by Location, Project Name";
      //this.cityAutocomplete.unbind("bounds");
      this.cityAutocomplete.setTypes(['(cities)']);
      //this.cityAutocomplete.setBounds({});
      this.cityAutocomplete.setOptions({
        strictBounds: false,
      });
    }
  }
  choosePlanForShare(property:any){
    const firstName=this.lookupModel.getLoggedUser().personalDetails.firstName;
    const propImage=property.propertyPreviewImage
  
    // this.router.navigate(['choosePlan'])
    Swal.fire({
      title: "Hello!"+firstName,
      html: 'Please Subscribe any <span style="color:red">Plan</span> for sharing of properties',
      // imageUrl: propImage,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
       confirmButtonText: 'Plans'

    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/choosePlan']);
      }
    });
  }

 
  fetchDetailsFromUlr(params: any): Observable<any> {
    return Observable.create(observer => {
      this.rsbService.getActiveCategories(params.module).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents) {
          this.catList = rsp.contents;
          this.lookupModel.setCategoryList(this.catList);
          const cat = this.catList.find(item => item.categoryId === params.cat);
          if (cat) {
            this.selectedCategory = cat;
            this.lookupModel.setCategory(cat);

            if (params.subcat) {
              const subCatArray = this.queryParams.subcat.split(',');
              this.subCatName =[];
              //this.selSubCatCount=subCatArray.length;
              this.selectedCategory.subCategory.forEach((item, index) => {
                if (subCatArray.includes(item.subCategoryId) === true) {
                  this.subCatName.push(item.subCategoryName);
                  item["isSelected"] = true;
                }
              });
              if (this.queryParams.subcat) {
                this.lookupModel.setSubCategory(this.queryParams.subcat && this.queryParams.subcat.includes(',') ? this.queryParams.subcat.split(',') : [this.queryParams.subcat]);
              }
            }
            if (this.queryParams.city) {
              const city = { cityName: this.queryParams.location, macroCity: this.queryParams.city, zip: this.queryParams.zip, cityBounds: null, lat: 0, long: 0 };
              if (this.queryParams.lat) {
                city.lat = parseFloat(this.queryParams.lat);
                city.long = parseFloat(this.queryParams.long);
              }
              this.lookupModel.setCity(city);

            }
            observer.next('success');
            observer.complete();
          } else {
            observer.error();
          }
        } else {
          this.catList = [];
          observer.error();
        }
      }, (err) => observer.error());
    });
  }

 

  

 

  getFirstTwoNonNullValues(obj): void {
    let count = 0;
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null) {
        if (count < 2) {
          this.firstTwoValues.push({ key: key, value: obj[key] });
        }
        count++;
        if (count === 2) {
          break; // Exit the loop after finding the first two non-null values
        }
      }
    }
  }

  getLastNonNullValues(obj): void {
    let count = 0;
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null) {
        count++;
        if (count >= 2) {
          this.lastValues.push({ key: key, value: obj[key] });// Exit the loop after finding the first two non-null values
        }
      }
    }
  }



  getFilterProperties(): void {
    this.rsbService.getAllAttribtesForWebsiteFilters().subscribe((rsp) => {
      if (rsp.statusCode === 0 && rsp.contents) {
        this.attrList = rsp.contents;
        this.attrList.map(item => item.setAttrCount = []);
        const attr = this.attrList.find(item => item.isDefault);
        if (attr) {
          this.beds.setValue(attr.attributePropertyId);
        }
      } else {
        this.attrList = [];
      }
    });
    this.rsbService.getActiveBudgetsForFilters().subscribe((rsp) => {
      if (rsp.statusCode === 0 && rsp.contents) {
        this.bugetList = rsp.contents;
        const budget = this.bugetList.find(item => item.isDefault);
        if (budget) {
          this.budget.setValue(budget.budgetId);
        }
      } else {
        this.bugetList = [];
      }
    });
  }

 

  

  openProperty(property: any): void {
    //console.log(property);
    this.lookupModel.setProperty(property);
    this.lookupModel.setCategory(this.selectedCategory);
    this.lookupModel.setSubCategory(this.selectedSubCat);
    if (this.selectedLocality && this.selectedLocality.localityName) {
      this.lookupModel.setLocality(this.selectedLocality);
    }
    this.lookupModel.setCity({ name: this.city.value, bounds: this.cityBounds });
    const text = property.propertyTitle.replaceAll(' ', '-')
    this.router.navigate(
      ['property-details', text],
      {
        queryParams: {
          listingId: property.listingId,
          cat: property.categoryId,
          subcat: property.subCategoryId,
          module: property.moduleId,
          country: property.countryId ? property.countryId : this.lookupModel.getCountry().codeId,
          city:property.city,
          area:property.area,
          pincode:property.pincode,
        }
      }
    );
  }

  updateScrollPos(event: any): void {
    if (event.endReached) {
      let listingIds = this.listings.map((obj) => {
        return obj.listingId
      })
      console.log('User is near bottom uppending rows');
      //let newList = this.allListings.slice(this.listings.length, this.listings.length + 20);
      //this.listings = this.listings.concat(newList);
      //this.cdr.detectChanges();
      if (this.cities.length) {
        let location = "", pincode = "", lat = "", long = "";
        this.cities.filter((item, index) => {
          index ? location = location ? location + "," + item.cityName : item.cityName : "";
          pincode = pincode ? pincode + "," + item.zip : item.zip;
          lat = lat ? lat + "," + item.lat : item.lat;
          long = long ? long + "," + item.long : item.long;
        })
        let list = []
        const args = {
          moduleId: [this.selectedModule.moduleId],
          categoryId: this.cat.value,
          subCategoryId: this.getSubCategoryFilterValues(),
          attributes: this.getAttributeFilterValues(),
          budgets: this.getBudgetFilterValues(),
          priceTypeId: this.selectedBudget ? this.lookupModel.getCountry().codeId : '',
          city: this.cities[0].cityName,//this.selectedCity ? this.selectedCity.cityName : this.queryParams.city,
          location: location,//this.selectedCity ? "" : this.queryParams.location,
          area: this.locality.value,
          countryId: this.lookupModel.getCountry().codeId,
          latitude: lat,//this.selectedLocality ? this.selectedLocality.latitude.toFixed(2) : (this.selectedCity ? this.selectedCity.lat : ''),
          longitude: long,//this.selectedLocality ? this.selectedLocality.longitude.toFixed(2) : (this.selectedCity ? this.selectedCity.long : ''),
          max: this.selectedLocality ? 3000 : 0,
          macroCity: this.selectedCity ? this.selectedCity.macroCity : '',
          zip: pincode,//this.selectedCity ? this.selectedCity.zip : this.queryParams.zip,
          listings: listingIds,
          allSubCat: this.selectedCategory.subCategory,
          customerListings: this.lookupModel.getLoggedUser() ? this.lookupModel.getLoggedUser().listings : [],
          skipListingRecords: this.listingContents,
          nearBySkipListingRecords: this.nearByListingContents,
          minDistance: this.minDistance,
          maxDistance: this.maxDistance,
          sortValue: this.sortValue
        };
        args['listings'] = listingIds;
        this.rsbService.getListingsBasedOnFilters(args).subscribe((rsp) => {
          if (rsp.contents && rsp.contents.length > 0) {
            list = rsp.contents;
            this.listingContents += list.length;
            // if(rsp.nearByLocationsContents && rsp.nearByLocationsContents.length > 0) {
            //   list.push(rsp.nearByLocationsContents);
            // }
            // this.listings = this.getText(list);

          }
          if (rsp.nearByLocations && rsp.nearByLocations.length > 0) {
            this.nearByListingContents += rsp.nearByLocations.length;
            list = list.concat(rsp.nearByLocations);
            this.minDistance = rsp.maxDistance;
            this.maxDistance = rsp.maxDistance + 20000;
          }


          this.listings = this.listings.concat(list);
          // For example, show only the first 100 characters
          this.combinedContent = this.listings.join(' ');
          this.shortenedContent = this.combinedContent.substring(0, 100) + '...';
          this.showFullContent;

        })
      }
    }
  }
  toggleContent(index: number) {
    this.showFullContent[index] = !this.showFullContent[index];
  }

  

  prepareKeywords(text: string): void {
    let keywords = [];
    if (this.selectedCategory) {
      keywords.push(this.selectedCategory.categoryName);
    }
    if (this.selectedSubCat) {
      keywords.push(this.selectedSubCat.subCategoryName);
    }
    if (this.city.value) {
      const cityText1 = this.selectedCategory.categoryName + ' Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.city.value;
      const cityText2 = 'Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.city.value;
      keywords.push(cityText1);
      keywords.push(cityText2);
    }
    if (this.locality.value) {
      const locText1 = this.selectedCategory.categoryName + ' Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.locality.value;
      const locText2 = 'Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.locality.value;
      keywords.push(locText1);
      keywords.push(locText2);

    }
    keywords.push(text);
    let keyWordText = keywords.join(', ');
    this.seoService.updateTag('keywords', keyWordText);
  }

  getText(listings: Array<any>): Array<any> {
    listings.forEach(property => {
      let text = '';
      if (property.attributes) {
        property.attributes.forEach(attr => {
          if (attr.attributeName.search('Bed') !== -1) {
            text += attr.attributePropertyName + ' Bed, ';
          }
          if (attr.attributeName.search('Bath') !== -1) {
            text += attr.attributePropertyName + 'Bath, ';
          }
        })
      }
      text += property.propertySize + ' ' + property.measurementName;
      property.bedBathText = text;
    });

    return listings;
  }

  removeFilter(filedName: string): void {
    switch (filedName) {
      case 'subCat': this.subCat.setValue(''); break;
      case 'city': this.city.setValue(''); break;
      case 'locality': this.locality.setValue(''); break;
      case 'budget': this.budget.setValue(''); break;
      case 'beds': this.beds.setValue(''); break;
    }
  }

  sortBy(filedName: string, event: any): void {
    switch (filedName) {
      case 'price':
        if (event.target.value === "1") {
          this.listings = this.listings.sort((a, b) => b.price - a.price);
        } else {
          this.listings = this.listings.sort((a, b) => a.price - b.price);
        }
        break;
    }
  }

  filterByBudget(index: any, getListings?: boolean): void {
    this.minDistance = 1000;
    this.maxDistance = 20000;
    //console.log(this.bugetList[index]);
    //budget.isSelected = !budget.isSelected;
    this.bugetList[index].isSelected = !this.bugetList[index].isSelected;
    if (this.bugetList[index].isSelected) {
      this.selBudgetCount.push(this.bugetList[index].budgetName);
    } else {
      var eleInd = this.selBudgetCount.indexOf(this.bugetList[index].budgetName);
      this.selBudgetCount.splice(eleInd, 1);
    }
    //this.bugetList[index].isSelected = !this.bugetList[index].isSelected;
    //this.selBudgetCount = this.bugetList[index].isSelected ? this.selBudgetCount+1 : this.selBudgetCount-1;
    if (getListings) {
    }
  }

  filterByAttribute(pindex: any, cindex: any, getListings?: boolean): void {
    this.minDistance = 1000;
    this.maxDistance = 20000;
    //console.log(this.attrList[pindex].attributeProperties[cindex]);
    //attribute.isSelected = !attribute.isSelected;
    this.attrList[pindex].attributeProperties[cindex].isSelected = !this.attrList[pindex].attributeProperties[cindex].isSelected;
    // this.attrList[pindex].setAttrCount = this.attrList[pindex].attributeProperties[cindex].isSelected ? this.attrList[pindex].setAttrCount +1 : this.attrList[pindex].setAttrCount-1;
    if (this.attrList[pindex].attributeProperties[cindex].isSelected) {
      this.attrList[pindex].setAttrCount.push(this.attrList[pindex].attributeProperties[cindex].swatchName);
    } else {
      var eleInd = this.attrList[pindex].setAttrCount.indexOf(this.attrList[pindex].attributeProperties[cindex].swatchName);
      this.attrList[pindex].setAttrCount.splice(eleInd, 1);
    }
   
  }


  contactOwner(property: any): void {
    const selectedListingId = property.listingId;
    const modelRef = this.modalService.open(SignupPopupComponent, { size: 'lg', });
    modelRef.componentInstance.listingId = selectedListingId
    modelRef.result.then((response) => {
      // alert("getting response"+JSON.stringify(response)); 
      if (response.statusCode === 0) {
        const customerId = response.contents[0].customerId;
        const listingId = property.listingId;

        this.rsbService.addListingsFavourite(customerId, listingId).subscribe((res) => {
          // alert("getting addListingsFavourites"+res)
          if (res.statusCode === 0) {
            Swal.fire({
              title: "Success",
              text: "Thank you for your request.We are working hard to find the best deals for you.Shortly you will find a confirmation in your Account",
              icon: "success",
              confirmButtonText: 'Continue'

            });
          }
        })

      }
    });

  }
  requestSiteVisit(property: any) {
    const selectedListingId = property.listingId;
    const modelRef = this.modalService.open(RequestSiteVisitPopupComponent, { size: 'lg', });
    modelRef.componentInstance.listingId = selectedListingId
    modelRef.componentInstance.responseEventEmmiter.subscribe((response) => {
      // alert("getting response"+JSON.stringify(response)); 

      if (response) {
        const customerId = response.customerId;
        const listingId = property.listingId;
        const date = response.date;

        this.rsbService.addListingsFavourite(customerId, listingId, date, false).subscribe((res) => {
          if (res.statusCode === 0) {
          }
          // alert("getting addListingsFavourites"+res)
        })
      }
    });
  }

  addToUserListing(property): void {
    const args = {
      listings: property.listingId,
      listingCustomerId: this.lookupModel.getProperty().vendorId,
      vendorMobile: property.vendorDetails.mobileNumber,
      vendorEmail: property.vendorDetails.email
    };
    this.rsbService.addListingsToAccount(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        if (this.lookupModel.getLoggedUser().activeStatus) {
          this.router.navigate(['my-listing']);
        } else {
          this.router.navigate(['profile']);
        }
      }
    });
  }
 

 


  getBudgetFilterValues(): any[] {
    let budgetFil = [];
    this.bugetList.forEach(item => {
      if (item.isSelected) {
        const newFil = {
          from: item.min,
          to: item.max
        };
        budgetFil.push(newFil);
      }
    });
    //console.log('Budget Filters', budgetFil);
    return budgetFil;
  }

  getSubCategoryFilterValues(): any[] {
    //alert("getting here 1");
    let subCategoryFil = []
    this.selectedCategory.subCategory.forEach(prop => {
      if (prop.isSelected) {
        subCategoryFil.push(prop.subCategoryId)
      }
    });
    // alert("getting here");
    this.selectedSubCatList = subCategoryFil;
    //alert(this.selectedSubCatList.length);
    return subCategoryFil;
  }

  getAttributeFilterValues(): any[] {
    let attributeFil = [];
    this.selAttributeProperty = [];
    //this.selectedAttribute = this.queryParams.attribute;
    this.attrList.forEach(attr => {
      let attributeList = [];
      attr.attributeProperties.forEach(prop => {
        if (prop.isSelected) {
          // alert("getting here");
          attributeList.push(prop.propertyId);
          this.selAttributeProperty.push(prop.propertyId);
          //this.selectedAttribute.push(prop.propertyId);
        }
      });
      if (attributeList.length > 0) {
        const newAttr = {
          attribute: attributeList
        };
        attributeFil.push(newAttr);
      }
    });
    return attributeFil;
  }

  get cat() { return this.filterForm.get('cat'); }
  get subCat() { return this.filterForm.get('subCat'); }
  get city() { return this.filterForm.get('city'); }
  get locality() { return this.filterForm.get('locality'); }
  get budget() { return this.filterForm.get('budget'); }
  get beds() { return this.filterForm.get('beds'); }
}
