import { Injectable } from '@angular/core';
import { IServiceMethodMap, DataService } from '../common/services/data.service';
import { RsbLookupModel } from '../common/model/rsb-lookup.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageService, BUS_EVENTS } from '../common/events/message.service';
import { tap } from 'rxjs/operators';
import { Message } from '../common/events/message';
import { Subject } from 'rxjs';

import { AnyARecord } from 'dns';
export const SRV_OPS: IServiceMethodMap = {

  sendMobileOtp: {
    method: 'otp/sendMobileOtp',
  },
  resendMobileOtp: {
    method: 'otp/resendMobileOtp'
  },
  verifyMobileOtp: {
    method: 'otp/verifyMobileOtp'
  },
  associateCustomerVerifyMobileOtp: {
    method: 'otp/associateCustomerVerifyMobileOtp'
  },
  addInitiateVideoCall: {
    method: 'associate/addInitiateVideoCall'
  },
  addProjectsFavourite: {
    method: 'associate/addProjectsFavourite'
  },
  addPgToFavourite: {
    method: 'associate/addPgToFavourite'
  },
  pgCustomerVerifyMobileOtp: {
    method: 'associate/pgCustomerVerifyMobileOtp'
  },


  requestSiteVisit: {
    method: 'associate/addRequestSiteVisit'
  },
  requestSiteVisitForProjectList: {
    method: 'associate/addRequestSiteVisitForProjectList'
  },
  requestSiteVisitForPgList: {
    method: 'associate/addRequestSiteVisitForPgList'
  },

  isMobileExists: {
    method: 'isMobileExists'
  },
  sendEmailOtp: {
    method: 'otp/sendEmailOtp'
  },
  resendEmailOtp: {
    method: 'otp/resendEmailOtp'
  },
  verifyEmailOtp: {
    method: 'otp/verifyEmailOtp'
  },
  isEmailExists: {
    method: 'isEmailExists'
  },
  favoriteProperties: {
    method: 'associate/addFavoriteProperties'
  },
  favoriteProjects: {
    method: 'associate/addfavoriteProjects'
  },
  favoritePghostel: {
    method: 'associate/addfavoritePghostel'
  },


  changePassword: {
    method: 'associate/changePassword'
  },
  myTeamMembers: {
    method: 'associate/addTeamMembers'
  },
  isMobileExist: {
    method: 'associate/isMobileExists'
  },
  getTeamMembers: {
    method: 'associate/getTeamMembers'
  },
  updateProfile: {
    method: 'updateProfile'
  },
  updateAssociatePersonalDetails: {
    method: 'associate/updateAssociatedPersonalDetails'
  },
  updateAssociateAddress: {
    method: 'associate/updateAssociatedAddress'
  },
  partOfProfile:{
    method: 'associate/partOfProfile'

  },
  bankAccountDetails: {
    method: 'associate/bankAccountDetails'
  },
  panAccountDetails: {
    method: 'associate/panAccountDetails'
  },
  getAssociateProfileInfo: {
    method: 'associate/getAssociateProfileInfo'
  },
  agentProfile: {
    method: 'updateProfessionalDetails'
  },
  updateAttachments: {
    method: 'sales/updateAttachments'
  },
  
  getAllListings: {
    method: 'getAllListings'
  },
  getFavoriteProperties: {
    method: 'associate/getFavoriteProperties'
  },
  getFavoriteProjects: {
    method: 'associate/getFavouriteProjects'
  },
  getFavoriteHostels: {
    method: 'associate/getFavouriteHostels'
  },
  getLeadsForProperties: {
    method: 'associate/getLeadsForProperties'
  },
  getMainLeadsOfAssociate: {
    method: 'associate/getMainLeadsOfAssociate'
  },
  
  addListingsFavourite: {
    method: 'associate/addListingsFavourite'
  },

  getLeadsForHostels: {
    method: 'associate/getLeadsForHostels'
  },
  
  getLeadsForSiteVisitProperties:{
  method:'associate/getLeadsForSiteVisitProperties'
  },
  getLeadsForEmiProperties:{
    method:'associate/getLeadsForEmiProperties'
  },
  getLeadsForVideocall:{
    method:'associate/getLeadsForVideocall'
  },
  getLeadsForProjects:{
    method:'associate/getLeadsForProjects'
  },
  getLeadsForSiteVisitProjects:{
    method:'associate/getLeadsForSiteVisitProjects'
  },
  getLeadsForEmiProjects:{
    method:'associate/getLeadsForEmiProjects'
  },
  
  getAllListingsForPg: {
    method: 'pgHostels/getHostelsOfCustomer'
  },
  getActiveModules: {
    method: 'master/getActiveModules'
  },
  getActiveModulesForPostProperty: {
    method: 'master/getActiveModulesForPostProperty'
  },
  getActiveCategories: {
    method: 'categories/getActiveCategories'
  },
  getAllPropertiesForCategories: {
    method: 'categories/getAllPropertiesForCategories'
  },
  getCategoriesForEdit: {
    method: 'getCategoriesForEdit'
  },
  getActiveMeasurements: {
    method: 'master/getActiveMeasurements'
  },
  getDashboardCount: {
    method: 'master/getModulesCountForDashboard'
  },
  addUpdateListings: {
    method: 'addUpdateListings'
  },
  addUpdatePgListings: {
    method: 'pgHostels/addPgHostel'
  },
  getAllMainModules: {
    method: 'admin/getAllMainModules'
  },
  getProfessionalDetails: {
    method: 'getProfessionalInfo'
  },
  publishPlan: {
    method: 'publishPlan'
  },
  postPublishForAssociate: {
    method: 'associate/postPublishForAssociate'
  },
  
  sendMobileOtpForForgotPassword:{
    method: 'otp/sendMobileOtpForAssociateForgotPassword',
  },
  forgotPwdVerifyMobileOtp: {
    method: 'otp/associateForgotPwdVerifyMobileOtp'
  },
  getListingBasedOnListingId: {
    method: 'getListingBasedOnListingId'
  },
  getCustomerListingsBasedOnListingId: {
    method: 'getCustomerListingsBasedOnListingId'
  },
  getListingsBasedOnFilters: {
    method: 'getListingsBasedOnFiltersForAssociate'
  },
  loanApplication: {
    method: 'loans/addLoans'
  },
  addProjectFinance: {
    method: 'enquire/addProjectFinance'
  },
  queryForSupport: {
    method: 'associate/queryForSupport'
  },
  getSupport:{
    method:'associate/getSupport'
  },
  getAllLogsForAssociate:{
    method:'associate/getAllLogsForAssociate'
  },
  getSales:{
    method:'sales/getSales'
  },
  
  myLeads: {
    method: 'getLeadInfo'
  },
  myViewLeads: {
    method: 'getViewLeadInfo'
  },
  getAllAttribtesForWebsiteFilters: {
    method: 'categories/getAllAttribtesForWebsiteFilters'
  },
  getActiveBudgetsForFilters: {
    method: 'master/getActiveBudgetsForFilters'
  },
  addListingsToAccount: {
    method: 'addListingsToAccount'
  },
  getListingsInfo: {
    method: 'getListingsInfo'
  },

  getContactInfo: {
    method: 'getContactInfo'
  },
  getListingsBasedOnCountry: {
    method: 'getListingsBasedOnCountry'
  },
  getListingsBasedOnCountryAndCity: {
    method: 'getListingsBasedOnCountryAndCity'
  },
  getAllLatestListings: {
    method: 'master/getAllLatestListings'
  },
  getActiveVendorTypes: {
    method: 'master/getActiveVendorTypes'
  },
  customerLogin: {
    method: 'customerLogin'
  },
  associateCustomerLogin: {
    method: 'associate/associateCustomerLogin'
  },
  updatevendortype: {
    method: 'updatevendortype'
  },
  getListingsBasedOnFiltersForPagination: {
    method: 'getListingsBasedOnFiltersForPagination'
  },
  getLatestListingsBasedOnSubModules: {
    method: 'getActiveHomePageCategoriesForHomePage'
  },
  getAllPropertiesForModules: {
    method: 'master/getAllPropertiesForModules'
  },
  addBuilderCompany: {
    method: 'builder/addBuilderCompany'
  },
  addPreviousProject: {
    method: 'builder/addPreviousProject'
  },
  addOngoingProject: {
    method: 'builder/addOngoingProject'
  },
  getBuilderCompanies: {
    method: 'builder/getBuilderCompanies'
  },
  addViewPgListingsToAccount: {
    method: 'pgHostels/addPgViewListingsToAccount'
  },
  updateBuilderCompany: {
    method: 'builder/updateBuilderCompanyp'
  },
  cibilScore: {
    method: 'enquire/cibilScore'
  },
  enquiryForSolar: {
    method: 'enquire/enquiryForSolar'
  },
  enquiryForPackers: {
    method: 'enquire/enquiryForPackers'
  },
  enquiryForInteriorDesign: {
    method: 'enquire/enquiryForInteriorDesign'
  },
  enquiryForFurniture: {
    method: 'enquire/enquiryForFurniture'
  },
  
  

  enquiryForHomeAutomation: {
    method: 'enquire/enquiryForHomeAutomation'
  },
  getHomeAutomation: {
    method:'enquire/getHomeAutomation'
  },
  requestForPropertyInsurance: {
    method: 'enquire/enquiryForPropertyInsurance'
  },
  getPropertyInsurance: {
    method:'enquire/getPropertyInsurance'
  },
  
  prefabHomesAndProperties: {
    method: 'enquire/enquiryForPrefabHomesAndProperties'
  },
  getprefabHomesAndProperties: {
    method:'enquire/getPrefabHomesAndProperties'
  },
  
  enquiryForDrones: {
    method: 'enquire/enquiryForDrones'
  },
  getDrones: {
    method:'enquire/getDrones'
  },
  
  enquiryForCcTv: {
    method: 'enquire/enquiryForCcTv'
  },
  surveyForLand: {
    method: 'enquire/surveyForLand'
  },
  fencingForLand: {
    method: 'enquire/fencingForLand'
  },
  applyForLoan: {
    method: 'enquire/forLoan'
  },
  applyForCreditCard: {
    method: 'enquire/enquiryForCreditCard'
  },
  
  getCibilScore:{
    method:'enquire/getCibilRequest'
  },
 
  addProjectToAccount: {
    method: 'addProjectToAccount'
  },
  getHostelDetailsBasedOnHostelId: {
    method: 'pgHostels/getHostelDetailsBasedOnHostelId'
  },
  addUpdateProject: {
    method: 'builder/addBuilderProperty'
  },
  getCustomerProjectsBasedOnProjectId: {
    method: 'builder/getProjectBasedOnProjectId'
  },
  addViewListingsToAccount: {
    method: 'addViewListingsToAccount'
  },
  logout: {
    method: 'updateMultipleRecords'
  },
  addBuilderProperty: {
    method: 'builder/addBuilderProperty'
  },
  getBuilderProperties: {
    method: 'builder/getBuilderProperty'
  },
  getProjectBasedOnProjectId: {
    method: 'builder/getProjectBasedOnProjectId'
  },

  

  getAllProjectListings: {
    method: 'builder/getAllProjects'
  },
  myLeadsForPg: {
    method: 'pgHostels/myLeadsForPg'
  },
  myViewLeadsForPg: {
    method: 'pgHostels/myViewLeadsForPG'
  },

  addPgListingsToAccount: {
    method: 'pgHostels/addPgListingsToAccount'
  },
  getBasicAttributesBasedOnName: {
    method: 'master/getBasicAttributesBasedOnName'
  },

  getHostelsForFilters: {
    method: 'pgHostels/getHostelsForFiltersForAssociate'
  },
  getProjectsBasedOnFiltersForTest: {
    method: 'getProjectsBasedOnFiltersForTestForAssociates'
  },
  getAllPgAttributesForWebsiteFilters: {
    method: 'master/getAllPgAttributesForWebsiteFilters'
  },
  updatePaymentStatus: {
    method: 'associate/updatePaymentStatus'
  },
  updatePlan: {
    method: 'associate/updateSelectedPlan'
  },
  getSolar: {
    method:'enquire/getSolarEnquire'
  },
  getPackers: {
    method:'enquire/getPackers'
  },
  getInteriorDesign: {
    method:'enquire/getInteriorDesign'
  },
  getFurniture: {
    method:'enquire/getFurniture'
  },
  
  

  
  getCctv: {
    method:'enquire/getCctvEnquire'
  },
  getLandSurvey:{
    method:'enquire/getLandSurvey'
  },
  getLandFencing: {
    method:'enquire/getLandFencing'
  },
  getProjectFinance: {
    method:'enquire/getProjectFinance'
  },
  getLoanRequest: {
    method:'enquire/getLoanRequest'
  },
  getCreditCardRequest: {
    method:'enquire/getCreditCard'
  },
  
  fetchNearByLocations: {
    method: 'fetchNearByLocations'
  },
 
  
 

}

@Injectable({
  providedIn: 'root'
})
export class RsbService {
  private dataSubject = new Subject<any>();
  data$ = this.dataSubject.asObservable();
  private queryParamsSource = new BehaviorSubject<any>(null);
  currentParams = this.queryParamsSource.asObservable();
  private imageSource = new BehaviorSubject<string | ArrayBuffer | null>(null);
  currentImage = this.imageSource.asObservable();
  private lastLoginKey = 'lastLoginTime';

 

  private sidebarStatus = new Subject<string>();

  sidebarStatus$ = this.sidebarStatus.asObservable();

  private bottomNavOpen=new Subject<string>();
  bottomNavOpen$=this.bottomNavOpen.asObservable();

  // private assProfilePic=new Subject<string>();
  // assProfilePic$=this.assProfilePic.asObservable();


  constructor(
    private lookupModel: RsbLookupModel,
    private dataService: DataService,
    private messageService: MessageService
  ) { }


  sendSidebarMessage(message: string) {
    this.sidebarStatus.next(message); 
  }
  setBottomNavOpen(openBottomNav:string){
    this.bottomNavOpen.next(openBottomNav);
  }
  // setassProfilePic(getAssProfilePic:string){
  //   this.assProfilePic.next(getAssProfilePic);
  // }
  // setLastLoginTime(): void {
  //   const currentTime = new Date().toISOString();
  //   localStorage.setItem(this.lastLoginKey, currentTime);
  // }

  // getLastLoginTime(): string | null {
  //   return localStorage.getItem(this.lastLoginKey);
  // }
  getLastLoginTime(): string | null {
    return localStorage.getItem(this.lastLoginKey);
  }

  // Set the current time as the new last login time
  updateLoginTime(): void {
    const currentTime = new Date().toISOString();
    localStorage.setItem(this.lastLoginKey, currentTime);
  }

  setPropertyData(data: any) {
    this.dataSubject.next(data)
  }
  updateParams(params: any) {
    this.queryParamsSource.next(params);
  }
  updateImage(imageSrc: string | ArrayBuffer | null) {
    this.imageSource.next(imageSrc);
  }

  addUpdateProject(args): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().customerId;
    return this.dataService.callPostAPI(SRV_OPS.addUpdateProject, args);
  }

  getNearbyLocalities(latitude: number, longitude: number) {
    const radius = 5000; // 20 km radius
    const query = `[out:json];node(around:${radius},${latitude},${longitude})[place~"locality|neighbourhood|suburb|village|hamlet|city"];out;`;
    const url = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(query)}`;
    return this.dataService.callGetAPI(url);
  }

  getPostalCode(lat: number, lon: number) {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;
    return this.dataService.callGetAPI(url);
  }

  getProjectsBasedOnFilters(args: any): Observable<any> {

    // if (this.lookupModel.getLoggedUser() && this.lookupModel.getLoggedUser().listings.length > 0) {
    //   args.listings = this.lookupModel.getLoggedUser().listings;

    // } else if (this.lookupModel.getLoggedUser()) {
    //   args.customerId = [this.lookupModel.getLoggedUser().customerId]
    // } else {
    //   args.listings = []
    //   args.customerId = []
    // }

    return this.dataService.callPostAPI(SRV_OPS.getProjectsBasedOnFiltersForTest, args);
  }
  changePassword(args: any): Observable<any> {

    return this.dataService.callPostAPI(SRV_OPS.changePassword, args);
  }
  myTeamMembers(args: any): Observable<any> {

    return this.dataService.callPostAPI(SRV_OPS.myTeamMembers, args);
  }
  isMobileExist(customers): Observable<any> {
    const args={
      mobile:customers.mobileNumber
    }
    return this.dataService.callPostAPI(SRV_OPS.isMobileExist, args);
  }
  getTeamMembers({ }): Observable<any> {

    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    }

    return this.dataService.callPostAPI(SRV_OPS.getTeamMembers, args);
  }

  updateBuilderCompany(args): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().customerId;
    return this.dataService.callPostAPI(SRV_OPS.updateBuilderCompany, args);
  }

  sendMobileOtp(mobile: string, countryCode: string): Observable<any> {
    const args = {
      mobile: mobile,
      countryCode: countryCode,
      moduleStatus: 2
    };
    return this.dataService.callPostAPI(SRV_OPS.sendMobileOtp, args);
  }

  resendMobileOtp(mobile: string, countryCode: string): Observable<any> {
    const args = {
      mobile: mobile,
      countryCode: countryCode,
      moduleStatus: 2
    };
    return this.dataService.callPostAPI(SRV_OPS.resendMobileOtp, args);
  }
  sendMobileOtpForForgotPassword(mobile: string, countryCode: string): Observable<any> {
    const args = {
      mobile: mobile,
      countryCode: countryCode,
      moduleStatus: 2
    };
    return this.dataService.callPostAPI(SRV_OPS.sendMobileOtpForForgotPassword, args);
  }
  forgotPwdVerifyMobileOtp(otp: string, pwd: string,confirmPwd:string, dialCode?: number, mobile?: string, twoDigitCode?: string): Observable<any> {
    const args = {
      otp: otp,
      moduleStatus: 2,
      password: pwd,
      confirmPwd:confirmPwd,
      countryCode: dialCode,
      mobile: mobile,
      twoDigitCountryCode: twoDigitCode,
      
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.forgotPwdVerifyMobileOtp, args);
    
  }
  addPgListingsToAccount(args: any): Observable<any> {
    args.customerId = this.lookupModel.getCustomerId();
    return this.dataService.callPostAPI(SRV_OPS.addPgListingsToAccount, args);
  }



  getAssociateProfileInfo(): Observable<any> {

    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    }

    return this.dataService.callPostAPI(SRV_OPS.getAssociateProfileInfo, args).pipe(tap((rsp) => {
      if (rsp.statusCode === 0) {
        this.lookupModel.setLoggedUser(rsp.contents[0]);
      }
    }));


  }


  updatePlan(args: any): Observable<any> {
    args.associateId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.updatePlan, args);
  }

  updatePaymentStatus(args: any): Observable<any> {
    args.associateId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.updatePaymentStatus, args);
  }
  verifyMobileOtp(otp: string, pwd: string, dialCode?: number, mobile?: string, twoDigitCode?: string, firstName?: string, email?: string): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId,
      otp: otp,
      moduleStatus: 2,
      password: pwd,
      countryCode: dialCode,
      mobile: mobile,
      email: email,

      firstName: firstName,
      twoDigitCountryCode: twoDigitCode,
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.verifyMobileOtp, args).pipe(tap((rsp) => {
      if (rsp.statusCode === 0) {
        this.lookupModel.setLoggedUser(rsp.contents[0]);
        this.lookupModel.setToken(rsp.customerToken);
        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }
  associateCustomerVerifyMobileOtp(otp: string, pwd: string, dialCode?: number, mobile?: string, twoDigitCode?: string, firstName?: string, email?: string): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId,
      otp: otp,
      moduleStatus: 2,
      password: pwd,
      countryCode: dialCode,
      mobile: mobile,
      email: email,
      // date:date,
      firstName: firstName,
      twoDigitCountryCode: twoDigitCode,
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.associateCustomerVerifyMobileOtp, args).pipe(tap((rsp) => {
      if (rsp.statusCode === 0) {

        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }
  addListingsFavourite(customerId: any, listingId: string, date?: string,emi?:boolean): Observable<any> {
    // alert("getting Api")
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId,
      customerId: customerId,
      listingId: listingId,
      date: date,
      emi:emi,
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.addListingsFavourite, args).pipe(tap((rsp) => {
      if (rsp.statusCode === 0) {

        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }
  addInitiateVideoCall(customerId: any, listingId: string, date?: string,emi?:boolean): Observable<any> {
    // alert("getting Api")
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId,
      customerId: customerId,
      listingId: listingId,
      date: date,
      emi:emi,
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.addInitiateVideoCall, args).pipe(tap((rsp) => {
      if (rsp.statusCode === 0) {

        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }
  addProjectsFavourite(customerId: any, projectId: string, date?: string,emi?:Boolean): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId,
      customerId: customerId,
      projectId: projectId,
      date: date,
      emi:emi,
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.addProjectsFavourite, args).pipe(tap((rsp) => {
      if (rsp.statusCode === 0) {

        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }
  addPgToFavourite(customerId: any, hostelId: string, date?: string): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId,
      customerId: customerId,
      date: date,
      hostelId: hostelId,
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.addPgToFavourite, args).pipe(tap((rsp) => {
      if (rsp.statusCode === 0) {

        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }
  requestSiteVisit(listingId: string, date: string, otp: string, pwd: string, dialCode?: number, mobile?: string, twoDigitCode?: string, firstName?: string, email?: string): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId,
      listingId: listingId,
      otp: otp,
      moduleStatus: 2,
      password: pwd,
      countryCode: dialCode,
      mobile: mobile,
      email: email,
      date: date,

      firstName: firstName,
      twoDigitCountryCode: twoDigitCode,
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.requestSiteVisit, args).pipe(tap((rsp) => {
      if (rsp.statusCode === 0) {
        this.lookupModel.setLoggedUser(rsp.contents[0]);
        this.lookupModel.setToken(rsp.customerToken);
        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }
  requestSiteVisitForProjectList(projectId: string, date: string, otp: string, pwd: string, dialCode?: number, mobile?: string, twoDigitCode?: string, firstName?: string, email?: string): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId,
      projectId: projectId,
      otp: otp,
      moduleStatus: 2,
      password: pwd,
      countryCode: dialCode,
      mobile: mobile,
      email: email,
      date: date,

      firstName: firstName,
      twoDigitCountryCode: twoDigitCode,
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.requestSiteVisitForProjectList, args).pipe(tap((rsp) => {
      if (rsp.statusCode === 0) {
        this.lookupModel.setLoggedUser(rsp.contents[0]);
        this.lookupModel.setToken(rsp.customerToken);
        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }
  requestSiteVisitForPgList(hostelId: string, date: string, otp: string, pwd: string, dialCode?: number, mobile?: string, twoDigitCode?: string, firstName?: string, email?: string): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId,
      hostelId: hostelId,
      otp: otp,
      moduleStatus: 2,
      password: pwd,
      countryCode: dialCode,
      mobile: mobile,
      email: email,
      date: date,

      firstName: firstName,
      twoDigitCountryCode: twoDigitCode,
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.requestSiteVisitForPgList, args).pipe(tap((rsp) => {
      if (rsp.statusCode === 0) {
        this.lookupModel.setLoggedUser(rsp.contents[0]);
        this.lookupModel.setToken(rsp.customerToken);
        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }



  customerLogin(countryCode: Number, mobile: string, pwd: string): Observable<any> {
    const args = {
      countryCode: countryCode,
      mobile: mobile,
      password: pwd,
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.customerLogin, args).pipe(tap((rsp) => {

      if (rsp.statusCode === 0 && rsp.contents) {
        this.lookupModel.setLoggedUser(rsp.contents[0]);
        this.lookupModel.setToken(rsp.customerToken);
        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }
  associateCustomerLogin(countryCode: Number, mobile: string, pwd: string): Observable<any> {
    const args = {
      countryCode: countryCode,
      mobile: mobile,
      password: pwd,
      ip: this.lookupModel.getUuid()
    };
    return this.dataService.callPostAPI(SRV_OPS.associateCustomerLogin, args).pipe(tap((rsp) => {

      if (rsp.statusCode === 0 && rsp.contents) {
        localStorage.setItem("associateId",rsp.contents[0].associateId)
        this.lookupModel.setLoggedUser(rsp.contents[0]);
        this.lookupModel.setToken(rsp.customerToken);
        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }

  updateProfile(args: any): Observable<any> {
    args.customerId = this.lookupModel.getCustomerId();
    return this.dataService.callPostAPI(SRV_OPS.updateProfile, args);
  }
  updateAssociatePersonalDetails(args: any): Observable<any> {
    args.associateId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.updateAssociatePersonalDetails, args);
  }
  updateAssociateAddress(args: any): Observable<any> {
     args.associateId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.updateAssociateAddress, args);
  }
  partOfProfile(args:any): Observable<any>{
    args.associateId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.partOfProfile, args);
  }
  bankAccountDetails(args: any): Observable<any> {
    args.associateId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.bankAccountDetails, args);
  }
  panAccountDetails(args: any): Observable<any> {
    args.associateId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.panAccountDetails, args);
  }
  agentProfile(args: any): Observable<any> {
    args.customerId = this.lookupModel.getCustomerId();
    return this.dataService.callPostAPI(SRV_OPS.agentProfile, args);
  }
  updateAttachments(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    args.salesId= this.lookupModel.getLoggedUser().getSaleId;
    return this.dataService.callPostAPI(SRV_OPS.updateAttachments, args);
  }
  


  getUserListings(mainModule: any): Observable<any> {
    const args = {
      customerId: this.lookupModel.getLoggedUser().associateId,
      moduleId: [this.lookupModel.getModule().moduleId],
      mainModuleId: mainModule.mainModuleId
    };
    return this.dataService.callPostAPI(SRV_OPS.getAllListings, args);
  }
  getProfessionalDetails({ }): Observable<any> {
    const args = {
      customerId: this.lookupModel.getCustomerId(),
    }
    return this.dataService.callPostAPI(SRV_OPS.getProfessionalDetails, args);
  }

  getUserListingsForPg(mainModule: any): Observable<any> {
    const args = {
      customerId: this.lookupModel.getLoggedUser().AssociateId,
      moduleId: [this.lookupModel.getModule().moduleId],
      mainModuleId: mainModule.mainModuleId
    };
    return this.dataService.callPostAPI(SRV_OPS.getAllListingsForPg, args);
  }
  getAllMainModules({ }): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.getAllMainModules, {});
  }

  myLeads(mainModule?: any): Observable<any> {
    const args = {
      customerId: this.lookupModel.getLoggedUser().customerId,
      mainModuleId: mainModule?.mainModuleId
    }

    return this.dataService.callPostAPI(SRV_OPS.myLeads, args);
  }

  myLeadsForPg(mainModule?: any): Observable<any> {
    const args = {
      customerId: this.lookupModel.getLoggedUser().customerId,
      mainModuleId: mainModule?.mainModuleId
    }

    return this.dataService.callPostAPI(SRV_OPS.myLeadsForPg, args);
  }
  myViewLeads(mainModule?: any): Observable<any> {
    const args = {
      customerId: this.lookupModel.getLoggedUser().customerId,
      ainModuleId: mainModule?.mainModuleId
    }

    return this.dataService.callPostAPI(SRV_OPS.myViewLeads, args);
  }

  myViewLeadsForPg(mainModule?: any): Observable<any> {
    const args = {
      customerId: this.lookupModel.getLoggedUser().customerId,
      ainModuleId: mainModule?.mainModuleId
    }

    return this.dataService.callPostAPI(SRV_OPS.myViewLeadsForPg, args);
  }

  getLeadInfoForPg(): void {

  }
  // getLeadInfo(mainModule:any): Observable<any> {


  //   const args = {
  //     listings: this.lookupModel.getlistingId(),
  //     customerId: this.lookupModel.getCustomerId(),
  //     mainModuleId:
  //   };
  //   return this.dataService.callPostAPI(SRV_OPS.getLeadInfo, args);
  // }


  logout(): Observable<any> {
    const args = {
      ip: this.lookupModel.getUuid(),
      customerId: this.lookupModel.getLoggedUser().customerId,
      status: 0
    }

    return this.dataService.callPostAPI(SRV_OPS.logout, args);
  }
  getHostelDetailsBasedOnHostelId(args: any): Observable<any> {
    // const args = {
    //   categoryId: this.lookupModel.getCategory().categoryId ? this.lookupModel.getCategory().categoryId : "",
    //   hostelId: this.lookupModel.getSubCategory() && this.lookupModel.getSubCategory().subCategoryId ? [this.lookupModel.getSubCategory().subCategoryId] : subCategoryId,
    //   moduleId: module
    // };
    return this.dataService.callPostAPI(SRV_OPS.getHostelDetailsBasedOnHostelId, args);
  }
  addViewPgListingsToAccount(args: any): Observable<any> {
    args.customerId = this.lookupModel.getCustomerId();
    return this.dataService.callPostAPI(SRV_OPS.addViewPgListingsToAccount, args);
  }

  sendEmailOtp(email: string): Observable<any> {
    const args = {
      email: email,
      moduleStatus: 2
    };
    return this.dataService.callPostAPI(SRV_OPS.sendEmailOtp, args);
  }

  resendEmailOtp(email: string): Observable<any> {
    const args = {
      email: email,
      moduleStatus: 2
    };
    return this.dataService.callPostAPI(SRV_OPS.resendEmailOtp, args);
  }

  verifyEmailOtp(email: string, otp: string): Observable<any> {
    const args = {
      email: email,
      otp: otp,
      moduleStatus: 2
    };
    return this.dataService.callPostAPI(SRV_OPS.verifyEmailOtp, args);
  }

  isEmailExists(email: string): Observable<any> {
    const args = {
      email: email
    };
    return this.dataService.callPostAPI(SRV_OPS.isEmailExists, args);
  }

  favoriteProperties(args): Observable<any> {

    return this.dataService.callPostAPI(SRV_OPS.favoriteProperties, args);
  }
  favoriteProjects(args): Observable<any> {

    return this.dataService.callPostAPI(SRV_OPS.favoriteProjects, args);
  }
  favoritePghostel(args): Observable<any> {

    return this.dataService.callPostAPI(SRV_OPS.favoritePghostel, args);
  }


  getActiveModules(moduleId?: string, countryId?: string): Observable<any> {
    let args = {
      status: 1,
      countryId: [''],
      moduleId: ''
    };

    if (moduleId && countryId) {
      args.countryId = [countryId],
        args.moduleId = moduleId
    } else {
      args.countryId = [this.lookupModel.getCountry().codeId];
      args.moduleId = this.lookupModel.getCountry().moduleId;
    }

    return this.dataService.callPostAPI(SRV_OPS.getActiveModules, args);
  }

  getActiveModulesForPostProperty(moduleId?: string, countryId?: string, vendorTypeId?: string): Observable<any> {
    let args = {
      status: 1,
      countryId: [''],
      moduleId: '',
      vendorTypeId: ''
    };
    if (vendorTypeId) {
      args['vendorTypeId'] = vendorTypeId
    }
    if (moduleId && countryId) {
      args.countryId = [countryId],
        args.moduleId = moduleId
    } else {
      args.countryId = [this.lookupModel.getCountry().codeId];
      args.moduleId = this.lookupModel.getCountry().moduleId;
    }

    return this.dataService.callPostAPI(SRV_OPS.getActiveModulesForPostProperty, args);
  }

  getActiveCategories(moduleId:String): Observable<any> {
    const args = {
      status: 1,
      moduleId: [moduleId]
    };
    return this.dataService.callPostAPI(SRV_OPS.getActiveCategories, args);
  }

  getListingForPagination(args: any): Observable<any> {
    if (this.lookupModel.getLoggedUser() && this.lookupModel.getLoggedUser().listings.length > 0) {
      args.listings = this.lookupModel.getLoggedUser().listings;

    } else if (this.lookupModel.getLoggedUser()) {
      args.customerId = [this.lookupModel.getLoggedUser().customerId]
    } else {
      args.listings = []
      args.customerId = []
    }

    return this.dataService.callPostAPI(SRV_OPS.getListingsBasedOnFiltersForPagination, args);
  }
  getAllPropertiesForModules(moduleId: string): Observable<any> {
    const args = {
      status: 1,
      moduleId: moduleId
    };
    return this.dataService.callPostAPI(SRV_OPS.getAllPropertiesForModules, args);
  }
  getFavoriteProperties(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getFavoriteProperties, args);
  }

  getFavoriteHostels(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getFavoriteHostels, args);
  }

  getFavoriteProjects(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getFavoriteProjects, args);
  }
  getLeadsForProperties(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getLeadsForProperties, args);
  }
  getMainLeadsOfAssociate(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getMainLeadsOfAssociate, args);
  }
  getLeadsForProjects(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getLeadsForProjects, args);
  }  
  getLeadsForHostels(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getLeadsForHostels, args);
  }
  getLeadsForSiteVisitProperties(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getLeadsForSiteVisitProperties, args);
  }
  getLeadsForEmiProperties(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getLeadsForEmiProperties, args);
  }
  getLeadsForVideocall(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getLeadsForVideocall, args);
  }
  getLeadsForSiteVisitProjects(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getLeadsForSiteVisitProjects, args);
  }
  getLeadsForEmiProjects(): Observable<any> {
    const args = {
      associateId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getLeadsForEmiProjects, args);
  }
  
  
  


  getAllPropertiesForCategories(categoryId: string, subCatId: string, moduleId: string): Observable<any> {
    const args = {
      status: 1,
      categoryId: categoryId,
      subCategoryId: subCatId,
      moduleId: [moduleId]
    };
    return this.dataService.callPostAPI(SRV_OPS.getAllPropertiesForCategories, args);
  }

  getCategoriesForEdit(listingId: string, categoryId: string, subCatId: string, moduleId: string): Observable<any> {
    const args = {
      listingId: listingId,
      categoryId: categoryId,
      subCategoryId: subCatId,
      moduleId: [moduleId]
    };
    return this.dataService.callPostAPI(SRV_OPS.getCategoriesForEdit, args);
  }
  getCustomerProjectsBasedOnProjectId(args: any): Observable<any> {
    if (this.lookupModel.getCustomerId()) {
      args.customerLoginStatus = 1,
        args.customerId = this.lookupModel.getCustomerId()
    }
    return this.dataService.callPostAPI(SRV_OPS.getCustomerProjectsBasedOnProjectId, args);
  }
  addProjectToAccount(args: any): Observable<any> {
    args.customerId = this.lookupModel.getCustomerId();
    return this.dataService.callPostAPI(SRV_OPS.addProjectToAccount, args);
  }

  getActiveMeasurements(): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.getActiveMeasurements, { status: 1 });
  }
  getDashboardCount({ }): Observable<any> {
    const args = {
      customerId: this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.getDashboardCount, args);
  }

  addUpdateListings(args: any): Observable<any> {
    // args.customerId = this.lookupModel.getCustomerId();
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    // args.vendorTypeId = this.appLookupModel.getLoggedUser().vendorTypeId;
    return this.dataService.callPostAPI(SRV_OPS.addUpdateListings, args);
  }
  addUpdatePgListings(args: any): Observable<any> {
    // args.customerId = this.lookupModel.getCustomerId();
    args.customerId = this.lookupModel.getLoggedUser().associateId;

    // args.vendorTypeId = this.appLookupModel.getLoggedUser().vendorTypeId;
    return this.dataService.callPostAPI(SRV_OPS.addUpdatePgListings, args);
  }
  fetchNearByLocations(args: any): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.fetchNearByLocations, args);
  }
  cibilScore(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.cibilScore, args);
  }
  enquiryForSolar(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.enquiryForSolar, args);
  }
  enquiryForPackers(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.enquiryForPackers, args);
  }
  enquiryForInteriorDesign(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.enquiryForInteriorDesign, args);
  }
  enquiryForFurniture(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.enquiryForFurniture, args);
  }
  
  
  // 123
  enquiryForHomeAutomation(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.enquiryForHomeAutomation, args);
  }
  requestForPropertyInsurance(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.requestForPropertyInsurance, args);
  }
  prefabHomesAndProperties(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.prefabHomesAndProperties, args);
  }
  enquiryForDrones(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.enquiryForDrones, args);
  }
  
  enquiryForCcTv(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.enquiryForCcTv, args);
  }
  surveyForLand(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.surveyForLand, args);
  }
  fencingForLand(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS.fencingForLand, args);
  }
  applyForLoan(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS. applyForLoan, args);
  }
  applyForCreditCard(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().associateId;
    return this.dataService.callPostAPI(SRV_OPS. applyForCreditCard, args);
  }
 
  getCibilRequest(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getCibilScore, args);
  }
  getSupport(): Observable<any> {
    const args = {
      associateId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getSupport, args);
  }
  getAllLogsForAssociate(): Observable<any> {
    const args = {
      associateId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getAllLogsForAssociate, args);
  }
  getSales(): Observable<any> {
    const args = {
      associateId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getSales, args);
  }
  
  

  getSolar(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getSolar, args);
  }
  getPackers(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getPackers, args);
  }
  getFurniture(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getFurniture, args);
  }
  
  getInteriorDesign(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getInteriorDesign, args);
  }
  
  
   
  getHomeAutomation(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getHomeAutomation, args);
  }
  getPropertyInsurance(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getPropertyInsurance, args);
  }
  getprefabHomesAndProperties(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getprefabHomesAndProperties, args);
  }
  getDrones(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getDrones, args);
  }
  


  getCctv(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getCctv, args);
  }

  getLandSurvey(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getLandSurvey, args);
  }
  getProjectFinance(): Observable<any> {
    // alert("getting getprojectfinance"+this.getProjectFinance)
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getProjectFinance, args);

  }


  getLandFencing(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getLandFencing, args);
  }
  getLoanRequest(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getLoanRequest, args);
  }
  getCreditCardRequest(): Observable<any> {
    const args = {
      customerId:this.lookupModel.getLoggedUser().associateId
    }
    return this.dataService.callPostAPI(SRV_OPS.getCreditCardRequest, args);
  }

  publishPlan(): Observable<any> {
    // alert(this.lookupModel.getVendorProperty().listingId);
    const args = {
      status: 1,
      listingId: this.lookupModel.getVendorProperty().listingId,
      customerId : this.lookupModel.getLoggedUser().associateId
    };
    return this.dataService.callPostAPI(SRV_OPS.publishPlan, args);
  }
  postPublishForAssociate(arg): Observable<any> {
    // alert(this.lookupModel.getVendorProperty().listingId);
    const args = {
      status: 1,
      listingId: this.lookupModel.getVendorProperty().listingId,
      customerId : this.lookupModel.getLoggedUser().associateId,
      allowMarketing:arg.allowMarketing,
      commissionPercentage:arg.commissionPercentage

    };
    return this.dataService.callPostAPI(SRV_OPS.postPublishForAssociate, args);
  }

  postPgPublishForAssociate(arg): Observable<any> {
    // alert(this.lookupModel.getVendorProperty().listingId);
    const args = {
      status: 1,
      listingId: this.lookupModel.getVendorProperty().hostelId,
      customerId : this.lookupModel.getLoggedUser().associateId,
      allowMarketing:arg.allowMarketing,
      commissionPercentage:arg.commissionPercentage

    };
    return this.dataService.callPostAPI(SRV_OPS.postPublishForAssociate, args);
  }
 
  addProjectFinance(prjFinanceArgs:any): Observable<any> {
    const args = {
      ...prjFinanceArgs
    };
    return this.dataService.callPostAPI(SRV_OPS.addProjectFinance, args).pipe(tap((rsp) => {

      if (rsp.statusCode === 0 && rsp.contents) {
        this.lookupModel.setLoggedUser(rsp.contents[0]);
        this.lookupModel.setToken(rsp.customerToken);
        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }
  queryForSupport(comment: string): Observable<any> {
    const args={
      associateId:this.lookupModel.getLoggedUser().associateId,
      comment:comment
    }
    return this.dataService.callPostAPI(SRV_OPS.queryForSupport, args);
  }


  loanApplication(name: string, mobile: string, email: string, pan: string, loanamount: Number, loantype: string, customerId: string): Observable<any> {
    const args = {
      name: name,
      mobile: mobile,
      email: email,
      pan: pan,
      loanamount: loanamount,
      loantype: loantype,
      customerid: customerId,
      requestFrom: 'Owner'
    };
    return this.dataService.callPostAPI(SRV_OPS.loanApplication, args).pipe(tap((rsp) => {

      if (rsp.statusCode === 0 && rsp.contents) {
        this.lookupModel.setLoggedUser(rsp.contents[0]);
        this.lookupModel.setToken(rsp.customerToken);
        this.messageService.sendMessage(new Message(BUS_EVENTS.LOGIN, true));
      }
    }));
  }
  getListingBasedOnListingId(args: any): Observable<any> {
    if (this.lookupModel.getCustomerId()) {
      args.customerLoginStatus = 1,
        args.customerId = this.lookupModel.getCustomerId()

    }

    return this.dataService.callPostAPI(SRV_OPS.getListingBasedOnListingId, args);
  }







  addBuilderCompany(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().customerId;
    return this.dataService.callPostAPI(SRV_OPS.addBuilderCompany, args)
  }

  getBuilderCompanies(args): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().customerId;
    return this.dataService.callPostAPI(SRV_OPS.getBuilderCompanies, args)
  }

  addOngoingProject(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().customerId;
    return this.dataService.callPostAPI(SRV_OPS.addOngoingProject, args)
  }

  addPreviousProject(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().customerId;
    return this.dataService.callPostAPI(SRV_OPS.addPreviousProject, args)
  }

  getCustomerListingsBasedOnListingId(args: any): Observable<any> {
    if (this.lookupModel.getCustomerId()) {
      args.customerLoginStatus = 1,
        args.customerId = this.lookupModel.getCustomerId()
    }
    return this.dataService.callPostAPI(SRV_OPS.getCustomerListingsBasedOnListingId, args);
  }

  getListingsBasedOnFilters(args: any): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.getListingsBasedOnFilters, args);
  }

  getLatestListingsBasedOnSubModules(city: string): Observable<any> {
    let cityNew = this.lookupModel.getCurrentLocation().city ? this.lookupModel.getCurrentLocation().city : 'Hyderabad';
    let cityLat;
    let cityLong;
    if (cityNew) {
      cityLat = cityNew.split(['']);
      cityLat.forEach((obj, index) => {
        if (obj == 'ā') {
          cityLat[index] = 'a'
        }
        if (obj == 'ū') {
          cityLat[index] = 'u'
        }
      })
      cityLong = cityLat.join('');
    }

    const args = {
      city: cityLong,
      state: this.lookupModel.getCurrentLocation().region_name,
      countryId: this.lookupModel.getCountry().codeId,
      regionCity: cityLong
    };
    return this.dataService.callPostAPI(SRV_OPS.getLatestListingsBasedOnSubModules, args);
  }

  getHostelsForFilters(args: any): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.getHostelsForFilters, args);
  }

  getAllAttribtesForWebsiteFilters(): Observable<any> {
    const subCategoryId = this.lookupModel.getCategory()?.subCategory.length ? this.lookupModel.getCategory()?.subCategory.map((obj) => { return obj.subCategoryId }) : [];
    const args = {
      categoryId: this.lookupModel.getCategory().categoryId,
      subCategoryId: this.lookupModel.getSubCategory() ? this.lookupModel.getSubCategory().subCategoryId : subCategoryId,
      moduleId: [this.lookupModel.getModule().moduleId]
    };
    return this.dataService.callPostAPI(SRV_OPS.getAllAttribtesForWebsiteFilters, args);
  }

  getActiveBudgetsForFilters(moduleId?: string): Observable<any> {
    const args = {
      countryId: this.lookupModel.getCountry().codeId,
      subCategoryId: this.lookupModel.getSubCategory() ? this.lookupModel.getSubCategory().subCategoryId : '',
      moduleId: [moduleId]
    };
    return this.dataService.callPostAPI(SRV_OPS.getActiveBudgetsForFilters, args);
  }

  getAllPgAttributesForWebsiteFilters(module: any): Observable<any> {
    //const subCategoryId = this.lookupModel.getCategory()?.subCategory.length ? this.lookupModel.getCategory()?.subCategory.map((obj) => { return obj.subCategoryId }) : [];
    const args = {
      //categoryId: this.lookupModel.getCategory().categoryId ? this.lookupModel.getCategory().categoryId : "",
      //subCategoryId: this.lookupModel.getSubCategory() && this.lookupModel.getSubCategory().subCategoryId ? [this.lookupModel.getSubCategory().subCategoryId] : subCategoryId,
      moduleId: module
    };
    return this.dataService.callPostAPI(SRV_OPS.getAllPgAttributesForWebsiteFilters, args);
  }

  addListingsToAccount(args: any): Observable<any> {
    args.customerId = this.lookupModel.getCustomerId();
    return this.dataService.callPostAPI(SRV_OPS.addListingsToAccount, args);
  }

  addViewListingsToAccount(args: any): Observable<any> {
    args.customerId = this.lookupModel.getCustomerId();
    return this.dataService.callPostAPI(SRV_OPS.addViewListingsToAccount, args);
  }

  getListingsInfo(): Observable<any> {
    const args = {
      customerId: this.lookupModel.getCustomerId(),
      moduleId: this.lookupModel.getModule().moduleId
    };
    return this.dataService.callPostAPI(SRV_OPS.getListingsInfo, args);
  }



  getContactInfo(args: any): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.getContactInfo, args);
  }

  getListingsBasedOnCountry(args: any): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.getListingsBasedOnCountry, args);
  }

  getListingsBasedOnCountryAndCity(args: any): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.getListingsBasedOnCountryAndCity, args);
  }

  getAllLatestListings(): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.getAllLatestListings, {});
  }

  getActiveVendorTypes(): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.getActiveVendorTypes, {});
  }
  updateVendortype(arg: any): Observable<any> {
    const args = {
      ...arg,
      customerId: this.lookupModel.getCustomerId()

    }
    //alert("getting here 1")
    return this.dataService.callPostAPI(SRV_OPS.updatevendortype, args);
  }


  addBuilderProject(args: any): Observable<any> {
    args.customerId = this.lookupModel.getLoggedUser().customerId;
    args.companyId = localStorage.getItem('companyId');
    return this.dataService.callPostAPI(SRV_OPS.addBuilderProperty, args)
  }
  getBuilderPropertyInfo({ }): Observable<any> {
    const args = {
      customerId: this.lookupModel.getLoggedUser().customerId,
      companyId: localStorage.getItem('companyId')
    }
    return this.dataService.callPostAPI(SRV_OPS.getBuilderProperties, args)
  }



  getProjectBasedOnProjectId(args: any): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.getProjectBasedOnProjectId, args);
  }

  getAllProjectListings(status: number): Observable<any> {
    const args = {
      vendorId: this.lookupModel.getLoggedUser().customerId,
      status: status
    };
    return this.dataService.callPostAPI(SRV_OPS.getAllProjectListings, args);
  }

  getBasicAttributesBasedOnName(args: any): Observable<any> {
    return this.dataService.callPostAPI(SRV_OPS.getBasicAttributesBasedOnName, args)
  }


}
