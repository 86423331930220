import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SubcategoryService } from 'src/app/common/services/subcategory.service';
import { RsbService } from 'src/app/service/rsb.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';


@Component({
  selector: 'app-add-attributes-compnent',
  templateUrl: './add-attributes-compnent.component.html',
  styleUrls: ['./add-attributes-compnent.component.css']
})
export class AddAttributesCompnentComponent implements OnInit, OnDestroy {
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  @Output() formChange: EventEmitter<boolean> = new EventEmitter();
  propertyImage:String = '';
  propertyImages: Array<string> = [
    '',
    '',
    '',
    '',
    '',
    ''
  ];
  selectedSubCategories: any[] = [];
  existing:any = {};
  currentIndex: number = 0;
  currentSubCategory: any = null;
  selectedProperty: any;
  isFormDirty: boolean = false;
  isAddAttribute: boolean = false;
  Sharing: string = '';
  tableView: boolean = false;
  tableData: any[] = [];
  amenities:any = [];
  attributeForm: FormGroup;
  propAmenities =[];
  subscriptions: Subscription = new Subscription();
  listAmenities: Array<any> = [];
  savedSubcategories: Set<string> = new Set();
  isMobileLayout: boolean = false;
  constructor(
    private subCategoryService: SubcategoryService,
    private lookupModel: RsbLookupModel,
    private modalService: NgbModal,
    private router: Router,
    private fb: FormBuilder,
    private rsbService: RsbService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.listAmenities = this.lookupModel.getAmenities();
    this.selectedProperty = this.lookupModel.getVendorProperty() ? this.lookupModel.getVendorProperty() : null;
    this.tableData = this.selectedProperty.attributes && this.selectedProperty.attributes.length ? this.selectedProperty.attributes : [];
    this.tableView = this.tableData && this.tableData.length ? true : false;
    
    this.initForm();
    this.loadSubCategories();
    if (this.tableData.length !== this.selectedSubCategories.length) {
    this.updateSharingType();
    this.populateForm();
    }
    
    this.selectedProperty = this.lookupModel.getVendorProperty() ? this.lookupModel.getVendorProperty() : null;
    if(this.selectedProperty && this.selectedProperty.images) {
      this.propertyImages = this.selectedProperty.images;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

  }

  changeAmenities(amenities): void {
    const propertyId = amenities.propertyId;
    const index = this.amenities.indexOf(propertyId);
    if (index > -1) {
      this.amenities.splice(index, 1);
    } else {  
      this.amenities.push(propertyId);
    }
  }

  uploadAws(event: any, index: number): void {
    const mdlRef = this.modalService.open(ImageCropperComponent, { windowClass: 'signInForm'});
    mdlRef.componentInstance.imageChangedEvent = event;
    mdlRef.result.then((cropResp: {image: any, markDefault: boolean}) => {
      if(cropResp && cropResp.image) {
        const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
        modalRef.componentInstance.file = cropResp.image;
        modalRef.result.then((path: string) => {
          if (path) {
            if(this.propertyImages[index]) {
              this.propertyImages[index] = path;
            } else {
              const imageList = this.propertyImages.filter(item => item !== '');
              if(cropResp.markDefault) {
                imageList.unshift(path);
                this.selectedProperty.propertyPreviewImage = path;
              } else {
                imageList.push(path);
              }
              if(imageList.length < 6) {
                const length = 6 - imageList.length;
                for(var i=0 ; i < length; i++) {
                  imageList.push('');
                }
              }
              this.propertyImages = imageList;
            }
          }
        });
      }
    })
  }
 
  
  
  
  
  
  

  initForm(): void {
    this.attributeForm = this.fb.group({
      rooms: ['', [Validators.required]],
      monthlyRent: ['', [Validators.required]],
      securityDepPerBed: ['', [Validators.required]],
      

    });

    this.subscriptions.add(
      this.attributeForm.valueChanges.subscribe(() => {
        this.emitFormChanges(true);
      })
    );
  }

  loadSubCategories(): void {
    this.selectedSubCategories = this.subCategoryService.getSelectedSubCategories();
    console.log("selected sub categories is",this.selectedSubCategories);
    if (this.selectedSubCategories.length > 0) {
      if (this.tableData.length !== this.selectedSubCategories.length) {
      this.currentSubCategory = this.selectedSubCategories[this.currentIndex];
      }
    } else {
      console.error('No selected subcategories found.');
    }
  }

  updateSharingType(): void {
    if (!this.currentSubCategory) return;

    switch (this.currentSubCategory.Name) {
      case 'Single Room':
        this.Sharing = 'Single Room';
        break;
      case 'Twin Sharing':
        this.Sharing = 'Twin Sharing';
        break;
      case 'Triple Sharing':
        this.Sharing = 'Triple Sharing';
        break;
      case 'Quad Room':
        this.Sharing = 'Quad Room';
        break;
      default:
        console.log('No matching sharing type.');
    }
  }

  submitNextSubcategory(): void {
    this.toastr.success('Added Successfully', this.currentSubCategory.subCategoryName, {
      positionClass: 'toast-top-right'
    });
    if (!this.currentSubCategory) return;
    // alert(JSON.stringify(this.amenities));
    const subcategoryData = {
      subCategoryId: this.currentSubCategory.propertyId,
      subCategoryName: this.currentSubCategory.swatchName ? this.currentSubCategory.swatchName : this.currentSubCategory.subCategoryName ?   this.currentSubCategory.subCategoryName : '',
      last: this.currentSubCategory.last,
      handle: this.currentSubCategory.handle,
      rooms: this.attributeForm.get('rooms')?.value,
      monthlyRent: this.attributeForm.get('monthlyRent')?.value,
      securityDepPerBed: this.attributeForm.get('securityDepPerBed')?.value,
      amenities: this.amenities,
      previewImage: this.propertyImages
    };
    this.propertyImages = ['','','','',''];

    const existingIndex = this.tableData.findIndex(
      data => data.subCategoryName === this.currentSubCategory.subCategoryName
    );
    if (existingIndex > -1) {
      this.tableData[existingIndex] = subcategoryData;
    } else {
      this.tableData.push(subcategoryData);


    }
    console.log("table data is",this.tableData);
    this.savedSubcategories.add(this.currentSubCategory.subCategoryName ? this.currentSubCategory.subCategoryName : this.currentSubCategory.swatchName ? this.currentSubCategory.swatchName :'');
    this.tableView = true;
    this.currentSubCategory = null;


    this.amenities = [];
    this.propertyImage = '';
     this.propertyImages = ['','','','',''];

    
    if (this.currentIndex < this.selectedSubCategories.length - 1) {

      this.moveToNextSubCategory();
      
    } else {
      console.log('All subcategories processed.');
    }
   
    this.emitFormChanges(false);


    // if (this.savedSubcategories.size === this.selectedSubCategories.length) {
    //   this.showNextPage.emit(3);
    // }
  }

  moveToNextSubCategory(): void {
    this.currentIndex++;
    while (this.currentIndex < this.selectedSubCategories.length && this.savedSubcategories.has(this.selectedSubCategories[this.currentIndex].swatchName)) {
      this.currentIndex++;
    }
    if (this.currentIndex < this.selectedSubCategories.length) {
      this.currentSubCategory = this.selectedSubCategories[this.currentIndex];
      this.updateSharingType();
      this.populateForm();
    }
  }

  emitFormChanges(val: boolean): void {
    if (this.isFormDirty !== val) {
      this.isFormDirty = val;
      this.formChange.emit(val);
    }
  }

  openAddAttributeForm(): void {
    this.isAddAttribute = true;
  }

  viewSubCategoryForm(subcategory: any): void {
    console.log("sub catgeory is",subcategory)
    this.currentIndex = this.selectedSubCategories.findIndex(
      sc => sc.swatchName === subcategory.subCategoryName
    );
    this.currentSubCategory = { ...subcategory };
    this.updateSharingType();
    this.populateForm();
    this.isAddAttribute = true;
  }

  private populateForm(): void {
    const existingData = this.tableData.find(
      data => data.subCategoryName ===   this.currentSubCategory.subCategoryName );
    this.propertyImages =  existingData && existingData.previewImage ? existingData.previewImage : this.propertyImages ;
    this.amenities = existingData && existingData.amenities ? existingData.amenities : [] ;
    if (existingData) {
      this.attributeForm.patchValue({
        rooms: existingData.rooms,
        monthlyRent: existingData.monthlyRent,
        securityDepPerBed: existingData.securityDepPerBed,
      });
    } else if (this.currentSubCategory) {
      this.attributeForm.patchValue({
        rooms: this.currentSubCategory.rooms,
        monthlyRent: this.currentSubCategory.monthlyRent,
        securityDepPerBed: this.currentSubCategory.securityDepPerBed,
      });
    }
  }
  
  

  next(): void {
    if (this.tableData.length !== this.selectedSubCategories.length) {
    if (this.attributeForm.invalid) {
      return;
    }
  }

    const propertyInfo = this.lookupModel.getVendorProperty();
    propertyInfo.attributes = this.tableData;
    this.lookupModel.setVendorProperty(propertyInfo);
    // this.submitNextSubcategory();

    if (this.tableData.length === this.selectedSubCategories.length) {
      this.currentSubCategory=null;
      this.showNextPage.emit(3);
    }
    else {
      this.toastr.error('Please fill the all subcategories fields!  ', 'Error', {
        positionClass: 'toast-top-right'
      });

    }

  }
  back(){
    this.showNextPage.emit(2);
  }

  get rooms() { return this.attributeForm.get('rooms'); }
  get monthlyRent() { return this.attributeForm.get('monthlyRent'); }
  get securityDepPerBed() { return this.attributeForm.get('securityDepPerBed'); }
  get provides() { return this.attributeForm.get('provides'); }
}

