import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
  selector: 'app-support-help',
  templateUrl: './support-help.component.html',
  styleUrls: ['./support-help.component.css']
})
export class SupportHelpComponent implements OnInit {
  comment: string = ''; 
  getSupportData:any;
  statusForSubmitted:boolean=false;
  isMobileLayout:boolean=false;

  statusForInProgress:boolean=false;
  statusForCancel:boolean=false;
  constructor(private rsbService: RsbService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.getSupport();
  }

  onCommentChange(event: Event): void {
    const inputElement = event.target as HTMLTextAreaElement;
    this.comment = inputElement.value; 
  }
  getSupport(): void {
    this.rsbService.getSupport().subscribe((res)=>{
      if(res.statusCode === 0 && res.contents.length) {
      this.getSupportData=res.contents;
      this.statusForSubmitted=true;

      } 
      else if(res.statusCode === 1){
        this.statusForInProgress=true;
      }
      else{
        this.statusForCancel=true;

      }
    })

  }
  

  submit() {
    console.log(this.comment); 
    if (this.comment) {
      this.rsbService.queryForSupport(this.comment).subscribe((rsp) => {
        if(rsp.statusCode===0){
          this.toastr.success('submitted successfully.');

          this.getSupport();
          this.statusForSubmitted=true;

        }

        });
    } 
  }
}