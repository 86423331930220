<header>
    <nav class="navbar navbar-light fixed-top bg-danger" *ngIf="!hideHeader">
        <div class="container">
            <div id="main-nav">
                <ul class="nav">
                    <li class="head-nav">
                        <div class="d-flex align-items-center my-2">
                            <img src="{{profilePicAss}}" width="50px" alt="profile image pillarBlock"
                                class="rounded-4 p-1 bg-white me-2">
                            <h5 style="color: azure;">{{personalDetails.firstName || 'N/A'}}</h5>
                        </div>
                    </li>
                    <ng-container *ngFor="let menu of menuOptions">
                      
                            <li class="nav-item" [ngClass]="{'active': menu.active}">
                                <a class="nav-link" (click)="openMenuItem(menu)">
                                    <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                                    <span
                                        [ngStyle]="{'color': menu.label === 'Nexus AddOn' && menu.active ? 'red' : 'white'}"
                                        *ngIf="menu.label === 'Nexus AddOn'; else otherMenu"
                                        (click)="collapse()">
                                    
                                        {{menu.label}}
                                    </span>
                                    <ng-template #otherMenu>
                                        <span 
                                          [ngClass]="{'hover-black': true}" 
                                          [ngStyle]="{'color': menu.active ? 'black' : 'white'}"  (click)="closeCollapse()">
                                          {{menu.label}}
                                        </span>
                                      </ng-template>
                                      <span *ngIf="menu.label === 'Nexus AddOn'">
                                        <!-- <i [ngClass]="{'icon-chevron-down': !isNexusAddonOpen, 'icon-chevron-up': isNexusAddonOpen}" (click)="collapse()"></i> -->
                                        <i [ngClass]="{'icon-chevron-down': !isNexusAddonOpen, 'icon-chevron-up': isNexusAddonOpen}" (click)="collapse()"></i>
  
                                    </span>
                                </a>
                          
                                <ul *ngIf="subMenuLabelColl">
                            
                                    <li *ngFor="let submenu of menu.submenuList" class="nav-item" [ngClass]="{'active': submenu.active}"  id="submenuItems">
                                        <a class="nav-link" style="background-color: #007bff;color: #ffffffcc;"  (click)="openSubmenuItem(menu, submenu)">
                                          <img style="width: 25px; height: auto;" [src]="submenu.sideSubMenuicon" alt="">&nbsp;
                                          {{submenu.label}}
                                        </a>
                                      </li>
                                </ul>
                            </li>
                    </ng-container>
               
             
                </ul>
                <ul class="bottom-nav">
                    <li class="notification-icon">
                        <a href="#">
                            <i class="ri-notification-2-line"></i>
                        </a>
                    </li>
                    <li class="email-icon">
                        <a href="#">
                            <i class="ri-question-line"></i>
                        </a>
                    </li>
                    <li class="setting-icon">
                        <a href="#">
                            <i class="ri-settings-3-line"></i>
                        </a>
                    </li>

                </ul>
            </div>

            <!-- <a class="toggle toggle hc-nav-trigger hc-nav-1" (click)="toggleNav()">
                <span></span>
            </a>
            <div class="brand-logo"><a href="javascript:;"><img src="assets/img/pillarblock_nexus.jpg" style="max-width:250px;"
                        alt="PillarBlock"></a></div> -->
                        <div class="d-flex justify-content-between align-items-center">
                            <a class="toggle toggle hc-nav-trigger hc-nav-1 me-auto" (click)="toggleNav()">
                                <span></span>
                            </a>
                            <div class="brand-logo ms-auto">
                                <a href="javascript:;">
                                    <img src="assets/img/pillarblock_nexus.jpg" class="img-fluid" alt="PillarBlock">
                                </a>
                            </div>
                        </div>
                        
                        
        </div>
    </nav>
       <header *ngIf="hideHeader">
        <div class="container-fluid bg-white fixed-top">
          <div class="d-flex align-items-center justify-content-between py-3">
              <img src="assets/img/icon/nexus-logo.png" alt="Pillar Blocks Logo">
              <div>
                  <i class="bi bi-bell-fill me-3"></i>
                  <i class="bi bi-question-circle"></i>
              </div>
          </div>
          </div>
    </header>
    <nav class="hc-offcanvas-nav hc-nav-1 nav-levels-overlap nav-position-left disable-body is-ios touch-device" [class.nav-open]="navSideBar">
        <div class="nav-container">
     <div class="nav-wrapper nav-wrapper-0">
        <div class="nav-content">
            <!-- <button type="button" (click)="closeNav()">&times;</button> -->
            <!-- <span (click)="closeNav()" style="float: right;"><img src="assets/mobile/img/closeicon.png" style="width: 25px;height: auto;"></span> -->
                  <!-- <img src="assets/mobile/img/closeicon.png" (click)="closeNav()" style="width: 25px; height: auto;"> -->
                  <!-- <div style="position: relative;">
                    <span class="hide" style="position: absolute; right: 0;">
                      <img src="assets/mobile/img/closeicon.png" style="width: 36px; height: auto;" (click)="closeNav()">
                    </span>
                  </div> -->
                  
                  <i class="ri-close-large-line"
                  (click)="onClose(); $event.stopPropagation()"
                  style="position: absolute; top: 10px; right: 10px; cursor: pointer; font-size: 20px; color: white; z-index: 9999;">
               </i>
               
               
                                
              
                  <ul class="nav">
                    <li class="head-nav">
                        <div class="d-flex align-items-center my-2">
                            <img [src]="lookupModel.getLoggedUser().personalDetails.profilePic 
                            ? lookupModel.getLoggedUser().personalDetails.profilePic 
                            : ''" width="50px" alt="profile image pillarBlock"
                                class="rounded-4 p-1 bg-white me-2" (click)="goToMyprofile()">
                            <h5 style="color:azure">{{personalDetails.firstName || 'N/A'}}</h5>
                        </div>
                    </li>
                    <ng-container *ngFor="let menu of menuOptions">
                      
                        <li class="nav-item" [ngClass]="{'active': menu.active}">
                            <a class="nav-link nav-link-hover" (click)="openMenuItem(menu)">
                                <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                                <span
                                    [ngStyle]="{'color': menu.label === 'Nexus Ad-On' && menu.active ? 'white' : 'white'}"
                                    *ngIf="menu.label === 'Nexus Ad-On'; else otherMenu"
                                    (click)="collapse()">
                                    {{ menu.label.split(' ')[0] }} <span class="addon">{{ menu.label.split(' ')[1] }}</span>
                                </span>
                                <ng-template #otherMenu>
                                    <span
                                      [ngClass]="{'hover-black': true}" 
                                      [ngStyle]="{  'color': menu.active ? 'black' : 'white','background-color': menu.active ? 'white' : 'transparent','box-shadow': menu.active ? '0 10px 12px rgba(0, 0, 0, 0.8)' : 'none','padding': '5px','border-radius': '4px'}"  (click)="closeCollapse()">
                                      {{menu.label}}
                                    </span>
                                  </ng-template>
                                  <span *ngIf="menu.label === 'Nexus AddOn'">
                                    <!-- <i [ngClass]="{'icon-chevron-down': -isNexusAddonOpen, 'icon-chevron-up': isNexusAddonOpen}" (click)="collapse()"></i> -->
                                    <i [ngClass]="{'icon-chevron-down': !isNexusAddonOpen, 'icon-chevron-up': isNexusAddonOpen}" (click)="collapse()"></i>
                                </span>
                            </a>
                      
                            <ul *ngIf="subMenuLabelColl">
                        
                                <li *ngFor="let submenu of menu.submenuList" class="nav-item" [ngClass]="{'active': submenu.active}"
                                  id="submenuItems">
                                    <a class="nav-link" style="background-color: #007bff;color: #ffffffcc;"  (click)="openSubmenuItem(menu, submenu)">
                                      <img style="width: 25px; height: auto;" [src]="submenu.sideSubMenuicon" alt="">
                                      <span [ngStyle]="{  'color': submenu.active ? 'black' : 'white','background-color': submenu.active ? 'white' : 'transparent','box-shadow': submenu.active ? '0 10px 12px rgba(0, 0, 0, 0.8)' : 'none','font-weight':'bold','padding': '5px','border-radius': '4px'}" >&nbsp;

                                      {{submenu.label}}
                                      </span>
                                    </a>
                                  </li>
                            </ul>
                        </li>
                </ng-container>
               
             
                </ul>
            <ul class="bottom-nav">
                <li class="notification-icon"><a href="#" class="nav-item">
                        <i class="ri-notification-2-line"></i>
                    </a></li>
                <li class="email-icon"><a href="#" class="nav-item">
                        <i class="ri-question-line"></i>
                    </a></li>
                <li class="setting-icon"><a href="#" class="nav-item">
                        <i class="ri-settings-3-line"></i>
                    </a></li>
            </ul>
        </div>
    </div>
</div>
    </nav>
</header>